import { toast } from "react-toastify";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { getApiService } from "../../../services/API-services/GetService";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getAdminUserListRequest, getAdminUserResponse } from "../../AdminUsers/User-service/UserModalResponse";
import { Dispatch } from "redux";
import { getCustomerData, getTotalCustomerData } from "../../../redux/Actions/CustomerAction/CustomerAction";

let errorShow = false;

/**
 * Get customer list service
 * @param customerListRequest 
 * @param token 
 * @param dispatch 
 * @param total 
 * @returns 
 */
export const getCustomerListService = (customerListRequest: getAdminUserListRequest, token: string, dispatch: Dispatch, total?: any) => {
  return new Promise<getAdminUserResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (customerListRequest.skip !== undefined && customerListRequest.skip !== null) {
        queryParams += `skip=${customerListRequest.skip}&`;
      }
      if (customerListRequest.take !== undefined && customerListRequest.take !== null) {
        queryParams += `take=${customerListRequest.take}&`;
      }
      if (customerListRequest.searchUsername !== undefined && customerListRequest.searchUsername !== null) {
        queryParams += `searchUsername=${customerListRequest.searchUsername}&`;
      }
      if (customerListRequest.search !== undefined && customerListRequest.search !== null) {
        queryParams += `search=${customerListRequest.search}&`
      }
      if (customerListRequest.search_username !== undefined && customerListRequest.search_username !== null) {
        queryParams += `search_username=${customerListRequest.search_username}&`
      }
      if (customerListRequest.search_phone_number !== undefined && customerListRequest.search_phone_number !== null) {
        queryParams += `search_phone_number=${customerListRequest.search_phone_number}&`
      }
      if (customerListRequest.search_first_name !== undefined && customerListRequest.search_first_name !== null) {
        queryParams += `search_first_name=${customerListRequest.search_first_name}&`
      }
      if (customerListRequest.search_last_name !== undefined && customerListRequest.search_last_name !== null) {
        queryParams += `search_last_name=${customerListRequest.search_last_name}&`
      }
      if (customerListRequest.search_email !== undefined && customerListRequest.search_email !== null) {
        queryParams += `search_email=${customerListRequest.search_email}&`
      }
      if (customerListRequest.search_age !== undefined && customerListRequest.search_age !== null) {
        queryParams += `search_age=${customerListRequest.search_age}&`
      }
      if (customerListRequest.search_is_active !== undefined && customerListRequest.search_is_active !== null) {
        queryParams += `search_is_active=${customerListRequest.search_is_active}&`
      }
      if (customerListRequest.p_IsDeleted !== undefined && customerListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${customerListRequest.p_IsDeleted}&`
      }
      if (customerListRequest.search_dob !== undefined && customerListRequest.search_dob !== null) {
        queryParams += `search_dob=${customerListRequest.search_dob}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.CUSTOMER + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
            dispatch(getTotalCustomerData(response));
        }
        if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getCustomerData(response));
        }
        else {
          dispatch(getCustomerData(response));
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Customer permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  })
}