import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UtilsData from "../../../Utils/Utils";
import { useNavigate } from "react-router-dom";
import { getAllStockHistoryService } from "./StockHistoryServices/StockHistoryService";
import {
  clearStockHistoryPageData,
  getStockHistoryPageData,
} from "../../../redux/Actions/StockHistoryAction/StockHistoryPageAction";
import { clearStockHistoryFilterData } from "../../../redux/Actions/StockHistoryAction/StockHistoryFilterAction";
import { clearStockHistoryData } from "../../../redux/Actions/StockHistoryAction/StockHistoryAction";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import { FaFilter } from "react-icons/fa";
import CustomPagination from "../../../components/Pagination/Pagination";
import CustomTable from "../../../components/Table/Table";
import Button from "../../../components/Button/Button";
import Filter from "../../../components/Filter/Filter";
import "../StockHistoryPage.js/StockHistory.scss";
import {
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../../services/Regular-services/ConvertUnitService";
import { modifyString } from "../../../services/Regular-services/StringService";
import { format } from "date-fns";

const StocksHistory = () => {
  const stockHistoryData = useSelector((state) => state.stockHistory);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const stockHistoryPage = useSelector(
    (state) => state.stockHistoryPage?.items
  );
  const stockHistoryFilter = useSelector((state) => state.stockHistoryFilter);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [stockHistoryActionData, setStockHistoryActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "stock"
      );
      setStockHistoryActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    value: true,
    unit: true,
    orderType: true,
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Value",
      selector: (row) => row.value,
    },
    {
      name: "Unit",
      selector: (row) => row.unit,
    },

    {
      name: "Order Type",
      selector: (row) => row.orderType,
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.Status,
    // },
    // {
    //   name: "Actions",
    //   cell: (row) => <Toggle checked={row.toggle} />,
    // },
  ];

  useEffect(() => {
    setCurrentPage(stockHistoryPage);
  }, [stockHistoryPage]);

  /**
   * Fetch inventory item data
   */
  const fetchStockHistory = () => {
    const requestBody = {
      p_skip:
        stockHistoryPage > 1
          ? (stockHistoryPage - 2) * 10
          : (stockHistoryPage - 1) * 10,
      p_take: 10,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (
      stockHistoryFilter.ingredientId !== "" &&
      stockHistoryFilter.ingredientId !== null
    ) {
      requestBody.p_ingredient_id = stockHistoryFilter.ingredientId;
    }
    if (
      stockHistoryFilter.orderDate !== "" &&
      stockHistoryFilter.orderDate !== null
    ) {
      requestBody.p_created_date = stockHistoryFilter.orderDate;
    }
    // if (vendorFilter.status !== "" && vendorFilter.status !== null) {
    //   requestBody.p_status = vendorFilter.status;
    // }
    // if (vendorFilter.payment !== "" && vendorFilter.payment !== null) {
    //   requestBody.p_payment = vendorFilter.payment;
    // }
    // return;
    getAllStockHistoryService(requestBody, tokenData, dispatch);
    if (stockHistoryPage > 1) {
      setCurrentPage(stockHistoryPage - 1); // Update the current page state
      dispatch(getStockHistoryPageData(stockHistoryPage - 1));
    }
  };

  useEffect(() => {
    if (
      stockHistoryData &&
      stockHistoryData.items &&
      stockHistoryData.items.length === 0
    ) {
      fetchStockHistory();
    } else {
      if (
        stockHistoryFilter?.ingredientId !== "" &&
        stockHistoryFilter?.orderDate !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (
      stockHistoryData.items[0]?.data &&
      stockHistoryData.items[0].data.length > 0
    ) {
      const transformedStockHistoryData = stockHistoryData.items[0].data.map(
        (item) => {
          // Extract and convert item value and unit
          let value = parseFloat(item.value);
          let unit = item.unit;
          if (value >= 0) {
            if (
              unit === "gm" ||
              unit === "kg" ||
              unit === "l" ||
              unit === "ml" ||
              unit === "mg"
            ) {
              if (unit === "gm" && value > 999) {
                value = GramToKg(value);
                unit = "kg";
              } else if (unit === "gm" && value > 0 && value < 1) {
                value = GramToMg(value);
                unit = "mg";
              } else if (unit === "ml" && value > 999) {
                value = MlToLitre(value);
                unit = "l";
              }
            }
          } else {
            if (
              unit === "gm" ||
              unit === "kg" ||
              unit === "l" ||
              unit === "ml" ||
              unit === "mg"
            ) {
              if (unit === "gm" && value < -999) {
                value = GramToKg(value);
                unit = "kg";
              } else if (unit === "gm" && value < 0 && value > -1) {
                value = GramToMg(value);
                unit = "mg";
              } else if (unit === "ml" && value < -999) {
                value = MlToLitre(value);
                unit = "l";
              }
            }
          }

          return {
            id: item.ingredient_id,
            name: item.ingredient_name,
            value: parseFloat(value) > 0 ? "+" + value : value,
            unit: unit,
            orderType: item.type,
            stockId: item.stockId,
            stockType: item.type,
            timeZone: item.created_at,
          };
        }
      );

      setTableData(transformedStockHistoryData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [stockHistoryData, stockHistoryFilter]);

  const styleData = {
    value: { type: { textAlign: "end" } },
  };

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (
        stockHistoryFilter.ingredientId !== "" &&
        stockHistoryFilter.ingredientId !== null
      ) {
        requestBody.p_ingredient_id = stockHistoryFilter.ingredientId;
      }
      if (
        stockHistoryFilter.orderDate !== "" &&
        stockHistoryFilter.orderDate !== null
      ) {
        const date = new Date(stockHistoryFilter.orderDate);
        // Formatting the date and time parts
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(date.getDate()).padStart(2, "0");

        // Combining them into the desired format
        const dateTimeFormat = `${year}-${month}-${day}`;

        requestBody.p_created_date = dateTimeFormat;
      }

      //return;
      getAllStockHistoryService(requestBody, tokenData, dispatch);
      dispatch(getStockHistoryPageData(pageNumber));
    }
  };
  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearStockHistoryFilterData());
    dispatch(clearStockHistoryPageData());
    dispatch(clearStockHistoryData());
    setIsToggled(false);
  };

  return (
    <>
      <div className="main-section order-new stocksHistory">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {stockHistoryActionData && stockHistoryActionData.length > 0
                      ? modifyString(stockHistoryActionData[0].name)
                      : "Stock"}{" "}
                    History
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/Stock")}>
                    {stockHistoryActionData && stockHistoryActionData.length > 0
                      ? stockHistoryActionData[0].name
                      : "Stock"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {stockHistoryActionData && stockHistoryActionData.length > 0
                      ? modifyString(stockHistoryActionData[0].name)
                      : "Stock"}{" "}
                    History
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {stockHistoryFilter?.ingredientId !== "" ||
                (stockHistoryFilter?.orderDate !== "" &&
                  stockHistoryFilter?.orderDate !==
                    format(new Date(), "yyyy-MM-dd")) ? (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {(stockHistoryFilter?.ingredientId !== "" ||
                        (stockHistoryFilter?.orderDate !== "" &&
                          stockHistoryFilter?.orderDate !==
                            format(new Date(), "yyyy-MM-dd"))) && (
                        <Dropdown.Item onClick={clearFilters}>
                          Clear Filter
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <></>
                )}
                <Button
                  data-bs-toggle="tooltip"
                  title="Filter"
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                {/* {spotData && spotData.items[0]?.data && spotData.items[0].data.length > 0 && (
                                    <ExcelData excelData={spotData.items[0].data} fileName={'image'}></ExcelData>
                                )} */}
                {/* {((stockHistoryActionData &&
                  stockHistoryActionData[0]?.add &&
                  stockHistoryActionData[0].add === "1") ||
                  !stockHistoryActionData) && (
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add Vendor"
                    onClick={() => navigate("/addvendors")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )} */}
              </div>
            </div>
            {isToggled && <Filter page="StockHistory"></Filter>}
            {stockHistoryData &&
              stockHistoryData.items[0]?.data &&
              stockHistoryData.items[0].data && (
                <>
                  <CustomTable
                    page="StockHistory"
                    showToggle={false}
                    checkBool={false}
                    showKey={showKey}
                    showActions={false}
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    disabled={
                      stockHistoryActionData &&
                      stockHistoryActionData[0]?.edit &&
                      stockHistoryActionData[0].edit === "0"
                    }
                    currentPage={currentPageNumber}
                    onPageChange={onPageChange}
                    styleData={styleData}
                  />

                  {stockHistoryData.items[0].total_records >
                    stockHistoryData.items[0].data.length &&
                    stockHistoryData.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={stockHistoryData.items[0].total_records}
                        currentPage={currentPageNumber}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default StocksHistory;
