// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand-select-section {
  background-color: #f1f1f1;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.45);
  height: 250px;
  overflow-x: auto;
  padding: 10px;
}
.brand-select-section ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}
.brand-select-section ul li {
  display: flex;
  list-style-type: none;
  margin-bottom: 10px;
  width: 50%;
}
.brand-select-section .b-check {
  display: flex;
  justify-content: center;
  margin: 0;
  width: 100%;
}

.brand-select-section::-webkit-scrollbar {
  width: 10px !important;
  border-radius: 5px;
}
.brand-select-section::-webkit-scrollbar-track {
  background-color: #DEE2E6 !important;
}
.brand-select-section::-webkit-scrollbar-thumb {
  background: #1D3B42 !important;
  border-radius: 5px;
}
.brand-select-section {
  scrollbar-width: thin;
  scrollbar-color: #1D3B42 #DEE2E6;
}`, "",{"version":3,"sources":["webpack://./src/pages/DiscountCatalogue/AddDiscountCatalogue/AddDiscountCatalogue.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;EACA,kBAAA;EACA,+BAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;AAAJ;AACI;EACI,UAAA;EACA,SAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;EACA,aAAA;AACR;AAAQ;EACI,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,UAAA;AAEZ;AACI;EACI,aAAA;EACA,uBAAA;EACA,SAAA;EACA,WAAA;AACR;;AAGI;EACI,sBAAA;EACA,kBAAA;AAAR;AAEI;EACI,oCAAA;AAAR;AAEI;EACI,8BAAA;EACA,kBAAA;AAAR;AAGI;EACI,qBAAA;EACA,gCAAA;AADR","sourcesContent":["@import '../../../variables';\n.brand-select-section{\n    background-color: #f1f1f1;\n    border-radius: 5px;\n    box-shadow: rgba(0, 0, 0, 0.45);\n    height: 250px;\n    overflow-x: auto;\n    padding: 10px;\n    ul{\n        padding: 0 ;\n        margin: 0;\n        display: flex;\n        justify-content: space-between;\n        flex-wrap: wrap;\n        padding: 10px;\n        li{\n            display: flex;\n            list-style-type: none;\n            margin-bottom: 10px;\n            width:50%;\n        }\n    }\n    .b-check {\n        display: flex;\n        justify-content: center;\n        margin: 0;\n        width: 100%;\n    }\n}\n.brand-select-section{\n    &::-webkit-scrollbar{\n        width: 10px !important;\n        border-radius: 5px;\n    }\n    &::-webkit-scrollbar-track{\n        background-color:$border-color !important;\n    }\n    &::-webkit-scrollbar-thumb{\n        background: $main-color !important;\n        border-radius: 5px;\n    }\n\n    & {\n        scrollbar-width: thin;\n        scrollbar-color:  $main-color $border-color;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
