const ServicePageInitialState = { 
    items: 1
};

/**
 * Store service page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const ServicePageReducer = (state = ServicePageInitialState, action) => {
    switch (action.type) {
        case 'GET_SERVICE_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_SERVICE_PAGE':
            return ServicePageInitialState;
        default:
            return state;
      };
}