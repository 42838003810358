import { BiAnalyse } from "react-icons/bi";
import { FaCartPlus, FaFlagCheckered, FaShoppingCart, FaStarHalfAlt } from "react-icons/fa";
import { FaFileLines } from "react-icons/fa6";
import { MdGroups, MdLiveTv, MdOutlineInventory, MdOutlineManageAccounts } from "react-icons/md";
import { GoOrganization } from "react-icons/go";
import { PiPercentFill, PiUserSwitchFill } from "react-icons/pi";

export const getIcon = (iconString) => {
    let match;
    if(iconString){
      match = iconString.match(/<([A-Za-z]+)([A-Za-z0-9]+) \/>/);
    }
    if (match && match[1] && match[2]) {
      const library = match[1].substring(0, 2).toLowerCase();
      const iconName = match[1]+match[2];
      let iconComponent = null;
      try {
        switch (iconString) {
          case "<MdGroups />":
            return <MdGroups />;
          case "<FaShoppingCart />":
            return <FaShoppingCart />;
          case "<FaCartPlus />":
            return <FaCartPlus />;
          case "<BiAnalyse />":
            return <BiAnalyse />;
          case "<PiUserSwitchFill />":
            return <PiUserSwitchFill />;
          case "<PiPercentFill />":
            return <PiPercentFill />;
          case "<FaFileLines />":
            return <FaFileLines />
          case "<FaFlagCheckered />":
            return <FaFlagCheckered />;
          case "<FaStarHalfAlt />":
            return <FaStarHalfAlt />;
          case "<MdLiveTv />":
            return<MdLiveTv />;
          case "<MdOutlineInventory />":
            return <MdOutlineInventory />
          case "<MdOutlineManageAccounts />":
            return <MdOutlineManageAccounts />
          case "<GoOrganization />":
            return <GoOrganization />
          default:
            console.error(`Unknown icon library: ${library}`);
            return iconString
        }
      } catch (error) {
        console.error(`Error loading ${library} icon: ${iconName}`);
      }
      return iconComponent;
    } 
  }