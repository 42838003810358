export const policyAdd = ({id, name, type, isActive, date, description, isDelete}) => ({
    type: 'ADD_POLICY_DATA',
    payload: {id, name, type, date, isActive, description, isDelete}
})

export const policyUpdate = ({id, name, type, isActive, date, description, isDelete}) => ({
    type: 'UPDATE_POLICY_DATA',
    payload: {id, name, type, date, isActive, description, isDelete}
})

export const policyRemoveItem = (id) => ({
    type: 'DELETE_POLICY_DATA',
    payload: id
  });