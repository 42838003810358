const discountPageInitialState = { 
    items: 1
};

/**
 * Store dicount page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const DiscountPageReducer = (state = discountPageInitialState, action) => {
    switch (action.type) {
        case 'GET_DISCOUNT_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_DISCOUNT_PAGE':
            return discountPageInitialState;
        default:
            return state;
      };
}