import React, { useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import Button from '../../components/Button/Button'
import CustomTable from '../../components/Table/Table'
import Footer from '../../components/Footer/Footer'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter/Filter'
import { FaFilter } from 'react-icons/fa'
import { Dropdown } from 'react-bootstrap'
import { GrMoreVertical } from 'react-icons/gr'
import ExcelData from '../../services/Regular-services/ExcelExport'
import Accordion from 'react-bootstrap/Accordion';
import { MdDeleteOutline, MdAdd, MdRemove } from 'react-icons/md';
import "../Country/Country.scss"

const columns = [
    {
      name: 'Name',
      selector: row => row.Name,
      sortable: true,
    },
    {
      name: 'Short name',
      selector: row => row.Short,
      sortable: true,
    },
    {
      name: 'Currency',
      selector: row => row.Currency,
    },
    
  ];

  const statecolumns = [
    {
      name: 'States Name',
      selector: row => row.Name,
    },
    
  ];
  
  const data = [
    {
      id: 1,
      name: 'India',
      Short: 'IND',
      Currency: 'Rupees',
    }
  ]
  const statedata = [
    {
      id: 1,
      name: 'Gujarat',
    },
    {
      id: 2,
      name: 'Andhra Pradesh',
    },
    {
      id: 3,
      name: 'Assam',
    },
    {
      id: 4,
      name: 'Goa',
    }
    
  ]
  
  //Show actions which is to be displayed in table

  const showKey = {
    name: true,
    Short: true,
    Currency: true,
  }
  //Show toggle which is to be displayed in table
  const showToggle = {
    status: false,
    delete: false
  }
  
  //Handle toggle change
  // const handleToggleChange = (id) => {
  // };
export default function Country() {

     //Getting data from reducer
  const navigate = useNavigate();

  const [isToggled, setIsToggled] = useState(false);

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  const [expandedItem, setExpandedItem] = useState(null);

  const toggleAccordionItem = (eventKey) => {
    setExpandedItem((prevExpandedItem) =>
      prevExpandedItem === eventKey ? null : eventKey
    );
  };

  return (
    <>
        <div className='main-section'>
        <div className='sidebar-content'>
          <Sidebar />
        </div>
        <div className='body-content'>
          <Header />
          <div className='table-body-content'>
            <div className='roles'>
              <div>
                <div className='title'>
                  <h4>Country</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Country</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className='btn-groups'>
                <Dropdown className='header-dropdown action-btn' >
                  <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item >Delete</Dropdown.Item>
                    <Dropdown.Item >Active</Dropdown.Item>
                    <Dropdown.Item >Inactive</Dropdown.Item>
                    <Dropdown.Item >Undo</Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
                <Button onClick={handleButtonClick} className="btn btn-fillter-main" name={<div className="filter">
                  <FaFilter className="filter-icon" />
                </div>}></Button>
                {data && data.length > 0 && (
                  <ExcelData excelData={data} fileName={'product'}></ExcelData>
                )}
                {/* <Button onClick={() => navigate('/add-country')} className="btn btn-fillter-main" name={<div className="Plus">
                  <FaPlus />
                </div>}></Button> */}
              </div>
            </div>
            {isToggled &&
              <Filter page='product'></Filter>}
            <Accordion activeKey={expandedItem} onSelect={toggleAccordionItem}>
              <Accordion.Item eventKey="0">
                <Accordion.Header className='p-relative'>
                  <div className='product-detail'>
                    <div className='product-name country'>
                      India 
                    </div>
                  </div>
                  <div className='delete-icon'>
                    <MdDeleteOutline />
                  </div>
                  {expandedItem === "0" ? (
                    <MdRemove className='arrow-icon' />
                  ) : (
                    <MdAdd className='arrow-icon' />
                  )}
                </Accordion.Header>
                <Accordion.Body>
                    <div className='row'>
                        <div className="col-md-6">
                            <CustomTable page='product' showToggle={showToggle} showKey={showKey}  showTooltip={true} data={data} heading={columns} checkBool={false} />
                        </div>
                        <div className="col-md-6">
                            <CustomTable page='product' showToggle={showToggle} showKey={showKey}  showTooltip={true} data={statedata} heading={statecolumns} checkBool={false} />
                        </div>
                    </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
