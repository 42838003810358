import React, { useEffect, useRef, useState } from "react";
import "./Table.scss";
import Table from "react-bootstrap/Table";
import Toggle from "../Toggle/Toggle";
import { FaEye, FaPlus, FaStarHalfAlt } from "react-icons/fa";
import Checkbox from '../../components/Checkbox/Checkbox'
import { useNavigate } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import { handledeletClick, handleLineLevelOrderConfirmationClick, handleOrderConfirmationClick, handleToggleChange, handleUndoDelete } from "../Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import { policyUpdate } from "../../redux/Actions/policyAction/PolicyAction";
import { formatDate } from "../../services/Regular-services/DateService";
import 'react-datepicker/dist/react-datepicker.css';
import { blogUpdate } from "../../redux/Actions/blogAction/BlogAction";
import { faqUpdate } from "../../redux/Actions/faqAction/FaqAction";
import UtilsData, { API_URL, ValidationMessage, imageUrlToFile } from "../../Utils/Utils";
import CustomPagination from "../Pagination/Pagination";
import { GrUndo } from "react-icons/gr";
import { getCheckboxData } from "../../redux/Actions/CheckboxAction/CheckboxAction";
import { toast } from "react-toastify";
import ErrorImage from './../../assets/images/placeholder.png'
import { updateSequenceService } from "../../services/Sequence-service/SequenceService";
import { FcCheckmark } from "react-icons/fc";
import { RxCross2 } from "react-icons/rx";
import { placeOrderService } from "../../pages/Order/OrderService/OrderService";
import { IoMdPrint } from "react-icons/io";
// import jsPDF from "jspdf";
// import 'jspdf-autotable';

export default function CustomTable(props) {
  /**
   * Getting page data
   */
  const categoryPageData = useSelector(state => state.categoryPage?.items);
  const SubCategoryPage = useSelector(state => state.SubCategoryPage?.items);
  const imagePage = useSelector(state => state.imagePage?.items);
  const rolePage = useSelector(state => state.rolePage?.items);
  const companyPage = useSelector(state => state.companyPage?.items)
  const adminPage = useSelector(state => state.adminPage?.items);
  const leafPage = useSelector(state => state.leafPage?.items);
  const productPage = useSelector(state => state.productPage?.items);
  const tagPage = useSelector(state => state.tagPage?.items);
  const brandPage = useSelector(state => state.brandPage?.items);
  const attributePage = useSelector(state => state.attributePage?.items);
  const warehousePage = useSelector(state => state.warehousePage?.items);
  const packagerPage = useSelector(state => state.packagerPage?.items);
  const discountPage = useSelector(state => state.discountPage?.items);
  const discountCataloguePage = useSelector(state => state.discountCataloguePage?.items);
  const branchPage = useSelector(state => state.branchPage?.items);
  const servicePage = useSelector(state => state.servicePage?.items);

  const categoryFilter = useSelector(state => state.categoryFilter);
  const SubCategoryFilter = useSelector(state => state.SubCategoryFilter);
  const ServiceProviderPage = useSelector(state => state.ServiceProviderPage?.items);
  const orderPage = useSelector(state => state.orderPage?.items);

  const tooltipRef = useRef(null);

  const userData = useSelector(state => state.auth);
  const navigate = useNavigate();

  const [selectAllValue, setSelectAllValue] = useState([]);

  const dispatch = useDispatch();
  const [disableArray, setDisableArray] = useState([]);

  const [sequenceArray, setSequenceArray] = useState([]);

  const [paymentSequenceArray, setPaymentSequenceArray] = useState([]);

  const [selectSequenceData, setSelectSequenceData] = useState(false);
  const [selectPaymentSequenceData, setPaymentSelectSequenceData] = useState(false);

  const loader = useSelector(state => state.loader?.items);

  const user = useSelector(state => state.auth?.user);
  const tokenData = UtilsData();

  const [currentPage, setCurrentPage] = useState(1); // Assuming initial page is 1
  const totalRecords = 10; // Assuming there are 100 records

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Update the current page state
  };

  //Adding Tooltip
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  //Handling Navigation
  const handleNavigate = (route, data, isEdit) => {
    const Data = {
      data: data,
      isEdit: isEdit,
      length: props.page === 'product' ? props.productLength : tableData.length
    }
    if (props.page === 'imageManager') {
      props.handleToggle(data, tableData.length);
    }
    else {
      navigate(route, { state: { Data } })
    }
  }

  let { data, heading, showId, showDescription } = props;
  const [tableData, setTableData] = useState([]);

  //Setting data on initialization
  const [imageErrors, setImageErrors] = useState([]);
  const [notificationImageErrors, setNotificationImageErrors] = useState([]);
  useEffect(() => {
    if (data && data?.items) {
      setTableData(data.items);
    }
    else {
      setTableData(data);
    }
  }, [data]);

  /**
   * Function for showing static image if image is broken
   * @param {*} index 
   */
  const handleImageError = (index) => {
    setImageErrors(prevErrors => {
      const newErrors = [...prevErrors, index];
      return newErrors;
    });
  };

  /**
   * Function for showing static image if image is broken
   * @param {*} index 
   */
  const handleNotificationImageError = (index) => {
    setNotificationImageErrors(prevErrors => {
      const newErrors = [...prevErrors, index];
      return newErrors;
    });
  };



  // Handling checkbox for uploading image
  useEffect(() => {
    if (props.page === 'uploadImage') {
      if (props.uploadImageValue && props.uploadImageValue.length > 0) {
        if (data.items) {
          const updateIndex = [];
          data.items.filter((item, index) => {
            return props.uploadImageValue.some((product) => {
              if (product.imageId === item.imageId) {
                updateIndex.push(index);
              }
              return product.imageId === item.imageId;
            });
          });
          setSelectedItems(updateIndex);
        }
        else {
          const updateIndex = [];
          data.filter((item, index) => {
            return props.uploadImageValue.some(product => {
              if (product.imageId === item.imageId) {
                updateIndex.push(index);
              }
              return product.imageId === item.imageId;
            });
          });
          setSelectedItems(updateIndex);
        }
      }
    }
  }, [data, props.page, props.uploadImageValue])

  //Disable other toggle keys if value of isAllow toggle key is false
  useEffect(() => {
    if (props.page === 'addRoles' || props.page === 'add-company') {
      let disableId = [];
      let selectValue = []
      if (data && data?.items) {
        data.items.filter((allow) => {
          if (allow.allow === false || allow.allow === 0 || allow.allow === '0') {
            disableId = [...disableId, allow.name]
            return true;
          }
          else {
            if (
              (allow.allow === 1 || allow.allow === true || allow.allow === '1') &&
              (allow.add === 1 || allow.add === true || allow.add === '1') &&
              (allow.edit === 1 || allow.edit === true || allow.edit === '1') &&
              (allow.view === 1 || allow.view === true || allow.view === '1') &&
              (allow.delete === 1 || allow.delete === true || allow.delete === '1')
            ) {
              selectValue = [...selectValue, allow.name];
            }
            return false;
          }
        })
      }
      else {
        data?.filter((allow) => {
          if (allow.allow === false || allow.allow === 0 || allow.allow === '0') {
            disableId = [...disableId, allow.name]
            return true;
          }
          else {
            if (
              (allow.allow === 1 || allow.allow === true || allow.allow === '1') &&
              (allow.add === 1 || allow.add === true || allow.add === '1') &&
              (allow.edit === 1 || allow.edit === true || allow.edit === '1') &&
              (allow.view === 1 || allow.view === true || allow.view === '1') &&
              (allow.delete === 1 || allow.delete === true || allow.delete === '1')
            ) {
              selectValue = [...selectValue, allow.name];
            }
            return false;
          }
        })
      }
      setSelectAllValue(selectValue);
      setDisableArray(disableId)
    }
  }, [props.page, data]);


  //Handling Toggle
  const handleToggle = (itemData, itemType, checked) => {
    const currentDate = new Date();
    const itemId = itemData.id;
    const formattedDate = formatDate(currentDate);
    const updatedData = tableData && tableData.map((item) => {
      if (item.id && item.id === itemId) {
        let updatedItem = item; //Data of element which we are toggling
        if (itemType !== "isActive" && itemType !== "toggle" && itemType !== "status" && itemType !== 'deleteItem') {
          updatedItem[itemType + "Toggle"] = !updatedItem[itemType + "Toggle"];
          return updatedItem
        }
        else {
          //If page = policies
          if (props.page && (props.page === 'Policies' || props.page === 'Policy' || props.page === 'policy')) {

            if (itemType === 'status') {
              handleToggleChange(dispatch,
                policyUpdate({ id: updatedItem.id, name: updatedItem.name, type: updatedItem.type, isActive: !updatedItem.isActive, date: formattedDate, description: updatedItem.description, isDelete: updatedItem.isDelete }), !updatedItem.isActive)
            }
          }

          //If page = category
          if (props.page && (props.page === 'category' || props.page === 'categories' || props.page === 'Category' || props.page === 'Categories')) {

            if (itemType === 'status') {
              handleToggleChange(dispatch, 'category', checked, tokenData, userData.user.data.userId, updatedItem, tableData.length, categoryPageData);
            }
          }

          //If page = sub-category 
          if (props.page === 'subCategory' || props.page === 'Subcategory' || props.page === 'subcategory' || props.page === 'SubCategory') {
            if (itemType === 'status') {
              handleToggleChange(dispatch, 'subCategory', checked, tokenData, userData.user.data.userId, updatedItem, tableData.length, SubCategoryPage);
            }
          }

          //If page = leaf-level-category
          if (props.page === 'leaf' || props.page === 'leafCategory') {
            if (itemType === 'status') {
              handleToggleChange(dispatch, 'leaf', checked, tokenData, userData.user.data.userId, updatedItem, tableData.length, leafPage);
            }
          }

          //If page = product
          if (props.page === 'product' || props.page === 'Product') {
            handleToggleChange(dispatch, 'product', checked, tokenData, user.data.userId, updatedItem, props.productLength, productPage);
          }
          // changes by sakshi
          if (props.page === 'company') {
            handleToggleChange(dispatch, 'company', checked, tokenData, user.data.userId, updatedItem, props.data.length, companyPage);
          }

          //If page = blog
          if (props.page === 'blog') {
            if (itemType === 'status') {
              handleToggleChange(dispatch, blogUpdate({ id: updatedItem.id, name: updatedItem.name, author: updatedItem.author, date: updatedItem.date, category: updatedItem.category, tags: updatedItem.tags, isActive: !updatedItem.isActive, isDelete: updatedItem.isDelete, restData: updatedItem.restData }), !updatedItem.isActive)
            }
          }
          //If page = faq
          if (props.page === 'faq') {
            if (itemType === 'status') {
              handleToggleChange(dispatch, faqUpdate({ id: updatedItem.id, question: updatedItem.question, answer: updatedItem.answer, category: updatedItem.category, tags: updatedItem.tags, date: updatedItem.date, isActive: !updatedItem.isActive, isDelete: updatedItem.isDelete }), !updatedItem.isActive)
            }
          }
          // If page = roles
          if (props.page === 'roles' || props.page === 'role') {
            if (itemType === 'status') {
              handleToggleChange(dispatch, 'roles', checked, tokenData, user.data.userId, updatedItem, tableData.length, rolePage);
            }
          }
          //If page = tags
          if (props.page === 'tag' || props.page === 'tags') {
            if (itemType === 'status') {
              const tagRequestBody = {
                p_id: updatedItem.tagId,
                p_title: updatedItem.title,
                p_isActive: updatedItem.isActive === '1' ? '0' : '1',
                p_IsDeleted: updatedItem.isDeleted,
                p_createdBy: updatedItem.createdBy,
                p_modifiedBy: userData.user.data.userId
              }
              handleToggleChange(dispatch, 'tags', checked, tokenData, userData.user.data.userId, tagRequestBody, tableData.length, tagPage);
            }
          }
          if (props.page === 'customer') {
            if (itemType === 'status') {
              return { ...item, [itemType]: !item[itemType] };
            }
          }
        }
      }
      else {
        if (item.name && item.name === itemData) {
          let updatedItem = item;
          //If page = add-roles 
          if (props.page === 'addRoles' || props.page === 'add-company') {
            if (itemType === "selectAll") {
              setSelectAllValue(checked === true ? [...selectAllValue, itemData] : selectAllValue.filter(id => id !== itemData))
              const togglesToUpdate = ["allow", "delete", "add", "view", "edit", "selectAll"];
              togglesToUpdate.forEach(toggleKey => {
                updatedItem[toggleKey] = checked ? 1 : 0;
              });
              if (checked === false) {
                setDisableArray([...disableArray, itemData]);
              }
              else {
                const mapId = disableArray.filter(id => id === itemData);
                if (mapId && mapId.length > 0) {
                  setDisableArray(disableArray.filter(id => id !== itemData));
                }
              }
            }
            else {
              updatedItem[itemType] = checked ? 1 : 0;
              if (checked === false) {
                setSelectAllValue(selectAllValue.filter(id => id !== itemData))
                if (itemType === 'allow') {
                  const togglesToUpdate = ["allow", "delete", "add", "view", "edit"];
                  togglesToUpdate.forEach(toggleKey => {
                    updatedItem[toggleKey] = checked ? 1 : 0;
                  });
                  setDisableArray([...disableArray, itemData]);
                }
              }
              else {
                if (itemType === 'allow') {
                  const mapId = disableArray.filter(id => id === itemData);
                  if (mapId && mapId.length > 0) {
                    setDisableArray(disableArray.filter(id => id !== itemData));
                  }
                }
              }
            }
            return updatedItem
          }
        }
        if (props.page === 'tag' || props.page === 'tags') {
          if (itemType === 'status') {
            const tagRequestBody = {
              p_id: itemData.tagId,
              p_title: itemData.title,
              p_isActive: itemData.isActive === '1' ? '0' : '1',
              p_IsDeleted: itemData.isDeleted,
              p_createdBy: itemData.createdBy,
              p_modifiedBy: userData.user.data.userId
            }
            handleToggleChange(dispatch, 'tags', checked, tokenData, userData.user.data.userId, tagRequestBody, tableData.length, tagPage);
          }
        }
        if (props.page && (props.page === 'brand' || props.page === 'Brand')) {
          if (itemType === 'status') {
            handleToggleChange(dispatch, 'brand', checked, tokenData, userData.user.data.userId, itemData, tableData.length, brandPage);
          }
        }
        if (props.page === 'product' || props.page === 'Product') {
          handleToggleChange(dispatch, 'product', checked, tokenData, user.data.userId, itemData, props.productLength, productPage);
        }
      }

      if (props.page === 'users') {
        if (itemType === 'status') {
          handleToggleChange(dispatch, 'users', checked, tokenData, user.data.userId, itemData, tableData.length, adminPage);
        }
      }
      if (props.page === 'attributes' || props.page === 'attribute') {
        const requestBody = {
          p_id: itemData.attributeId,
          p_title: itemData.title,
          p_isActive: itemData.isActive === '1' ? '0' : '1',
          p_IsDeleted: itemData.isDeleted,
          p_createdBy: itemData.createdBy,
          p_modifiedBy: user.data.userId
        }
        handleToggleChange(dispatch, 'attributes', checked, tokenData, user.data.userId, requestBody, tableData.length, attributePage);
      }
      else if (props.page === 'warehouse') {
        const requestBody = {
          p_id: itemData.warehouseId,
          p_name: itemData.name,
          p_address: itemData.address,
          p_pincode: itemData.pincode,
          p_isActive: itemData.is_active === '1' ? '0' : '1',
          p_IsDeleted: itemData.IsDeleted,
          p_modified_by: user.data.userId
        }
        handleToggleChange(dispatch, 'warehouse', checked, tokenData, user.data.userId, requestBody, tableData.length, warehousePage);
      }
      else if (props.page === 'packager') {
        const requestBody = {
          p_id: itemData.productPackagerId,
          p_name: itemData.name,
          p_address: itemData.address,
          p_type: itemData.type,
          p_pincode: itemData.pincode,
          p_isActive: itemData.is_active === '1' ? '0' : '1',
          p_IsDeleted: itemData.IsDeleted,
          p_modified_by: user.data.userId
        }
        handleToggleChange(dispatch, 'packager', checked, tokenData, user.data.userId, requestBody, tableData.length, packagerPage);
      }
      else if (props.page === 'discount') {
        handleToggleChange(dispatch, 'discount', checked, tokenData, user.data.userId, itemData, tableData.length, discountPage);
      }
      else if (props.page === 'discountCatalogue') {
        handleToggleChange(dispatch, 'discountCatalogue', checked, tokenData, user.data.userId, itemData, tableData.length, discountCataloguePage);
      }
      else if (props.page === 'branch') {
        const requestBody = {
          p_id: itemData.branchId,
          p_name: itemData.name,
          p_address: itemData.address,
          p_pincode: itemData.pincode,
          p_isActive: itemData.isActive === '1' ? '0' : '1',
          p_IsDeleted: itemData.isDeleted,
          p_created_by: itemData.createdBy,
          p_modified_by: user.data.userId
        }
        handleToggleChange(dispatch, 'branch', checked, tokenData, user.data.userId, requestBody, tableData.length, branchPage);
      }
      else if (props.page === 'serviceProvider') {
        handleToggleChange(dispatch, 'serviceProvider', checked, tokenData, user.data.userId, itemData, tableData.length, ServiceProviderPage)
      }
      else if (props.page === 'service') {
        handleToggleChange(dispatch, 'service', checked, tokenData, user.data.userId, itemData, tableData.length, servicePage)
      }
      return item;
    });
    setTableData(updatedData);
  };

  /**
   * Function for undo the deleted data
   */
  const handleUndoClick = (item) => {
    if (props.page === 'roles' || props.page === 'role') {
      handleUndoDelete(dispatch, 'roles', item, tokenData, user.data.userId, tableData.length, rolePage);
    }
    else if (props.page === 'users') {
      handleUndoDelete(dispatch, 'users', item, tokenData, user.data.userId, tableData.length, adminPage);
    }
    else if (props.page === 'category' || props.page === 'categories') {
      handleUndoDelete(dispatch, 'category', item, tokenData, user.data.userId, tableData.length, categoryPageData);
    }
    else if (props.page === 'subCategory') {
      handleUndoDelete(dispatch, 'subCategory', item, tokenData, user.data.userId, tableData.length, SubCategoryPage);
    }
    else if (props.page === 'leafCategory') {
      handleUndoDelete(dispatch, 'leafCategory', item, tokenData, user.data.userId, tableData.length, leafPage);
    }
    else if (props.page === 'tag' || props.page === 'tags') {
      handleUndoDelete(dispatch, 'tags', item, tokenData, user.data.userId, tableData.length, tagPage);
    }
    else if (props.page === 'imageManager' || props.page === 'imagemanager') {
      let data = item;
      imageUrlToFile(API_URL.TOKEN_URL + item.imagePath)
        .then(file => {
          data.imagePath = [file];
          handleUndoDelete(dispatch, 'imageManager', data, tokenData, user.data.userId, tableData.length, imagePage);
        })
        .catch(error => {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        });
    }
    else if (props.page === 'brand' || props.page === 'Brand') {
      handleUndoDelete(dispatch, 'brand', item, tokenData, user.data.userId, tableData.length, brandPage);
    }
    else if (props.page === 'product' || props.page === 'Product') {
      handleUndoDelete(dispatch, 'product', item, tokenData, user.data.userId, props.productLength, productPage);
    }
    else if (props.page === 'attributes' || props.page === 'attribute') {
      handleUndoDelete(dispatch, 'attributes', item, tokenData, user.data.userId, tableData.length, attributePage);
    }
    else if (props.page === 'warehouse') {
      handleUndoDelete(dispatch, 'warehouse', item, tokenData, user.data.userId, tableData.length, warehousePage);
    }
    else if (props.page === 'packager') {
      handleUndoDelete(dispatch, 'packager', item, tokenData, user.data.userId, tableData.length, packagerPage);
    }
    else if (props.page === 'discount') {
      handleUndoDelete(dispatch, 'discount', item, tokenData, user.data.userId, tableData.length, discountPage)
    }
    else if (props.page === 'discountCatalogue') {
      handleUndoDelete(dispatch, 'discountCatalogue', item, tokenData, user.data.userId, tableData.length, discountCataloguePage)
    }
    else if (props.page === 'branch') {
      handleUndoDelete(dispatch, 'branch', item, tokenData, user.data.userId, tableData.length, branchPage)
    }
    else if (props.page === 'serviceProvider') {
      handleUndoDelete(dispatch, 'serviceProvider', item, tokenData, user.data.userId, tableData.length, ServiceProviderPage)
    }
    else if (props.page === 'service') {
      handleUndoDelete(dispatch, 'service', item, tokenData, user.data.userId, tableData.length, servicePage)
    }
  }

  /**
   * Function for undo the deleted data
   */
  const handleDeleteClick = (item) => {
    if (props.page === 'roles' || props.page === 'role') {
      handledeletClick(item, dispatch, 'roles', tokenData, tableData.length, rolePage);
    }
    else if (props.page === 'company') {
      // console.log(item);
      handledeletClick(item, dispatch, 'company', tokenData, tableData.length, companyPage);
    }
    else if (props.page === 'users') {
      handledeletClick(item, dispatch, 'users', tokenData, tableData.length, adminPage);
    }
    else if (props.page === 'category' || props.page === 'categories') {
      handledeletClick(item, dispatch, 'category', tokenData, tableData.length, categoryPageData);
    }
    else if (props.page === 'subCategory') {
      handledeletClick(item, dispatch, 'subCategory', tokenData, tableData.length, SubCategoryPage);
    }
    else if (props.page === 'leafCategory') {
      handledeletClick(item, dispatch, 'leafCategory', tokenData, tableData.length, leafPage);
    }
    else if (props.page === 'tag' || props.page === 'tags') {
      handledeletClick(item, dispatch, 'tags', tokenData, tableData.length, tagPage);
    }
    else if (props.page === 'imageManager' || props.page === 'imagemanager') {
      handledeletClick(item, dispatch, 'imageManager', tokenData, tableData.length, imagePage);
    }
    else if (props.page === 'brand' || props.page === 'Brand') {
      handledeletClick(item, dispatch, 'brand', tokenData, tableData.length, brandPage);
    }
    else if (props.page === 'product' || props.page === 'Product') {
      handledeletClick(item, dispatch, 'product', tokenData, props.productLength, productPage);
    }
    else if (props.page === 'attributes' || props.page === 'attribute') {
      handledeletClick(item, dispatch, 'attributes', tokenData, tableData.length, attributePage);
    }
    else if (props.page === 'warehouse') {
      handledeletClick(item, dispatch, 'warehouse', tokenData, tableData.length, warehousePage);
    }
    else if (props.page === 'packager') {
      handledeletClick(item, dispatch, 'packager', tokenData, tableData.length, packagerPage);
    }
    else if (props.page === 'discount') {
      handledeletClick(item, dispatch, 'discount', tokenData, tableData.length, discountPage);
    }
    else if (props.page === 'discountCatalogue') {
      handledeletClick(item, dispatch, 'discountCatalogue', tokenData, tableData.length, discountCataloguePage);
    }
    else if (props.page === 'branch') {
      handledeletClick(item, dispatch, 'branch', tokenData, tableData.length, branchPage);
    }
    else if (props.page === 'serviceProvider') {
      handledeletClick(item, dispatch, 'serviceProvider', tokenData, tableData.length, ServiceProviderPage);
    }
    else if (props.page === 'service') {
      handledeletClick(item, dispatch, 'service', tokenData, tableData.length, servicePage)
    }
    else if (props.page === 'order') {
      handledeletClick(item, dispatch, 'order', tokenData, tableData.length, orderPage);
    }
  }

  // const initialValues = {};
  // for (let i = 0; i < data.length; i++) {
  //   initialValues[`check${i}`] = false; // Set your initial value here
  // }

  // const generatePDF = (orderData) => {
  //   const doc = new jsPDF();

  //   const tableColumn = ["Order No.", "Name", "Table", "Products"];
  //   const tableRows = [];

  //     const productDetails = orderData.productData.map(product => 
  //       `${product.quantity} x ${product.productName}`
  //     ).join('\n');

  //     const rowData = [
  //       orderData.orderNumber,
  //       orderData.customerName,
  //       orderData.tableNo,
  //       productDetails
  //     ];

  //     tableRows.push(rowData);

  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 20,
  //     margin: { top: 20, right: 10, bottom: 10, left: 10 },
  //     styles: { fontSize: 10, cellPadding: 2 },
  //     columnStyles: {
  //       0: { cellWidth: 52 },
  //       1: { cellWidth: 30 },
  //       2: { cellWidth: 20 },
  //       3: { cellWidth: 50 },
  //     },
  //     didDrawPage: (data) => {
  //       doc.text("Order Details", 14, 15);
  //     },
  //   });

  //   doc.save("order_details.pdf");
  // };

  // const printOrderDetails = (orderData) => {
  //   // Create a new window for printing
  //   const printWindow = window.open('', '', 'height=600,width=800');

  //   // Define the HTML structure for the table
  //   const htmlContent = `
  //     <html>
  //     <head>
  //       <title>Order Details</title>
  //       <style>
  //         table {
  //           width: 100%;
  //           border-collapse: collapse;
  //         }
  //         table, th, td {
  //           border: 1px solid black;
  //         }
  //         th, td {
  //           padding: 8px;
  //           text-align: left;
  //         }
  //         th {
  //           background-color: #f2f2f2;
  //         }
  //       </style>
  //     </head>
  //     <body>
  //       <h1>Order Details</h1>
  //       <table>
  //         <thead>
  //           <tr>
  //             <th>Order No.</th>
  //             <th>Name</th>
  //             <th>Table</th>
  //             <th>Products</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           <tr>
  //             <td>${orderData.orderNumber}</td>
  //             <td>${orderData.customerName}</td>
  //             <td>${orderData.tableNo}</td>
  //             <td>${orderData.productData.map(product => `${product.quantity} x ${product.productName}`).join('<br>')}</td>
  //           </tr>
  //         </tbody>
  //       </table>
  //     </body>
  //     </html>
  //   `;

  //   // Write the HTML content to the new window
  //   printWindow.document.open();
  //   printWindow.document.write(htmlContent);
  //   printWindow.document.close();

  //   // Trigger the print dialog
  //   printWindow.print();
  // };


  // const printOrderDetails = (orderData) => {
  //   // Create a new window for printing
  //   const printWindow = window.open('', '', 'height=600,width=800');

  //   // Define the HTML structure for the table with in_CSS
  //   const htmlContent = `
  //     <html>
  //     <head>
  //       <title>Order Details</title>
  //       <style>
  //         body {
  //           font-family: Arial, sans-serif;
  //           margin: 0;
  //           padding: 0;
  //           width: 104mm;
  //         }
  //         h1 {
  //           text-align: center;
  //           font-size: 16px;
  //           margin-top: 10px;
  //         }
  //         p {
  //           margin: 5px 0;
  //           font-size: 12px;
  //         }
  //         .order-details {
  //           margin: 20px;
  //           text-align: left;
  //         }
  //         table {
  //           width: 100%;
  //           border-collapse: collapse;
  //           margin-top: 20px;
  //           font-size: 12px;
  //         }
  //         th, td {
  //           padding: 5px;
  //           text-align: left;
  //           border-top: 1px solid black;
  //           border-bottom: 1px solid black;
  //         }
  //         .footer {
  //           margin-top: 20px;
  //           text-align: center;
  //           font-size: 12px;
  //         }
  //         .download {
  //           display: flex;
  //           padding: 0 10px;
  //           justify-content: flex-end;
  //         }
  //         .bar {
  //           background-color: #your-main-color; /* Replace with your main color */
  //           color: white;
  //           font-size: 23px;
  //           border-radius: 50%;
  //           width: 45px;
  //           height: 45px;
  //           display: flex;
  //           align-items: center;
  //           justify-content: center;
  //           margin-left: auto;
  //         }
  //       </style>
  //     </head>
  //     <body>
  //       <h1>Order Details</h1>
  //       <div class="order-details">
  //         <p>Order No. - <strong>${orderData?.orderNumber}</strong></p>
  //         <p>Dine In: ${orderData.type === '0' ? 'Yes' : 'No'}</p>
  //         <p>Table Number: ${orderData.type === '0' ? orderData.tableNo : '—'}</p>
  //         <p>Cashier: ${orderData?.brand_name}</p>
  //         <table>
  //           <tbody>
  //             <tr>
  //               <td>${orderData.productData.map(product => `${product.quantity} x ${product.productName}`).join('<br>')}</td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>
  //       <div class="footer">
  //         <p>Thanks for visiting!</p>
  //       </div>
  //       <div class="download">
  //         <div class="bar">
  //           <!-- Your icon or content here -->
  //         </div>
  //       </div>
  //     </body>
  //     </html>
  //   `;

  //   // Write the HTML content to the new window
  //   printWindow.document.open();
  //   printWindow.document.write(htmlContent);
  //   printWindow.document.close();

  //   // Trigger the print dialog
  //   printWindow.print();
  // };  

  const printOrderDetails = (orderData) => {
    // Create a new window for printing
    const printWindow = window.open('', '', 'height=600,width=800');
    // Define the HTML structure for the table with inline CSS
    const htmlContent = `
      <html>
      <head>
        <title>Order Details</title>
      </head>
      <body style="font-family: Arial, sans-serif; margin: 0 auto; padding: 0; width: 80mm; text-align: center;">
        <h1 style="text-align: center; font-size: 16px; margin-top: 10px;">Order Details</h1>
        <div style="margin: 10px; text-align: left;">
          <p style="margin: 5px 0; font-size: 12px;">Order No. - <strong>${orderData?.orderNumber}</strong></p>
          <p style="margin: 5px 0; font-size: 12px; border-top: 1px solid black;">
          <br>
            Dine In: ${orderData?.type === '0' ? 'Yes' : 'No'}<br>
            Table Number: ${orderData?.type === '0' ? orderData.tableNo : 'Parcel'}<br>
            Date: ${orderData?.createdAt ? orderData.createdAt : ''}<br>
            Cashier: ${orderData?.brand_name ? orderData.brand_name : ''}<br>
          </p>
          ${orderData?.instructions ? `<p style="font-style: arial; font-size: 12px; ;">General Instruction</p> <p style="font-style: arial; font-size: 10px;">${orderData.instructions}</p>` : ''}

             <tbody>
          ${orderData?.productData && orderData.productData.length > 0
        ? orderData.productData.map(product => `
              <tr>
               <td style=" font-size: 12px;">
  ${product.quantity} x ${product.productName} - ${product.food_type_two}<br>
  ${product?.product_instruction ? `<span class="instructions" style="font-style: italic; font-size: 12px;">Instruction: ${product.product_instruction}</span>` : ''}
  ${product?.attribute_data && product.attribute_data !== '' && product.attribute_data !== 'null' && product.attribute_data !== 'NULL'
            ? `<div class="attribute-data" style="font-style: italic; font-size: 12px; color: gray;">${JSON.parse(product.attribute_data).map(attr => `
        ${attr.cat_name}: ${attr.name} 
      `).join('<br>')}</div>`
            : ''}
</td>

              </tr>
            `).join('')
        : '<tr><td>No products available.</td></tr>'
      }
        </tbody>
        </div>
        <div style="margin-top: 10px; text-align: center; font-size: 12px;">
          <p>Thanks for visiting!</p>
        </div>
        <div style="display: flex; padding: 0 10px; justify-content: flex-end;">
          <div style="background-color: #your-main-color; color: white; font-size: 16px; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; margin-left: auto;">
            <!-- Your icon or content here -->
          </div>
        </div>
      </body>
      </html>
    `;

    // Write the HTML content to the new window
    printWindow.document.open();
    printWindow.document.write(htmlContent);
    printWindow.document.close();

    // Trigger the print dialog
    printWindow.print();
  };

  useEffect(() => {
    if (props.totalRecords && props.totalRecords !== 0) {
      const newArray = Array.from({ length: props.totalRecords }, (_, index) => ({ value: index + 1, label: String(index + 1) }));
      setSequenceArray(newArray);
      setPaymentSequenceArray(newArray)
    }
    else {
      if (tableData && tableData.length > 0) {
        const newArray = Array.from({ length: tableData.length }, (_, index) => ({ value: index + 1, label: String(index + 1) }));
        setSequenceArray(newArray);
        setPaymentSequenceArray(newArray)
      }
    }
  }, [tableData, props.totalRecords]);

  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);


  //Checkbox change on checking from header
  const handleHeaderCheckboxChange = (event) => {
    const { checked } = event.target;
    setSelectAllCheck(checked);
    const updatedSelectedItems = checked ? tableData.map((_, index) => index) : [];
    setSelectedItems(updatedSelectedItems);
  };

  //Checkbox cheange on checking from body
  const handleRowCheckboxChange = (index, event) => {
    const { checked } = event.target;
    let updatedSelectedItems = [...selectedItems];
    if (checked) {
      if (props.restrictOneImage && props.restrictOneImage === true) {
        if (updatedSelectedItems.length >= 1) {
          // If maximum limit reached, uncheck the first checked image
          updatedSelectedItems.shift();
          updatedSelectedItems.push(index);
        }
        else {
          updatedSelectedItems.push(index);
        }
      }
      else {
        if (props.restrictFive === true && updatedSelectedItems.length >= 5) {
          // If maximum limit reached, uncheck the first checked image
          toast.warn('Only 5 images are allowed!');
        }
        else {
          updatedSelectedItems.push(index);
        }
      }
    } else {
      updatedSelectedItems = updatedSelectedItems.filter((item) => item !== index);
    }
    if (props.page === 'uploadImage') {
      const filteredTableData = tableData && tableData.filter((item, index) => {
        return updatedSelectedItems && updatedSelectedItems.includes(index);
      });
      props.onCheckChange({ filterData: filteredTableData, errorImageList: imageErrors });
    }
    setSelectedItems(updatedSelectedItems);
    setSelectAllCheck(updatedSelectedItems.length === tableData.length);
    dispatch(getCheckboxData({ data: updatedSelectedItems }));
  };


  /**
   * Function for updating sequence in category and sub-category
   * @param {*} event 
   * @param {*} item 
   */

  // changes by sakshi
   /**
   * Functions for total amount val in onChange and  changeOrderPaymentStatus
   * @param {*} event 
   * @param {*} item 
   */

  // function parseCurrency(value) {
  //   return parseFloat(value.replace(/[^0-9.-]+/g, ''));
  // }
  // function formatCurrency(value) {
  //   return `₹${value.toFixed(2)}`;
  // }
  const onChange = (event, item) => {

    if (props.page === 'category') {
      const requestBody = {
        p_Id: item.id,
        p_NewSequence: parseInt(event.target.value),
        p_Type: "category"
      }
      dispatch(updateSequenceService(requestBody, tokenData, categoryPageData + 1))
    }
    if (props.page === 'subCategory') {
      const requestBody = {
        p_Id: item.id,
        p_NewSequence: parseInt(event.target.value),
        p_Type: "sub_category"
      }
      dispatch(updateSequenceService(requestBody, tokenData, SubCategoryPage + 1))
    }
    if (props.page === 'order') {

      const productData = item.productData.map((product) => ({
        product_id: Number(product.productId),
        product_size: product.size,
        brand_id: item.brand_id,
        brand_cgst: item.brand_cgst,
        brand_sgst: item.brand_sgst,
        brand_vat: item.brand_vat,
        quantity: Number(product.quantity),
        product_price: Number(product.price),
        product_instruction: product?.product_instruction && product.product_instruction !== '' ? product.product_instruction : null,
        food_type_one: product?.food_type_1 && product.food_type_1 !== '' ? product.food_type_1 : null,
        food_type_two: product?.food_type_2 && product.food_type_2 !== '' ? product.food_type_2 : null,
        attribute_data: product.attribute_data ? product.attribute_data : null,
        map_table_id: product?.map_table_id ? product.map_table_id : 0,
        purchase_price: product?.purchase_price ? product.purchase_price : 0
      }))
  
      const groupedByBrandId = productData.reduce((acc, product) => {
        const { brand_id, brand_cgst, brand_sgst, product_price, quantity, brand_vat, ...productData } = product;
        let brandEntry = acc.find((entry) => entry.brand_id.toString() === brand_id.toString());
  
        if (!brandEntry) {
          brandEntry = {
            brand_id: brand_id.toString(),
            p_total_amount: Number(item.total_amount_with_tax),
            p_total_tax_amount: Number(item.total_tax_amount),
            p_delivery_charges: item?.total_delivery_charge ? item.total_delivery_charge : 0,
            p_discount_id: item?.discount_id && item.discount_id ? item?.discount_id : 0,
            p_discount_code: item?.Discount_Code && item.Discount_Code !== "" ? item.Discount_Code : '',
            p_discount_amount: item?.total_discount_amount && item.total_discount_amount !== "" && item.total_discount_amount > 0 ? Number(item.total_discount_amount) : 0,
            product_data: []
          };
          acc.push(brandEntry);
        }
  
        brandEntry.product_data.push({ ...productData, quantity, product_price });
        return acc;
      }, []);
      const requestBody = {
        p_id: item.id,
        p_mobile_no: Number(item.phoneNumber),
        p_first_name: item.firstName,
        p_last_name: item.lastName,
        p_email: item.email,
        p_table_no: item.type === '0' ? item.tableNo : '',
        p_instruction: item.instructions,
        p_address_id: item && item.address_id ? item.address_id : null,
        p_orderData: groupedByBrandId,
        p_status: event.target.value,
        p_type: item.type,
        p_payment: item.payment,
        p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }

      let orderStatusData = event.target.value === '0' ? 'Pending' : event.target.value === '1' ? 'Confirm' : event.target.value === '2' ? 'In-progress' : event.target.value === '3' ? 'Complete' : event.target.value === '4' ? 'Cancel' : '';

      if (orderStatusData === 'Confirm' || orderStatusData === 'Cancel') {
        handleToggleChange(
          dispatch,
          'order',
          orderStatusData,
          tokenData,
          user.data.userId,
          requestBody,
          tableData.length,
          orderPage);
      }
      else {
        dispatch(placeOrderService(requestBody, tokenData, 'update', false, orderPage));
      }
    }
    setSelectSequenceData(false);
  }

  const changeOrderPaymentStatus = (event, item) => {

    const productData = item.productData.map((product) => ({
      product_id: Number(product.productId),
      product_size: product.size,
      brand_id: item.brand_id,
      brand_cgst: item.brand_cgst,
      brand_sgst: item.brand_sgst,
      brand_vat: item.brand_vat,
      quantity: Number(product.quantity),
      product_price: Number(product.price),
      product_instruction: product?.product_instruction && product.product_instruction !== '' ? product.product_instruction : null,
      food_type_one: product?.food_type_1 && product.food_type_1 !== '' ? product.food_type_1 : null,
      food_type_two: product?.food_type_2 && product.food_type_2 !== '' ? product.food_type_2 : null,
      attribute_data: product.attribute_data ? product.attribute_data : null,
      map_table_id: product?.map_table_id ? product.map_table_id : 0,
      purchase_price: product?.purchase_price ? product.purchase_price : 0
    }))

    const groupedByBrandId = productData.reduce((acc, product) => {
      const { brand_id, brand_cgst, brand_sgst, product_price, quantity, brand_vat, ...productData } = product;
      let brandEntry = acc.find((entry) => entry.brand_id.toString() === brand_id.toString());

      if (!brandEntry) {
        brandEntry = {
          brand_id: brand_id.toString(),
          p_total_amount: Number(item.total_amount_with_tax),
          p_total_tax_amount: Number(item.total_tax_amount),
          p_delivery_charges: item?.total_delivery_charge ? item.total_delivery_charge : 0,
          p_discount_id: item?.discount_id && item.discount_id ? item?.discount_id : 0,
          p_discount_code: item?.Discount_Code && item.Discount_Code !== "" ? item.Discount_Code : '',
          p_discount_amount: item?.total_discount_amount && item.total_discount_amount !== "" && item.total_discount_amount > 0 ? Number(item.total_discount_amount) : 0,
          product_data: []
        };
        acc.push(brandEntry);
      }

      brandEntry.product_data.push({ ...productData, quantity, product_price });
      return acc;
    }, []);

    const requestBody = {
      p_id: item.id,
      p_mobile_no: Number(item.phoneNumber),
      p_first_name: item.firstName,
      p_last_name: item.lastName,
      p_email: item.email,
      p_table_no: item.type === '0' ? item.tableNo : '',
      p_instruction: item.instructions,
      p_address_id: item && item.address_id ? item.address_id : null,
      p_orderData: groupedByBrandId,
      p_status: item.orderStatus,
      p_type: item.type,
      p_payment: event.target.value,
      p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    
    dispatch(placeOrderService(requestBody, tokenData, 'update', false, orderPage, true));
  }

  // handleSelectedAttributes(JSON.parse(product.attribute_data))


  const orderStatusArray = [
    {
      id: 1,
      value: '0',
      status: 'Pending'
    },
    {
      id: 2,
      value: '1',
      status: 'Confirmed'
    },
    {
      id: 3,
      value: '2',
      status: 'In Progress'
    },
    {
      id: 4,
      value: '3',
      status: 'Completed'
    },
    {
      id: 5,
      value: '4',
      status: 'Cancelled'
    }
  ]

  const orderPaymentArray = [
    {
      id: 1,
      value: '0',
      status: 'Pending'
    },
    {
      id: 2,
      value: '1',
      status: 'Paid'
    }
  ]

  return (
    <>
      <div className="py-3">
        {(!tableData || tableData.length <= 0) && loader === false && (
          <>
            <div className="input-search">
              <div className="no-record">
                {props.page === 'import' ? (
                  <h3 data-bs-toggle="tooltip" title="No records!" align='center'>Imported data might be in wrong format or too long!</h3>
                ) : (
                  <h3 data-bs-toggle="tooltip" title="No records!">No records!</h3>
                )}
              </div>
            </div>
          </>
        )}
        {tableData && tableData.length > 0 && (
          <>
            <Table className="pt-3 custom-table" bordered responsive>
              <thead>
                <tr>
                  {props.checkBool && props.page !== 'uploadImage' && props.page !== 'product' &&
                    <th style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
                      <Checkbox name='checkHead' className='table-checkbox' checked={selectAllCheck} onChange={handleHeaderCheckboxChange}></Checkbox></th>}

                  {props.checkBool && props.page === 'product' &&
                    <th style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>
                      <input type='checkbox' name='checkHead' className='table-checkbox' checked={selectAllCheck} onChange={handleHeaderCheckboxChange}></input></th>}

                  {props.checkBool && props.page === 'uploadImage' && <th style={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}></th>}
                  {heading.map((item, index) => (
                    // changes by sakshi
                    <th key={index}>{item.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData && tableData.map((item, index) => (
                  <tr key={index}>
                    {props.checkBool && props.page !== 'product' &&
                      <td key={`checkbox_${index}`}>
                        <Checkbox name={'check' + index} className='table-checkbox' checked={selectedItems.includes(index)} onChange={(event) => handleRowCheckboxChange(index, event)} />
                      </td>}
                    {props.checkBool && props.page === 'product' &&
                      <td key={`product_checkbox_${index}`}>
                        <input type="checkbox" name={'check' + index} className='table-checkbox' checked={selectedItems.includes(index)} onChange={(event) => handleRowCheckboxChange(index, event)} />
                      </td>}
                    {Object.keys(item).map((key, subIndex) => {
                      if (
                        key === "allow" || key === "add" || key === "view" || key === 'edit' || key === 'delete'
                      ) {
                        return (
                          <td key={subIndex} style={{ width: "120px", minWidth: "120px", maxWidth: "120px" }} data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? (item[key] === true ? 'active' : 'inactive') : undefined}>
                            <Toggle disabled={(props.disabled === true && (props.page === 'addRoles' || props.page === 'add-company'))
                              || (disableArray && disableArray.includes && disableArray.includes(item.name)
                                && key !== 'allow' && key !== 'selectAll')
                            }
                              value={item[key] === '1' ? 1 : (item[key] === '0' ? 0 : item[key])}
                              onChange={(e) =>
                                handleToggle(item.name, key, e.target.checked)
                              }
                            />
                          </td>
                        );
                      }
                      else if (key === "banner" || key === "imageUrl") {
                        return (
                          <td key={subIndex}>
                            {imageErrors.includes(item.imageId) ? (
                              <img src={ErrorImage} alt="broken_image" />
                            ) : (
                              <img src={item.banner || URL.createObjectURL(item.imageUrl)} alt="banner" onError={() => handleImageError(item.imageId)} />
                            )}
                          </td>
                        );
                      }
                      else if ((key === 'image' || key === 'imagePath') && props.showImage) {
                        if (item.image && item.image.length > 0 && item.image[0].imageUrl) {
                          return (
                            <td key={subIndex}>
                              {imageErrors.includes(item.image[index].imageId) ? (
                                <img src={ErrorImage} alt="broken_image" />
                              ) : (
                                <img src={URL.createObjectURL(item.image[0].imageUrl)} alt="data" onError={() => handleImageError(item.image[index].imageId)} />
                              )}
                            </td>
                          );
                        }
                        else if (item.imagePath) {
                          return (
                            <td key={subIndex}>
                              {imageErrors.includes(item.imageId) ? (
                                <img src={ErrorImage} alt="broken_image" />
                              ) : (
                                <img src={API_URL.TOKEN_URL + item.imagePath} alt="data" onError={() => handleImageError(item.imageId)} />
                              )}
                            </td>
                          );
                        }
                        else {
                          return (
                            <td key={subIndex}>
                              {/* <img alt="data" /> */}
                              <p>No image to display</p>
                            </td>
                          );
                        }
                      }
                      else if (key === 'description' || key === 'question' || key === 'answer' || key === 'Description') {
                        if (showDescription) {
                          const isError = item?.message && item.message !== 'Done' ? true : false
                          return <td key={subIndex} className={isError ? 'error-cell' : undefined}>{item[key] && typeof item[key] === 'string' ? new DOMParser().parseFromString(item[key], 'text/html').body.innerText : item[key]}</td>
                        }
                        else {
                          return null;
                        }
                      }
                      else if (key === "id" || key === "parent_id") {
                        if (showId) {
                          const isError = item?.message && item.message !== 'Done' ? true : false;
                          return <td key={subIndex} className={isError ? 'error-cell' : undefined}>{item[key]}</td>
                        }
                        return null;
                      }
                      else if (props.showKey[key]) {
                        const displayValue = Array.isArray(item[key]) ? item[key].join(', ') : item[key];
                        const isError = item?.message && item.message !== 'Done' ? true : false;

                        //If key = sequence
                        if (key === 'sequence') {
                          const currentSelectedValue = sequenceArray && sequenceArray.length > 0 ?
                            (sequenceArray.find((option) => option.value === item.sequence)?.value || item.sequence) :
                            (item.sequence); //Current Index
                          if (selectSequenceData !== currentSelectedValue) {
                            return (
                              //Sequence dropdown open on click
                              <>
                                {((props.page === 'category' && categoryFilter.isDelete === '1') || (props.page === 'subCategory' && SubCategoryFilter.isDelete === '1')) ? (

                                  <td key={item.sequence} data-bs-toggle={(props.showTooltip && key !== 'actions') ? 'tooltip' : undefined} title={(props.showTooltip && key !== 'actions') ? displayValue : undefined}>
                                    <p>
                                      {currentSelectedValue}
                                    </p>
                                  </td>
                                ) :
                                  <td onClick={() => setSelectSequenceData(currentSelectedValue)} key={item.sequence} data-bs-toggle={(props.showTooltip && key !== 'actions') ? 'tooltip' : undefined} title={(props.showTooltip && key !== 'actions') ? displayValue : undefined}>
                                    <p>
                                      {currentSelectedValue}
                                    </p>
                                  </td>
                                }
                              </>
                            )
                          }
                          else {
                            return (
                              //Sequence dropdown close on change
                              <td key={item.sequence}>
                                <div className="select-dropdown">
                                  <select onChange={(event) => onChange(event, item)} value={currentSelectedValue}>
                                    <option disabled={true}>Select...</option>
                                    {sequenceArray.map((item, sequenceIndex) => (
                                      <option key={sequenceIndex} value={item.value}>{item.label}</option>
                                    ))}
                                  </select>
                                </div>
                              </td>
                            )
                          }
                        }

                        if (key === 'orderStatus') {
                          const selectedValue = sequenceArray && sequenceArray.length > 0 ? sequenceArray.find((option) => option?.value === index + 1)?.value : (index + 1); //Current Index
                          if (selectSequenceData !== selectedValue) {
                            return (
                              //Sequence dropdown open on click
                              <>
                                {props.page === 'order' && item.orderStatus !== '3' && item.orderStatus !== '4' ? (
                                  <td onClick={() => setSelectSequenceData(index + 1)}
                                    key={subIndex}
                                    data-bs-toggle={(props.showTooltip && key !== 'actions') ? 'tooltip' : undefined}
                                    title={(props.showTooltip && key !== 'actions') ? (item.orderStatus === '0' ? 'Pending' : item.orderStatus === '1' ? 'Confirmed' : item.orderStatus === '2' ? 'In-progress' : item.orderStatus === '3' ? 'Completed' : item.orderStatus === '4' ? 'Cancelled' : item.orderStatus) : undefined}
                                    className={item.orderStatus === '0' ? 'Pending' : item.orderStatus === '1' ? 'Confirmed' : item.orderStatus === '2' ? 'In-progress' : item.orderStatus === '3' ? 'Completed' : item.orderStatus === '4' ? 'Cancelled' : ''}
                                  >
                                    <p className="status-bg">
                                      {item.orderStatus === '0' ? 'Pending' : item.orderStatus === '1' ? 'Confirmed' : item.orderStatus === '2' ? 'In Progress' : item.orderStatus === '3' ? 'Completed' : 'Cancelled'}
                                    </p>
                                  </td>
                                ) : (
                                  <td
                                    key={subIndex}
                                    data-bs-toggle={(props.showTooltip && key !== 'actions') ? 'tooltip' : undefined}
                                    title={(props.showTooltip && key !== 'actions') ? (item.orderStatus === '0' ? 'Pending' : item.orderStatus === '1' ? 'Confirmed' : item.orderStatus === '2' ? 'In-progress' : item.orderStatus === '3' ? 'Completed' : item.orderStatus === '4' ? 'Cancelled' : item.orderStatus) : undefined}
                                    className={item.orderStatus === '0' ? 'Pending' : item.orderStatus === '1' ? 'Confirmed' : item.orderStatus === '2' ? 'In-progress' : item.orderStatus === '3' ? 'Completed' : item.orderStatus === '4' ? 'Cancelled' : ''}
                                  >
                                    <p className="status-bg">
                                      {item.orderStatus === '0' ? 'Pending' : item.orderStatus === '1' ? 'Confirmed' : item.orderStatus === '2' ? 'In Progress' : item.orderStatus === '3' ? 'Completed' : 'Cancelled'}
                                    </p>
                                  </td>
                                )}
                              </>
                            )
                          }
                          else {
                            return (
                              //Sequence dropdown close on change
                              <td key={subIndex}>
                                <div className="select-dropdown">
                                  <select value={item.orderStatus} onChange={(event) => onChange(event, item)}>
                                    <option disabled={true}>Select...</option>
                                    {orderStatusArray.map((item, statusIndex) => (
                                      <option key={statusIndex} value={item.value}>{item.status}</option>
                                    ))}
                                  </select>
                                </div>
                              </td>
                            )
                          }
                        }

                        if (key === 'payment') {
                          const selectedValue = paymentSequenceArray && paymentSequenceArray.length > 0 ? paymentSequenceArray.find((option) => option?.value === index + 1)?.value : (index + 1); //Current Index
                          if (selectPaymentSequenceData !== selectedValue) {
                            return (
                              //Sequence dropdown open on click
                              <>
                                {props.page === 'order' && (item.orderStatus !== '4' && !(item.orderStatus === '3' && item.payment === '1')) ? (
                                  <td onClick={() => setPaymentSelectSequenceData(index + 1)}
                                    key={subIndex}
                                    data-bs-toggle={(props.showTooltip && key !== 'actions') ? 'tooltip' : undefined}
                                    title={(props.showTooltip && key !== 'actions') ? (item.payment === '0' ? 'Pending' : item.payment === '1' ? 'Paid' : item.orderStatus) : undefined}
                                    className={item.payment === '0' ? 'Cancelled' : item.payment === '1' ? 'Completed' : ''}
                                  >
                                    <p className="status-bg">
                                      {item.payment === '0' ? 'Pending' : item.payment === '1' ? 'Paid' : ''}
                                    </p>
                                  </td>
                                ) : (
                                  <td
                                    key={subIndex}
                                    data-bs-toggle={(props.showTooltip && key !== 'actions') ? 'tooltip' : undefined}
                                    title={(props.showTooltip && key !== 'actions') ? (item.payment === '0' ? 'Pending' : item.payment === '1' ? 'Paid' : item.orderStatus) : undefined}
                                    className={item.payment === '0' ? 'Cancelled' : item.payment === '1' ? 'Completed' : ''}
                                  >
                                    <p className="status-bg">
                                      {item.payment === '0' ? 'Pending' : item.payment === '1' ? 'Paid' : ""}
                                    </p>
                                  </td>
                                )}
                              </>
                            )
                          }
                          else {
                            return (
                              //Sequence dropdown close on change
                              <td key={subIndex}>
                                <div className="select-dropdown">
                                  <select value={item.payment} onChange={(event) => changeOrderPaymentStatus(event, item)}>
                                    <option disabled={true}>Select...</option>
                                    {orderPaymentArray.map((item, statusIndex) => (
                                      <option key={statusIndex} value={item.value}>{item.status}</option>
                                    ))}
                                  </select>
                                </div>
                              </td>
                            )
                          }
                        }

                        if (key === 'image' || key === 'banner_Image' || key === 'logo_image') {
                          return (
                            <td key={subIndex} className={isError ? 'error-cell' : undefined} data-bs-toggle={(props.showTooltip && key !== 'actions') ? 'tooltip' : undefined} title={(props.showTooltip && key !== 'actions') ? displayValue : undefined}>
                              {notificationImageErrors.includes(item[key]) ? (
                                <img src={ErrorImage} alt="broken_image" />
                              ) : (
                                <img src={API_URL.TOKEN_URL + 'uploads/images/' + item[key]} alt={item[key]} onError={() => handleNotificationImageError(item[key])} />
                              )}
                            </td>
                          );
                        }
                        return (
                          <td key={subIndex} data-bs-toggle={(props.showTooltip && key !== 'actions') ? 'tooltip' : undefined} title={(props.showTooltip && key !== 'actions') ? displayValue : undefined} className={isError ? 'error-cell' : (item.productStatus !== 0 && item.productStatus !== '0') ? 'product-status-color' : 'width-data'}>
                            {displayValue}
                          </td>
                        );
                      }
                      else {
                        return null
                      }
                    })
                    }
                    {
                      //Select All Toggle
                      props.showSelectAll && (
                        <td style={{ width: "120px", minWidth: "120px", maxWidth: "120px" }} >
                          <Toggle disabled={(props.disabled === true && (props.page === 'addRoles' || props.page === 'add-company'))
                          }
                            value={(selectAllValue && selectAllValue.includes && selectAllValue.includes(item.name)) ? 1 : 0}
                            onChange={(e) =>
                              handleToggle(item.name, 'selectAll', e.target.checked)
                            }
                          />
                        </td>
                      )
                    }
                    {
                      props.showToggle && (
                        <>
                          {
                            props.showToggle.status && (
                              <td style={{ width: "80px", minWidth: "80px", maxWidth: "80px" }} key={index}>
                                <Toggle disabled={(props.disabled === true) || (props.showActions.showUndo && (
                                  (item.is_delete === '1' || item.IsDeleted === '1' || item.isDelete === '1') ||
                                  (item.isDeleted === '1')
                                ))}
                                  value={((item.status === 1 || item.status === '1') ? 1 : 0) ||
                                    ((item.isActive === 1 || item.isActive === '1') ? 1 : 0) ||
                                    ((item.active === '1' || item.active === 1) ? 1 : 0) ||
                                    ((item.is_active === '1' || item.is_active === 1) ? 1 : 0) || false}
                                  onChange={(e) =>
                                    handleToggle(item, 'status', e.target.checked)
                                  }
                                />
                              </td>
                            )
                          }
                        </>
                      )
                    }
                    {
                      (props.showActions) && (
                        <td style={{ width: "200px", minWidth: "200px", maxWidth: "200px" }} className="actions">
                          <div className='action-icon'>
                            {
                              props.showActions.showView && (
                                (props.showActions.showUndo && (
                                  (item.is_delete === '0' || item.IsDeleted === '0' || item.isDelete === '0') ||
                                  (item.isDeleted === '0')
                                )) ||
                                (props.showActions.showUndo === null || props.showActions.showUndo === undefined)) && (
                                <FaEye data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? 'view' : undefined} onClick={() => handleNavigate(props.showActions.navigate, item, false)} className='icon-color' />)
                            }
                            {
                              (props.showActions.showEdit && ((props.page === 'order' && item.orderStatus !== '3' && item.orderStatus !== '4') || props.page !== 'order')) && (
                                (props.showActions.showUndo && (
                                  (item.is_delete === '0' || item.IsDeleted === '0' || item.isDelete === '0') ||
                                  (item.isDeleted === '0')
                                )) ||
                                (props.showActions.showUndo === null || props.showActions.showUndo === undefined)) && (<MdEdit data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? 'edit' : undefined} onClick={() => handleNavigate(props.showActions.navigate, item, true)} className='icon-color' />)
                            }
                            {

                              props.showActions.showDelete && (
                                (props.showActions.showUndo && (
                                  (item.is_delete === '0' || item.IsDeleted === '0' || item.isDelete === '0') ||
                                  (item.isDeleted === '0')
                                )) ||
                                (props.showActions.showUndo === null || props.showActions.showUndo === undefined))
                              && (<MdDelete data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? 'delete' : undefined} className='icon-color' onClick={() => handleDeleteClick(item)} />)
                            }
                            {
                              props.showActions.showReview && (
                                (props.showActions.showUndo && (
                                  (item.is_delete === '0' || item.IsDeleted === '0' || item.isDelete === '0') ||
                                  (item.isDeleted === '0')
                                )) ||
                                (props.showActions.showUndo === null || props.showActions.showUndo === undefined)) && (<FaStarHalfAlt onClick={() => navigate('/product-review-management')} data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? 'Review' : undefined} className='icon-color' />)
                            }
                            {props.showActions.showUndo && (
                              (item.is_delete === '1' || item.isDeleted === '1' || item.IsDeleted === '1' || item.isDelete === '1')) && (
                                <GrUndo onClick={() => handleUndoClick(item)} data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? 'undo' : undefined} className='icon-color' />
                              )}
                            {
                              props.showActions.showPlus && (
                                <FaPlus data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? 'Plus' : undefined}
                                  onClick={() => handleNavigate(props.showActions.navigate, item, true)}
                                  className='icon-color' />)
                            }
                            {props.showActions.showPrint && <IoMdPrint className='icon-color' onClick={() => printOrderDetails(item)} />}
                            {item.orderStatus === '0' && (
                              <>
                                {
                                  props.showActions.showRightIcon && (
                                    <FcCheckmark data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? 'Accept' : undefined}
                                      className='icon-color right-icon-color' onClick={() => handleOrderConfirmationClick('Confirm', item, tokenData, dispatch, orderPage)} />)
                                }
                                &nbsp;
                                {
                                  props.showActions.showWrongIcon && (
                                    <RxCross2 data-bs-toggle={(props.showTooltip) ? 'tooltip' : undefined} title={(props.showTooltip) ? 'Reject' : undefined}
                                      className='icon-color wrong-icon-color' onClick={() => handleOrderConfirmationClick('Cancel', item, tokenData, dispatch, orderPage)} />)
                                }
                              </>
                            )}
                            {/* changes by sakshi*/}
                            {(props.showActions.showRightProductIcon && item.productStatus !== 1 && item.productStatus !== '1') && (
                              <FcCheckmark
                                data-bs-toggle={props.showTooltip ? 'tooltip' : undefined}
                                title={props.showTooltip ? 'Accept' : undefined}
                                className='icon-color right-icon-color'
                                onClick={() => handleLineLevelOrderConfirmationClick(item, tokenData, dispatch, orderPage)}
                              />
                            )}
                            {(props.showActions.showRightProductIcon && (item.productStatus === 1 || item.productStatus === '1')) && (
                              <span>Delivered</span>
                            )}
                          </div>
                        </td>
                      )
                    }
                  </tr>
                ))}
              </tbody>
            </Table>
            {props.page !== 'roles' && props.page !== 'role' && props.page !== 'users' && props.page !== 'category' && props.page !== 'subCategory' && props.page !== 'leafCategory' && props.page !== 'tags' && props.page !== 'imageManager' && props.page !== 'uploadImage' && props.page !== 'brand' && props.page !== 'discount' && props.page !== 'product' && props.page !== 'attributes' && props.page !== 'warehouse' && props.page !== 'packager' && props.page !== 'discountCatalogue' && props.page !== 'inventory' && props.page !== 'branch' && props.page !== 'serviceProvider' && props.page !== 'service' && props.page !== 'import' && props.page !== 'order' && props.page !== 'customer' && props.page !== 'add-company' && props.page !== 'company' && (
              <div className="custom-pagination">
                <CustomPagination totalRecords={totalRecords} currentPage={currentPage} onPageChange={onPageChange} />
              </div>
            )}
          </>)
        }
      </div>
    </>
  );
}