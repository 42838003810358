/**
 * Set notification list data into reducer
 * @param {*} Notification 
 * @returns {*}
 */
export const getNotificationData = (Notification) =>({
    type: 'GET_NOTIFICATION_DATA',
    payload: Notification
})

/**
 * clear notificaiton list data
 * @returns {*}
 */
export const clearNotificationData = () =>({
    type: 'CLEAR_NOTIFICATION_DATA'
})