// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-detail .admin-detail-section {
  background: #FFF;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.admin-detail .admin-detail-section strong {
  font-size: 55px;
  color: #000;
  line-height: 70px;
  font-weight: 700;
}
.admin-detail .admin-detail-section span {
  font-size: 22px;
  color: #000;
}

.customerCard {
  background-color: #FFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
}
.customerCard canvas {
  height: 350px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dashboard.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,uCAAA;AADR;AAEQ;EACI,eAAA;EACA,WCVC;EDWD,iBAAA;EACA,gBAAA;AAAZ;AAEQ;EACI,eAAA;EACA,WChBC;ADgBb;;AAIA;EACI,sBCtBS;EDuBT,uCAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;AADJ;AAGI;EACI,wBAAA;AADR","sourcesContent":["@import\"../../variables\";\n.admin-detail{\n    .admin-detail-section {\n        background: #FFF;\n        padding:20px 10px;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        border-radius: 5px;\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n        strong{\n            font-size: 55px;\n            color: $black-color;\n            line-height: 70px;\n            font-weight: 700;\n        }\n        span{\n            font-size: 22px;\n            color: $black-color;\n        }\n    }\n}\n.customerCard{\n    background-color: $white-color;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    margin-top: 20px;\n    padding: 10px;\n    border-radius: 5px;\n\n    canvas{\n        height: 350px !important;\n    }\n}","$main-color:#1D3B42;\n$primary-color:#FF4F2B;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$successColor:#28a745;\n$dangerColor:#dc3545;\n$border-color:#DEE2E6;\n$red-color: #ff0000;\n$star-rating: #e1c100;\n$disabled-bg:#e9ecef;\n$gray:#E6E6E6;\n$lightLIGHTGRAY:#aaa;\n$orange-color:#008dff;\n$completed:#22bb33;\n$Confirmed:#fcaa11;\n$In-progress:#5bc0de;\n$Pending:#deb887;\n$Cancelled:#bb2124;\n$bespeakk-color:#e25233;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
