const faqInitialState = { 
    items: []
};

export const FaqReducer = (state = faqInitialState, action) => {
    switch (action.type) {
        case 'ADD_FAQ_DATA':
            return {
                ...state,
                items: [...state.items, action.payload]
            };
        case 'UPDATE_FAQ_DATA':
            const index = state.items.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.items[index] = action.payload;
            }
            return {
                ...state
            };
        case 'DELETE_FAQ_DATA':
            state.items = state.items.filter(item => item.id !== action.payload);
            return {
                ...state
            };
        default:
            return state;
      };
}