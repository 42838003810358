import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import CustomTable from "../../components/Table/Table";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import { GrMoreVertical } from "react-icons/gr";
import Filter from "../../components/Filter/Filter";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import { getAllDiscountService } from "./DiscountService/DiscountService";
import UtilsData from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import { format } from 'date-fns';
import { clearDiscountData } from "../../redux/Actions/DiscountAction/DiscountAction";
import { clearDiscountFilterData } from "../../redux/Actions/DiscountAction/DiscountFilterAction";
import { clearDiscountPageData, getDiscountPageData } from "../../redux/Actions/DiscountAction/DiscountPageAction";

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  discount_type: true,
  discount_value: true,
  max_discount_ammount: true,
  min_cart_value: true,
  start_date: true,
  end_date: true,
  discountCode: true
};

const showToggle = {
  status: true,
  delete: false
}

export default function Discount() {

  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const dispatch = useDispatch();
  const tokenData = UtilsData();

  const sideBarData = useSelector(state => state.sideBar.items);
  const authuserData = useSelector(state => state.auth?.user);
  const [discountActionData, setdiscountActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(item => item.router_link === 'discount');
      setdiscountActionData(actionData)
    }
  }, [sideBarData]);


  //Show actions which is to be displayed in table

  const showActions = {
    showView: ((discountActionData[0]?.view && discountActionData[0].view === '1') || (!discountActionData)) ? true : false,
    showEdit: ((discountActionData[0]?.edit && discountActionData[0].edit === '1') || (!discountActionData)) ? true : false,
    showDelete: ((discountActionData[0]?.delete && discountActionData[0].delete === '1') || (!discountActionData)) ? true : false,
    showUndo: ((discountActionData[0]?.edit && discountActionData[0].edit === '1') || (!discountActionData)) ? true : false,
    navigate: ((discountActionData[0]?.edit && discountActionData[0].edit === '1') || (!discountActionData)) ? '/edit-discount' : false
  }

  const [showActionBool, setShowActionBool] = useState(false);
  //Headings
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if ((discountActionData[0]?.view && discountActionData[0].view === '0') &&
      (discountActionData[0]?.edit && discountActionData[0].edit === '0') &&
      (discountActionData[0]?.add && discountActionData[0].add === '0') &&
      (discountActionData[0]?.delete && discountActionData[0].delete === '0')) {
      setColumns(
        [
          {
            name: "Name",
            selector: (row) => row.Name
          },
          {
            name: "Type",
            selector: (row) => row.Type
          },
          {
            name: "value",
            selector: (row) => row.value
          },
          {
            name: "Max Discount",
            selector: (row) => row.Value
          },
          {
            name: "Min Cart",
            selector: (row) => row.cart
          },
          {
            name: "Start",
            selector: (row) => row.Date
          },
          {
            name: "End",
            selector: (row) => row.date
          },
          {
            name: "Code",
            selector: (row) => row.Code
          },
          {
            name: "Status",
            selector: (row) => row.status
          }
        ]
      )
      setShowActionBool(false);
    }
    else {
      setShowActionBool(true);
      setColumns(
        [
          {
            name: "Name",
            selector: (row) => row.Name
          },
          {
            name: "Type",
            selector: (row) => row.Type
          },
          {
            name: "value",
            selector: (row) => row.value
          },
          {
            name: "Max Discount",
            selector: (row) => row.Value
          },
          {
            name: "Min Cart",
            selector: (row) => row.cart
          },
          {
            name: "Start",
            selector: (row) => row.Date
          },
          {
            name: "End",
            selector: (row) => row.date
          },
          {
            name: "Code",
            selector: (row) => row.Code
          },
          {
            name: "Status",
            selector: (row) => row.status
          },
          {
            name: "Actions",
            selector: (row) => row.actions
          }
        ]
      )
    }
  }, [discountActionData]);


  const discount = useSelector(state => state.discount);
  const discountPage = useSelector(state => state.discountPage?.items);
  const discountFilter = useSelector(state => state.discountFilter);

  const [discountData, setDiscountData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(discountPage);
  }, [discountPage]);


  /**
   * Fetch discount data
   */
  const fetchDiscount = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10
    }
    if (discountPage > 1) {
      requestBody.p_skip = (discountPage - 2) * 10;
      dispatch(getDiscountPageData(discountPage - 1));
    }
    else {
      requestBody.p_skip = (discountPage - 1) * 10;
      dispatch(getDiscountPageData(discountPage));
    }

    if (discountFilter.name !== '' && discountFilter.name !== null) {
      requestBody.p_title = discountFilter.name;
    }
    if (discountFilter.type !== '' && discountFilter.type !== null) {
      requestBody.p_discount_type = discountFilter.type;
    }
    if (discountFilter.start_date !== '' && discountFilter.start_date !== null) {
      requestBody.p_start_date = format(new Date(discountFilter.start_date), 'yyyy-MM-dd');
    }
    if (discountFilter.end_date !== '' && discountFilter.end_date !== null) {
      requestBody.p_end_date = format(new Date(discountFilter.end_date), 'yyyy-MM-dd');
    }
    if (discountFilter.code !== '' && discountFilter.code !== null) {
      requestBody.p_discount_code = discountFilter.code;
    }

    if (discountFilter.isActive !== '' && discountFilter.isActive !== null) {
      requestBody.p_IsActive = discountFilter.isActive;
    }
    else {
      requestBody.p_IsActive = '1';
    }

    if (discountFilter.isDelete !== '' && discountFilter.isDelete !== null) {
      requestBody.p_isDeleted = discountFilter.isDelete;
    }
    getAllDiscountService(requestBody, tokenData, dispatch);
  }

  useEffect(() => {
    if (discount && discount.items && discount.items.length === 0) {
      fetchDiscount();
    }
    else {
      if (discount.items && discount.items[0].data && discount.items[0].data && discount.items[0].data.length > 0) {
        const transformedData = discount.items[0].data.map(item => ({
          discountId: item.discountId,
          name: item.name,
          discount_type: (item.discount_type === 0 || item.discount_type === '0') ? (authuserData?.data?.symbol ? authuserData.data.symbol : '₹') : '%',
          discount_value: item.discount_ammount,
          max_discount_ammount: item.max_discount_ammount && item.max_discount_ammount !== '' ? item.max_discount_ammount : '—',
          min_cart_value: item.min_cart_value,
          start_date: format(new Date(item.start_date), 'yyyy-MM-dd'),
          end_date: format(new Date(item.end_date), 'yyyy-MM-dd'),
          discountCode: item.discountCode,
          is_first_order_discount: item.is_first_order_discount,
          discount_type_on: item.discount_type_on,
          is_active: item.is_active,
          IsDeleted: item.IsDeleted,
          created_by: item.created_by,
          modified_by: item.modified_by,
          created_at: item.created_at,
          modified_at: item.modified_at,
          discount_apply_on: item.discount_apply_on,
          spacific_item: item.spacific_item
        }))
        setDiscountData(transformedData)
      }
      else {
        setDiscountData([]);
      }
    }
    if ((discountFilter.name !== '' && discountFilter.name !== null) || (discountFilter.type !== '' && discountFilter.type !== null) || (discountFilter.start_date !== '' && discountFilter.start_date !== null) || (discountFilter.end_date !== '' && discountFilter.end_date !== null) || (discountFilter.code !== '' && discountFilter.code !== null) || (discountFilter.isActive !== '' && discountFilter.isActive !== null) || (discountFilter.isDelete !== '' && discountFilter.isDelete !== null)) {
      setIsToggled(true);
    }
    // eslint-disable-next-line
  }, [discount])

  /**
   * On page change
   * @param {*} pageNumber 
   */
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(getDiscountPageData(pageNumber));
    if (pageNumber !== currentPage) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10
      }
      if (discountFilter.name !== '' && discountFilter.name !== null) {
        requestBody.p_title = discountFilter.name;
      }
      if (discountFilter.type !== '' && discountFilter.type !== null) {
        requestBody.p_discount_type = discountFilter.type;
      }
      if (discountFilter.start_date !== '' && discountFilter.start_date !== null) {
        requestBody.p_start_date = format(new Date(discountFilter.start_date), 'yyyy-MM-dd');
      }
      if (discountFilter.end_date !== '' && discountFilter.end_date !== null) {
        requestBody.p_end_date = format(new Date(discountFilter.end_date), 'yyyy-MM-dd');
      }
      if (discountFilter.code !== '' && discountFilter.code !== null) {
        requestBody.p_discount_code = discountFilter.code;
      }

      if (discountFilter.isActive !== '' && discountFilter.isActive !== null) {
        requestBody.p_IsActive = discountFilter.isActive;
      }
      else {
        requestBody.p_IsActive = '1';
      }

      if (discountFilter.isDelete !== '' && discountFilter.isDelete !== null) {
        requestBody.p_isDeleted = discountFilter.isDelete;
      }
      getAllDiscountService(requestBody, tokenData, dispatch);
    }
  }

  /**
   * clear filtered data
   */
  const clearFilters = () => {
    dispatch(clearDiscountData());
    dispatch(clearDiscountFilterData());
    dispatch(clearDiscountPageData());
    setIsToggled(false);
  }
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Discount List</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Discount </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                <Dropdown className="header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>Delete</Dropdown.Item>
                    <Dropdown.Item>Active</Dropdown.Item>
                    <Dropdown.Item>Inactive</Dropdown.Item>
                    <Dropdown.Item>Undo</Dropdown.Item>
                    {((discountFilter.name !== '' && discountFilter.name !== null) || (discountFilter.type !== '' && discountFilter.type !== null) || (discountFilter.start_date !== '' && discountFilter.start_date !== null) || (discountFilter.end_date !== '' && discountFilter.end_date !== null) || (discountFilter.code !== '' && discountFilter.code !== null) || (discountFilter.isActive !== '' && discountFilter.isActive !== null) || (discountFilter.isDelete !== '' && discountFilter.isDelete !== null)) && (
                      <Dropdown.Item onClick={clearFilters}>Clear Filters</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  data-bs-toggle="tooltip"
                  title="Filter"
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                {(discount && discount.items && discount.items[0]?.data && discount.items[0].data.length > 0) && (
                  <ExcelData excelData={discount.items[0].data} fileName={'discount'}></ExcelData>
                )}
                {((discountActionData[0]?.add && discountActionData[0].add === '1') || (!discountActionData)) && (
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add Discount"
                    onClick={() => navigate("/add-discount")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {isToggled && <Filter page='discount'></Filter>}
            {(discount && discount.items && discount.items[0]?.data && discount.items[0].data) && (
              <>
                <CustomTable page='discount' showDescription={true} showToggle={showToggle} showKey={showKey} showActions={showActionBool ? showActions : false} showTooltip={true} checkBool={true} data={discountData} heading={columns} disabled={(discountActionData[0]?.edit && discountActionData[0].edit === '0')} />

                {(discount.items[0].total_records > discount.items[0].data.length && discount.items[0].total_records > 10) && (
                  <CustomPagination totalRecords={discount.items[0].total_records} currentPage={currentPage} onPageChange={onPageChange} />
                )}
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
