/**
 * Setting admin user page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getAdminPageData = (page) => ({
    type: 'GET_ADMIN_PAGE',
    payload: page
})

/**
 * Clearing admin user page data
 * @returns {*}
 */
export const clearAdminPageData = () => ({
    type: 'CLEAR_ADMIN_PAGE'
})