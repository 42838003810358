const imageGalleryInitialState = { 
    items: []
};

/**
 * Store image list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const ImageGalleryReducer = (state = imageGalleryInitialState, action) => {
    switch (action.type) {
        case 'GET_IMAGE_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_IMAGE_DATA':
            return imageGalleryInitialState
        default:
            return state;
      };
}

const totalImageInitialState = {
    items: []
}

/**
 * Store total image list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalImageReducer = (state = totalImageInitialState, action) => {
    switch (action.type) {
        case 'TOTAL_IMAGE':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_IMAGE':
            return totalImageInitialState;
        default:
            return state;
      };
}