import { toast } from "react-toastify";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { getApiService } from "../../../services/API-services/GetService";
import { NotificationByIdResponse, getAllNotificationListRequest, getAllNotificationListResponse } from "./NotificationModal";
import { Dispatch } from "redux";
import { clearNotificationData, getNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearNotificationLoader, setNotificationLoader } from "../../../redux/Actions/NotificationAction/NotificationLoader";

let errorShow = false;

/**
 * Service for getting notification list
 */
export const getNotificationListService = (notificationRequest: getAllNotificationListRequest, token: string, dispatch: Dispatch, navigate: any) => {
    return new Promise<getAllNotificationListResponse>(async (resolve, reject) => {
        try {
            let queryParams = '';
            // Adding query parameters if they are provided
            if (notificationRequest.p_skip !== undefined && notificationRequest.p_skip !== null) {
                queryParams += `p_skip=${notificationRequest.p_skip}&`;
            }
            if (notificationRequest.p_take !== undefined && notificationRequest.p_take !== null) {
                queryParams += `p_take=${notificationRequest.p_take}&`;
            }
            const response = await getApiService(API_URL.BASE_URL + API_URL.NOTIFICATION + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                dispatch(clearNotificationLoader());
                dispatch(getNotificationData(response));
            }
            else {
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
                dispatch(clearNotificationLoader());
            }
            resolve(response)
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }
            dispatch(clearNotificationLoader());
        } finally {
            errorShow = false;;
        }
    })
}

/*
 * Service For getting notification by id
*/
export const getNotificationById = (
    id: string, token: string,
    dispatch: Dispatch, navigate: any): Promise<NotificationByIdResponse> => {
    return new Promise<NotificationByIdResponse>(async (resolve, reject) => {
        try {
            const response = await getApiService(API_URL.BASE_URL + API_URL.NOTIFICATION + '/' + id, token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                dispatch(clearNotificationData());
                dispatch(setNotificationLoader());
                resolve(response);
            }
            else {
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
            }
            resolve(response);
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }
        } finally {
            errorShow = false;
        }
    });
};

/**
 * Service for deleting notification by id
 * @param id 
 * @param token 
 * @param navigate 
 * @returns 
 */
export const deleteNotificationById = (
    id: string,
    token: string, navigate?: any) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await deleteApiService(API_URL.BASE_URL + API_URL.NOTIFICATION + '/' + id, token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                dispatch(clearNotificationData());
                dispatch(setNotificationLoader());
                toast.success('notification deleted successfully!');
                if (window.location.pathname === '/import-data') {
                    if (navigate) {
                        navigate('/home');
                    }
                }
            }
            else {
                toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                errorShow = true
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
            }
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }
        } finally {
            errorShow = false;
        }
    };
};