const DiscountBrandInitialState = { 
    items: []
};

/**
 * Store discount brand list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const DiscountBrandReducer = (state = DiscountBrandInitialState, action) => {
    switch (action.type) {
        case 'GET_DISCOUNT_BRAND':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_DISCOUNT_BRAND':
            return DiscountBrandInitialState;
        default:
            return state;
      };
}