/**
 * Set category filtered data into reducer
 * @param {*} param0 
 * @returns {*} 
 */
export const getCategoryFilterData = ({name,code, isActive, isDelete}) => ({
    type: 'GET_CATEGORY_FILTER',
    payload: {name,code, isActive, isDelete}
})

/**
 * Clear category filtered data
 * @returns {*}
 */
export const clearCategoryFilterData = () => ({
    type: 'CLEAR_CATEGORY_FILTER'
})