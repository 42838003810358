/**
 * Set discount filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getDiscountFilterData = ({name, type, start_date, end_date, code, isActive, isDelete}) => ({
    type: 'GET_DISCOUNT_FILTER',
    payload: {name, type, start_date, end_date, code, isActive, isDelete}
})

/**
 * Clear discount filtered data
 * @returns {*}
 */
export const clearDiscountFilterData = () => ({
    type: 'CLEAR_DISCOUNT_FILTER'
})