/**
 * Get company page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getCompanyPageData = (page) => ({
    type: 'GET_COMPANY_PAGE',
    payload: page
})

/**
 * Clear company page data
 * @returns {*}
 */
export const clearCompanyPageData = () => ({
    type: 'CLEAR_COMPANY_PAGE'
})