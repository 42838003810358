const orderInitialState = {
    items: [],
    timeStamp: '',
    totalRecords: 0,
    api: false,
    dataCount: 0,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

/**
 * Store leaf level category list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const OrderReducer = (state = orderInitialState, action) => {
    switch (action.type) {
        case 'GET_ORDER_DATA':
            return {
                ...state,
                items: action.payload.order,
                timeStamp: action.payload.time,
                totalRecords: action.payload.totalRecords,
                api: action.payload.api,
                dataCount: action.payload.dataCount ? action.payload.dataCount : state.dataCount,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        case 'ADD_ORDER':
            const newOrders = action.payload.order; // Assuming payload contains an array of orders

            let updatedItems = [...state.items];
            
            newOrders.forEach(newOrder => {
                const newOrderId = newOrder.orderId; // Assuming each order has a unique 'orderId'
                const existingOrderIndex = updatedItems.findIndex(order => order.orderId === newOrderId);
            
                if (existingOrderIndex >= 0) {
                    // Update existing order
                    updatedItems = [
                        ...updatedItems.slice(0, existingOrderIndex),
                        newOrder,
                        ...updatedItems.slice(existingOrderIndex + 1)
                    ];
                } else {
                    // Add new order
                    updatedItems = [...updatedItems, newOrder];
                }
            });

            const pendingOrdersCount = updatedItems && updatedItems.length > 0 ? updatedItems.filter(order => order.status === '0').length : state.dataCount;

            return {
                ...state,
                // items: [...state.items, ...action.payload.order],
                items: updatedItems,
                totalRecords: action.payload.totalRecords,
                timeStamp: action.payload.time,
                api: action.payload.api,
                // dataCount: action.payload.dataCount ? state.dataCount + action.payload.dataCount : state.dataCount
                dataCount: pendingOrdersCount,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        case 'UPDATE_ORDER_STATUS':
            return {
                ...state,
                items: state.items?.length ? state.items.map((item) =>
                    item.orderId === action.payload.orderId
                        ? { ...item, status: action.payload.status }
                        : item
                ) : [],
                totalRecords: state.totalRecords,
                timeStamp: state.timeStamp,
                api: state.api,
                dataCount: state.dataCount,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        case 'CLEAR_ORDER_DATA':
            return orderInitialState
        default:
            return state;
    };
}

const totalOrderInitialState = {
    items: []
}

/**
 * Store total leaf level category list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalOrderReducer = (state = totalOrderInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_ORDER_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_ORDER_DATA':
            return totalOrderInitialState
        default:
            return state;
    };
}