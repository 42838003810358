const warehouseInitialState = { 
    items: []
};

/**
 * Store warehouse list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const warehouseReducer = (state = warehouseInitialState, action) => {
    switch (action.type) {
        case 'GET_WAREHOUSE_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_WAREHOUSE_DATA':
            return warehouseInitialState;
        default:
            return state;
      };
}

const totalwarehouseInitialState = {
    items: []
}

/**
 * Store total warehouse list data (No notification)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalWarehouseReducer = (state = totalwarehouseInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_WAREHOUSE':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_WAREHOUSE':
            return totalwarehouseInitialState
        default:
            return state;
      };
}