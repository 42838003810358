/**
 * Set tag filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getTagFilterData = ({title, isActive, isDelete}) => ({
    type: 'GET_TAG_FILTER',
    payload: {title, isActive, isDelete}
})

/**
 * Clear tag filtered data
 * @returns {*}
 */
export const clearTagFilterData = () => ({
    type: 'CLEAR_TAG_FILTER'
})