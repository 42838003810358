/**
 * Set service list data into reducer
 * @param {*} Service 
 * @returns {*}
 */
export const getServiceData = (Service) => ({
    type: 'GET_SERVICE_DATA',
    payload: Service
})

/**
 * Clear Set service list data
 * @returns {*}
 */
export const clearServiceData = () => ({
    type: 'CLEAR_SERVICE_DATA'
})

/**
 * Set total service list data into reducer (No pagination)
 * @param {*} Service 
 * @returns {*}
 */
export const getTotalServiceData = (Service) => ({
    type: 'GET_TOTAL_SERVICE',
    payload: Service
})

/**
 * Clear total service list data into reducer (No pagination)
 * @returns {*}
 */
export const clearTotalServiceData = () => ({
    type: 'CLEAR_TOTAL_SERVICE'
})