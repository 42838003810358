import './Button.scss';
import React from 'react';

export default function Button(props) {
  const { type, name, ...rest } = props
  return (
    <>
      <button className="btn" type={props.type} {...rest}>{props.name}</button>
    </>
  )
}