import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function BootstrapTooltips(propData) {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {propData.name}
        </Tooltip>
    );
    return (
        <>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
            >
                <h3 className='d-inline-flex'>Hover over me</h3>
            </OverlayTrigger>
        </>
    )
}