import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import "../Order/Order.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaAngleDown, FaAngleUp, FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { Accordion, Dropdown } from "react-bootstrap";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrderService,
  uploadOrderPDFService,
} from "./OrderService/OrderService";
import UtilsData, { ValidationMessage } from "../../Utils/Utils";
import { IoLogoWhatsapp } from "react-icons/io";
import {
  clearOrderPageData,
  getOrderPageData,
} from "../../redux/Actions/orderAction/OrderPAgeAction";
import { format } from "date-fns";
import CustomPagination from "../../components/Pagination/Pagination";
import { clearOrderFilterData } from "../../redux/Actions/orderAction/OrderFilterAction";
import { clearOrderData } from "../../redux/Actions/orderAction/OrderAction";
import { IoIosArrowForward } from "react-icons/io";
import { orderDate } from "../../services/Regular-services/DateService";
import { HiMiniInformationCircle } from "react-icons/hi2";
import newOrderGif from "../../assets/images/new-order-gif.gif";
import { MdOutlineCancel } from "react-icons/md";
// import { handleViewOrderQuantity } from "../../components/Alertmessage/Alertmessage";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { setNotificationSound } from "../../redux/Actions/NotificationAction/NotificationSound";
import Toggle from "../../components/Toggle/Toggle";
import {
  addUpdateUserService,
  getUserById,
} from "../AdminUsers/User-service/UserService";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

//Show keys which is to be displayed in table
const showKey = {
  id: true,
  customerName: true,
  phoneNumber: true,
  createdAt: true,
  orderStatus: true,
  payment: true,
  taxAmount: true,
  discountAmount: true,
  orderAmount: true,
  totalOrderWithTax: true,
  // zomatoSwiggyPrice:true,
  totalItem: true,
  tableName: true,
};

export default function Order() {
  const [columns, setColumns] = useState([
    {
      name: "Name",
      selector: (row) => row.Name,
    },
    {
      name: "Mobile",
      selector: (row) => row.Name,
    },
    {
      name: "Date",
      selector: (row) => row.Date,
    },
    {
      name: "Table",
      selector: (row) => row.tableName,
    },
    {
      name: "Quantities",
      selector: (row) => row.Items,
    },
    {
      name: "Subtotal",
      selector: (row) => row.Items,
    },
    {
      name: "Tax",
      selector: (row) => row.Items,
    },
    {
      name: "Discount",
      selector: (row) => row.Items,
    },
    {
      name: "Total",
      selector: (row) => row.total,
    },
    // {
    //   name: "Zomato/Swiggy price",
    //   selector: (row) => row.zomatoSwiggyPrice,
    // },
    {
      name: "Payment",
      selector: (row) => row.Status,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
    },
    {
      name: "Actions",
      selector: (row) => row.action,
    },
  ]);

  const productHeading = [
    {
      name: "Product Name",
      selector: (row) => row.Name,
    },
    {
      name: "Size",
      selector: (row) => row.Date,
    },
    {
      name: "Quantity",
      selector: (row) => row.Name,
    },
    {
      name: "Price",
      selector: (row) => row.total,
    },
    {
      name: "Total",
      selector: (row) => row.total,
    },
    {
      name: "Type",
      selector: (row) => row.total,
    },
    {
      name: "Instruction",
      selector: (row) => row.instruction,
    },
    {
      name: "Actions",
      selector: (row) => row.action,
    },
  ];

  const showProductKeys = {
    productName: true,
    quantity: true,
    size: true,
    tablePrice: true,
    food_type_two: true,
    table_product_instruction: true,
    totalTablePrice: true,
  };

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const authuserData = useSelector((state) => state.auth?.user);
  const order = useSelector((state) => state.order);
  const orderFilter = useSelector((state) => state.OrderFilter);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const [currentPageNumber, setCurrentPage] = useState(1);
  const tokenData = UtilsData(); //Getting token
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [expandedItem, setExpandedItem] = useState([]);
  const [orderStatusFilter, setOrderStatusFilter] = useState([]);
  const soundData = useSelector((state) => state.notificationSound?.sound);
  const [beepOption, setBeepOption] = useState(0);

  useEffect(() => {
    if (authuserData && authuserData?.token) {
      setBeepOption(authuserData.data?.beep_option === "1" ? 1 : 0);
    }
  }, [authuserData]);

  const toggleAccordionItem = (eventKey) => {
    setExpandedItem((prevExpandedItems) =>
      prevExpandedItems.includes(eventKey)
        ? prevExpandedItems.filter((key) => key !== eventKey)
        : [...prevExpandedItems, eventKey]
    );
  };

  const [groupedTableData, setGroupedTableData] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);
  const filterDateData = orderDate();

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [orderActionData, setOrderActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setOrderActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    setCurrentPage(orderPage);
  }, [orderPage]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (orderActionData[0]?.view && orderActionData[0].view === "1") ||
      !orderActionData
        ? true
        : false,
    showEdit:
      (orderActionData[0]?.edit && orderActionData[0].edit === "1") ||
      !orderActionData
        ? true
        : false,
    showDelete:
      (orderActionData[0]?.delete && orderActionData[0].delete === "1") ||
      !orderActionData
        ? false
        : false,
    showRightIcon:
      (orderActionData[0]?.edit && orderActionData[0].edit === "1") ||
      !orderActionData
        ? true
        : false,
    showWrongIcon:
      (orderActionData[0]?.edit && orderActionData[0].edit === "1") ||
      !orderActionData
        ? true
        : false,
    navigate:
      (orderActionData[0]?.edit && orderActionData[0].edit === "1") ||
      !orderActionData
        ? "/edit-order"
        : false,
    showKOTPrint: true,
    showInvoice: true,
  };

  const showProductActions = {
    showRightProductIcon: true,
  };

  useEffect(() => {
    if (
      orderActionData[0]?.view &&
      orderActionData[0].view === "0" &&
      orderActionData[0]?.edit &&
      orderActionData[0].edit === "0" &&
      orderActionData[0]?.add &&
      orderActionData[0].add === "0" &&
      orderActionData[0]?.delete &&
      orderActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name",
          selector: (row) => row.Name,
        },
        {
          name: "Mobile",
          selector: (row) => row.Name,
        },
        {
          name: "Date",
          selector: (row) => row.Date,
        },
        {
          name: "Table",
          selector: (row) => row.tableName,
        },
        {
          name: "Quantities",
          selector: (row) => row.Items,
        },
        {
          name: "Subtotal",
          selector: (row) => row.Items,
        },
        {
          name: "Tax",
          selector: (row) => row.Items,
        },
        {
          name: "Discount",
          selector: (row) => row.Items,
        },
        {
          name: "Total",
          selector: (row) => row.total,
        },
        // {
        //   name: "Zomato/Swiggy price",
        //   selector: (row) => row.zomatoSwiggyPrice,
        // },
        {
          name: "Payment",
          selector: (row) => row.Status,
        },
        {
          name: "Status",
          selector: (row) => row.Status,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name",
          selector: (row) => row.Name,
        },
        {
          name: "Mobile",
          selector: (row) => row.Name,
        },
        {
          name: "Date",
          selector: (row) => row.Date,
        },
        {
          name: "Table",
          selector: (row) => row.tableName,
        },
        {
          name: "Quantities",
          selector: (row) => row.Items,
        },
        {
          name: "Subtotal",
          selector: (row) => row.Items,
        },
        {
          name: "Tax",
          selector: (row) => row.Items,
        },
        {
          name: "Discount",
          selector: (row) => row.Items,
        },
        {
          name: "Total",
          selector: (row) => row.total,
        },
        // {
        //   name: "Zomato/Swiggy price",
        //   selector: (row) => row.zomatoSwiggyPrice,
        // },
        {
          name: "Payment",
          selector: (row) => row.Status,
        },
        {
          name: "Status",
          selector: (row) => row.Status,
        },
        {
          name: "Actions",
          selector: (row) => row.action,
        },
      ]);
    }
  }, [orderActionData]);

  const getOrderStatus = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Confirmed";
      case "2":
        return "In-progress";
      case "3":
        return "Completed";
      case "4":
        return "Cancelled";
      default:
        return "";
    }
  };

  useEffect(() => {
    const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
    if (
      ((order &&
        order.items &&
        order.items.length < 1 &&
        order.api === false) ||
        !order) &&
      orderFilter &&
      ((orderFilter.orderDate !== "" &&
        orderFilter.orderDate !== null &&
        orderFilter.orderDate !== currentDateFormatted) ||
        (orderFilter.status && orderFilter.status === "") ||
        (orderFilter.orderType && orderFilter.orderType === ""))
    ) {
      setTableData([]);
      fetchOrder();
    } else {
      const orderTableData = order.items
        .map((item) => ({
          id: item.orderId,
          customerName:
            item.first_name !== "Bespeakk" && item.first_name !== "bespeakk"
              ? item.first_name + " " + item.last_name
              : "N/A",
          phoneNumber:
            item.phone_number &&
            item.phone_number !== "" &&
            Number(item.phone_number) !== 8155006366
              ? item.phone_number
              : "N/A",
          createdAt: item.createdAt ? format(item.createdAt, "dd-MM-yyyy") : "",
          orderCreatedAt: item.createdAt,
          orderModifiedAt: item.modifiedAt,
          modifiedAt: item.modifiedAt
            ? format(item.modifiedAt, "dd-MM-yyyy")
            : "",
          tableName: item.spot_name,
          type: item.type,
          tableType:
            item.type === "2"
              ? "Delivery"
              : item.type === "1"
              ? "Take away"
              : "Dine in",
          // totalItem: item?.productData ? JSON.parse(item.productData)?.length : 0,
          totalItem: item?.productData
            ? JSON.parse(item.productData).reduce(
                (sum, product) => sum + Number(product.quantity),
                0
              )
            : 0,

          orderAmount: item.productData
            ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
              JSON.parse(item.productData).reduce(
                (sum, product) =>
                  sum + Number(product.price) * Number(product.quantity),
                0
              )
            : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
              "0",

          orderSubTotal: item.productData
            ? JSON.parse(item.productData).reduce(
                (sum, product) =>
                  sum + Number(product.price) * Number(product.quantity),
                0
              )
            : 0,

          deliveryChange:
            (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            (item?.delivery_charge ? item.delivery_charge : "0"),

          total_delivery_charge: item?.delivery_charge
            ? Number(item.delivery_charge)
            : 0,

          taxAmount:
            (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            (item?.total_tax_amount ? item.total_tax_amount : "0"),
          discountAmount:
            (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            (item.total_discount_amount ? item.total_discount_amount : "0"),
          total_tax_amount: item?.total_tax_amount
            ? Number(item.total_tax_amount)
            : 0,

          totalOrderWithTax:
            (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            (item?.order_amount ? item.order_amount : "0"),

          total_amount_with_tax: item?.order_amount
            ? Number(item.order_amount)
            : 0,

          orderNumber: item.order_number,

          productData: item.productData
            ? JSON.parse(item.productData).map((product, index) => ({
                productId: product?.productId ? Number(product.productId) : 0,
                productName: product?.productName
                  ? product?.productName.replace(/^'(.*)'$/, "$1")
                  : "",
                size:
                  product?.attribute_data &&
                  product.attribute_data !== "" &&
                  product.attribute_data !== "null" &&
                  product?.attribute_data !== "NULL"
                    ? getFormattedSize(JSON.parse(product.attribute_data))
                    : product.size,
                product_size: product.size,
                quantity: product.quantity,

                product_attribute: product?.attribute_data
                  ? JSON.parse(product.attribute_data)
                  : null,

                tablePrice: product.price
                  ? (authuserData?.data?.symbol
                      ? authuserData.data.symbol
                      : "₹") + Number(product.price)
                  : (authuserData?.data?.symbol
                      ? authuserData.data.symbol
                      : "₹") + 0,

                price: product.price ? Number(product.price) : 0,

                totalTablePrice:
                  product.quantity && product.price
                    ? (authuserData?.data?.symbol
                        ? authuserData.data.symbol
                        : "₹") +
                      Number(product.quantity) * Number(product.price)
                    : (authuserData?.data?.symbol
                        ? authuserData.data.symbol
                        : "₹") + 0,

                totalPrice:
                  product.quantity && product.price
                    ? Number(product.quantity) * Number(product.price)
                    : 0,
                food_type_two:
                  product?.food_type_2 &&
                  product.food_type_2 !== "" &&
                  product.food_type_2 !== "null" &&
                  product.food_type_2 !== "NULL"
                    ? product.food_type_2 === "0"
                      ? "No Onion/Garlic"
                      : product.food_type_2 === "1"
                      ? "Jain"
                      : "Regular"
                    : "Regular",
                product_instruction:
                  product?.product_instruction &&
                  product.product_instruction !== "" &&
                  product.product_instruction !== "null" &&
                  product.product_instruction !== "NULL"
                    ? product.product_instruction
                    : null,
                table_product_instruction:
                  product?.product_instruction &&
                  product.product_instruction !== "" &&
                  product.product_instruction !== "null" &&
                  product.product_instruction !== "NULL"
                    ? product.product_instruction
                    : "—",
                food_type_2:
                  product?.food_type_2 &&
                  product.food_type_2 !== "" &&
                  product.food_type_2 !== "null"
                    ? product.food_type_2
                    : null,
                food_type_1:
                  product?.food_type_1 &&
                  product.food_type_1 !== "" &&
                  product.food_type_1 !== "null"
                    ? product.food_type_1
                    : null,
                attribute_data:
                  product?.attribute_data &&
                  product.attribute_data !== "" &&
                  product.attribute_data !== "null" &&
                  product?.attribute_data !== "NULL"
                    ? product.attribute_data
                    : null,
                // attribute_data:"",
                map_table_id: product?.map_table_id ? product.map_table_id : 0,
                orderId: item.orderId,
                productStatus: product?.status ? product.status : "0",
                cgst: product?.cgst,
                sgst: product.sgst,
                igst: product.igst,
                vat: product.vat,
              }))
            : [],
          // email: item.email,
          zomatoSwiggyPrice: item.zomato_swiggy_other_amount
            ? `${item.zomato_swiggy_other_amount}`
            : null,
          payment: item.payment,
          orderStatus: item.status,
          user_id: item.user_id,
          firstName:
            item.first_name !== "Bespeakk" && item.first_name !== "bespeakk"
              ? item.first_name
              : "",
          lastName: item.last_name,
          instructions: item.instructions,
          brand_id: item.brand_id,
          brand_name: item.brand_name,
          brand_gst_number: item.brand_gst_number,
          address_id: item?.address_id,
          address_line1: item.address_line1,
          address_line2: item.address_line2,
          address_city: item.address_city,
          address_postal_code: item.address_postal_code,
          address_title: item.address_title,
          total_discount_amount: item.total_discount_amount,
          discount_id: item.discount_id,
          Discount_Code: item.Discount_Code,
          delivery_charge: item.delivery_charge,
          companyName: item.companyName,
          companyAddress: item.companyAddress,
          table_no: item.table_no,
          tableNo:
            item.table_no !== "" && item.table_no !== null
              ? item.table_no
              : "—",
        }))
        .filter(
          (order, index, self) =>
            index === self.findIndex((o) => o.id === order.id)
        );
      setTableData(orderTableData);
      // onPlay();
    }
    // eslint-disable-next-line
  }, [order]);

  const styleData = {
    phoneNumber: { type: { textAlign: "end" } },
    createdAt: { type: { textAlign: "center" } },
    taxAmount: { type: { textAlign: "end" } },
    discountAmount: { type: { textAlign: "end" } },
    orderAmount: { type: { textAlign: "end" } },
    totalOrderWithTax: { type: { textAlign: "end" } },
    totalItem: { type: { textAlign: "end" } },
    tableNo: { type: { textAlign: "end" } },
  };

  const styleProductData = {
    quantity: { type: { textAlign: "end" } },
    tablePrice: { type: { textAlign: "end" } },
    totalTablePrice: { type: { textAlign: "end" } },
  };

  useEffect(() => {
    const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
    if (
      (orderFilter.orderDate !== "" &&
        orderFilter.orderDate !== null &&
        orderFilter.orderDate !== currentDateFormatted) ||
      (orderFilter.status && orderFilter.status !== "") ||
      (orderFilter.orderType && orderFilter.orderType !== "")
    ) {
      setIsToggled(true);
    }
  }, [orderFilter]);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setExpandedItem(tableData.map((_, index) => index));
    }
  }, [tableData]);

  /**
   * Fetch order data
   */
  const fetchOrder = () => {
    if (orderPage > 1) {
      const requestBody = {
        p_skip: (orderPage - 2) * 10,
        p_take: 2000,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (orderFilter.orderDate !== "" && orderFilter.orderDate !== null) {
        requestBody.p_created_date = orderFilter.orderDate;
      }
      if (orderFilter.status !== "" && orderFilter.status !== null) {
        requestBody.p_status = orderFilter.status;
      }
      if (orderFilter.orderType !== "" && orderFilter.orderType !== null) {
        requestBody.p_type = orderFilter.orderType;
      }

      setCurrentPage(orderPage - 1); // Update the current page state
      dispatch(getOrderPageData(orderPage - 1));
      getAllOrderService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (orderPage - 1) * 10,
        p_take: 2000,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (orderFilter.orderDate !== "" && orderFilter.orderDate !== null) {
        requestBody.p_created_date = orderFilter.orderDate;
      }
      if (orderFilter.status !== "" && orderFilter.status !== null) {
        requestBody.p_status = orderFilter.status;
      }
      if (orderFilter.orderType !== "" && orderFilter.orderType !== null) {
        requestBody.p_type = orderFilter.orderType;
      }
      getAllOrderService(requestBody, tokenData, dispatch);
    }
  };

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  const onPageChange = (page) => {
    if (page !== currentPageNumber) {
      const requestBody = {
        p_skip: (page - 1) * 10,
        p_take: 2000,
      };
      if (orderFilter.orderDate !== "" && orderFilter.orderDate !== null) {
        requestBody.p_created_date = orderFilter.orderDate;
      }
      if (orderFilter.status !== "" && orderFilter.status !== null) {
        requestBody.p_status = orderFilter.status;
      }
      if (orderFilter.orderType !== "" && orderFilter.orderType !== null) {
        requestBody.p_type = orderFilter.orderType;
      }

      setCurrentPage(page); // Update the current page state
      dispatch(getOrderPageData(page));
      getAllOrderService(requestBody, tokenData, dispatch);
    }
  };

  const clearFilter = () => {
    dispatch(clearOrderPageData());
    dispatch(clearOrderFilterData());
    dispatch(clearOrderData());
    setIsToggled(false);
  };

  const [filteredTableData, setFilteredData] = useState(tableData);

  useEffect(() => {
    setFilteredData(
      orderStatusFilter.length && tableData.length
        ? tableData.filter((item) =>
            orderStatusFilter.includes(item.orderStatus)
          )
        : tableData
    );
  }, [tableData, orderStatusFilter]);

  useEffect(() => {
    if (
      tableData &&
      tableData.length > 0 &&
      filteredTableData &&
      filteredTableData.length > 0
    ) {
      const groupedData = Object.values(
        filteredTableData.reduce((acc, order) => {
          if (!acc[order.id]) {
            acc[order.id] = [];
          }
          acc[order.id].push(order);
          return acc;
        }, {})
      );
      setGroupedTableData(groupedData);
    }
    // eslint-disable-next-line
  }, [tableData, filteredTableData]);

  const handleClickStatusFilter = (filter) => {
    const filteredStatus =
      orderStatusFilter && orderStatusFilter.length > 0
        ? orderStatusFilter.find((item) => item === filter)
        : false;
    if (filteredStatus) {
      const filterData = orderStatusFilter.filter((item) => item !== filter);
      setOrderStatusFilter(filterData);
      return;
    }
    const newFilteredData = [...orderStatusFilter, filter];
    setOrderStatusFilter(newFilteredData);
    return;
  };
  // changes by sakshi
  const [viewOrderModal, setViewOrderModal] = useState(false);

  const toggleSound = () => {
    dispatch(setNotificationSound(!soundData));
  };

  const getFormattedSize = (data) => {
    // Group attributes by cat_name
    const groupedAttributes = data.reduce((acc, attr) => {
      const { cat_name, name } = attr;

      if (!acc[cat_name]) {
        acc[cat_name] = [];
      }

      acc[cat_name].push(name);
      return acc;
    }, {});

    // Format the output string
    const formattedSize = Object.entries(groupedAttributes)
      .map(([cat_name, names]) => `${cat_name}: ${names.join(", ")}`)
      .join(", ");

    return formattedSize;
    // }

    // // Return the original size if attribute_data is not valid
    // return product.size;
  };

  const fetchUser = async () => {
    try {
      const userData = await getUserById(
        authuserData.data.userId,
        tokenData,
        dispatch
      );
      // setBeepOption(userData.data.beep_option === '1' ? 1 : 0)
      return userData.data;
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  };
  const handleBeepToggleChange = async (e) => {
    setBeepOption(e.target.checked ? 1 : 0);
    const data = await fetchUser();
    const requestBody = {
      p_user_id: data.userId,
      p_username: data.username,
      p_first_name: data.firstName,
      p_last_name: data.lastName,
      p_email: data.email,
      p_role_permissions_id: data.rolePermissionsId,
      p_is_active: data.isActive === "1" ? "1" : "0",
      p_wp_sms_allow: data.wp_sms_allow === "1" ? "1" : "0",
      p_created_by: data?.createdBy
        ? data?.createdBy
        : authuserData.data.userId,
      p_phone_number: data.phoneNumber,
      p_brand_ids:
        data?.brand_ids && JSON.parse(data.brand_ids).length > 0
          ? JSON.parse(data.brand_ids).map((item) => ({
              id: Number(item?.brand_id),
            }))
          : null,
      p_branch_ids:
        data?.branch_ids && JSON.parse(data.branch_ids).length > 0
          ? JSON.parse(data.branch_ids).map((item) => ({
              id: Number(item?.branch_id),
            }))
          : null,
      p_IsDeleted: data.isDeleted,
      p_modified_by: authuserData.data.userId,
      p_beep_option: e.target.checked === true ? "1" : "0",
    };

    dispatch(
      addUpdateUserService(
        requestBody,
        tokenData,
        "update",
        false,
        false,
        authuserData
      )
    );
  };

  const generatePDFAndUpload = async (orderData) => {
    const { companyLogo, companyName, companyOwnerName, companyPhneNumber } =
      authuserData.data;
    const cleanProductData = orderData.productData;
    const isSingleProduct = cleanProductData.length === 1;
    const totalWithoutTax =
      Number(orderData.total_amount_with_tax) -
      Number(orderData.total_tax_amount) +
      Number(orderData.total_discount_amount) -
      Number(orderData?.delivery_charge);

    const generateTaxInfo = (item) => {
      let taxInfo = "";
      const price = Number(item.price) || 0;

      const quantity = item.quantity || 1;
      const cgst = Number(item.cgst) || 0;
      const sgst = Number(item.sgst) || 0;
      const igst = Number(item.igst) || 0;
      const vat = Number(item.vat) || 0;

      if (cgst > 0)
        taxInfo += `<div><span class="short-font">CGST:</span> <span class="short-font">${
          (quantity * price * cgst) / 100
        }</span></div>`;
      if (sgst > 0)
        taxInfo += `<div><span class="short-font">SGST:</span> <span class="short-font">${
          (quantity * price * sgst) / 100
        }</span></div>`;
      if (igst > 0)
        taxInfo += `<div><span class="short-font">IGST:</span> <span class="short-font">${
          (quantity * price * igst) / 100
        }</span></div>`;
      if (vat > 0)
        taxInfo += `<div><span class="short-font">VAT:</span> <span class="short-font">${
          (quantity * price * vat) / 100
        }</span></div>`;

      return taxInfo;
    };

    const invoiceHtml = `
    <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 5px;
            text-align: left;
            font-size: 12px;
          }
          .header {
            text-align: center;
            margin-bottom: 10px;
          }
          .company-info, .order-info {
            margin: 5px;
            text-align: center;
          }
          .invoice-table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 5px;
            font-size: 10px;
          }
          .invoice-table th, .invoice-table td {
            padding: 5px;
            border: 1px solid #ddd;
            text-align: left;
            vertical-align: top;
          }
          .total {
            font-weight: bold;
          }
          .thank-you-message {
            text-align: center;
            margin-top: 10px;
          }
          ${
            isSingleProduct
              ? `
          .invoice-table {
            margin-top: 0;
          }
          .company-info, .order-info {
            margin-bottom: 0;
          }
          .thank-you-message {
            margin-top: 5px;
          }
          `
              : ""
          }
          @media print {
            .page-break {
              page-break-before: always;
            }
            .invoice-table {
              page-break-inside: auto;
            }
            .invoice-table tr {
              page-break-inside: avoid;
              page-break-after: auto;
            }
          }
          .short-font {
          font-size: 8px
         }
        </style>
      </head>
      <body>
        <div class="header">
          <p class="company-info">
            <strong>${orderData?.companyName}</strong><br />
            ${
              orderData?.branch_tax_type === "GST"
                ? "GSTIN - " + orderData?.branch_gst_number
                : ""
            }<br />
            ${orderData?.companyAddress}
          </p>
          <p class="order-info">
            Date: ${
              orderData?.createdAt ? orderData?.createdAt : format(new Date())
            }<br />
            Dine In: ${orderData.type === "0" ? "Yes" : "No"}<br />
            Table Number: ${
              orderData.type === "0" ? orderData.table_no : "—"
            }<br />
            Cashier: ${orderData?.companyName}
          </p>
        </div>
        <div class="invoice-content">
          <table class="invoice-table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Tax</th>
                <th></th>
                <th>Qty</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              ${cleanProductData
                .map(
                  (item) => `
                  <tr>
                    <td>${item.productName} - ${
                    item.food_type_2 === "2"
                      ? "Regular"
                      : item.food_type_2 === "1"
                      ? "Jain"
                      : item.food_type_2 === "0"
                      ? "No onion/garlic"
                      : ""
                  }</td>
                    <td>${generateTaxInfo(item)}</td>
                    <td>
                      ${
                        item.attribute_data &&
                        item.attribute_data !== "" &&
                        item.attribute_data !== "null" &&
                        item.attribute_data !== "NULL"
                          ? `<div class="attribute-data">
                            ${JSON.parse(item.attribute_data)
                              .map(
                                (attr, attrIndex) => `
                                <div key="${attrIndex}" class="attr-div">
                                  ${attr.cat_name}: ${attr.name}
                                </div>`
                              )
                              .join("")}
                          </div>`
                          : ""
                      }
                    </td>
                    <td>${item.quantity}</td>    
                    <td>${item.price}</td>
                    <td>${Number(item.quantity) * parseFloat(item.price)}</td>
                  </tr>`
                )
                .join("")}
              <tr>
                <td class="total">Sub Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="total">${
                  orderData?.country_symbol || "₹"
                }${totalWithoutTax}</td>
              </tr>
              <tr>
                <td>TotalTax</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>${Number(orderData?.total_tax_amount)}</td>
              </tr>
              ${
                orderData?.discount_id
                  ? `
                <tr>
                  <td>Discount Amount</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>${Number(orderData?.total_discount_amount).toFixed(
                    2
                  )}</td>
                </tr>`
                  : ""
              }
              ${
                orderData.type === "2"
                  ? `
                <tr>
                  <td>Delivery charge</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>${orderData?.delivery_charge || 0}</td>
                </tr>`
                  : ""
              }
              <tr>
                <td class="total">Grand Total</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="total">${orderData?.country_symbol || "₹"}${Number(
      orderData?.total_amount_with_tax
    )}</td>
              </tr>
            </tbody>
          </table>
          <p class="thank-you-message">Thanks for visiting!</p>
        </div>
      </body>
    </html>`;

    // Create a temporary div to render the HTML content
    const tempDiv = document.createElement("div");
    tempDiv.style.position = "absolute";
    tempDiv.style.left = "-9999px";
    tempDiv.style.top = "-9999px";
    tempDiv.style.width = "210mm";
    tempDiv.style.height = "297mm";
    tempDiv.style.overflow = "hidden";
    tempDiv.innerHTML = invoiceHtml;
    document.body.appendChild(tempDiv);

    try {
      // Convert HTML to Canvas
      const canvas = await html2canvas(tempDiv, { scale: 1.5 });
      const imgData = canvas.toDataURL("image/jpeg", 0.7);
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight); // Use JPEG

      // Convert PDF to Blob
      const pdfBlob = pdf.output("blob");
      const file = new File(
        [pdfBlob],
        `invoice-${orderData?.orderNumber}.pdf`,
        {
          type: "application/pdf",
        }
      );

      // Prepare upload data
      const uploadData = {
        pdf: file,
        p_order_user: orderData.firstName + " " + orderData.lastName,
        p_order_phone_number: orderData.phoneNumber,
        p_total_amt: orderData.totalOrderWithTax,
        p_company_name: companyName,
        // p_branch_user_phone_number: companyPhneNumber,
        // p_branch_user_name: companyOwnerName,
        // p_branch_Address: user.data.branchAddress,
      };
      // return
      dispatch(uploadOrderPDFService(uploadData, tokenData, navigate));
    } catch (error) {
      console.error("Error generating PDF or uploading:", error);
    } finally {
      // Clean up by removing the temporary div
      document.body.removeChild(tempDiv);
    }
  };

  const [totalAmt, setTotalAmt] = useState(0);
  useEffect(() => {
    const amt = tableData.reduce((accumulator, current) => {
      // Check if the orderStatus is "3"
      if (current.orderStatus === "3") {
        return accumulator + current.total_amount_with_tax;
      }
      return accumulator; // Return the accumulator unchanged if the condition is not met
    }, 0);
    const totalAmountWithRupeeSymbol = `₹${amt}`;
    setTotalAmt(totalAmountWithRupeeSymbol);
  }, [tableData]);
 
  return (
    <>
      <div className="main-section order-new">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div className="order-number-show">
                <div>
                  <div className="title">
                    <h4>
                      {orderActionData && orderActionData.length > 0
                        ? orderActionData[0].name
                        : "Orders"}
                    </h4>
                  </div>
                  <div className="beep-option">
                    <h6>Beep</h6>
                    <Toggle
                      className="beep-class"
                      onChange={handleBeepToggleChange}
                      checked={beepOption === 1}
                    />
                  </div>
                  <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate("/home")}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {orderActionData && orderActionData.length > 0
                        ? orderActionData[0].name
                        : "Orders"}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>

              <div className="btn-groups">
                {((orderFilter.orderDate !== `${filterDateData}` &&
                  orderFilter.orderDate !== "" &&
                  orderFilter.orderDate !== null) ||
                  (orderFilter.status !== "" && orderFilter.status !== null) ||
                  (orderFilter.orderType !== "" &&
                    orderFilter.orderType !== null)) && (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {((orderFilter.orderDate !== `${filterDateData}` &&
                        orderFilter.orderDate !== "") ||
                        (orderFilter.status !== "0,1,2" &&
                          orderFilter.status !== "") ||
                        orderFilter.orderType !== "") && (
                        <Dropdown.Item onClick={clearFilter}>
                          Clear Filters
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <Button
                  data-bs-toggle="tooltip"
                  title="Filter"
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                {tableData && tableData.length > 0 && (
                  <ExcelData
                    excelData={tableData}
                    fileName={"image"}
                  ></ExcelData>
                )}
                <Button
                  data-bs-toggle="tooltip"
                  title="Toggle Sound"
                  onClick={toggleSound}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      {!soundData ? (
                        <GiSpeakerOff style={{ fontSize: 25 }} />
                      ) : (
                        <GiSpeaker style={{ fontSize: 25 }} />
                      )}
                    </div>
                  }
                ></Button>
                {/* <Button
                  data-bs-toggle="tooltip"
                  title="Add Order"
                  onClick={() => navigate("/add-order")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button> */}

                {/* changes by sakshi */}
                {/* <Button
                  data-bs-toggle="tooltip"
                  title="View Orders"
                  name="View"
                  onClick={() => handleViewOrderModal()}
                ></Button> */}
              </div>
            </div>
            {isToggled && <Filter page="order"></Filter>}

            <div className="main-box-color">
              {order &&
                order?.items &&
                order.items.length > 0 &&
                tableData.length > 0 && (
                  <div className="heading-boxes">
                    <div className="boxes-container">
                      <div
                        className={`box Pending ${
                          orderStatusFilter &&
                          orderStatusFilter.length > 0 &&
                          orderStatusFilter.filter((item) => item === "0")
                            .length > 0
                            ? "box-active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickStatusFilter("0")}
                      >
                        <h6>Pending</h6>
                        <span>
                          {" "}
                          {
                            tableData.filter((item) => item.orderStatus === "0")
                              .length
                          }
                        </span>
                      </div>
                      <div
                        className={`box Confirmed ${
                          orderStatusFilter &&
                          orderStatusFilter.length > 0 &&
                          orderStatusFilter.filter((item) => item === "1")
                            .length > 0
                            ? "box-active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickStatusFilter("1")}
                      >
                        <h6>Confirmed</h6>
                        <span>
                          {
                            tableData.filter((item) => item.orderStatus === "1")
                              .length
                          }
                        </span>
                      </div>
                      <div
                        className={`box In-progress ${
                          orderStatusFilter &&
                          orderStatusFilter.length > 0 &&
                          orderStatusFilter.filter((item) => item === "2")
                            .length > 0
                            ? "box-active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickStatusFilter("2")}
                      >
                        <h6 style={{ textAlign: "left" }}>In progress</h6>
                        <span>
                          {
                            tableData.filter((item) => item.orderStatus === "2")
                              .length
                          }
                        </span>
                      </div>
                      <div
                        className={`box Completed ${
                          orderStatusFilter &&
                          orderStatusFilter.length > 0 &&
                          orderStatusFilter.filter((item) => item === "3")
                            .length > 0
                            ? "box-active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickStatusFilter("3")}
                      >
                        <h6>Completed</h6>
                        <span>
                          {
                            tableData.filter((item) => item.orderStatus === "3")
                              .length
                          }
                        </span>
                      </div>
                      <div
                        className={`box Cancelled ${
                          orderStatusFilter &&
                          orderStatusFilter.length > 0 &&
                          orderStatusFilter.filter((item) => item === "4")
                            .length > 0
                            ? "box-active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClickStatusFilter("4")}
                      >
                        <h6>Cancelled</h6>
                        <span>
                          {
                            tableData.filter((item) => item.orderStatus === "4")
                              .length
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              <div className="box Amount">Amount : {totalAmt}</div>
            </div>
            {order &&
            order?.items &&
            order.items.length > 0 &&
            tableData.length > 0 &&
            filteredTableData.length > 0 &&
            groupedTableData.length > 0 ? (
              <>
                {groupedTableData.map((orders, index) => (
                  <Accordion
                    key={index}
                    activeKey={expandedItem}
                    onSelect={toggleAccordionItem}
                    className={`product-accordions order-bg-remove`}
                  >
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header
                        className={`p-relative  ${
                          orders.length > 0 && orders[0].orderStatus
                            ? getOrderStatus(orders[0].orderStatus)
                            : ""
                        }`}
                      >
                        <div className={`product-detail`}>
                          <div className="product-name">
                            {orders[0].orderNumber}
                          </div>
                          <div className="gif-iocn">
                            {orders[0]?.orderModifiedAt &&
                              orders[0].orderCreatedAt &&
                              orders[0]?.orderModifiedAt >
                                orders[0].orderCreatedAt &&
                              orders[0].orderStatus !== "3" &&
                              orders[0].orderStatus !== "4" && (
                                <img src={newOrderGif} alt="new order"></img>
                              )}
                          </div>
                        </div>

                        {!expandedItem.includes(index) ? (
                          // <MdRemove className='arrow-icon' />
                          <FaAngleDown className="arrow-icon" />
                        ) : (
                          // <MdAdd className='arrow-icon' />
                          <FaAngleUp className="arrow-icon" />
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <span>
                            {orders[0]?.brand_gst_number &&
                              orders[0].brand_gst_number !== "" && (
                                <span>
                                  <b>GSTIN -&nbsp;</b>
                                  {orders[0].brand_gst_number}
                                </span>
                              )}
                          </span>
                          <div className="order-heading">
                            <div>
                              <span className="table-type">
                                <b>Type -&nbsp;</b>
                                {orders[0].tableType}
                              </span>
                              {(orders[0].payment === "5" ||
                                orders[0].payment === "6") && (
                                <span className="table-type">
                                  <b>Zomato/Swiggy Price -&nbsp;</b>₹
                                  {orders[0].zomatoSwiggyPrice
                                    ? orders[0].zomatoSwiggyPrice
                                    : "0.00"}
                                </span>
                              )}
                            </div>

                            {orders[0].phoneNumber !== "N/A" &&
                              orders[0].phoneNumber !== "8155006366" &&
                              orders[0].phoneNumber !== 8155006366 && (
                                <span className="table-type">
                                  <IoLogoWhatsapp
                                    className="icon-color"
                                    onClick={() => {
                                      generatePDFAndUpload(orders[0]);
                                    }}
                                  />
                                </span>
                              )}
                          </div>
                        </div>
                        <CustomTable
                          page="order"
                          showKey={showKey}
                          showActions={showActionBool ? showActions : false}
                          showTooltip={true}
                          data={orders}
                          heading={columns}
                          checkBool={false}
                          styleData={styleData}
                        />
                        <div>
                          {orders[0]?.address_id &&
                            orders[0].address_id !== "" && (
                              <>
                                <b>Address -&nbsp;</b>
                                {orders[0].address_line1},{" "}
                                {orders[0].address_line2},{" "}
                                {orders[0].address_city},{" "}
                                {orders[0].address_postal_code} (Type -{" "}
                                {orders[0].address_title})
                              </>
                            )}
                        </div>
                        <div>
                          {orders[0]?.instructions &&
                            orders[0].instructions !== "" &&
                            orders[0].instructions !== "null" &&
                            orders[0].instructions !== "NULL" && (
                              <>
                                <HiMiniInformationCircle className="i-icon-class" />
                                &nbsp;{orders[0].instructions}
                              </>
                            )}
                        </div>
                        {orders[0].productData &&
                          orders[0].productData.length > 0 &&
                          orders.map((order, productIndex) => {
                            return (
                              <div className="col-12" key={productIndex}>
                                <Accordion
                                  defaultActiveKey={["0"]}
                                  className="product-accordion"
                                >
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                      <IoIosArrowForward />
                                      Product Details
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <CustomTable
                                        page="order"
                                        showKey={showProductKeys}
                                        showActions={showProductActions}
                                        showTooltip={true}
                                        data={order.productData}
                                        heading={productHeading}
                                        newOrderData={order}
                                        styleData={styleProductData}
                                      />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            );
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </>
            ) : (
              <div className="input-search">
                <div className="no-record">
                  <h3 data-bs-toggle="tooltip" title="No records!">
                    No records!
                  </h3>
                </div>
              </div>
            )}
            {order.totalRecords > 0 &&
              order.totalRecords > order.items.length &&
              order.totalRecords > 10 && (
                <CustomPagination
                  totalRecords={order.totalRecords}
                  currentPage={currentPageNumber}
                  onPageChange={onPageChange}
                />
              )}
          </div>
          <Footer />
        </div>
      </div>

      {/* changes by sakshi */}
      {viewOrderModal && (
        <div className="modal-overlay">
          <div className="modal-content-1">
            <div>
              <h5>Orders review</h5>
              <button
                className="modal-close"
                onClick={() => setViewOrderModal(!viewOrderModal)}
              >
                <MdOutlineCancel />
              </button>
            </div>
            {/* {pendingOrders && pendingOrders.length > 0 ? (
              <table border="1">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Attributes</th>
                    <th>Jain/regular</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingOrders.map((order, index) => (
                    <tr key={index}>
                      <td>{order.product_name}</td>
                      <td>{order.number_of_orders}</td>
                      <td>dough: cheese burst, size: regular</td>
                      <td>Regular</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <b>There are no pending orders!</b>
            )} */}
          </div>
        </div>
      )}
    </>
  );
}
