import { getApiService } from "../../services/API-services/GetService";
import { Dispatch } from "redux";
import { setLoader,clearLoader } from "../../redux/Actions/LoaderAction";
import { API_URL,removePersistStorage,toastClass, ValidationMessage } from "../../Utils/Utils";
import { toast } from "react-toastify";


let errorShow = false;
export const dashboardService = (
    token: string, dispatch: Dispatch
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        dispatch(setLoader())
        const response = await getApiService(API_URL.BASE_URL + API_URL.DASHBOARD , token);
        if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
          resolve(response);
        }
        else {
          if (!document.querySelector(toastClass)) {
            toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Order permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
          }
          errorShow = true;
          if (response?.message && response.message === 'Unauthorized access!') {
            removePersistStorage(dispatch);
          }
        }
        resolve(response)
      } catch (error) {
        if (errorShow === false) {
          toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
  
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    });
  };