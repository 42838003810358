import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown } from "react-bootstrap";
import "../Tag/Tag.scss";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../components/Pagination/Pagination";
import { getAllAttributeService } from "./AttributeService/AttributeService";
import UtilsData from "../../Utils/Utils";
import { clearAttributeFilterData } from "../../redux/Actions/AttributeAction/AttributeFilterAction";
import { clearAttributeData } from "../../redux/Actions/AttributeAction/AttributeAction";
import {
  clearAttributePageData,
  getAttributePageData,
} from "../../redux/Actions/AttributeAction/AttributePageAction";

//Show toggle which is to be displayed in table
const showToggle = {
  status: true,
  delete: false,
};

const showKey = {
  title: true,
};

export default function Attribute() {
  const attribute = useSelector((state) => state.attribute);
  const [currentPage, setCurrentPage] = useState(1);
  const attributeFilter = useSelector((state) => state.attributeFilter);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = UtilsData();
  const attributePage = useSelector((state) => state.attributePage?.items);

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [attributeActionData, setattributeActionData] = useState([]);

  const [columns, setColumns] = useState([]);

  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setattributeActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (
      attributeActionData[0]?.view &&
      attributeActionData[0].view === "0" &&
      attributeActionData[0]?.edit &&
      attributeActionData[0].edit === "0" &&
      attributeActionData[0]?.add &&
      attributeActionData[0].add === "0" &&
      attributeActionData[0]?.delete &&
      attributeActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Title",
          selector: (row) => row.title,
        },
        {
          name: "Status",
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Title",
          selector: (row) => row.title,
        },
        {
          name: "Status",
        },
        {
          name: "Actions",
          selector: (row) => row.action,
        },
      ]);
    }
  }, [attributeActionData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (attributeActionData[0]?.view && attributeActionData[0].view === "1") ||
      !attributeActionData
        ? true
        : false,
    showEdit:
      (attributeActionData[0]?.edit && attributeActionData[0].edit === "1") ||
      !attributeActionData
        ? true
        : false,
    showDelete:
      (attributeActionData[0]?.delete &&
        attributeActionData[0].delete === "1") ||
      !attributeActionData
        ? true
        : false,
    showUndo:
      (attributeActionData[0]?.edit && attributeActionData[0].edit === "1") ||
      !attributeActionData
        ? true
        : false,
    navigate:
      (attributeActionData[0]?.edit && attributeActionData[0].edit === "1") ||
      !attributeActionData
        ? "/add-attribute"
        : false,
  };

  useEffect(() => {
    setCurrentPage(attributePage);
  }, [attributePage]);

  const [isToggled, setIsToggled] = useState(false);

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Fetch attribute data
   */
  const fetchAttribute = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
    };
    if (attributePage > 1) {
      requestBody.p_skip = (attributePage - 2) * 10;
      dispatch(getAttributePageData(attributePage - 1));
    } else {
      requestBody.p_skip = (attributePage - 1) * 10;
      dispatch(getAttributePageData(attributePage));
    }

    if (attributeFilter.name !== "" && attributeFilter.name !== null) {
      requestBody.p_title = attributeFilter.name;
    }
    if (attributeFilter.isActive !== "" && attributeFilter.isActive !== null) {
      requestBody.p_isActive = attributeFilter.isActive;
    } else {
      requestBody.p_isActive = "1";
    }

    if (attributeFilter.isDelete !== "" && attributeFilter.isDelete !== null) {
      requestBody.p_IsDeleted = attributeFilter.isDelete;
    }
    getAllAttributeService(requestBody, tokenData, dispatch);
  };

  useEffect(() => {
    if (attribute && attribute.items.length === 0) {
      fetchAttribute();
    } else {
      if (
        attributeFilter.name !== "" ||
        attributeFilter.isActive !== "" ||
        attributeFilter.isDelete !== ""
      ) {
        setIsToggled(true);
      }
    }
    // eslint-disable-next-line
  }, [attribute]);

  /**
   * Getting attribute list on page change
   * @param {*} pageNumber
   */
  const onPageChange = (pageNumber) => {
    if (currentPage !== pageNumber) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_limit: 10,
      };
      if (attributeFilter.name !== "") {
        requestBody.p_title = attributeFilter.name;
      }
      if (attributeFilter.isActive !== "") {
        requestBody.p_isActive = attributeFilter.isActive;
      } else {
        requestBody.p_isActive = "1";
      }

      if (attributeFilter.isDelete !== "") {
        requestBody.p_IsDeleted = attributeFilter.isDelete;
      }
      getAllAttributeService(requestBody, tokenData, dispatch);
      dispatch(getAttributePageData(pageNumber));
      setCurrentPage(pageNumber);
    }
  };

  /**
   * Clear filtered data
   */
  const clearFilters = () => {
    dispatch(clearAttributeFilterData());
    dispatch(clearAttributeData());
    dispatch(clearAttributePageData());
    setIsToggled(false);
  };

  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {attributeActionData && attributeActionData.length > 0
                      ? attributeActionData[0].name
                      : "Attributes"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{attributeActionData && attributeActionData.length > 0
                      ? attributeActionData[0].name
                      : "Attributes"}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {(attributeFilter.name !== "" ||
                  attributeFilter.isActive !== "" ||
                  attributeFilter.isDelete !== "") && (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filter
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <Button
                  data-bs-toggle="tooltip"
                  title="Filter"
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                {attribute &&
                  attribute.items[0]?.data &&
                  attribute.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={attribute.items[0]?.data}
                      fileName={"image"}
                    ></ExcelData>
                  )}
                {((attributeActionData[0]?.add &&
                  attributeActionData[0].add === "1") ||
                  !attributeActionData) && (
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add Tag"
                    onClick={() => navigate("/add-attribute")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {isToggled && <Filter page="attributes"></Filter>}
            {attribute &&
              attribute.items[0]?.data &&
              attribute.items[0].data && (
                <>
                  <CustomTable
                    page="attributes"
                    showToggle={showToggle}
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    data={attribute.items[0].data}
                    heading={columns}
                    disabled={
                      attributeActionData[0]?.edit &&
                      attributeActionData[0].edit === "0"
                    }
                  />

                  {attribute.items[0].total_records >
                    attribute.items[0].data.length &&
                    attribute.items[0].total_records > 10 && (
                      <CustomPagination
                        totalRecords={attribute.items[0].total_records}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                      />
                    )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
