const CustomerFilterInitialState = {
    email: '', 
    phone: ''
};

/**
 * Store CUSTOMER filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const CustomerFilterReducer = (state = CustomerFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_CUSTOMER_FILTER':
            return {
                ...state,
                email: action.payload.email,
                phone: action.payload.phone
            }
        case 'CLEAR_CUSTOMER_FILTER':
            return CustomerFilterInitialState;
        default:
            return state;
      };
}