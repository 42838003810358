/**
 * set ORDER page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getOrderPageData = (page) => ({
    type: 'GET_ORDER_PAGE',
    payload: page
})

/**
 * Clear ORDER page data
 * @returns {*}
 */
export const clearOrderPageData = () => ({
    type: 'CLEAR_ORDER_PAGE'
})