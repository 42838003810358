const productPageInitialState = { 
    items: 1
};

/**
 * store product page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number} 
 */
export const productPageReducer = (state = productPageInitialState, action) => {
    switch (action.type) {
        case 'GET_PRODUCT_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_PRODUCT_PAGE':
            return productPageInitialState;
        default:
            return state;
      };
}