import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from '../../../components/input/Input';
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from '../../../components/Sidebar/Sidebar';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import Button from '../../../components/Button/Button';
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from '../../../Utils/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import Toggle from '../../../components/Toggle/Toggle';
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateAttributeService } from '../AttributeService/AttributeService';

//Validation schema
const validationSchema = Yup.object({
  attributeTitle: Yup.string().required(ValidationMessage.TITLE_REQUIRED)
})

export default function AddAttribute() {

  //Setting initial values
  const [initialValues, setInitialValues] = useState({
    attributeTitle: '',
    status: true
  })

  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector(state => state.auth.user);
  const attributePage = useSelector(state => state.attributePage?.items);
  const token = UtilsData();

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        attributeTitle: Data.data.title,
        status: (Data.data.isActive === '0' || Data.data.isActive === true || Data.data.isActive === 1) ? false : true
      })
    }
  }, [Data])

  /**
   * Handling form submission
   * @param {*} values 
   */
  const handleSubmit = (values) => {
    if (!Data) {
      const requestBody = {
        p_title: values.attributeTitle,
        p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_createdBy: userData.data.userId
      }
      dispatch(addUpdateAttributeService(requestBody, token, 'add', navigate));
    }
    else {
      const requestBody = {
        p_id: Data.data.attributeId,
        p_title: values.attributeTitle,
        p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_IsDeleted: Data.data.isDeleted,
        p_createdBy: Data.data.createdBy,
        p_modifiedBy: userData.data.userId
      }

      if (Data?.length && attributePage > 1) {
        if ((values.status === '0' || values.status === 0 || values.status === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateAttributeService(requestBody, token, 'update', navigate, attributePage), 'update');
          }
          else {
            handlesaveClick(dispatch, addUpdateAttributeService(requestBody, token, 'update', navigate, attributePage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateAttributeService(requestBody, token, 'update', navigate, attributePage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateAttributeService(requestBody, token, 'update', navigate), 'update');
      }
    }
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Attribute</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/attributes")}>Attributes</Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Attribute</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Attribute</h4>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
              {({ setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-12">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Attribute title <span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="attributeTitle"
                          label="Title"
                          placeholder="Enter Attribute title"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          maxLength={20}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field name='status'
                          className='toggle'
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || (!Data)) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/attributes', navigate)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}