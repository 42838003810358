import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import { Breadcrumb, Dropdown } from 'react-bootstrap'
import CustomTable from '../../components/Table/Table'
import Footer from '../../components/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import { FaFilter } from 'react-icons/fa'
import Filter from '../../components/Filter/Filter'
import Button from '../../components/Button/Button'
import ExcelData from '../../services/Regular-services/ExcelExport'
import { useDispatch, useSelector } from 'react-redux'
import { clearCustomerData } from '../../redux/Actions/CustomerAction/CustomerAction'
import { clearCustomerPageData, getCustomerPageData } from '../../redux/Actions/CustomerAction/CustomerPageAction'
import { getCustomerListService } from './CustomerService/CustomerService'
import UtilsData from '../../Utils/Utils'
import { formatUTCDate } from '../../services/Regular-services/DateService'
import CustomPagination from '../../components/Pagination/Pagination'
import { GrMoreVertical } from 'react-icons/gr'
import { clearCustomerFilterData } from '../../redux/Actions/CustomerAction/CustomerFilterAction'

const columns = [
  {
    name: 'Name ',
    selector: row => row.name,
  },
  // {
  //   name: 'Email ',
  //   selector: row => row.email,
  // },
  {
    name: 'Phone Number ',
    selector: row => row.number,
  },
  {
    name: 'Total Orders',
    selector: row => row.orders,
  },
  {
    name: 'Actions',
    selector: row => row.actions,
  }
];


//Show toggle which is to be displayed in table
const showToggle = {
  status: false,
  delete: false
}


//Show actions which is to be displayed in table
const showActions = {
  showView: true,
  showEdit: false,
  showDelete: false,
  navigate: '/customer-view'
}

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  // email: true,
  number: true,
  orders: true
}


export default function Customer() {
  const [isToggled, setIsToggled] = useState(false);
  const navigate = useNavigate();
  const customerFilter = useSelector(state => state.customerFilter);
  const customerPage = useSelector(state => state.customerPage?.items);
  const customer = useSelector(state => state.customer);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const token = UtilsData();
  const [customerListData, setCustomerListData] = useState([]);

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  useEffect(() => {
    setCurrentPage(customerPage)
  }, [customerPage]);

  /**
   * Fetch user data
   */
  const fetchUsers = () => {
    const requestData = {
      skip: 0,
      take: 10
    }
    if (customerFilter.email !== '' && customerFilter.email !== null) {
      requestData.search_email = customerFilter.email;
    }
    if (customerFilter.phone !== '' && customerFilter.phone !== null) {
      requestData.search_phone_number = customerFilter.phone;
    }
    if (customerPage > 1) {
      requestData.skip = (customerPage - 2) * 10;
      setCurrentPage(customerPage - 1); // Update the current page state
      dispatch(getCustomerPageData(customerPage - 1))
    }
    else {
      requestData.skip = (customerPage - 1) * 10;
      setCurrentPage(customerPage); // Update the current page state
      dispatch(getCustomerPageData(customerPage))
    }
    getCustomerListService(requestData, token, dispatch);
  }

  useEffect(() => {
    if (customer && customer.items && customer.items.length === 0) {
      fetchUsers();
    }
    else {
      if (customerFilter.email !== '' || customerFilter.phone !== '') {
        setIsToggled(true);
      }
    }

    if (customer.items[0]?.data && customer.items[0].data.length > 0) {
      const transformedAdminData = customer.items[0].data.map(item => ({
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
        number: item.phoneNumber,
        orders: item.order_count,
        userId: item.userId,
        username: item.username,
        roleName: item.role_name,
        createdAt: formatUTCDate(item.createdAt),
        lastLoginAt: formatUTCDate(item.lastLoginAt),
        isActive: item.isActive,
        isDeleted: item.isDeleted,
        firstName: item.firstName,
        lastName: item.lastName,
        rolePermissionsId: item.rolePermissionsId,
        typeId: item.typeId,
        createdBy: item.createdBy
      }));
      setCustomerListData(transformedAdminData);
    }
    else {
      setCustomerListData([]);
    }
    // eslint-disable-next-line
  }, [customer]);

  /**
  * Integrate listing API on page change
  */
  const onPageChange = (pageNumber) => {
    const requestData = {
      skip: (pageNumber - 1) * 10,
      take: 10
    }
    if (customerFilter.email !== '' && customerFilter.email !== null) {
      requestData.search_email = customerFilter.email;
    }
    if (customerFilter.phone !== '' && customerFilter.phone !== null) {
      requestData.search_phone_number = customerFilter.phone;
    }
    if (pageNumber !== currentPage) {
      getCustomerListService(requestData, token, dispatch);
    }
    setCurrentPage(pageNumber); // Update the current page state
    dispatch(getCustomerPageData(pageNumber));
  };

  const clearFilters = () => {
    dispatch(clearCustomerFilterData());
    setIsToggled(false);
    dispatch(clearCustomerPageData());
    dispatch(clearCustomerData());
  }

  return (
    <>
      <div className='main-section'>
        <div className='sidebar-content'>
          <Sidebar />
        </div>
        <div className='body-content'>
          <Header />
          <div className='table-body-content'>
            <div className='roles'>
              <div>
                <div className='title'>
                  <h4>Customer List</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Customer List</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className='btn-groups'>
                {((customerFilter.email !== '' && customerFilter.email !== null) || (customerFilter.phone !== '' && customerFilter.phone !== null)) && (
                  <Dropdown className='header-dropdown action-btn' >
                    <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                      <GrMoreVertical />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={clearFilters}>Clear Filters</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <Button data-bs-toggle='tooltip' title='Filter' onClick={handleButtonClick} className="btn btn-fillter-main" data-testid="Filter" name={<div className="filter" >
                  <FaFilter className="filter-icon" />
                </div>}></Button>
                {customer.items[0]?.data && customer.items[0].data.length > 0 && (
                  <ExcelData excelData={customer.items[0]?.data} fileName={'customer'}></ExcelData>
                )}
              </div>
            </div>
            {isToggled &&
              <Filter page='customer'></Filter>}

            {customer && customer.items[0]?.data && customer.items[0].data && (
              <>
                <CustomTable
                  page='customer'
                  showKey={showKey}
                  showActions={showActions}
                  showTooltip={true}
                  data={customerListData}
                  heading={columns}
                  showToggle={showToggle} />

                {(customer.items[0]?.total_records > 0 && customer.items[0].total_records > customer.items[0].data.length && customer.items[0].total_records > 10) && (
                  <CustomPagination totalRecords={customer.items[0].total_records} currentPage={currentPage} onPageChange={onPageChange} />
                )}
              </>
            )
            }
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}