const orderPageInitialState = { 
    items: 1
};

/**
 * Store leaf level category page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const OrderPageReducer = (state = orderPageInitialState, action) => {
    switch (action.type) {
        case 'GET_ORDER_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_ORDER_PAGE':
            return orderPageInitialState;
        default:
            return state;
      };
}