/**
 * Set warehouse filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getWarehouseFilterData = ({name, isActive, isDelete}) => ({
    type: 'GET_WAREHOUSE_FILTER',
    payload: {name, isActive, isDelete}
})

/**
 * Clear warehouse filtered data
 * @returns {*}
 */
export const clearWarehouseFilterData = () => ({
    type: 'CLEAR_WAREHOUSE_FILTER'
})