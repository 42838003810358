import { Dispatch } from "redux";
import { clearLoader, setLoader } from "../../redux/Actions/LoaderAction";
import { SequenceRequest } from "./SequenceModal";
import { putApiService } from "../API-services/PutService";
import { API_URL, ValidationMessage, removePersistStorage } from "../../Utils/Utils";
import { toast } from "react-toastify";
import { getCategoryPageData } from "../../redux/Actions/categoryAction/CategoryPageAction";
import { clearCategoryData } from "../../redux/Actions/categoryAction/CategoryAction";
import { getSubCategoryPageData } from "../../redux/Actions/subCategoryAction/SubCategoryPageAction";
import { clearSubCategoryData } from "../../redux/Actions/subCategoryAction/SubCategoryAction";

let errorShow = false;

/*
 * Service for adding and updating warehouse
*/
export const updateSequenceService = (
    sequenceRequest: SequenceRequest,
    token: string, pageNumber?: any) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setLoader());
            const response = await putApiService(API_URL.BASE_URL + API_URL.SEQUENCE, token, sequenceRequest);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                if(sequenceRequest.p_Type === 'category'){
                    dispatch(getCategoryPageData(pageNumber));
                    dispatch(clearCategoryData());
                }
                else if(sequenceRequest.p_Type === 'sub_category'){
                    dispatch(getSubCategoryPageData(pageNumber));
                    dispatch(clearSubCategoryData());
                }
                toast.success('Sequence updated successfully!');
            }
            else {
                toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
            }
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }
            
        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    };
};