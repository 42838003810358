import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
// import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import Toggle from '../../../components/Toggle/Toggle';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from '../../../Utils/Utils';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import CustomTable from '../../../components/Table/Table';
import { addUpdateCompanyService, getCompanyById } from '../Company-service/companyService';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { clearCompanyPageData } from '../../../redux/Actions/companyAction/CompanyPageAction';

const columns = [
  {
    name: 'Module Name',
    selector: row => row.module,
    sortable: true,
  },
  {
    name: 'Is Allow',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'Add',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'View',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'Update ',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'Delete ',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'Select All ',
    cell: (row) => <Toggle checked={row.toggle} />,
  }
];

const showKey = {
  "name": true
}

export default function AddCompany() {

  // changes by sakshi
  const location = useLocation();
  const { Data } = location.state || {};
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  // console.log('Data: ', Data);


  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
      category: Yup.string().required(ValidationMessage.CATEGORY_REQUIRED),
      // company_email: Yup.string().required(ValidationMessage.EMAIL_REQUIRED),
      address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
      pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
      country: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
      // owner_email: Yup.string().required(ValidationMessage.EMAIL_REQUIRED),
      mainColor: Yup.string().required('Company Main Color is required'),
      primaryColor: Yup.string().required('Company Primary Color is required'),
      company_code: Yup.string().required('Company Code is required'),
      first_name: Yup.string().required('Owner First Name is required'),
      last_name: Yup.string().required('Owner Last Name is required'),
      owner_phone_no: Yup.string().required(ValidationMessage.MOBILE_REQUIRED).max(10,ValidationMessage.MOBILE_LENGTH),
      company_range: Yup.string().required('Company Range is required'),
      p_industry_id: Yup.string().required('Industry Id is required')
    })
  );

  const [roleId, setRoleId] = useState(0);

  const [companyInitialValues, setCompanyInitialValues] = useState({
    id: '',
    upload1: null,
    name: '',
    address: '',
    pincode: '',
    company_email: null,
    mainColor: '',
    primaryColor: '',
    country: '',
    company_code: '',
    first_name: '',
    last_name: '',
    owner_email: null,
    owner_phone_no: '',
    company_range: '',
    gst_number: '',
    category: '',
    cgst: 0,
    sgst: 0,
    vat: 0,
    p_industry_id: '',
    status: true, // for active and in active
    p_userId: null,
    p_is_deleted: "0",
    Permissions: [
      {
        "name": "Customer",
        "icon": null,
        "router_link": "customer",
        "parent_id": 25,
        "sequence": 2,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "0",
        "add_id": "2",
        "view_id": "4",
        "edit_id": "3",
        "delete_id": "5"
      },
      {
        "name": "Subscriber",
        "icon": null,
        "router_link": "subscriber",
        "parent_id": 25,
        "sequence": 3,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "6",
        "add_id": "7",
        "view_id": "9",
        "edit_id": "8",
        "delete_id": "10"
      },
      {
        "name": "Orders",
        "icon": "<FaShoppingCart />",
        "router_link": "orders",
        "parent_id": 3,
        "sequence": 4,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "11",
        "add_id": "12",
        "view_id": "14",
        "edit_id": "13",
        "delete_id": "15"
      },
      {
        "name": "Carts",
        "icon": "<FaCartPlus />",
        "router_link": "carts",
        "parent_id": 4,
        "sequence": 5,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "16",
        "add_id": "17",
        "view_id": "19",
        "edit_id": "18",
        "delete_id": "20"
      },
      {
        "name": "Brand",
        "icon": null,
        "router_link": "brand-list",
        "parent_id": 26,
        "sequence": 7,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "21",
        "add_id": "22",
        "view_id": "24",
        "edit_id": "23",
        "delete_id": "25"
      },
      {
        "name": "Categories",
        "icon": null,
        "router_link": "category",
        "parent_id": 26,
        "sequence": 8,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "26",
        "add_id": "27",
        "view_id": "29",
        "edit_id": "28",
        "delete_id": "30"
      },
      {
        "name": "Sub Categories",
        "icon": null,
        "router_link": "sub-category",
        "parent_id": 26,
        "sequence": 9,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "31",
        "add_id": "32",
        "view_id": "34",
        "edit_id": "33",
        "delete_id": "35"
      },
      {
        "name": "Product",
        "icon": null,
        "router_link": "product",
        "parent_id": 26,
        "sequence": 10,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "36",
        "add_id": "37",
        "view_id": "39",
        "edit_id": "38",
        "delete_id": "40"
      },
      {
        "name": "Image Manager",
        "icon": null,
        "router_link": "imagemanager",
        "parent_id": 26,
        "sequence": 11,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "41",
        "add_id": "42",
        "view_id": "44",
        "edit_id": "43",
        "delete_id": "45"
      },
      {
        "name": "Leaf Level Categories",
        "icon": null,
        "router_link": "leaf-level-category",
        "parent_id": 26,
        "sequence": 12,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "46",
        "add_id": "47",
        "view_id": "49",
        "edit_id": "48",
        "delete_id": "50"
      },
      {
        "name": "Tags",
        "icon": null,
        "router_link": "tags",
        "parent_id": 26,
        "sequence": 13,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "51",
        "add_id": "52",
        "view_id": "54",
        "edit_id": "53",
        "delete_id": "55"
      },
      {
        "name": "packager and manufacturer",
        "icon": null,
        "router_link": "packager-and-manufacturer",
        "parent_id": 26,
        "sequence": 14,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "126",
        "add_id": "127",
        "view_id": "129",
        "edit_id": "128",
        "delete_id": "130"
      },
      {
        "name": "Attributes",
        "icon": null,
        "router_link": "attributes",
        "parent_id": 26,
        "sequence": 15,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "131",
        "add_id": "132",
        "view_id": "134",
        "edit_id": "133",
        "delete_id": "135"
      },
      {
        "name": "Admin User",
        "icon": null,
        "router_link": "adminusers",
        "parent_id": 28,
        "sequence": 17,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "56",
        "add_id": "57",
        "view_id": "59",
        "edit_id": "58",
        "delete_id": "60"
      },
      {
        "name": "Roles List",
        "icon": null,
        "router_link": "roles",
        "parent_id": 28,
        "sequence": 18,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "61",
        "add_id": "62",
        "view_id": "64",
        "edit_id": "63",
        "delete_id": "65"
      },
      {
        "name": "Discount",
        "icon": null,
        "router_link": "discount",
        "parent_id": 27,
        "sequence": 20,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "66",
        "add_id": "67",
        "view_id": "69",
        "edit_id": "68",
        "delete_id": "70"
      },
      {
        "name": "Discount Catalogue",
        "icon": null,
        "router_link": "discount-catalogue",
        "parent_id": 27,
        "sequence": 21,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "71",
        "add_id": "72",
        "view_id": "74",
        "edit_id": "73",
        "delete_id": "75"
      },
      {
        "name": "Policy List",
        "icon": null,
        "router_link": "policy-list",
        "parent_id": 29,
        "sequence": 23,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "76",
        "add_id": "77",
        "view_id": "79",
        "edit_id": "78",
        "delete_id": "80"
      },
      {
        "name": "FAQ List",
        "icon": null,
        "router_link": "faq-list",
        "parent_id": 29,
        "sequence": 24,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "81",
        "add_id": "82",
        "view_id": "84",
        "edit_id": "83",
        "delete_id": "85"
      },
      {
        "name": "Blog List",
        "icon": null,
        "router_link": "blog-list",
        "parent_id": 29,
        "sequence": 25,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "86",
        "add_id": "87",
        "view_id": "89",
        "edit_id": "88",
        "delete_id": "90"
      },
      {
        "name": "Country",
        "icon": null,
        "router_link": "country",
        "parent_id": 30,
        "sequence": 26,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "91",
        "add_id": "92",
        "view_id": "94",
        "edit_id": "93",
        "delete_id": "95"
      },
      {
        "name": "Tax",
        "icon": null,
        "router_link": "tax",
        "parent_id": 30,
        "sequence": 27,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "96",
        "add_id": "97",
        "view_id": "99",
        "edit_id": "98",
        "delete_id": "100"
      },
      {
        "name": "Warehouse",
        "icon": null,
        "router_link": "warehouse",
        "parent_id": 30,
        "sequence": 28,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "101",
        "add_id": "102",
        "view_id": "104",
        "edit_id": "103",
        "delete_id": "105"
      },
      {
        "name": "Product Review Management",
        "icon": "<FaStarHalfAlt />",
        "router_link": "product-review-management",
        "parent_id": 22,
        "sequence": 29,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "106",
        "add_id": "107",
        "view_id": "109",
        "edit_id": "108",
        "delete_id": "110"
      },
      {
        "name": "Tv",
        "icon": "<MdLiveTv />",
        "router_link": "tv",
        "parent_id": 23,
        "sequence": 30,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "111",
        "add_id": "112",
        "view_id": "114",
        "edit_id": "113",
        "delete_id": "115"
      },
      {
        "name": "Influencer Reviews",
        "icon": "<FaStarHalfAlt />",
        "router_link": "influencer-reviews",
        "parent_id": 24,
        "sequence": 31,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "116",
        "add_id": "117",
        "view_id": "119",
        "edit_id": "118",
        "delete_id": "120"
      },
      {
        "name": "Inventory",
        "icon": "<MdOutlineInventory />",
        "router_link": "inventory",
        "parent_id": 31,
        "sequence": 32,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "121",
        "add_id": "122",
        "view_id": "124",
        "edit_id": "123",
        "delete_id": "125"
      }
    ]
  })

  const navigate = useNavigate();
  const brandtypeOptions = [
    { value: "no tax", label: "no tax" },
    { value: "GST", label: "GST" },
    { value: "VAT", label: "VAT" },
  ];

  const countryOptions = [
    { value: '101', label: "India" },
    { value: '102', label: "Australia" },
    { value: '103', label: "London", }
  ]
  const industryOptions = [
    { value: '1', label: "Restaurant" },
  ]


  /**
 * Fetching role detail data
 * @returns {*}
 */
  const fetchCompany = async () => {
    try {
      const company = await getCompanyById(Data.data.id, tokenData, dispatch);
      if (company && company.status === 'success' && company.data.companyDetails.length > 0) {
        const details = company.data.companyDetails[0];
        const colors = JSON.parse(details.company_color);
        setRoleId(details.role_id)

        // setBase64IMG(details.company_logo)
        setCompanyInitialValues({
          id: details.id,
          p_userId: details.userID,
          p_is_deleted: details.IsDeleted,
          status: (details.is_active === true || details.is_active === 1 || details.is_active === '1') ? 1 : 0,
          upload1: details.company_logo,
          name: details.name,
          address: details.address,
          pincode: details.pincode,
          company_email: details.company_email,
          mainColor: colors.mainColor,
          primaryColor: colors.primaryColor,
          country: details.country,
          company_code: details.company_code,
          first_name: details.first_name,
          last_name: details.last_name,
          owner_email: details.email,
          owner_phone_no: details.phone_number,
          company_range: details.company_range,
          gst_number: details.gst_number,
          category: details.tax_type,
          cgst: Number(details.cgst),
          sgst: Number(details.sgst),
          vat: Number(details.vat),
          p_industry_id: String(details.industry_id),
          Permissions: company.data.permissions.filter(item => item.parent_id !== 0)
          // Handle permissions or other fields as needed
        });
      }
      return company;
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  };
  useEffect(() => {
    if (Data && Data.data) {
      fetchCompany();
    }
    // eslint-disable-next-line
  }, [Data])


  const handleSubmit = (values) => {
    const companyPermissionData = values.Permissions.reduce((acc, permission) => {
      // Ensure acc is an array
      const permissionTypes = ['allow', 'add', 'view', 'edit', 'delete'];
      const permissionData = permissionTypes.reduce((data, type) => {
        if (permission[type] === 1 || permission[type] === '1' || permission[type] === true) {
          const id = parseInt(permission[type + '_id']);
          data.push({ id: id, value: (permission[type] === true || permission[type] === 1 || permission[type] === '1') ? '1' : '1' });
        }
        return data;
      }, []);

      acc.push(...permissionData);
      return acc;
    }, []);
    // const base64String = base64IMG.replace(/^data:image\/[a-z]+;base64,/, '');
    // logic for add company
    // if (!Data) {

    // const base64Data = base64IMG.split(',')[1];

    const requestBody = {
      // company_id: 0,
      ...(Data && Data.data && { company_id: values.id, p_userId: values.p_userId }),
      p_is_deleted: values.p_is_deleted,
      name: values.name,
      address: values.address,
      pincode: Number(values.pincode),
      p_is_active: values.status,
      company_logo: values.upload1,
      company_color: { mainColor: values.mainColor, primaryColor: values.primaryColor },
      tax_type: values.category,
      cgst: values.cgst,
      sgst: values.sgst,
      vat: values.vat,
      country: values.country,
      company_code: values.company_code,
      gst_number: values.gst_number,
      company_email: values.company_email ? values.company_email : null,
      owner_first_name: values.first_name,
      owner_last_name: values.last_name,
      owner_email: values.owner_email ? values.owner_email : null,
      owner_phone_number: Number(values.owner_phone_no),
      ...(Data && Data.data && { p_role_id: roleId }),
      role_permission_data: companyPermissionData,
      company_range: Number(values.company_range),
      p_industry_id: Number(values.p_industry_id)
    };
    // console.log("request body ", requestBody);
    // return
    dispatch(clearCompanyPageData());
    if (!Data) {
      dispatch(addUpdateCompanyService(requestBody, tokenData, 'add', navigate))
    }
    else {
      handlesaveClick(
        dispatch,
        addUpdateCompanyService(requestBody, tokenData, "update", navigate),
        "update"
      );
    }
    // }
    // logic for update company

    // else {
    //   const requestBody = {
    //     company_id: values.id,
    //     name: values.name,
    //     address: values.address,
    //     pincode: Number(values.pincode),
    //     company_logo: base64IMG,
    //     company_color: { mainColor: values.mainColor, primaryColor: values.primaryColor },
    //     tax_type: values.category,
    //     cgst: values.cgst,
    //     sgst: values.sgst,
    //     vat: values.vat,
    //     country: values.country,
    //     company_code: values.company_code,
    //     gst_number: values.gst_number,
    //     company_email: values.company_email,
    //     owner_first_name: values.first_name,
    //     owner_last_name: values.last_name,
    //     owner_email: values.owner_email,
    //     owner_phone_number: Number(values.owner_phone_no),
    //     role_permission_data: companyPermissionData,
    //     company_range: Number(values.company_range),
    //     p_industry_id: Number(values.p_industry_id)
    //   }
    //   // if (Data?.length && rolePage > 1) {
    //   //   if ((values.status === '0' || values.status === 0 || values.status === false)) {
    //   //     if (Data.length > 1) {
    //   //       handlesaveClick(dispatch, addUpdateRolesService(requestBody, tokenData, 'update', navigate, rolePage), 'update');
    //   //     }
    //   //     else {
    //   //       handlesaveClick(dispatch, addUpdateRolesService(requestBody, tokenData, 'update', navigate, rolePage - 1), 'update');
    //   //     }
    //   //   }
    //   //   else {
    //   //     handlesaveClick(dispatch, addUpdateRolesService(requestBody, tokenData, 'update', navigate, rolePage), 'update');
    //   //   }
    //   // }
    //   // else {
    //   //   handlesaveClick(dispatch, addUpdateRolesService(requestBody, tokenData, 'update', navigate), 'update');
    //   // }
    //   if (Data && Data.data) {
    //     console.log("updated request body:", requestBody);

    //     // return
    //     handlesaveClick(
    //       dispatch,
    //       addUpdateCompanyService(requestBody, tokenData, "update", navigate),
    //       "update"
    //     );
    //   } else {
    //     dispatch(addUpdateCompanyService(requestBody, tokenData, "add", navigate));
    //   }
    // }
  }
  // const [base64IMG, setBase64IMG] = useState('');

  const MAX_FILE_SIZE_KB = 500; // Maximum file size in KB (e.g., 500 KB)

  const convertToBase64 = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      // Check the file size
      const fileSizeKB = file.size / 1024; // Convert bytes to KB

      if (fileSizeKB > MAX_FILE_SIZE_KB) {
        toast.error(`File size exceeds the limit of ${MAX_FILE_SIZE_KB}KB.`);
        event.target.value = null;
        setFieldValue('upload1', null);
        // setBase64IMG('');
        // console.log(base64IMG);
        
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          // setBase64IMG(reader.result);
          setFieldValue('upload1', reader.result);
        };

        reader.onerror = (error) => {
          // console.error('Error reading file:', error);
          setFieldValue('upload1', null);
          toast.error('Error converting file to Base64.');
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const setTaxValidation = (valueData, values, setFieldValue) => {
    const type = valueData;
    // console.log("type: ", type);

    if (type === 'GST') {
      setValidationSchema(Yup.object({
        name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
        category: Yup.string().required(ValidationMessage.CATEGORY_REQUIRED),
        // company_email: Yup.string().required(ValidationMessage.EMAIL_REQUIRED),
        address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
        pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
        country: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
        // owner_email: Yup.string().required(ValidationMessage.EMAIL_REQUIRED),
        mainColor: Yup.string().required('Company Main Color is required'),
        primaryColor: Yup.string().required('Company Primary Color is required'),
        company_code: Yup.string().required('Company Code is required'),
        first_name: Yup.string().required('Owner First Name is required'),
        last_name: Yup.string().required('Owner Last Name is required'),
        owner_phone_no: Yup.string().required(ValidationMessage.MOBILE_REQUIRED).max(10,ValidationMessage.MOBILE_LENGTH),
        company_range: Yup.string().required('Company Range is required'),
        gst_number: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
        sgst: Yup.number().required(ValidationMessage.FIELD_REQUIRED).min(0, "Cannot add value below 0").max(99.99, "Cannot add value above 99.99").test('max-decimal', "Decimal only accepted for once", value => {
          return /^(\d{1,2}(\.\d)?)?$/.test(value);
        }),
        cgst: Yup.number().required(ValidationMessage.FIELD_REQUIRED).min(0, "Cannot add value below 0").max(99.99, "Cannot add value above 99.99").test('max-decimal', "Decimal only accepted for once", value => {
          return /^(\d{1,2}(\.\d)?)?$/.test(value);
        }),
      }))
      setFieldValue("vat", 0.00);
    }
    else if (type === 'VAT') {
      setValidationSchema(Yup.object({
        name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
        category: Yup.string().required(ValidationMessage.CATEGORY_REQUIRED),
        // company_email: Yup.string().required(ValidationMessage.EMAIL_REQUIRED),
        address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
        pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
        country: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
        // owner_email: Yup.string().required(ValidationMessage.EMAIL_REQUIRED),
        mainColor: Yup.string().required('Company Main Color is required'),
        primaryColor: Yup.string().required('Company Primary Color is required'),
        company_code: Yup.string().required('Company Code is required'),
        first_name: Yup.string().required('Owner First Name is required'),
        last_name: Yup.string().required('Owner Last Name is required'),
        owner_phone_no: Yup.string().required(ValidationMessage.MOBILE_REQUIRED).max(10,ValidationMessage.MOBILE_LENGTH),
        company_range: Yup.string().required('Company Range is required'),
        vat: Yup.number().required(ValidationMessage.FIELD_REQUIRED).min(0, "Cannot add value below 0").max(99.99, "Cannot add value above 99.99").test('max-decimal', "Decimal only accepted for once", value => {
          return /^(\d{1,2}(\.\d)?)?$/.test(value);
        }),
      }))
      setFieldValue("gst_number", "");
      setFieldValue("sgst", 0.00);
      setFieldValue("cgst", 0.00);
    }
    else {
      setValidationSchema(Yup.object({
        name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
        category: Yup.string().required(ValidationMessage.CATEGORY_REQUIRED),
        // company_email: Yup.string().required(ValidationMessage.EMAIL_REQUIRED),
        address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
        pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
        country: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
        // owner_email: Yup.string().required(ValidationMessage.EMAIL_REQUIRED),
        mainColor: Yup.string().required('Company Main Color is required'),
        primaryColor: Yup.string().required('Company Primary Color is required'),
        company_code: Yup.string().required('Company Code is required'),
        first_name: Yup.string().required('Owner First Name is required'),
        last_name: Yup.string().required('Owner Last Name is required'),
        owner_phone_no: Yup.string().required(ValidationMessage.MOBILE_REQUIRED).max(10,ValidationMessage.MOBILE_LENGTH),
        company_range: Yup.string().required('Company Range is required')
      }))
      setFieldValue("gst_number", "");
      setFieldValue("sgst", 0.00);
      setFieldValue("cgst", 0.00);
      setFieldValue("vat", 0.00);
    }
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Company</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate('/company')}>Company</Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Company</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Company</h4>
            </div>
            <Formik initialValues={companyInitialValues}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Name<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="name"
                          label="name"
                          placeholder="Name"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.name}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Company Email<span className=""></span></h4>
                        </div>
                        <Input
                          type="email"
                          name="company_email"
                          label="Email"
                          placeholder="Company Email"
                          className="add-roles"
                          value={values.company_email}
                          autoComplete="new-password"
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Address<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="address"
                          label="address"
                          placeholder="Address"
                          className="add-roles"
                          value={values.address}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Pincode<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="pincode"
                          label="Pincode"
                          placeholder="Pincode"
                          className="add-roles"
                          value={values.pincode}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Country<span className="required-label"></span></h4>
                        </div>
                        <Field
                          className="select-menu mb-2"
                          name="country"
                          options={countryOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          // changeData={(value) => setFieldValue("country", value)}
                          changeData={(value) => setFieldValue("p_industry_id", value)}
                          value={
                            values.country === "" || values.country === null ? false : values.country
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Industry Type<span className="required-label"></span></h4>
                        </div>
                        <Field
                          className="select-menu mb-2"
                          name="p_industry_id"
                          options={industryOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          changeData={(value) => setFieldValue("industry", value)}
                          value={
                            values.p_industry_id === "" || values.p_industry_id === null ? false : values.p_industry_id
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">Company Logo</h4>
                        </div>
                        <input
                          id="upload1"
                          name="upload1"
                          type="file"
                          accept=".jpg, .png"
                          onChange={(event) => convertToBase64(event, setFieldValue)}
                          disabled={Data && Data.isEdit === false}
                        />

                        {values.upload1 ? (
                          <img
                            style={{
                              display: 'block',
                              width: '100px',
                              height: '100px',
                              marginTop: '10px',
                              borderRadius: '5px',
                              border: '1px solid #ddd',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                            }}
                            id="base64image"
                            src={`${values.upload1 !== '' && values.upload1} `}
                            // src={`data:image/jpeg;base64,${values.upload1}`}
                            alt="Company Logo"
                          />
                        ) : (
                          <div
                            style={{
                              marginTop: '10px',
                              color: '#999',
                              fontSize: '14px'
                            }}
                          >
                            No Image
                          </div>
                        )}

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Company Code<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="company_code"
                          label="company Code"
                          placeholder="Company Code"
                          className="add-roles"
                          value={values.company_code}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Company Main Color<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="color"
                          name="mainColor"
                          label="Company Main Color"
                          placeholder="Company Main Color"
                          className="add-roles"
                          value={values.mainColor}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Company Primary Color<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="color"
                          name="primaryColor"
                          label="Company Primary Color"
                          placeholder="Company Primary Color"
                          className="add-roles"
                          value={values.primaryColor}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Owner First Name<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="first_name"
                          label="Owner First Name"
                          placeholder="Owner First Name"
                          className="add-roles"
                          value={values.first_name}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Owner Last Name<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="last_name"
                          label="Owner Last Name"
                          placeholder="Owner Last Name"
                          className="add-roles"
                          value={values.last_name}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Owner Email<span className=""></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="owner_email"
                          label="Owner Email"
                          placeholder="Owner Email"
                          className="add-roles"
                          value={values.owner_email}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Owner Phone no.<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="owner_phone_no"
                          label="Owner phone no."
                          placeholder="Owner Phone no."
                          className="add-roles"
                          Regex={/[^0-9\s]/g}
                          value={values.owner_phone_no}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Company Range<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="company_range"
                          label="Company Range"
                          placeholder="Company Range"
                          className="add-roles"
                          value={values.company_range}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Tax type<span className="required-label"></span></h4>
                        <Field
                          className="select-menu mb-2"
                          name="category"
                          options={brandtypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          changeData={(value) => setTaxValidation(value, values, setFieldValue)}
                          value={
                            values.category === "" || values.category === null ? false : values.category
                          }
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    {values.category === 'GST' ? (
                      <>
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">GST Number<span className="required-label"></span></h4>
                            </div>
                            <Input
                              type="text"
                              name="gst_number"
                              label="GST Number"
                              placeholder="Enter GST Number"
                              className="add-roles"
                              maxLength={20}
                              Regex={/[^A-Za-z0-9\s]/g}
                              value={values.gst_number}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">CGST<span className="required-label"></span></h4>
                            </div>
                            <Input
                              type="number"
                              name="cgst"
                              label="CGST"
                              placeholder="Enter CGST"
                              className="add-roles"
                              // maxLength={20}
                              Regex={/[^0-9.\s]/g}
                              value={values.cgst}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">SGST<span className="required-label"></span></h4>
                            </div>
                            <Input
                              type="number"
                              name="sgst"
                              label="Enter SGST"
                              placeholder="SGST"
                              className="add-roles"
                              // maxLength={20}
                              Regex={/[^0-9.\s]/g}
                              value={values.sgst}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                      </>
                    ) : values.category === "VAT" && (
                      <div className="col-md-6">
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">VAT<span className="required-label"></span></h4>
                          </div>
                          <Input
                            type="number"
                            name="vat"
                            label="VAT"
                            placeholder="Enter VAT"
                            className="add-roles"
                            maxLength={20}
                            Regex={/[^0-9.\s]/g}
                            value={values.vat}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field name='status'
                          className='toggle'
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="paragraph-editor">
                      <h4 className="paragraph-title mb-0">Permissions</h4>
                    </div>

                    <CustomTable page='add-company' showKey={showKey} showTooltip={true} checkBool={false} data={values.Permissions} heading={columns} showSelectAll={true} disabled={Data && Data.isEdit === false} />

                    <div className="col-12">
                      <div className="btn-submit">
                        {(
                          (Data && Data.isEdit === true) || (!Data)
                        ) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/company', navigate)}
                              />
                            </>
                          )}
                      </div>
                    </div>

                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
