/**
 * Set sub-category filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getSubCategoryFilterData = ({name, category, code, type, isActive, isDelete}) => ({
    type: 'GET_SUB_CATEGORY_FILTER',
    payload: {name, category, code, type, isActive, isDelete}
})

/**
 * Clear sub-category filtered data
 * @returns {*}
 */
export const clearSubCategoryFilterData = () => ({
    type: 'CLEAR_SUB_CATEGORY_FILTER'
})