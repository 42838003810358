import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Button from '../../../components/Button/Button'
import Header from "../../../components/Header/Header";
import Footer from '../../../components/Footer/Footer'
import Input from '../../../components/input/Input'
import Toggle from '../../../components/Toggle/Toggle';
import CKEditorCpmponent from '../../../components/CKEditor-component/CKEditorCpmponent';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from '../../../Utils/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateCategoryService, getCategoryById } from '../Category-service/CategoryService.tsx';
import { parseImage } from '../../../services/Regular-services/ImageService';
import UploadImage from '../../../components/UploadImage/UploadImage';
import ShowImage from '../../../components/ShowImage/ShowImage';
import { toast } from 'react-toastify';


export default function AddCategory() {

  const userData = useSelector(state => state.auth);
  const categoryPage = useSelector(state => state.categoryPage?.items);
  const tokenData = UtilsData();

  const [errorImages, setErrorImages] = useState([]);
  const [showImage, setShowImage] = useState(false);

  const [validationSchema, setValidationSchema] = useState(Yup.object({
    categoryName: Yup.string().required(ValidationMessage.CATEGORY_NAME_REQUIRED),
    categoryType: Yup.string().required(ValidationMessage.CATEGORY_TYPE_REQUIRED),
    // attributeType: Yup.string().required("Attribute type is required"),
    categoryCode: Yup.string().required(ValidationMessage.CATEGORY_CODE_REQUIRED),
    description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED),
    upload: Yup.array().min(1, ValidationMessage.UPLOAD_IMAGE)
  }))

  //Setting initial values
  const [initialValues, setInitialValues] = useState({
    categoryName: '',
    categoryType: '',
    attributeType: null,
    categoryCode: '',
    description: '',
    upload: [],
    active: true
  });



  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Getting data from category page
  const location = useLocation();
  const { Data } = location.state || {};

  /**
   * Fetching category detail data
   */
  const fetchCategory = async () => {
    try {
      // Call the getRoleById function
      const categoryData = await getCategoryById(Data.data.id, tokenData, dispatch);
      const categoryImage = categoryData.data.image_data ? parseImage(JSON.stringify(categoryData.data.image_data)) : null;
      const categoryImageData = categoryImage ? categoryImage.map(item => ({
        imageId: parseInt(item.id),
        url: item.url,
        imageType: parseInt(item.type)
      })) : [];
      setShowImage(true);
  
      setInitialValues({
        categoryName: Data.data.name,
        categoryType: categoryData.data.type,
        categoryCode: Data.data.code,
        description: Data.data.description,
        upload: categoryImageData,
        attributeType:categoryData.data.selection_type,
        active: (Data.data.isActive === true || Data.data.isActive === '1' || Data.data.isActive === 1) ? true : false
      })
      return categoryData;
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  };

  //Reinitializing data if we get data from category
  useEffect(() => {
    if (Data && Data.data) {
      fetchCategory();
    }
    // eslint-disable-next-line
  }, [Data])

  /**
   * Handling form submission
   * @param {*} values 
   */
  const handleSubmit = (values) => {
    const imageId = values.upload.map(item => ({
      id: item.imageId,
      type_id: item.imageType
    }))
    if (!Data) {
      const requestBody = {
        p_name: values.categoryName,
        p_description: values.description,
        p_categorycode: values.categoryCode,
        p_isActive: (values.active === true || values.active === 1 || values.active === '1') ? '1' : '0',
        p_type: values.categoryType,
        p_selection_type:values.attributeType,
        p_createdBy: userData.user.data.userId,
        p_imageIds: imageId,
      }
      dispatch(addUpdateCategoryService(requestBody, tokenData, 'add', navigate))
    }
    else {
      const requestBody = {
        p_id: Data.data.id,
        p_name: values.categoryName,
        p_description: values.description,
        p_categorycode: values.categoryCode,
        p_isActive: (values.active === true || values.active === 1 || values.active === '1') ? '1' : '0',
        p_type: values.categoryType,
        p_IsDeleted: "0",
        p_createdBy: Data.data.createdBy,
        p_modifiedBy: userData.user.data.userId,
        p_imageIds: imageId,
        p_selection_type:values.attributeType,
      }
      if (Data?.length && categoryPage > 1) {
        if ((values.active === '0' || values.active === 0 || values.active === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateCategoryService(requestBody, tokenData, 'update', navigate, categoryPage), 'update');
          }
          else {
            handlesaveClick(dispatch, addUpdateCategoryService(requestBody, tokenData, 'update', navigate, categoryPage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateCategoryService(requestBody, tokenData, 'update', navigate, categoryPage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateCategoryService(requestBody, tokenData, 'update', navigate), 'update');
      }
    }
  }

  const categoryTypeOptions = [
    { value: "1", label: "Blog" },
    { value: "0", label: "Product" },
    { value: "2", label: "Attribute" },
    { value: "3", label: "Service" }
  ];
  const attributeTypeOptions = [
    { value: "0", label: "No Selection" },
    { value: "1", label: "Single Selection" },
    { value: "2", label: "Multi Select" }
  ]

  const setCategoryValidation = (valueData, ) => {
    const type = valueData;
    if (type === '2') {
      setValidationSchema(Yup.object({
        categoryName: Yup.string().required(ValidationMessage.CATEGORY_NAME_REQUIRED),
        categoryType: Yup.string().required(ValidationMessage.CATEGORY_TYPE_REQUIRED),
        attributeType: Yup.string().required(ValidationMessage.ATTRIBUTE_TYPE_REQUIRED),
        categoryCode: Yup.string().required(ValidationMessage.CATEGORY_CODE_REQUIRED),
        description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED),
        upload: Yup.array().min(1, ValidationMessage.UPLOAD_IMAGE)
      }))
    }
    else{
      setValidationSchema(Yup.object({
        categoryName: Yup.string().required(ValidationMessage.CATEGORY_NAME_REQUIRED),
        categoryType: Yup.string().required(ValidationMessage.CATEGORY_TYPE_REQUIRED),
        categoryCode: Yup.string().required(ValidationMessage.CATEGORY_CODE_REQUIRED),
        description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED),
        upload: Yup.array().min(1, ValidationMessage.UPLOAD_IMAGE)
      }))
    }
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Categories</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate('/category')}>Category</Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Category</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Category</h4>
            </div>
            <div className="category-section add-roles-form">
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">Category Name<span className="required-label"></span></h4>
                          </div>
                          <Input
                            type="text"
                            name="categoryName"
                            label="Category Name"
                            placeholder="Enter Category Name"
                            className="add-roles"
                            maxLength={20}
                            Regex={/[^a-zA-Z\s]/g}
                            value={values.categoryName}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">Category Type<span className="required-label"></span></h4>
                          <Field
                            className="select-menu mb-2"
                            name="categoryType"
                            options={categoryTypeOptions}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            value={values.categoryType}
                            changeData={(value) => setCategoryValidation(value)}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                      {values.categoryType === '2' && (
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Attribute Type<span className="required-label"></span></h4>
                            <Field
                              className="select-menu mb-2"
                              name="attributeType"
                              options={attributeTypeOptions}
                              component={CustomSelect}
                              placeholder="Select..."
                              isMulti={false}
                              value={values.attributeType}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-md-12">
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">Category Code<span className="required-label"></span></h4>
                          </div>
                          <Input
                            type="text"
                            name="categoryCode"
                            label="Category Code"
                            placeholder="Enter Category Code"
                            className="add-roles"
                            maxLength={50}
                            disabled={Data && Data.isEdit === false}
                            value={values.categoryCode}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="upload-image">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">Category Image<span className="required-label"></span></h4>
                          </div>
                          <UploadImage
                            name='upload'
                            onChange={setFieldValue}
                            value={values.upload}
                            disabled={Data && Data.isEdit === false}
                            filterByType={5} setErrorImages={setErrorImages}
                            setShowImage={setShowImage} />

                          <ShowImage value={values.upload}
                            errorImages={errorImages} s
                            showImage={showImage} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">Description<span className="required-label"></span></h4>
                          <Field name='description' component={CKEditorCpmponent} disabled={Data && Data.isEdit === false} maxLength={255}></Field>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">Status</h4>
                          <Field name='active' setFieldValue={setFieldValue} className='toggle' component={Toggle} disabled={Data && Data.isEdit === false} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || (!Data)) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/category', navigate)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}