const tagInitialState = { 
    items: []
};

/**
 * Store tag list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const tagReducer = (state = tagInitialState, action) => {
    switch (action.type) {
        case 'GET_TAG_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TAG_DATA':
            return tagInitialState;
        default:
            return state;
      };
}

const totalTagInitialState = {
    items: []
}

/**
 * Store total tag list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalTagReducer = (state = totalTagInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_TAG_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_TAG_DATA':
            return totalTagInitialState;
        default:
            return state;
      };
}