import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/Button/Button';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/Table/Table';
import Filter from '../../components/Filter/Filter';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { GrMoreVertical } from 'react-icons/gr';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useDispatch, useSelector } from 'react-redux';
import UtilsData from '../../Utils/Utils';
import { clearCompanyData } from '../../redux/Actions/companyAction/CompanyAction';
import { clearCompanyFilterData } from '../../redux/Actions/companyAction/CompanyFilterAction';
import { getCompanyService } from './Company-service/companyService';
import Toggle from '../../components/Toggle/Toggle';
import { getCompanyPageData } from '../../redux/Actions/companyAction/CompanyPageAction';
import CustomPagination from '../../components/Pagination/Pagination';

//Show keys which is to be displayed in table
const showKey = {
    name: true,
    address: true,
    country_name: true,
    pincode: true,
    company_code: true,
    tax_type: true,
}

export default function Company() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isToggled, setIsToggled] = useState(false);
    const companyData = useSelector(state => state.company);
    const companyFilter = useSelector(state => state.companyFilter);
    const token = UtilsData();
    const initialCompanyPage = useSelector(state => state.companyPage.items);

    const [currentPageNumber, setCurrentPage] = useState(1);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setCurrentPage(initialCompanyPage);
    }, [initialCompanyPage]);

    const showToggle = {
        status: true
    }

    const handleButtonClick = () => {
        setIsToggled((prevIsToggled) => !prevIsToggled);
    };
    // const sideBarData = useSelector(state => state.sideBar.items);

    // const [companyActionData, setCompanyActionData] = useState([]);


    //Show actions which is to be displayed in table
    const showActions = {
        showView: true,
        showEdit: true,
        showDelete: true,
        showUndo: true,
        navigate: '/add-company'
    }
    //Headings
    const columns = [
        {
            name: 'Company Name',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true
        },
        {
            name: 'Country',
            selector: row => row.country_name,
            sortable: true
        },
        {
            name: 'Pincode',
            selector: row => row.pincode,
            sortable: true
        },
        {
            name: 'Company Code',
            selector: row => row.company_code,
            sortable: true
        },
        {
            name: 'Tax Type',
            selector: row => row.tax_type,
            sortable: true
        },
        {
            name: 'Status',
            selector: row => row.is_active,
            sortable: true
        },
        {
            name: 'Action',
            cell: (row) => <Toggle checked={row.toggle} />
        }
    ];


    // useEffect(() => {
    //     if ((companyActionData[0]?.view && companyActionData[0].view === '0') &&
    //         (companyActionData[0]?.edit && companyActionData[0].edit === '0') &&
    //         (companyActionData[0]?.add && companyActionData[0].add === '0') &&
    //         (companyActionData[0]?.delete && companyActionData[0].delete === '0')) {
    //         setColumns(
    //             [
    //                 {
    //                     name: 'Role Name',
    //                     selector: row => row.name,
    //                     sortable: true
    //                 },
    //                 {
    //                     name: 'Description',
    //                     selector: row => row.description,
    //                     sortable: true
    //                 },
    //                 {
    //                     name: 'Status',
    //                     cell: (row) => <Toggle checked={row.toggle} />
    //                 }
    //             ]
    //         )
    //         setShowActionBool(false);
    //     }
    //     else {
    //         setShowActionBool(true);
    //         setColumns(
    //             [
    //                 {
    //                     name: 'Role Name',
    //                     selector: row => row.name,
    //                     sortable: true
    //                 },
    //                 {
    //                     name: 'Description',
    //                     selector: row => row.description,
    //                     sortable: true
    //                 },
    //                 {
    //                     name: 'Status',
    //                     cell: (row) => <Toggle checked={row.toggle} />
    //                 },
    //                 {
    //                     name: 'Actions',
    //                     selector: row => row.edit
    //                 }
    //             ]
    //         )
    //     }
    // }, [companyActionData]);

    const tooltipRef = useRef(null);
    useEffect(() => {
        if (tooltipRef.current) {
            const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
            return () => {
                tooltip.dispose();
            };
        }
    }, []);

    /**
     * Fetch company list
     */
    const fetchCompany = () => {
        // console.log("company: ", companyFilter);
        
        if (initialCompanyPage > 1) {
            const companyRequestBody = {
                p_skip: (initialCompanyPage - 2) * 10,
                p_take: 10
            }
            if (companyFilter.p_pincode !== '' && companyFilter.p_pincode !== null) {
                companyRequestBody.p_pincode = companyFilter.p_pincode;
            }
            if (companyFilter.p_address !== '' && companyFilter.p_address !== null) {
                companyRequestBody.p_address = companyFilter.p_address;
            }
            if (companyFilter.p_company_name !== '' && companyFilter.p_company_name !== null) {
                companyRequestBody.p_company_name = companyFilter.p_company_name;
            }
            if (companyFilter.p_is_active !== '' && companyFilter.p_is_active !== null) {
                companyRequestBody.p_is_active = companyFilter.p_is_active;
            }
            else {
                companyRequestBody.p_is_active = '1';
            }
            if (companyFilter.p_IsDeleted !== '' && companyFilter.p_IsDeleted !== null) {
                companyRequestBody.p_IsDeleted = companyFilter.p_IsDeleted;
            }
            setCurrentPage(initialCompanyPage - 1); // Update the current page state
            dispatch(getCompanyPageData(initialCompanyPage - 1))
            // console.log("company request body : ",companyRequestBody);

            getCompanyService(companyRequestBody, token, dispatch);
        }
        else {
            const companyRequestBody = {
                p_skip: (initialCompanyPage - 1) * 10,
                p_take: 10
            }
            if (companyFilter.p_pincode !== '' && companyFilter.p_pincode !== null) {
                companyRequestBody.p_pincode = companyFilter.p_pincode;
            }
            if (companyFilter.p_address !== '' && companyFilter.p_address !== null) {
                companyRequestBody.p_address = companyFilter.p_address;
            }
            if (companyFilter.p_company_name !== '' && companyFilter.p_company_name !== null) {
                companyRequestBody.p_company_name = companyFilter.p_company_name;
            }
            if (companyFilter.p_is_active !== '' && companyFilter.p_is_active !== null) {
                companyRequestBody.p_is_active = companyFilter.p_is_active;
            }
            else {
                companyRequestBody.p_is_active = '1';
            }
            if (companyFilter.p_IsDeleted !== '' && companyFilter.p_IsDeleted !== null) {
                companyRequestBody.p_IsDeleted = companyFilter.p_IsDeleted;
            }
            getCompanyService(companyRequestBody, token, dispatch);
        }
    }


    useEffect(() => {
        if (companyData && companyData.items && companyData.items.length === 0) {
            fetchCompany();
        }
        else {
            if (companyFilter.company_name !== '' || companyFilter.company_address !== '' || companyFilter.pincode !== '') {
                setIsToggled(false);
            }
        }
        // eslint-disable-next-line 

        // here update changes by sakshi
        if (companyData.items?.data && companyData.items.data.length > 0) {
            // console.log("company data : ",companyData.items.total_records);

            const transformedCompanyData = companyData.items.data.map(item => {
                // console.log("item:",item);  // Log the item for debugging
                return {
                    id: item.id,
                    name: item.company_name,
                    address: item.address,
                    country_name: item.country_name,
                    pincode: item.pincode,
                    company_code: item.company_code,
                    tax_type: item.tax_type,
                    is_active: item.is_active,
                    is_delete: item.IsDeleted,
                };
            });
         
            setTableData(transformedCompanyData)
        }
        else {
            setTableData([]);
        }
        // eslint-disable-next-line
    }, [companyData])

    /**
    * Integrate listing API on page change
    */
    const onPageChange = (pageNumber) => {
        const companyRequestBody = {
            p_skip: (pageNumber - 1) * 10,
            p_take: 10
        }

        if (companyFilter.p_pincode !== '' && companyFilter.p_pincode !== null) {
            companyRequestBody.p_pincode = companyFilter.p_pincode;
        }
        if (companyFilter.p_address !== '' && companyFilter.p_address !== null) {
            companyRequestBody.p_address = companyFilter.p_address;
        }
        if (companyFilter.p_company_name !== '' && companyFilter.p_company_name !== null) {
            companyRequestBody.p_company_name = companyFilter.p_company_name;
        }
        if (companyFilter.p_is_active !== '' && companyFilter.p_is_active !== null) {
            companyRequestBody.p_is_active = companyFilter.p_is_active;
        }
        else {
            companyRequestBody.p_is_active = '1';
        }
        if (companyFilter.p_IsDeleted !== '' && companyFilter.p_IsDeleted !== null) {
            companyRequestBody.p_IsDeleted = companyFilter.p_IsDeleted;
        }

        setCurrentPage(pageNumber); // Update the current page state
        dispatch(getCompanyPageData(pageNumber))
        getCompanyService(companyRequestBody, token, dispatch);
    };

    /**
     * Clear filtered data
     */
    const clearCompanyFilters = () => {
        dispatch(clearCompanyFilterData());
        dispatch(clearCompanyData());
        setIsToggled(false);
    }

    return (
        <>
            <div className='main-section'>
                <div className='sidebar-content'>
                    <Sidebar />
                </div>
                <div className='body-content'>
                    <Header />
                    <div className='table-body-content'>
                        <div className='roles'>
                            <div>
                                <div className='title'>
                                    <h4>Company</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Company</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className='btn-groups'>
                                <Dropdown className='header-dropdown action-btn' >
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                                        <GrMoreVertical />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item >Delete</Dropdown.Item>
                                        <Dropdown.Item >Active</Dropdown.Item>
                                        <Dropdown.Item >Inactive</Dropdown.Item>
                                        <Dropdown.Item >Undo</Dropdown.Item>
                                        {(companyFilter.company_name !== '' || companyFilter.pincode !== '' || companyFilter.address !== '' ||  companyFilter.p_is_active!== '' ||  companyFilter.p_IsDeleted!== '') && (
                                            <Dropdown.Item onClick={clearCompanyFilters}>Clear Filters</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Button onClick={handleButtonClick} className="btn btn-fillter-main" name={<div className="filter">
                                    <FaFilter className="filter-icon" />
                                </div>}></Button>
                                <Button data-bs-toggle='tooltip' title='Export' className="btn btn-fillter-main" name={<div className="filter">
                                    <SiMicrosoftexcel className="filter-icon" />
                                </div>}></Button>
                                <Button onClick={() => navigate('/add-company')} className="btn btn-fillter-main" name={<div className="Plus">
                                    <FaPlus />
                                </div>}></Button>
                            </div>
                        </div>
                        {isToggled &&
                            <Filter page='company' handleButtonClick={handleButtonClick}></Filter>}
                        {companyData && companyData?.items && companyData.items?.data && companyData.items.data.length > 0 && (
                            <>
                                {/* <CustomTable showKey={showKey} showActions={showActions} showTooltip={true} showId={false} data={tableData} heading={columns} page="company" checkBool={false} currentPage={currentPageNumber} /> */}
                                <CustomTable showKey={showKey} showActions={showActions} showTooltip={true} showId={false} data={tableData} heading={columns} page="company" checkBool={false} currentPage={currentPageNumber} onPageChange={onPageChange} showToggle={showToggle} />

                                {(companyData.items.total_records > companyData.items.data.length && companyData.items.total_records > 10) && (
                                    <CustomPagination totalRecords={companyData.items.total_records} currentPage={currentPageNumber} onPageChange={onPageChange} />
                                )}
                            </>
                        )}
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}