export const capitalizeAfterSpace = (wordData) => {
    // Split the string into words
    let words = wordData.split(" ");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back together
    return words.join(" ");
}

export const separateFirstAndLastName = (name) => {
    const fullName = name;
    const splitName = fullName.split(" ");
    if(splitName && splitName.length > 1){
        const lastName = splitName.pop(); // Remove and get the last element
        const firstName = splitName.join(" ");
        return {firstName: firstName, lastName:lastName}
    }
    else{
        const firstName = splitName.join(" ");
        const lastName = '';
        return {firstName: firstName, lastName: lastName}
    }
}
