import { Dispatch } from "redux";
import { AddUpdateAdminUserRequest, GetUserDetailResponse, UserTypeResponse, getAdminUserListRequest, getAdminUserResponse, verifyUserRequest } from "./UserModalResponse";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, getToken, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearAdminData, getAdminData, getUserType } from "../../../redux/Actions/adminAction/AdminUserAction"
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearAdminPageData, getAdminPageData } from "../../../redux/Actions/adminAction/AdminPageAction";
import { updateAuthData } from "../../../redux/Actions/authAction/AuthAction";

let errorShow = false;

/**
 * Get user list service
 * @param adminRequest 
 * @param token 
 * @param dispatch 
 * @param total 
 * @returns 
 */
export const getUserListService = (adminRequest: getAdminUserListRequest, token: string, dispatch: Dispatch, total?: any) => {
  return new Promise<getAdminUserResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (adminRequest.skip !== undefined && adminRequest.skip !== null) {
        queryParams += `skip=${adminRequest.skip}&`;
      }
      if (adminRequest.take !== undefined && adminRequest.take !== null) {
        queryParams += `take=${adminRequest.take}&`;
      }
      if (adminRequest.searchUsername !== undefined && adminRequest.searchUsername !== null) {
        queryParams += `searchUsername=${adminRequest.searchUsername}&`;
      }
      if (adminRequest.search !== undefined && adminRequest.search !== null) {
        queryParams += `search=${adminRequest.search}&`
      }
      if (adminRequest.search_username !== undefined && adminRequest.search_username !== null) {
        queryParams += `search_username=${adminRequest.search_username}&`
      }
      if (adminRequest.search_phone_number !== undefined && adminRequest.search_phone_number !== null) {
        queryParams += `search_phone_number=${adminRequest.search_phone_number}&`
      }
      if (adminRequest.search_first_name !== undefined && adminRequest.search_first_name !== null) {
        queryParams += `search_first_name=${adminRequest.search_first_name}&`
      }
      if (adminRequest.search_last_name !== undefined && adminRequest.search_last_name !== null) {
        queryParams += `search_last_name=${adminRequest.search_last_name}&`
      }
      if (adminRequest.search_email !== undefined && adminRequest.search_email !== null) {
        queryParams += `search_email=${adminRequest.search_email}&`
      }
      if (adminRequest.search_age !== undefined && adminRequest.search_age !== null) {
        queryParams += `search_age=${adminRequest.search_age}&`
      }
      if (adminRequest.search_is_active !== undefined && adminRequest.search_is_active !== null) {
        queryParams += `search_is_active=${adminRequest.search_is_active}&`
      }
      if (adminRequest.p_IsDeleted !== undefined && adminRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${adminRequest.p_IsDeleted}&`
      }
      if (adminRequest.search_dob !== undefined && adminRequest.search_dob !== null) {
        queryParams += `search_dob=${adminRequest.search_dob}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.USERS + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getAdminData(response));
        }
        else {
          dispatch(getAdminData(response));
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'User permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  })
}


/*
 * Service to get individual user
*/
export const getUserById = (
  id: string, token: string,
  dispatch: Dispatch): Promise<GetUserDetailResponse> => {
  return new Promise<GetUserDetailResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      const response = await getApiService(API_URL.BASE_URL + API_URL.USERS + '/' + id, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        resolve(response);
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/**
 * Add and update admin user
 * @param addUpdateUserRequest 
 * @param token 
 * @param type 
 * @param navigate 
 * @returns 
 */
export const addUpdateUserService = (
  addUpdateUserRequest: AddUpdateAdminUserRequest,
  token: string, type: string, navigate?: any, pageNumber?: number, user?: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.USERS, token, addUpdateUserRequest);
      }
      if (type === 'update') {
        response = await putApiService(API_URL.BASE_URL + API_URL.USERS, token, addUpdateUserRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearAdminData());
        if (type === 'add') {
          dispatch(clearAdminPageData());
          toast.success("User added successfully!");
          navigate('/adminusers');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            dispatch(clearAdminPageData());
            toast.success('User undeleted successfully!');
          }
          else {
            if (navigate) {
              if(pageNumber){
                dispatch(getAdminPageData(pageNumber+1));
              }
              else{
                dispatch(clearAdminPageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: "User updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/adminusers');
              });
            }
            else {
              if(!user) {
                toast.success('User status updated successfully!');
              }
            }
          }
          
          if(user) {
            let userData = user;
            userData.data.beep_option = addUpdateUserRequest?.p_beep_option;
            if(addUpdateUserRequest?.p_beep_option === '1') {
              toast.success('Multiple beep enabled successfully!')
            }
            else{
              toast.success('Multiple beep disabled successfully!')
            }
            dispatch(updateAuthData({user: userData, reload: false}));
          }
        }
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getAdminPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};


/**
* verify user API calling for unique user 
*/
export const verifyUniqueUser = (verifyUserRequest: verifyUserRequest) => {
  return new Promise<getAdminUserResponse>(async (resolve, reject) => {
    try {
      const token = getToken();

      const response = await postApiService(API_URL.BASE_URL + API_URL.VERIFY_EMAIL, token, verifyUserRequest);
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
    }
  })
}

/*
 * Service to delete role
*/
export const deleteUserService = (
  id: string,
  token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.USERS + '/' + id, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearAdminData());
        toast.success('User deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getAdminPageData(pageNumber))
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/**
 * Get user type list service
 * @param dispatch 
 * @returns 
 */
export const getUserTypeList = (dispatch: Dispatch) => {
  return new Promise<UserTypeResponse>(async (resolve, reject) => {
    try {
      const response = await getApiService(API_URL.BASE_URL + API_URL.USER_TYPE);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(getUserType(response.data));
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  })
}