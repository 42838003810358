import './App.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Login from './pages/login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Resetpassword from './pages/Resetpassword/Resetpassword';
import { useDispatch, useSelector } from 'react-redux';
import Roles from './pages/Roles/Roles'
import ErrorPage from './pages/ErrorPage/Error';
import CustomTable from './components/Table/Table';
import Addroles from './pages/Roles/Add-role/AddRoles';
import Imagemanager from './pages/Imagemanager/Imagemanager';
import BrandList from './pages/BrandList/BrandList';
import Addimage from './pages/Imagemanager/Add-image/Addimage';
import Addbrand from './pages/BrandList/Add-brand/Addbrand';
import Category from './pages/Category/Category';
import AdminUsers from './pages/AdminUsers/AdminUsers';
import AddCategory from './pages/Category/AddCategory/AddCategory';
import Adduser from './pages/AdminUsers/Add-user/Adduser';
import LeafLevelCategory from './pages/LeafLevelCategory/LeafLevelCategory';
import AddLeafLevelCategory from './pages/LeafLevelCategory/AddLeafLevelCategory/AddLeafLevelCategory';
import Tag from './pages/Tag/Tag';
import AddTag from './pages/Tag/AddTag/AddTag';
import SubCategory from './pages/SubCategory/SubCategory';
import AddSubCategory from './pages/SubCategory/AddSubCategory/AddSubCategory';
import Product from './pages/Product/Product';
import AddProduct from './pages/Product/AddProduct/AddProduct';
import Blog from './pages/Blog/Blog';
import Addblog from './pages/Blog/Add-blog/Add-blog';
import Faq from './pages/Faq/Faq';
import AddFaq from './pages/Faq/Add-Faq/Add-Faq';
import Policies from './pages/Policies/Policies';
import Addpolicy from './pages/Policies/Add-policy/Add-policy';
import Customer from './pages/Customer/Customer';
import Customerview from './pages/Customer/Customer-view/Customer-view';
import Subscribers from './pages/Subscribers/Subscribers';
import ProductReview from './pages/Product/ProductReview/ProductReview';
import Discount from './pages/Discount/Discount';
import Tv from './pages/Tv/Tv';
import AddTv from './pages/Tv/AddTv/AddTv';
import AddDiscount from './pages/Discount/AddDiscount/AddDiscount';
import DiscountCatalogue from './pages/DiscountCatalogue/DiscountCatalogue';
import AddDiscountCatalogue from './pages/DiscountCatalogue/AddDiscountCatalogue/AddDiscountCatalogue';
import OTP from './pages/OTP/OTP';
import BootstrapTooltips from './components/BootstrapTooltips/BootstrapTooltips';
import Filter from './components/Filter/Filter';
import UploadImage from './components/UploadImage/UploadImage';
import Tax from './pages/Tax/Tax';
import AddTax from './pages/Tax/AddTax/AddTax';
import Warehouse from './pages/Warehouse/Warehouse';
import AddWarehouse from './pages/Warehouse/AddWarehouse/AddWarehouse';
import Country from './pages/Country/Country';
import Carts from './pages/Carts/Carts';
import Order from './pages/Order/Order';
import ViewCart from './pages/Carts/ViewCart/ViewCart';
import OrderDetail from './pages/OrderDetail/OrderDetail';
import Invoice from './components/Invoice/Invoice';
import Inventory from './pages/Inventory/Inventory';
import AddInventory from './pages/Inventory/AddInventory/AddInventory';
import { useEffect, useState } from 'react';
import OrderProsessing from './pages/Order/OrderProsessing/OrderProsessing';
import logoImage from './assets/images/loader-gif.gif';
import PackagerAndManufacturer from './pages/Packager/PackagerAndManufacturer ';
import AddPackager from './pages/Packager/AddPackager/AddPackager';
import Attribute from './pages/Attributes/Attribute';
import AddAttribute from './pages/Attributes/AddAttribute/AddAttribute';
import Service from './pages/Service/Service';
import AddService from './pages/Service/AddService/AddService';
import Branches from './pages/Branches/Branches';
import AddBranches from './pages/Branches/AddBranches/AddBranches';
import ServiceProvider from './pages/ServiceProvider/ServiceProvider';
import AddServiceProvider from './pages/ServiceProvider/AddServiceProvider/AddServiceProvider';
import ServiceAppointments from './pages/ServiceAppointments/ServiceAppointments';
import ServiceAppointmentsProcess from './pages/ServiceAppointments/ServiceAppointmentsProcess/ServiceAppointmentsProcess';
import ImportData from './pages/ImportData/ImportData';
import Dashboard from './pages/Dashboard/Dashboard';
import AddOrder from './pages/Order/AddOrder/AddOrder';
import { getAllOrderService, getAllTimeStampOrderService } from './pages/Order/OrderService/OrderService';
import UtilsData from './Utils/Utils';
import { format } from 'date-fns';
import useSound from 'use-sound';
import notificationSound from './assets/audio/order-placed.wav';
import Company from './pages/Company/Company';
import AddCompany from './pages/Company/AddCompany/AddCompany';
import OrderCart from './components/OrderCart/OrderCart';
import { orderDate } from './services/Regular-services/DateService';
import { getAllBrandService } from './pages/BrandList/BrandService/BrandService';
import Spots from './pages/Spot/Spots';
import AddSpot from './pages/Spot/Add-spot/AddSpot';

function App() {
  const user = useSelector(state => state.auth);
  const loader = useSelector(state => state.loader?.items);
  const sideBarData = useSelector(state => state.sideBar?.items);
  const dispatch = useDispatch();
  const [routeData, setRouteData] = useState([]);
  const orderFilter = useSelector(state => state.OrderFilter);
  const tokenData = UtilsData();
  const order = useSelector(state => state.order);
  const totalBrand = useSelector(state => state.totalBrand);
  const soundData = useSelector(state => state.notificationSound?.sound);

  const [isAudio, setIsAudio] = useState(false);

  const [playSound] = useSound(notificationSound);

  /**
   * Setting first link in sidebar as the initial link after login
   */
  useEffect(() => {
    if (sideBarData.length > 0) {
      if (Array.isArray(sideBarData)) {
        sideBarData.sort((a, b) => a.sequence - b.sequence);
        let currentRoute = null;
        let newRoute = [];
        for (const item of sideBarData) {
          if (item.parent_id === 0) {
            currentRoute = {
              path: item.router_link,
              name: item.name,
              subRoutes: []
            };
            newRoute.push(currentRoute);
          } else {
            if (currentRoute && item.parent_id !== 3 && item.parent_id !== 4 && item.parent_id !== 22 && item.parent_id !== 23 && item.parent_id !== 24 && item.parent_id !== 31) {
              if (item.allow !== '0') {
                currentRoute.subRoutes.push({
                  path: '/' + item.router_link,
                  name: item.name
                });
              }
            } else {
              if (item.allow !== '0') {
                let separateRoute = {
                  path: '/' + item.router_link,
                  name: item.name
                };
                newRoute.push(separateRoute);
              }
            }
          }
        }
        const filteredRouteData = newRoute.filter(route => {
          if (route.subRoutes && route.subRoutes.length === 0) {
            return false; // Filter out routes with empty subRoutes
          }
          return true; // Keep routes with non-empty subRoutes
        });
        newRoute.length = 0; // Clear the current contents of routeData
        newRoute.push(...filteredRouteData); // Push the filtered routes back into routeData
        setRouteData(newRoute);
      }
    }
    // eslint-disable-next-line
  }, [sideBarData]);

  const fetchBrand = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 1000000
    }
    getAllBrandService(requestBody, tokenData, dispatch, true);
  }

  const onPlay = () => {
    if (isAudio) {
      playSound();
      setIsAudio(false)
    }
  }

  let lastDay = orderFilter.orderDate;

  const checkDateChange = () => {
    const currentDay = orderDate();
    if (currentDay !== lastDay) {

      if (user.isAuthenticated && 
        (orderFilter && (orderFilter.status === '' || !orderFilter.status) && 
            (orderFilter.orderType === '' || !orderFilter.orderType))) {
        if (user.user.data.typeId === 2) {
          window.location.reload();
          // fetchOrder(currentDay);
          // dispatch(getOrderFilterData({ orderDate: currentDay, status: '', orderType: '' }))
        }
      }
    }
  }

  useEffect(() => {
    setInterval(checkDateChange, 10000);
    // eslint-disable-next-line
  }, [setInterval])
  // Check every minute

  /**
   * Fetch order data
   */
  const fetchOrder = (dateData) => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    if (orderFilter?.orderDate !== '' && orderFilter?.orderDate !== null) {
      requestBody.p_created_date = dateData ? dateData : orderFilter?.orderDate;
    }
    if (orderFilter?.status !== '' && orderFilter?.status !== null) {
      requestBody.p_status = orderFilter?.status;
    }
    if (orderFilter?.orderType !== '' && orderFilter?.orderType !== null) {
      requestBody.p_type = orderFilter?.orderType;
    }
    getAllOrderService(requestBody, tokenData, dispatch);
  }

  useEffect(() => {
    if (isAudio) {
      onPlay()
    }
    // eslint-disable-next-line
  }, [isAudio])



  const fetchTimeStampOrder = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 1000,
      p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    if (order?.timeStamp && order.timeStamp !== '') {
      requestBody.p_created_time = order.timeStamp;
    }
    if (orderFilter?.orderDate !== '' && orderFilter?.orderDate !== null) {
      requestBody.p_created_date = orderFilter?.orderDate;
    }
    getAllTimeStampOrderService(requestBody, tokenData, dispatch).then((response) => {
      if (response && response.status === 'success') {
        if (response.data.length > 0) {
          setIsAudio(true);
        }
      }
    });
  }

  useEffect(() => {
    const currentDateFormatted = format(new Date(), 'yyyy-MM-dd');
    if ((user.isAuthenticated) && 
    ((order && order.items && 
      order.items.length < 1 && 
      order.api === false) || !order) && 
      (orderFilter && 
        (orderFilter.orderDate !== '' && 
          orderFilter.orderDate !== null
          && orderFilter.orderDate === currentDateFormatted) && 
          (orderFilter.status === '' || !orderFilter.status) && 
          (orderFilter.orderType === '' || !orderFilter.orderType))) {
      if (user.user.data.typeId === 2) {
        fetchOrder();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, user, orderFilter]);


  useEffect(() => {
    const currentDateFormatted = format(new Date(), 'yyyy-MM-dd');

    // Check if user is authenticated, orders exist, and filters match the conditions
    const shouldFetchData = (
      user.isAuthenticated &&
      order &&
      order.items &&
      order.items.length >= 0 &&
      orderFilter && (
        (orderFilter.orderDate === currentDateFormatted) &&
        (orderFilter?.status === '' || orderFilter?.status === null) &&
        (orderFilter?.orderType === '' || orderFilter?.orderType === null)
      )
    );

    const interval = setInterval(() => {
      if (shouldFetchData) {
        if (user.user.data.typeId === 2) {
          fetchTimeStampOrder();
        }
      }
    }, 10000);

    if (totalBrand && totalBrand.items && totalBrand.items.length === 0 && user?.user && user.user?.data && user.user.data?.typeId === 2) {
      fetchBrand();
    }

    const multipleBeepInterval = setInterval(() => {
      if (user?.user && user.user?.data && user.user.data?.typeId === 2) {
        if (totalBrand && totalBrand.items && totalBrand.items.length > 0 && totalBrand.items[0]?.data && totalBrand.items[0].data.length > 0) {
          if (order && order?.items && order.items.length > 0) {
            const hasMultipleBeep = totalBrand.items[0].data.some(brand => (brand.multipal_beep === '1' || brand.multipal_beep === 1));
            const pendingOrders = order.items.some(order => order.status === '0');
            if (hasMultipleBeep && pendingOrders && soundData) {
              // console.log("Playing sound");
              playSound();
            }
          }
        }
      }
    }, 5000);
    return () => {
      clearInterval(interval);
      clearInterval(multipleBeepInterval);
    };
    // eslint-disable-next-line
  }, [order, user, orderFilter, totalBrand, setInterval, playSound, soundData]);

  /**
   * Logout function
   */
  // const logoutFunction = async () => {
  //   await persistStore(store, { storage }).purge();
  //   dispatch(clearSidebarData());
  //   dispatch(logout());
  // }

  /**
   * Logout if not checked remember me
   */
  // useEffect(() => {
  //   const handleWindowClose = (event) => {
  //     if (user.isAuthenticated && !user.rememberMe) {

  //       const currentTime = CalculateCurrentTime();
  //       const [currentHours, currentMinutes, currentSeconds] = currentTime.split(':').map(Number);

  //       const specificTime = user.loginTime;
  //       const [specificHours, specificMinutes, specificSeconds] = specificTime.split(':').map(Number);

  //       // Calculate the difference in seconds
  //       const currentTimeInSeconds = currentHours * 3600 + currentMinutes * 60 + currentSeconds;
  //       const specificTimeInSeconds = specificHours * 3600 + specificMinutes * 60 + specificSeconds;
  //       const timeDifferenceInSeconds = currentTimeInSeconds - specificTimeInSeconds;

  //       // Convert the difference to hours
  //       const timeDifference = Math.abs(timeDifferenceInSeconds) / 3600;
  //       if (timeDifference >= 1) {
  //         logoutFunction();
  //       }
  //     }
  //   };
  //   window.addEventListener('beforeunload', handleWindowClose);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleWindowClose);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  /**
   * Set and remove loader class
   */
  useEffect(() => {
    if (loader === true) {
      document.body.classList.add('loader-show');
    } else {
      document.body.classList.remove('loader-show');
    }
    // Clean up function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove('loader-show');
    };
  }, [loader]);

  /**
   * Navigate to first link in sidebar 
   * @returns {*}
   */
  const navigateToFirstPath = () => {
    if (routeData.length > 0) {
      const firstRoute = routeData[0];
      if (firstRoute.subRoutes && firstRoute.subRoutes.length > 0) {
        // If the first route contains subRoutes, navigate to the path of the first element in subRoutes
        return <Navigate to={firstRoute.subRoutes[0].path} />;
      } else if (firstRoute.path) {
        // If the first route does not contain subRoutes, but has its own path, navigate to its path
        return <Navigate to={firstRoute.path} />;
      }
    }
    else {
      return '/';
    }
  };

  //Authenticated Routes Start

  const authenticatedRoutes = [
    {
      path: '/',
      element: navigateToFirstPath()
    },
    {
      path: '/home',
      element: navigateToFirstPath()
    },
    {
      path: '/roles',
      element: <Roles />
    },
    {
      path: '/addroles',
      element: <Addroles />
    },
    {
      path:'/spots',
      element:<Spots/>
    },
    {
      path:'/addspots',
      elements:<AddSpot/>
    },
    {
      path: '/imagemanager',
      element: <Imagemanager />
    },
    {
      path: '/addimage',
      element: <Addimage />
    },
    {
      path: '/addcategory',
      element: <AddCategory />
    },
    {
      path: '/edit-category',
      element: <AddCategory />
    },
    {
      path: '/adduser',
      element: <Adduser />
    },
    {
      path: '/edit-user',
      element: <Adduser />
    },
    {
      path: '/brand-list',
      element: <BrandList />
    },
    {
      path: '/add-brand',
      element: <Addbrand />
    },
    {
      path: '/edit-brand',
      element: <Addbrand />
    },
    {
      path: '/category',
      element: <Category />
    },
    {
      path: '/adminusers',
      element: <AdminUsers />
    },
    {
      path: '/leaf-level-category',
      element: <LeafLevelCategory />
    },
    {
      path: '/add-leaf-level-category',
      element: <AddLeafLevelCategory />
    },
    {
      path: '/edit-leaf-level-category',
      element: <AddLeafLevelCategory />
    },
    {
      path: '/tags',
      element: <Tag />
    },
    {
      path: '/add-tags',
      element: <AddTag />
    },
    {
      path: '/sub-category',
      element: <SubCategory />
    },
    {
      path: '/add-sub-category',
      element: <AddSubCategory />
    },
    {
      path: '/edit-sub-category',
      element: <AddSubCategory />
    },
    {
      path: '/blog-list',
      element: <Blog />
    },
    {
      path: '/add-blog',
      element: <Addblog />
    },
    {
      path: '/edit-blog',
      element: <Addblog />
    },
    {
      path: '/faq-list',
      element: <Faq />
    },
    {
      path: '/add-faq',
      element: <AddFaq />
    },
    {
      path: '/policy-list',
      element: <Policies />
    },
    {
      path: '/add-policy',
      element: <Addpolicy />
    },
    {
      path: '/edit-policy',
      element: <Addpolicy />
    },
    {
      path: '/customer',
      element: <Customer />
    },
    {
      path: '/customer-view',
      element: <Customerview />
    },
    {
      path: '/subscriber',
      element: <Subscribers />
    },
    {
      path: '/product',
      element: <Product />
    },
    {
      path: '/add-product',
      element: <AddProduct />
    },
    {
      path: '/product-review-management',
      element: <ProductReview />
    },
    {
      path: '/discount',
      element: <Discount />
    },
    {
      path: '/tv',
      element: <Tv />
    },
    {
      path: '/add-tv',
      element: <AddTv />
    },
    {
      path: '/add-discount',
      element: <AddDiscount />
    },
    {
      path: '/edit-discount',
      element: <AddDiscount />
    },
    {
      path: '/discount-catalogue',
      element: <DiscountCatalogue />
    },
    {
      path: '/add-discount-catalogue',
      element: <AddDiscountCatalogue />
    },
    {
      path: '/edit-discount-catalogue',
      element: <AddDiscountCatalogue />
    },
    {
      path: '/filter',
      element: <Filter />
    },
    {
      path: '/upload-image',
      element: <UploadImage />
    },
    {
      path: '/tax',
      element: <Tax />
    },
    {
      path: '/add-tax',
      element: <AddTax />
    },
    {
      path: '/warehouse',
      element: <Warehouse />
    },
    {
      path: '/add-warehouse',
      element: <AddWarehouse />
    },
    {
      path: '/country',
      element: <Country />
    },
    {
      path: '/carts',
      element: <Carts />
    },
    {
      path: '/orders',
      element: <Order />
    },
    {
      path: '/add-order',
      element: <AddOrder />
    },
    {
      path: '/edit-order',
      element: <AddOrder />
    },
    {
      path: '/cart-detail',
      element: <ViewCart />
    }
    ,
    {
      path: '/order-detail',
      element: <OrderDetail />
    },
    {
      path: '/inventory',
      element: <Inventory />
    },
    {
      path: '/add-inventory',
      element: <AddInventory />
    },
    {
      path: '/order-prosessing',
      element: <OrderProsessing />
    },
    {
      path: '/packager-and-manufacturer',
      element: <PackagerAndManufacturer />
    },
    {
      path: '/add-packager',
      element: <AddPackager />
    },
    {
      path: '/attributes',
      element: <Attribute />
    },
    {
      path: '/add-attribute',
      element: <AddAttribute />
    },
    {
      path: '/service',
      element: <Service />
    },
    {
      path: '/add-service',
      element: <AddService />
    },
    {
      path: '/branch',
      element: <Branches />
    },
    {
      path: '/add-branch',
      element: <AddBranches />
    },
    {
      path: '/service-provider',
      element: <ServiceProvider />
    },
    {
      path: '/add-service-provider',
      element: <AddServiceProvider />
    },
    {
      path: '/service-appointment',
      element: <ServiceAppointments />
    },
    {
      path: '/service-appointment-process',
      element: <ServiceAppointmentsProcess />
    },
    {
      path: '/import-data',
      element: <ImportData />
    },
    {
      path: '/dashboard',
      element: <Dashboard />
    },
    {
      path: '/company',
      element: <Company />
    },
    {
      path: '/add-company',
      element: <AddCompany />
    }
  ];

  //Authenticated Routes End


  // Unauthenticated Routes Start

  const unauthenticatedRoutes = [
    {
      path: '/',
      element: <Navigate to='login' />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/reset-password',
      element: <Resetpassword />
    },
    {
      path: '/otp',
      element: <OTP />
    }
  ]

  //Unauthenticated Routes End


  // Common Routes Start
  const commonRoutes = [
    {
      path: '/error',
      element: <ErrorPage />
    },
    {
      path: '/custom-table',
      element: <CustomTable />
    },
    {
      path: '/tooltip',
      element: <BootstrapTooltips />
    },
    {
      path: '/invoice',
      element: <Invoice />
    },
    {
      path: '/order-cart',
      element: <OrderCart />
    },
  ]
  // Common Routes Start

  return (
    <>
      <div className={`fullpage-loader ${loader ? '' : 'fullpage-loader--invisible'}`}>
        <div className="fullpage-loader__logo">
          <img src={logoImage} alt="Logo" />
        </div>
      </div>
      <Router>
        <Routes>
          <Route path='*' element={<ErrorPage />} />
          {commonRoutes && commonRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          ))}

          {unauthenticatedRoutes && unauthenticatedRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={user.isAuthenticated ? navigateToFirstPath() : route.element}
            />
          ))}

          {authenticatedRoutes && authenticatedRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={user.isAuthenticated ? route.element : <Navigate to="/login" />}
            />
          ))}
        </Routes>
      </Router>
    </>
  );
}

export default App;