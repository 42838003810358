const brandFilterInitialState = {
    name: '', 
    category: '', 
    subCategory: '', 
    leafCategory: '', 
    tags: [],
    isActive: '',
    isDelete: '',
    type: ''
};

/**
 * Store brand filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const brandFilterReducer = (state = brandFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_BRAND_FILTER':
            return {
                ...state,
                name: action.payload.name,
                category: action.payload.category, 
                subCategory: action.payload.subCategory, 
                leafCategory: action.payload.leafCategory, 
                tags: action.payload.tags,
                isActive: action.payload.isActive,
                isDelete: action.payload.isDelete,
                type: action.payload.type
            }
        case 'CLEAR_BRAND_FILTER':
            return brandFilterInitialState;
        default:
            return state;
      };
}