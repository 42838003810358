/**
 * Set image list data into reducer
 * @param {*} data 
 * @returns {*}
 */
export const getImageData = (data) => ({
    type: 'GET_IMAGE_DATA',
    payload: data
});

/**
 * Clear image list data
 * @returns {*}
 */
export const clearImageData = () => ({
    type: 'CLEAR_IMAGE_DATA'
});

/**
 * Set total image list data into reducer (No pagination)
 * @param {*} data 
 * @returns {*}
 */
export const getTotalImageData = (data) => ({
    type: 'TOTAL_IMAGE',
    payload: data
})

/**
 * Clear total image list data (No pagination)
 * @returns {*}
 */
export const clearTotalImageData = () => ({
    type: 'CLEAR_TOTAL_IMAGE'
})