import { Dispatch } from "redux";
import { addUpdatePackagerRequest, getAllPackagerRequest, getAllPackagerResponse } from "./PackagerModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearPackagerData, clearTotalPackagerData, getPackagerData, getTotalPackagerData } from "../../../redux/Actions/PackagerAction/PackagerAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearPackagerPageData, getPackagerPageData } from "../../../redux/Actions/PackagerAction/PackagerPageAction";

let errorShow = false;
/*
 * Service to get Packager and Manufacturer list
*/
export const getAllPackagerService = (
  packagerListRequest: getAllPackagerRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<getAllPackagerResponse> => {
  return new Promise<getAllPackagerResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (packagerListRequest.p_skip !== undefined && packagerListRequest.p_skip !== null) {
        queryParams += `p_skip=${packagerListRequest.p_skip}&`;
      }
      if (packagerListRequest.p_take !== undefined && packagerListRequest.p_take !== null) {
        queryParams += `p_take=${packagerListRequest.p_take}&`;
      }
      if (packagerListRequest.p_name !== undefined && packagerListRequest.p_name !== null) {
        queryParams += `p_name=${packagerListRequest.p_name}&`;
      }
      if (packagerListRequest.p_type !== undefined && packagerListRequest.p_type !== null) {
        queryParams += `p_type=${packagerListRequest.p_type}&`
      }
      if (packagerListRequest.p_is_active !== undefined && packagerListRequest.p_is_active !== null) {
        queryParams += `p_is_active=${packagerListRequest.p_is_active}&`
      }
      if (packagerListRequest.p_IsDeleted !== undefined && packagerListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${packagerListRequest.p_IsDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.PACKAGER + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(getTotalPackagerData(response));
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getPackagerData(response));
        }
        else {
          dispatch(getPackagerData(response))
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Packager permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating packager
*/
export const addUpdatePackagerService = (
  addUpdatePackagerRequest: addUpdatePackagerRequest,
  token: string, type: string, navigate?: any, pageNumber?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.PACKAGER, token, addUpdatePackagerRequest);
      }
      if (type === 'update') {
        response = await putApiService(API_URL.BASE_URL + API_URL.PACKAGER, token, addUpdatePackagerRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearPackagerData());
        dispatch(clearTotalPackagerData());
        if (type === 'add') {
          dispatch(clearPackagerPageData());
          const toastMessage = addUpdatePackagerRequest.p_type === '0' ? 'Manufacturer' : (addUpdatePackagerRequest.p_type === '1' ? 'Packager' : (addUpdatePackagerRequest.p_type === '2' ? 'importer' : 'Data'))
          toast.success(toastMessage + ' added successfully!');
          navigate('/packager-and-manufacturer');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            toast.success('Packager undeleted successfully!');
          }
          else {
            if (navigate) {
              const toastMessage = addUpdatePackagerRequest.p_type === '0' ? 'Manufacturer' : (addUpdatePackagerRequest.p_type === '1' ? 'Packager' : (addUpdatePackagerRequest.p_type === '2' ? 'importer' : 'Data'))
              if (pageNumber) {
                dispatch(getPackagerPageData(pageNumber + 1))
              }
              else {
                dispatch(clearPackagerPageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: toastMessage + " updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/packager-and-manufacturer');
              });
            }
            else {
              const toastMessage = addUpdatePackagerRequest.p_type === '0' ? 'Manufacturer' : (addUpdatePackagerRequest.p_type === '1' ? 'Packager' : (addUpdatePackagerRequest.p_type === '2' ? 'importer' : 'Data'))
              toast.success(toastMessage + ' status updated successfully!');
            }
          }
        }
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getPackagerPageData(pageNumber))
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for deleting packager
*/
export const deletePackagerService = (deleteId: number, token: string, type: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.PACKAGER + '/' + deleteId, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearPackagerData());
        dispatch(clearTotalPackagerData());
        const toastMessage = type === '0' ? 'Manufacturer' : (type === '1' ? 'Packager' : (type === '2' ? 'importer' : 'Data'))
        toast.success(toastMessage + ' deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getPackagerPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};