const packagerInitialState = { 
    items: []
};

/**
 * Store packager list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const packagerReducer = (state = packagerInitialState, action) => {
    switch (action.type) {
        case 'GET_PACKAGER_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_PACKAGER_DATA':
            return packagerInitialState;
        default:
            return state;
      };
}

const totalpackagerInitialState = {
    items: []
}

/**
 * Store total packager list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*} 
 */
export const TotalPackagerReducer = (state = totalpackagerInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_PACKAGER':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_PACKAGER':
            return totalpackagerInitialState
        default:
            return state;
      };
}