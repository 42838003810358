/**
 * Set product list data into reducer
 * @param {*} data 
 * @returns {*}
 */
export const getProductData = (data) => ({
    type: 'GET_PRODUCT_DATA',
    payload: data
})

/**
 * clear product list data
 * @returns {*}
 */
export const clearProductData = () => ({
    type: 'CLEAR_PRODUCT_DATA'
})

/**
 * Set total product list data into reducer (No pagination)
 * @param {*} data 
 * @returns {*}
 */
export const getTotalProductData = (data) => ({
    type: 'TOTAL_PRODUCT_DATA',
    payload: data
})

/**
 * Clear total product list data (No pagination)
 * @returns {*}
 */
export const clearTotalProductData = () => ({
    type: 'CLEAR_TOTAL_PRODUCT'
})