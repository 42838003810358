/**
 * Action to set notification loader
 * @return {*}
 */
export const setNotificationLoader = () => ({
    type: 'SET_NOTIFICATION_LOADER'
})

/**
 * Action to clear notification loader
 * @return {*}
 */
export const clearNotificationLoader = () => ({
    type: 'CLEAR_NOTIFICATION_LOADER'
})