/**
 * Set category page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getCategoryPageData = (page) => ({
    type: 'GET_CATEGORY_PAGE',
    payload: page
})

/**
 * Clear category page data
 * @returns {*}
 */
export const clearCategoryPageData = () => ({
    type: 'CLEAR_CATEGORY_PAGE'
})