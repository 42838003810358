/**
 * Set service provicer page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getServiceProviderPageData = (page) => ({
    type: 'GET_SERVICE_PROVIDER_PAGE',
    payload: page
})

/**
 * Clear service provider page data
 * @returns {*}
 */
export const clearServiceProviderPageData = () => ({
    type: 'CLEAR_SERVICE_PROVIDER_PAGE'
})