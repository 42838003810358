import React, { useEffect, useState } from "react";
import "./AddRoles.scss";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import "../Roles";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Toggle from "../../../components/Toggle/Toggle";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { handleCancleClick, handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import CustomTable from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { addUpdateRolesService, getRoleById } from "../Role-Service/roleService.tsx";
import { clearRolePageData } from "../../../redux/Actions/rolesAction/RolesPageAction.js";
import { toast } from "react-toastify";


const columns = [
  {
    name: 'Module Name',
    selector: row => row.module,
    sortable: true,
  },
  {
    name: 'Is Allow',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'Add',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'View',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'Update ',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'Delete ',
    cell: (row) => <Toggle checked={row.toggle} />,
  },
  {
    name: 'Select All ',
    cell: (row) => <Toggle checked={row.toggle} />,
  }
];

const showKey = {
  "name": true
}


//Validation schema
const validationSchema = Yup.object({
  RoleTitle: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
  description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED)
});


export default function Addroles() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth);
  const rolePage = useSelector(state => state.rolePage.items);
  const sideBarData = useSelector(state => state.sideBar?.items);
  const tokenData = UtilsData();

  const location = useLocation();
  const { Data } = location.state || {};

  const [createdBy, setCreatedBy] = useState(null);
  const [isDeleted, setIsDeleted] = useState('0');


  const [initialValues, setInitialValues] = useState({
    RoleTitle: '',
    description: '',
    status: true,
    Permissions: [
      {
        "name": "Customer",
        "icon": null,
        "router_link": "customer",
        "parent_id": 25,
        "sequence": 2,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "0",
        "add_id": "2",
        "view_id": "4",
        "edit_id": "3",
        "delete_id": "5"
      },
      {
        "name": "Subscriber",
        "icon": null,
        "router_link": "subscriber",
        "parent_id": 25,
        "sequence": 3,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "6",
        "add_id": "7",
        "view_id": "9",
        "edit_id": "8",
        "delete_id": "10"
      },
      {
        "name": "Orders",
        "icon": "<FaShoppingCart />",
        "router_link": "orders",
        "parent_id": 3,
        "sequence": 4,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "11",
        "add_id": "12",
        "view_id": "14",
        "edit_id": "13",
        "delete_id": "15"
      },
      {
        "name": "Carts",
        "icon": "<FaCartPlus />",
        "router_link": "carts",
        "parent_id": 4,
        "sequence": 5,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "16",
        "add_id": "17",
        "view_id": "19",
        "edit_id": "18",
        "delete_id": "20"
      },
      {
        "name": "Brand",
        "icon": null,
        "router_link": "brand-list",
        "parent_id": 26,
        "sequence": 7,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "21",
        "add_id": "22",
        "view_id": "24",
        "edit_id": "23",
        "delete_id": "25"
      },
      {
        "name": "Categories",
        "icon": null,
        "router_link": "category",
        "parent_id": 26,
        "sequence": 8,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "26",
        "add_id": "27",
        "view_id": "29",
        "edit_id": "28",
        "delete_id": "30"
      },
      {
        "name": "Sub Categories",
        "icon": null,
        "router_link": "sub-category",
        "parent_id": 26,
        "sequence": 9,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "31",
        "add_id": "32",
        "view_id": "34",
        "edit_id": "33",
        "delete_id": "35"
      },
      {
        "name": "Product",
        "icon": null,
        "router_link": "product",
        "parent_id": 26,
        "sequence": 10,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "36",
        "add_id": "37",
        "view_id": "39",
        "edit_id": "38",
        "delete_id": "40"
      },
      {
        "name": "Image Manager",
        "icon": null,
        "router_link": "imagemanager",
        "parent_id": 26,
        "sequence": 11,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "41",
        "add_id": "42",
        "view_id": "44",
        "edit_id": "43",
        "delete_id": "45"
      },
      {
        "name": "Leaf Level Categories",
        "icon": null,
        "router_link": "leaf-level-category",
        "parent_id": 26,
        "sequence": 12,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "46",
        "add_id": "47",
        "view_id": "49",
        "edit_id": "48",
        "delete_id": "50"
      },
      {
        "name": "Tags",
        "icon": null,
        "router_link": "tags",
        "parent_id": 26,
        "sequence": 13,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "51",
        "add_id": "52",
        "view_id": "54",
        "edit_id": "53",
        "delete_id": "55"
      },
      {
        "name": "packager and manufacturer",
        "icon": null,
        "router_link": "packager-and-manufacturer",
        "parent_id": 26,
        "sequence": 14,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "126",
        "add_id": "127",
        "view_id": "129",
        "edit_id": "128",
        "delete_id": "130"
      },
      {
        "name": "Attributes",
        "icon": null,
        "router_link": "attributes",
        "parent_id": 26,
        "sequence": 15,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "131",
        "add_id": "132",
        "view_id": "134",
        "edit_id": "133",
        "delete_id": "135"
      },
      {
        "name": "Admin User",
        "icon": null,
        "router_link": "adminusers",
        "parent_id": 28,
        "sequence": 17,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "56",
        "add_id": "57",
        "view_id": "59",
        "edit_id": "58",
        "delete_id": "60"
      },
      {
        "name": "Roles List",
        "icon": null,
        "router_link": "roles",
        "parent_id": 28,
        "sequence": 18,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "61",
        "add_id": "62",
        "view_id": "64",
        "edit_id": "63",
        "delete_id": "65"
      },
      {
        "name": "Discount",
        "icon": null,
        "router_link": "discount",
        "parent_id": 27,
        "sequence": 20,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "66",
        "add_id": "67",
        "view_id": "69",
        "edit_id": "68",
        "delete_id": "70"
      },
      {
        "name": "Discount Catalogue",
        "icon": null,
        "router_link": "discount-catalogue",
        "parent_id": 27,
        "sequence": 21,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "71",
        "add_id": "72",
        "view_id": "74",
        "edit_id": "73",
        "delete_id": "75"
      },
      {
        "name": "Policy List",
        "icon": null,
        "router_link": "policy-list",
        "parent_id": 29,
        "sequence": 23,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "76",
        "add_id": "77",
        "view_id": "79",
        "edit_id": "78",
        "delete_id": "80"
      },
      {
        "name": "FAQ List",
        "icon": null,
        "router_link": "faq-list",
        "parent_id": 29,
        "sequence": 24,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "81",
        "add_id": "82",
        "view_id": "84",
        "edit_id": "83",
        "delete_id": "85"
      },
      {
        "name": "Blog List",
        "icon": null,
        "router_link": "blog-list",
        "parent_id": 29,
        "sequence": 25,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "86",
        "add_id": "87",
        "view_id": "89",
        "edit_id": "88",
        "delete_id": "90"
      },
      {
        "name": "Country",
        "icon": null,
        "router_link": "country",
        "parent_id": 30,
        "sequence": 26,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "91",
        "add_id": "92",
        "view_id": "94",
        "edit_id": "93",
        "delete_id": "95"
      },
      {
        "name": "Tax",
        "icon": null,
        "router_link": "tax",
        "parent_id": 30,
        "sequence": 27,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "96",
        "add_id": "97",
        "view_id": "99",
        "edit_id": "98",
        "delete_id": "100"
      },
      {
        "name": "Warehouse",
        "icon": null,
        "router_link": "warehouse",
        "parent_id": 30,
        "sequence": 28,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "101",
        "add_id": "102",
        "view_id": "104",
        "edit_id": "103",
        "delete_id": "105"
      },
      {
        "name": "Product Review Management",
        "icon": "<FaStarHalfAlt />",
        "router_link": "product-review-management",
        "parent_id": 22,
        "sequence": 29,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "106",
        "add_id": "107",
        "view_id": "109",
        "edit_id": "108",
        "delete_id": "110"
      },
      {
        "name": "Tv",
        "icon": "<MdLiveTv />",
        "router_link": "tv",
        "parent_id": 23,
        "sequence": 30,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "111",
        "add_id": "112",
        "view_id": "114",
        "edit_id": "113",
        "delete_id": "115"
      },
      {
        "name": "Influencer Reviews",
        "icon": "<FaStarHalfAlt />",
        "router_link": "influencer-reviews",
        "parent_id": 24,
        "sequence": 31,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "116",
        "add_id": "117",
        "view_id": "119",
        "edit_id": "118",
        "delete_id": "120"
      },
      {
        "name": "Inventory",
        "icon": "<MdOutlineInventory />",
        "router_link": "inventory",
        "parent_id": 31,
        "sequence": 32,
        "allow": "0",
        "add": "0",
        "view": "0",
        "edit": "0",
        "delete": "0",
        "allow_id": "121",
        "add_id": "122",
        "view_id": "124",
        "edit_id": "123",
        "delete_id": "125"
      }
    ]
  });

  /**
   * Fetching role detail data
   * @returns {*}
   */
  const fetchRole = async () => {
    try {
      // Call the getRoleById function
      const role = await getRoleById(Data.data.id, tokenData, dispatch);
      // Execute the returned async function to fetch the data

      if (role && role.status === 'success') {
        setInitialValues({
          RoleTitle: role.data.roleDetails[0].title,
          description: role.data.roleDetails[0].description,
          status: (role.data.roleDetails[0].is_active === true || role.data.roleDetails[0].is_active === 1 || role.data.roleDetails[0].is_active === '1') ? 1 : 0,
          Permissions: role.data.permissions.filter(item => item.parent_id !== 0 && item.allow !== '0')
        });
        setCreatedBy(role.data.roleDetails[0].created_by);
        setIsDeleted(role.data.roleDetails[0].IsDeleted)
      }
      return role;
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchRole();
    }
    else if (sideBarData && sideBarData.length > 0) {
      const modifiedData = sideBarData.map(item => {
        if (item.parent_id !== 0) {
          return {
            ...item,
            // allow: "0",
            // add: "0",
            // view: "0",
            // edit: "0",
            // delete: "0"
          };
        }
        return item;
      });

      const filteredData = modifiedData.filter(item => item.parent_id !== 0 && item.allow !== '0');

      setInitialValues({
        RoleTitle: '',
        description: '',
        status: true,
        Permissions: filteredData
      })
    }
    // eslint-disable-next-line
  }, [Data, sideBarData])

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {

    const rolePermisionData = values.Permissions.reduce((acc, permission) => {
      // Extract id and value for each permission type
      const permissionTypes = ['allow', 'add', 'view', 'edit', 'delete'];
      const permissionData = permissionTypes.reduce((data, type) => {
        if (permission[type] === 1 || permission[type] === true || permission[type] === '1') {
          const id = parseInt(permission[type + '_id']);
          data.push({ id: id, value: (permission[type] === true || permission[type] === 1 || permission[type] === '1') ? '1' : '1' });
        }
        return data;
      }, []);
      acc.push(...permissionData);
      return acc;
    }, []);

    if (!Data) {
      const requestBody = {
        title: values.RoleTitle,
        description: values.description,
        p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        createdBy: userData.user.data.userId,
        modifiedBy: userData.user.data.userId,
        permissionData: rolePermisionData
      }
      dispatch(clearRolePageData());
      dispatch(addUpdateRolesService(requestBody, tokenData, 'add', navigate))
    }
    else {
      const requestBody = {
        id: Data.data.id,
        title: values.RoleTitle,
        description: values.description,
        p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_IsDeleted: isDeleted,
        createdBy: createdBy,
        modifiedBy: userData.user.data.userId,
        permissionData: rolePermisionData
      }
      if (Data?.length && rolePage > 1) {
        if ((values.status === '0' || values.status === 0 || values.status === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateRolesService(requestBody, tokenData, 'update', navigate, rolePage), 'update');
          }
          else {
            handlesaveClick(dispatch, addUpdateRolesService(requestBody, tokenData, 'update', navigate, rolePage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateRolesService(requestBody, tokenData, 'update', navigate, rolePage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateRolesService(requestBody, tokenData, 'update', navigate), 'update');
      }
    }
  }

  return (
    <>
      <div className="main-section d-none-search">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Role</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate('/roles')}>Roles</Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Role</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Role</h4>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Role Title<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="RoleTitle"
                        placeholder="Role Title"
                        className="add-roles"
                        maxLength={20}
                        Regex={/[^a-zA-Z\s]/g}
                        value={values.RoleTitle}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Description<span className="required-label"></span></h4>
                      </div>
                      <Field name="description"
                        component={CKEditorCpmponent}
                        maxLength={255}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field name='status'
                          className='toggle'
                          setFieldValue={setFieldValue}
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title mb-0">Permissions</h4>
                    </div>
                    <CustomTable disabled={Data && Data.isEdit === false} page='addRoles' showKey={showKey} showTooltip={true} checkBool={false} data={values.Permissions} heading={columns} showSelectAll={true} />
                    <div className="col-12">
                      <div className="btn-submit">
                        {
                          ((Data && Data.isEdit === true) || (!Data)) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/Roles', navigate)}
                              />
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}