import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import CKEditorCpmponent from '../../../components/CKEditor-component/CKEditorCpmponent';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from '../../../Utils/Utils';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '../../../components/Toggle/Toggle';
import { getAllSubCategoryService } from '../../SubCategory/SubCategoryService/SubCategoryService';
import { addUpdateLeafLevelService, getLeafCategoryById } from '../LeafLevelService/LeafLevelService';
import UploadImage from '../../../components/UploadImage/UploadImage';
import ShowImage from '../../../components/ShowImage/ShowImage';
import { parseImage } from '../../../services/Regular-services/ImageService';

//Validation schema
const validationSchema = Yup.object({
  leafName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
  parentCategory: Yup.string().required(ValidationMessage.SINGLE_CATEGORY_SELECT),
  upload: Yup.array().min(1, ValidationMessage.UPLOAD_IMAGE),
  description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED)
})


export default function AddLeafLevelCategory() {

  //Setting initial values
  const [initialValues, setInitialValues] = useState({
    leafName: '',
    parentCategory: '',
    upload: [],
    description: '',
    status: true
  })

  const dispatch = useDispatch();
  const [showImage, setShowImage] = useState(false);
  const leafPage = useSelector(state => state.leafPage?.items);
  const user = useSelector(state => state.auth.user);
  const tokenData = UtilsData();
  const [errorImages, setErrorImages] = useState([]);

  //Getting data from reducer
  const navigate = useNavigate();

  //Getting data from leaf-level-category-list page
  const location = useLocation();
  const { Data } = location.state || {};

  const token = UtilsData();
  const subCategoryListData = useSelector(state => state.totalSubCategory);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);

  /**
   * Fetch Sub category data
   */
  const fetchSubCategoryData = () => {
    const subCategoryRequestBody = {
      p_skip: 0,
      p_take: 100000000
    }
    getAllSubCategoryService(subCategoryRequestBody, token, dispatch, true);
  }

  useEffect(() => {
    if (subCategoryListData && subCategoryListData.items && subCategoryListData.items.length === 0) {
      fetchSubCategoryData();
    }
    else {
      if (subCategoryListData.items[0]?.data && subCategoryListData.items[0].data.length > 0) {
        const subCategoryOptions = subCategoryListData.items[0].data.map(item => ({
          value: item.id,
          label: item.name,
          is_active: item.is_active
        }));
        setSubCategoryListOptions(subCategoryOptions);
      }
    }
    // eslint-disable-next-line
  }, [subCategoryListData])

  /**
   * Custom styles for React Select
   */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === '0' ? '#F00' : '#000', // Set color to red if is_active is '0'
    }),
  };

  /**
   * Fetching leaf level category detail data
   */
  const fetchLeafCategoryDetail = async () => {
    const leafDetail = await getLeafCategoryById(Data.data.id, token, dispatch);

    const leafImage = leafDetail.data.image_data ? parseImage(JSON.stringify(leafDetail.data.image_data)) : [];
    const leafImageData = leafImage.map(item => ({
      imageId: parseInt(item.id),
      url: item.url,
      imageType: parseInt(item.type)
    }))
    setShowImage(true);
    setInitialValues({
      leafName: leafDetail.data.Title,
      parentCategory: leafDetail.data.SubcategoryId,
      upload: leafImageData,
      description: leafDetail.data.Description,
      status: (leafDetail.data.is_active === '1' || leafDetail.data.is_active === true || leafDetail.data.is_active === 1) ? true : false
    })
  }

  useEffect(() => {
    if (Data && Data.data) {
      fetchLeafCategoryDetail();
    }
    // eslint-disable-next-line
  }, [Data])

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {

    const imageIds = values.upload.map(item => ({
      id: item.imageId,
      type_id: item.imageType
    }))
    if (!Data) {
      const requestBody = {
        p_name: values.leafName,
        p_description: values.description,
        p_subcategoryId: values.parentCategory,
        p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_createdBy: user.data.userId,
        p_imageIds: imageIds
      }
      dispatch(addUpdateLeafLevelService(requestBody, tokenData, 'add', navigate))
    }
    else {
      const requestBody = {
        p_id: Data.data.id,
        p_name: values.leafName,
        p_description: values.description,
        p_subcategoryId: values.parentCategory,
        p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_deleted: Data.data.isDeleted,
        p_createdBy: Data.data.createdBy,
        p_modifiedBy: user.data.userId,
        p_imageIds: imageIds
      }
      if (Data?.length && leafPage > 1) {
        if ((values.active === '0' || values.active === 0 || values.active === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateLeafLevelService(requestBody, tokenData, 'update', navigate, leafPage), 'update');
          }
          else {
            handlesaveClick(dispatch, addUpdateLeafLevelService(requestBody, tokenData, 'update', navigate, leafPage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateLeafLevelService(requestBody, tokenData, 'update', navigate, leafPage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateLeafLevelService(requestBody, tokenData, 'update', navigate), 'update');
      }
    }
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Leaf Level Categories</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/leaf-level-category")}>Leaf Level Categories</Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Leaf Level Categories</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Leaf Level Categories</h4>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Name<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="leafName"
                          label="Title"
                          placeholder="Enter Name"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          maxLength={20}
                          value={values.leafName}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Sub Category<span className="required-label"></span></h4>
                        <Field
                          className="select-menu mb-2"
                          name="parentCategory"
                          options={subCategoryListOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.parentCategory}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Leaf Level Category Image<span className="required-label"></span></h4>
                        </div>
                        <UploadImage name='upload' onChange={setFieldValue}
                          value={values.upload} disabled={Data && Data.isEdit === false}
                          filterByType={7} setErrorImages={setErrorImages} setShowImage={setShowImage} />

                        <ShowImage value={values.upload} errorImages={errorImages} showImage={showImage} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Description<span className="required-label"></span></h4>
                        <Field name="description"
                          component={CKEditorCpmponent}
                          maxLength={255}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field name='status' setFieldValue={setFieldValue}
                          component={Toggle} disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {(((Data && Data.isEdit === true) || (!Data)) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/leaf-level-category', navigate)}
                              />
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}