/**
 * setting attribute page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getAttributePageData = (page) => ({
    type: 'GET_ATTRIBUTE_PAGE',
    payload: page
})

/**
 * clearing attribute page data from reducer
 * @returns {*}
 */
export const clearAttributePageData = () => ({
    type: 'CLEAR_ATTRIBUTE_PAGE'
})