import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Filter from "../../components/Filter/Filter";
import "../Dashboard/Dashboard.scss";
// import { Chart as ChartJS } from "chart.js/auto";
import { defaults } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import sourceData from "../../data/sourceData.json";
import revenueData from "../../data/revenueData.json";
import { dashboardService } from "./DashboardService";
import UtilsData, { convertNumbers, restoreNumber } from "../../Utils/Utils";
import { useDispatch } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale, //x axis
  LinearScale, //y axis 
  PointElement,
  LineElement,
  Title, Tooltip
} from 'chart.js';

defaults.maintainAspectRatio = false;
defaults.responsive = true;

defaults.plugins.title.display = true;
defaults.plugins.title.align = "start";
defaults.plugins.title.font.size = 20;
defaults.plugins.title.color = "black";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

export default function Dashboard() {
  // const navigate = useNavigate();

  // const [isToggled, setIsToggled] = useState(false);

  let isToggled = false;
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  // const handleButtonClick = () => {
  //   setIsToggled((prevIsToggled) => !prevIsToggled);
  // };

  // changes by sakshi
  const [dashboardData, setDashboardData] = useState({
    totalCustomers: null,
    totalOrders: null,
    totalRevenue: null
  });

  const getDashBoardService = async () => {
    const data = await dashboardService(tokenData, dispatch);
    const customers = convertNumbers(data.data.length);
    const orders = convertNumbers(data.data.reduce((acc, user) => acc + user.total_orders, 0));
    const revenue = convertNumbers(data.data.reduce((acc, user) => acc + parseInt(user.total_money_spent), 0));
    setDashboardData({
      totalCustomers: customers,
      totalOrders: orders,
      totalRevenue: revenue
    });
    console.log("data : ", data.data);
  };

  useEffect(() => {
    getDashBoardService();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Dashboard</h4>
                </div>
              </div>
            </div>
            {isToggled && <Filter></Filter>}
            <div className="admin-detail">
              <div className="row">
                <div className="col-3">
                  <div className="admin-detail-section">
                    <strong>{dashboardData.totalCustomers}</strong>
                    <span>Total customers</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="admin-detail-section">
                    <strong>{dashboardData.totalOrders}</strong>
                    <span>Total orders</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="admin-detail-section">
                    <strong>₹{dashboardData.totalRevenue}</strong>
                    <span>Total revenue</span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="admin-detail-section">
                    <strong>360</strong>
                    <span>Total sold products</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart">
              <div className="row">
                <div className="col-md-6">
                  <div className="dataCard customerCard">
                    <Bar
                      data={{
                        labels: Object.keys(dashboardData),
                        datasets: [
                          {
                            label: "Count",
                            data: Object.values(dashboardData).map(data=>restoreNumber(data)),
                            backgroundColor: [
                              "rgba(43, 63, 229, 0.8)",
                              "rgba(250, 192, 19, 0.8)",
                              "rgba(253, 135, 135, 0.8)",
                            ],
                            borderRadius: 5,
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          title: {
                            display: true,
                            text: "Bar Chart",
                          },
                          responsive: true,
                          interaction: {
                            mode: 'index',
                            intersect: false,
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false,
                            },
                          },
                          y: {
                            grid: {
                              display: false,
                            },
                            ticks: {
                              callback: function (value) {
                                return convertNumbers(value);
                              }
                            }
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="dataCard categoryCard customerCard">
                    <Doughnut
                      data={{
                        labels: Object.keys(dashboardData).map((data) => data),
                        datasets: [
                          {
                            label: "Count",
                            data: sourceData.map((data) => data.value),
                            backgroundColor: [
                              "rgba(43, 63, 229, 0.8)",
                              "rgba(250, 192, 19, 0.8)",
                              "rgba(253, 135, 135, 0.8)",
                            ],
                            borderColor: [
                              "rgba(43, 63, 229, 0.8)",
                              "rgba(250, 192, 19, 0.8)",
                              "rgba(253, 135, 135, 0.8)",
                            ],
                          },
                        ],
                      }}
                      options={{
                        plugins: {
                          title: {
                            text: "Revenue Sources",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="dataCard revenueCard customerCard">
                    <Line
                      data={{
                        labels: revenueData.map((data) => data.label),
                        datasets: [
                          {
                            label: "Revenue",
                            data: revenueData.map((data) => data.revenue),
                            backgroundColor: "#064FF0",
                            borderColor: "#064FF0",
                          },
                          {
                            label: "Cost",
                            data: revenueData.map((data) => data.cost),
                            backgroundColor: "#FF3030",
                            borderColor: "#FF3030",
                          },
                        ],
                      }}
                      options={{
                        elements: {
                          line: {
                            tension: 0.5,
                          },
                        },
                        plugins: {
                          title: {
                            text: "Monthly Revenue & Cost",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}


// dashboard widgets
// total no of customers, orders, revenue, total sold products