// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-name {
  font-size: 18px !important;
  font-weight: 600 !important;
}
@media (max-width: 991px) {
  .product-name {
    font-size: 20px !important;
  }
}
@media (max-width: 991px) and (max-width: 575px) {
  .product-name {
    font-size: 16px !important;
  }
}
@media (max-width: 991px) and (max-width: 575px) and (max-width: 420px) {
  .product-name {
    font-size: 12px !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container.react-datepicker__view-calendar-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.react-datepicker-ignore-onclickoutside {
  height: 40px;
  padding: 10px !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Inventory/AddInventory/AddInventory.scss"],"names":[],"mappings":"AACA;EACI,0BAAA;EACA,2BAAA;AAAJ;AAEC;EAJD;IAKE,0BAAA;EACA;AACF;AAAE;EAPF;IAQG,0BAAA;EAGD;AACF;AAHG;EATH;IAUI,0BAAA;EAMF;AACF;;AAFA;EACC,WAAA;AAKD;;AAHA;EACC,aAAA;EACA,yBAAA;EACA,mBAAA;AAMD;;AAJA;EACC,YAAA;EACA,wBAAA;AAOD;;AALA;EACI,sBAAA;AAQJ;;AALA;EACC,0BAAA;AAQD","sourcesContent":["@import\"../../../variables\";\n.product-name{\n    font-size: 18px !important;\n    font-weight: 600 !important;\n\t\n\t@media(max-width:991px){\n\t\tfont-size: 20px !important;\n\n\t\t@media(max-width:575px){\n\t\t\tfont-size: 16px !important;\n\t\t\t@media(max-width:420px){\n\t\t\t\tfont-size: 12px !important;\n\t\t\t}\n\t\t}\n\t}\n}\n.react-datepicker-wrapper {\n\twidth: 100%;\n}\n.react-datepicker__input-container.react-datepicker__view-calendar-icon {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\talign-items: center;\n}\n.react-datepicker-ignore-onclickoutside {\n\theight: 40px;\n\tpadding: 10px !important;\n}\n.react-datepicker__input-container input{\n    width: 100% !important;\n\n}\n.react-datepicker__view-calendar-icon input {\n\tpadding: 6px 10px 5px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
