import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import "../Order/Order.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import { FaAngleDown, FaAngleUp, FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import { GrMoreVertical } from "react-icons/gr";
import { Accordion, Dropdown } from "react-bootstrap";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrderService } from "./OrderService/OrderService";
import UtilsData from "../../Utils/Utils";
import {
  clearOrderPageData,
  getOrderPageData,
} from "../../redux/Actions/orderAction/OrderPAgeAction";
import { format } from "date-fns";
import CustomPagination from "../../components/Pagination/Pagination";
import { clearOrderFilterData } from "../../redux/Actions/orderAction/OrderFilterAction";
import { clearOrderData } from "../../redux/Actions/orderAction/OrderAction";
import { IoIosArrowForward } from "react-icons/io";
import { orderDate } from "../../services/Regular-services/DateService";
import { HiMiniInformationCircle } from "react-icons/hi2";
import newOrderGif from "../../assets/images/new-order-gif.gif";
import { MdOutlineCancel } from "react-icons/md";
import { handleViewOrderQuantity } from "../../components/Alertmessage/Alertmessage";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { setNotificationSound } from "../../redux/Actions/NotificationAction/NotificationSound";

//Show keys which is to be displayed in table
const showKey = {
  id: true,
  customerName: true,
  phoneNumber: true,
  createdAt: true,
  orderStatus: true,
  payment: true,
  taxAmount: true,
  orderAmount: true,
  totalOrderWithTax: true,
  totalItem: true,
  tableNo: true,
};

export default function Order() {
  const [columns, setColumns] = useState([
    {
      name: "Name",
      selector: (row) => row.Name,
    },
    {
      name: "Mobile",
      selector: (row) => row.Name,
    },
    {
      name: "Date",
      selector: (row) => row.Date,
    },
    {
      name: "Table",
      selector: (row) => row.Date,
    },
    {
      name: "Total Quantity",
      selector: (row) => row.Items,
    },
    {
      name: "Subtotal",
      selector: (row) => row.Items,
    },
    {
      name: "Tax",
      selector: (row) => row.Items,
    },
    {
      name: "Total",
      selector: (row) => row.total,
    },
    {
      name: "Payment",
      selector: (row) => row.Status,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ]);

  const productHeading = [
    {
      name: "Product Name",
      selector: (row) => row.Name,
    },
    {
      name: "Size",
      selector: (row) => row.Date,
    },
    {
      name: "Quantity",
      selector: (row) => row.Name,
    },
    {
      name: "Price",
      selector: (row) => row.total,
    },
    {
      name: "Total",
      selector: (row) => row.total,
    },
    {
      name: "Type",
      selector: (row) => row.total,
    },
    {
      name: "Instruction",
      selector: (row) => row.instruction,
    },
    {
      // changes
      name: "Action",
    },
  ];

  const showProductKeys = {
    productName: true,
    quantity: true,
    size: true,
    tablePrice: true,
    food_type_two: true,
    table_product_instruction: true,
    totalTablePrice: true,
  };

  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const order = useSelector((state) => state.order);
  const orderFilter = useSelector((state) => state.OrderFilter);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const [currentPageNumber, setCurrentPage] = useState(1);
  const tokenData = UtilsData(); //Getting token
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [expandedItem, setExpandedItem] = useState([]);
  const [orderStatusFilter, setOrderStatusFilter] = useState([]);
  const soundData = useSelector((state) => state.notificationSound?.sound);

  const toggleAccordionItem = (eventKey) => {
    setExpandedItem((prevExpandedItems) =>
      prevExpandedItems.includes(eventKey)
        ? prevExpandedItems.filter((key) => key !== eventKey)
        : [...prevExpandedItems, eventKey]
    );
  };

  const sideBarData = useSelector((state) => state.sideBar.items);

  const [groupedTableData, setGroupedTableData] = useState([]);

  const [orderActionData, setOrderActionData] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);
  const authuserData = useSelector((state) => state.auth?.user);
  const filterDateData = orderDate();

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "orders"
      );
      setOrderActionData(actionData);
    }
  }, [sideBarData]);

  // const onPlay = () => {
  //   new Audio(notificationSound).play()
  // }

  useEffect(() => {
    setCurrentPage(orderPage);
  }, [orderPage]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (orderActionData[0]?.view && orderActionData[0].view === "1") ||
      !orderActionData
        ? true
        : false,
    showEdit:
      (orderActionData[0]?.edit && orderActionData[0].edit === "1") ||
      !orderActionData
        ? true
        : false,
    showDelete:
      (orderActionData[0]?.delete && orderActionData[0].delete === "1") ||
      !orderActionData
        ? false
        : false,
    showRightIcon:
      (orderActionData[0]?.edit && orderActionData[0].edit === "1") ||
      !orderActionData
        ? true
        : false,
    showWrongIcon:
      (orderActionData[0]?.edit && orderActionData[0].edit === "1") ||
      !orderActionData
        ? true
        : false,
    navigate:
      (orderActionData[0]?.edit && orderActionData[0].edit === "1") ||
      !orderActionData
        ? "/edit-order"
        : false,
    showPrint: true,
  };

  const showProductActions = {
    showRightProductIcon: true,
  };

  useEffect(() => {
    if (
      orderActionData[0]?.view &&
      orderActionData[0].view === "0" &&
      orderActionData[0]?.edit &&
      orderActionData[0].edit === "0" &&
      orderActionData[0]?.add &&
      orderActionData[0].add === "0" &&
      orderActionData[0]?.delete &&
      orderActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name",
          selector: (row) => row.Name,
        },
        {
          name: "Mobile",
          selector: (row) => row.Name,
        },
        {
          name: "Date",
          selector: (row) => row.Date,
        },
        {
          name: "Table",
          selector: (row) => row.Date,
        },
        {
          name: "Total Quantity",
          selector: (row) => row.Items,
        },
        {
          name: "Subtotal",
          selector: (row) => row.Items,
        },
        {
          name: "Tax",
          selector: (row) => row.Items,
        },
        {
          name: "Total",
          selector: (row) => row.total,
        },
        {
          name: "Payment",
          selector: (row) => row.Status,
        },
        {
          name: "Status",
          selector: (row) => row.Status,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name",
          selector: (row) => row.Name,
        },
        {
          name: "Mobile",
          selector: (row) => row.Name,
        },
        {
          name: "Date",
          selector: (row) => row.Date,
        },
        {
          name: "Table",
          selector: (row) => row.Date,
        },
        {
          name: "Total Quantity",
          selector: (row) => row.Items,
        },
        {
          name: "Subtotal",
          selector: (row) => row.Items,
        },
        {
          name: "Tax",
          selector: (row) => row.Items,
        },
        {
          name: "Total",
          selector: (row) => row.total,
        },
        {
          name: "Payment",
          selector: (row) => row.Status,
        },
        {
          name: "Status",
          selector: (row) => row.Status,
        },
        {
          name: "Action",
          selector: (row) => row.action,
        },
      ]);
    }
  }, [orderActionData]);

  const getOrderStatus = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Confirmed";
      case "2":
        return "In-progress";
      case "3":
        return "Completed";
      case "4":
        return "Cancelled";
      default:
        return "";
    }
  };

  // const calculateTaxAmount = (item, productData) => {
  //   const totalAmount = productData.reduce((sum, product) => {
  //     const price = Number(product.price);
  //     const quantity = Number(product.quantity);
  //     return sum + (price * quantity);
  //   }, 0);

  //   if (isNaN(totalAmount) || totalAmount === 0) {
  //     return 0;
  //   }

  //   if (item?.brand_tax_type === 'GST') {
  //     const cgst = parseFloat(item?.brand_cgst) || 0;
  //     const sgst = parseFloat(item?.brand_sgst) || 0;
  //     return ((cgst + sgst) * totalAmount) / 100;
  //   } else if (item?.brand_tax_type === 'VAT') {
  //     const vat = parseFloat(item?.brand_vat) || 0;
  //     return (vat * totalAmount) / 100;
  //   }

  //   return 0;
  // };

  useEffect(() => {
    const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
    if (
      ((order &&
        order.items &&
        order.items.length < 1 &&
        order.api === false) ||
        !order) &&
      orderFilter &&
      ((orderFilter.orderDate !== "" &&
        orderFilter.orderDate !== null &&
        orderFilter.orderDate !== currentDateFormatted) ||
        (orderFilter.status && orderFilter.status === "") ||
        (orderFilter.orderType && orderFilter.orderType === ""))
    ) {
      setTableData([]);
      fetchOrder();
    } else {
      const orderTableData = order.items
        .map((item) => ({
          id: item.orderId,
          customerName: item.first_name + " " + item.last_name,
          phoneNumber: item.phone_number,
          createdAt: item.createdAt ? format(item.createdAt, "dd-MM-yyyy") : "",
          orderCreatedAt: item.createdAt,
          orderModifiedAt: item.modifiedAt,
          modifiedAt: item.modifiedAt
            ? format(item.modifiedAt, "dd-MM-yyyy")
            : "",
          tableNo:
            item.table_no !== "" && item.table_no !== null
              ? item.table_no
              : "—",
          // totalItem: item?.productData ? JSON.parse(item.productData)?.filter((order, index, self) =>
          //   index === self.findIndex((o) => o.productId === order.productId)
          // )?.length : 0,
          type: item.type,
          tableType:
            item.type === "2"
              ? "Delivery"
              : item.type === "1"
              ? "Take away"
              : "Dine in",
          // totalItem: item?.productData ? JSON.parse(item.productData)?.length : 0,
          totalItem: item?.productData
            ? JSON.parse(item.productData).reduce(
                (sum, product) => sum + Number(product.quantity),
                0
              )
            : 0,

          orderAmount: item.productData
            ? (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
              JSON.parse(item.productData).reduce(
                (sum, product) =>
                  sum + Number(product.price) * Number(product.quantity),
                0
              )
            : (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
              "0",

          deliveryChange:
            (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            (item?.delivery_charge ? item.delivery_charge : "0"),
          total_delivery_charge: item?.delivery_charge
            ? Number(item.delivery_charge)
            : 0,

          // taxAmount: (authuserData?.data?.symbol ? authuserData.data.symbol : '₹') + calculateTaxAmount(item, JSON.parse(item.productData)),

          taxAmount:
            (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            (item?.total_tax_amount ? item.total_tax_amount : "0"),

          total_tax_amount: item?.total_tax_amount
            ? Number(item.total_tax_amount)
            : 0,

          // totalOrderWithTax: (authuserData?.data?.symbol ? authuserData.data.symbol : '₹') + ((JSON.parse(item.productData).reduce((sum, product) => (sum + Number(product.price) * Number(product.quantity)), 0)) + calculateTaxAmount(item, JSON.parse(item.productData))),

          totalOrderWithTax:
            (authuserData?.data?.symbol ? authuserData.data.symbol : "₹") +
            (item?.order_amount ? item.order_amount : "0"),

          total_amount_with_tax: item?.order_amount
            ? Number(item.order_amount)
            : 0,

          orderNumber: item.order_number,

          productData: item.productData
            ? JSON.parse(item.productData).map((product, index) => ({
                // ...product,
                productId: product?.productId ? Number(product.productId) : 0,
                productName: product?.productName
                  ? product?.productName.replace(/^'(.*)'$/, "$1")
                  : "", // Remove leading and trailing single quotes
                size:
                  product?.attribute_data &&
                  product.attribute_data !== "" &&
                  product.attribute_data !== "null" &&
                  product?.attribute_data !== "NULL"
                    ? getFormattedSize(JSON.parse(product.attribute_data))
                    : product.size,
                // size:product.size,
                quantity: product.quantity,

                product_attribute: product?.attribute_data
                  ? JSON.parse(product.attribute_data)
                  : null,

                tablePrice: product.price
                  ? (authuserData?.data?.symbol
                      ? authuserData.data.symbol
                      : "₹") + Number(product.price)
                  : (authuserData?.data?.symbol
                      ? authuserData.data.symbol
                      : "₹") + 0,

                price: product.price ? Number(product.price) : 0,

                totalTablePrice:
                  product.quantity && product.price
                    ? (authuserData?.data?.symbol
                        ? authuserData.data.symbol
                        : "₹") +
                      Number(product.quantity) * Number(product.price)
                    : (authuserData?.data?.symbol
                        ? authuserData.data.symbol
                        : "₹") + 0,

                totalPrice:
                  product.quantity && product.price
                    ? Number(product.quantity) * Number(product.price)
                    : 0,
                food_type_two:
                  product?.food_type_2 &&
                  product.food_type_2 !== "" &&
                  product.food_type_2 !== "null" &&
                  product.food_type_2 !== "NULL"
                    ? product.food_type_2 === "0"
                      ? "No Onion/Garlic"
                      : product.food_type_2 === "1"
                      ? "Jain"
                      : "Regular"
                    : "Regular",
                product_instruction:
                  product?.product_instruction &&
                  product.product_instruction !== "" &&
                  product.product_instruction !== "null" &&
                  product.product_instruction !== "NULL"
                    ? product.product_instruction
                    : null,
                table_product_instruction:
                  product?.product_instruction &&
                  product.product_instruction !== "" &&
                  product.product_instruction !== "null" &&
                  product.product_instruction !== "NULL"
                    ? product.product_instruction
                    : "—",
                food_type_2:
                  product?.food_type_2 &&
                  product.food_type_2 !== "" &&
                  product.food_type_2 !== "null"
                    ? product.food_type_2
                    : null,
                food_type_1:
                  product?.food_type_1 &&
                  product.food_type_1 !== "" &&
                  product.food_type_1 !== "null"
                    ? product.food_type_1
                    : null,
                attribute_data:
                  product?.attribute_data &&
                  product.attribute_data !== "" &&
                  product.attribute_data !== "null" &&
                  product?.attribute_data !== "NULL"
                    ? product.attribute_data
                    : null,
                // attribute_data:"",
                map_table_id: product?.map_table_id ? product.map_table_id : 0,
                orderId: item.orderId,
                productStatus: product?.status ? product.status : 0, // changes by sakshi
              }))
            : [],
          // email: item.email,
          payment: item.payment,
          orderStatus: item.status,
          user_id: item.user_id,
          firstName: item.first_name,
          lastName: item.last_name,
          instructions: item.instructions,
          brand_id: item.brand_id,
          brand_name: item.brand_name,
          brand_gst_number: item.brand_gst_number,
          address_id: item?.address_id,
          address_line1: item.address_line1,
          address_line2: item.address_line2,
          address_city: item.address_city,
          address_postal_code: item.address_postal_code,
          address_title: item.address_title,
          total_discount_amount: item.total_discount_amount,
          discount_id: item.discount_id,
          Discount_Code: item.Discount_Code,
          delivery_charge: item.delivery_charge,
          // delivery_charge:item.delivery_charge
        }))
        .filter(
          (order, index, self) =>
            index === self.findIndex((o) => o.id === order.id)
        );
      setTableData(orderTableData);
      // onPlay();
    }
    // eslint-disable-next-line
  }, [order]);

  useEffect(() => {
    const currentDateFormatted = format(new Date(), "yyyy-MM-dd");
    if (
      (orderFilter.orderDate !== "" &&
        orderFilter.orderDate !== null &&
        orderFilter.orderDate !== currentDateFormatted) ||
      (orderFilter.status && orderFilter.status !== "") ||
      (orderFilter.orderType && orderFilter.orderType !== "")
    ) {
      setIsToggled(true);
    }
  }, [orderFilter]);

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setExpandedItem(tableData.map((_, index) => index));
    }
  }, [tableData]);

  /**
   * Fetch order data
   */
  const fetchOrder = () => {
    if (orderPage > 1) {
      const requestBody = {
        p_skip: (orderPage - 2) * 10,
        p_take: 2000,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (orderFilter.orderDate !== "" && orderFilter.orderDate !== null) {
        requestBody.p_created_date = orderFilter.orderDate;
      }
      if (orderFilter.status !== "" && orderFilter.status !== null) {
        requestBody.p_status = orderFilter.status;
      }
      if (orderFilter.orderType !== "" && orderFilter.orderType !== null) {
        requestBody.p_type = orderFilter.orderType;
      }

      setCurrentPage(orderPage - 1); // Update the current page state
      dispatch(getOrderPageData(orderPage - 1));
      getAllOrderService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (orderPage - 1) * 10,
        p_take: 2000,
        p_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (orderFilter.orderDate !== "" && orderFilter.orderDate !== null) {
        requestBody.p_created_date = orderFilter.orderDate;
      }
      if (orderFilter.status !== "" && orderFilter.status !== null) {
        requestBody.p_status = orderFilter.status;
      }
      if (orderFilter.orderType !== "" && orderFilter.orderType !== null) {
        requestBody.p_type = orderFilter.orderType;
      }
      getAllOrderService(requestBody, tokenData, dispatch);
    }
  };

  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  const onPageChange = (page) => {
    if (page !== currentPageNumber) {
      const requestBody = {
        p_skip: (page - 1) * 10,
        p_take: 2000,
      };
      if (orderFilter.orderDate !== "" && orderFilter.orderDate !== null) {
        requestBody.p_created_date = orderFilter.orderDate;
      }
      if (orderFilter.status !== "" && orderFilter.status !== null) {
        requestBody.p_status = orderFilter.status;
      }
      if (orderFilter.orderType !== "" && orderFilter.orderType !== null) {
        requestBody.p_type = orderFilter.orderType;
      }

      setCurrentPage(page); // Update the current page state
      dispatch(getOrderPageData(page));
      getAllOrderService(requestBody, tokenData, dispatch);
    }
  };

  const clearFilter = () => {
    dispatch(clearOrderPageData());
    dispatch(clearOrderFilterData());
    dispatch(clearOrderData());
    setIsToggled(false);
  };

  const [filteredTableData, setFilteredData] = useState(tableData);

  useEffect(() => {
    setFilteredData(
      orderStatusFilter.length && tableData.length
        ? tableData.filter((item) =>
            orderStatusFilter.includes(item.orderStatus)
          )
        : tableData
    );
  }, [tableData, orderStatusFilter]);

  useEffect(() => {
    if (
      tableData &&
      tableData.length > 0 &&
      filteredTableData &&
      filteredTableData.length > 0
    ) {
      const groupedData = Object.values(
        filteredTableData.reduce((acc, order) => {
          if (!acc[order.id]) {
            acc[order.id] = [];
          }
          acc[order.id].push(order);
          return acc;
        }, {})
      );
      setGroupedTableData(groupedData);
    }
    // eslint-disable-next-line
  }, [tableData, filteredTableData]);

  const handleClickStatusFilter = (filter) => {
    const filteredStatus =
      orderStatusFilter && orderStatusFilter.length > 0
        ? orderStatusFilter.find((item) => item === filter)
        : false;
    if (filteredStatus) {
      const filterData = orderStatusFilter.filter((item) => item !== filter);
      setOrderStatusFilter(filterData);
      return;
    }
    const newFilteredData = [...orderStatusFilter, filter];
    setOrderStatusFilter(newFilteredData);
    return;
  };
  // changes by sakshi
  const [viewOrderModal, setViewOrderModal] = useState(false);
  const [pendingOrders, setPendingOrders] = useState([]);

  useEffect(() => {
    // console.log("pending orders : ", pendingOrders);
  }, [pendingOrders]);

  const handleViewOrderModal = async () => {
    setViewOrderModal(!viewOrderModal);
    const data = await handleViewOrderQuantity(tokenData, dispatch);
    setPendingOrders(data.data);
  };

  const toggleSound = () => {
    dispatch(setNotificationSound(!soundData));
  };

  const getFormattedSize = (data) => {
    // Check if attribute_data is valid and parse it
    // if (product?.attribute_data && product.attribute_data !== '' && product.attribute_data !== 'null' && product.attribute_data !== 'NULL') {
    // const attributes = JSON.parse(product.attribute_data);

    // Group attributes by cat_name
    const groupedAttributes = data.reduce((acc, attr) => {
      const { cat_name, name } = attr;

      if (!acc[cat_name]) {
        acc[cat_name] = [];
      }

      acc[cat_name].push(name);
      return acc;
    }, {});

    // Format the output string
    const formattedSize = Object.entries(groupedAttributes)
      .map(([cat_name, names]) => `${cat_name}: ${names.join(", ")}`)
      .join(", ");

    return formattedSize;
    // }

    // // Return the original size if attribute_data is not valid
    // return product.size;
  };

  return (
    <>
      <div className="main-section order-new">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div className="order-number-show">
                <div>
                  <div className="title">
                    <h4>Orders</h4>
                  </div>
                  <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate("/home")}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Order</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>

              <div className="btn-groups">
                {((orderFilter.orderDate !== `${filterDateData}` &&
                  orderFilter.orderDate !== "" &&
                  orderFilter.orderDate !== null) ||
                  (orderFilter.status !== "" && orderFilter.status !== null) ||
                  (orderFilter.orderType !== "" &&
                    orderFilter.orderType !== null)) && (
                  <Dropdown className="header-dropdown action-btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      data-bs-toggle="tooltip"
                      title="Action"
                    >
                      <GrMoreVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {((orderFilter.orderDate !== `${filterDateData}` &&
                        orderFilter.orderDate !== "") ||
                        (orderFilter.status !== "0,1,2" &&
                          orderFilter.status !== "") ||
                        orderFilter.orderType !== "") && (
                        <Dropdown.Item onClick={clearFilter}>
                          Clear Filters
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <Button
                  data-bs-toggle="tooltip"
                  title="Filter"
                  onClick={handleButtonClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                {tableData && tableData.length > 0 && (
                  <ExcelData
                    excelData={tableData}
                    fileName={"image"}
                  ></ExcelData>
                )}
                <Button
                  data-bs-toggle="tooltip"
                  title="Toggle Sound"
                  onClick={toggleSound}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      {!soundData ? (
                        <GiSpeakerOff style={{ fontSize: 25 }} />
                      ) : (
                        <GiSpeaker style={{ fontSize: 25 }} />
                      )}
                    </div>
                  }
                ></Button>
                <Button
                  data-bs-toggle="tooltip"
                  title="Add Order"
                  onClick={() => navigate("/add-order")}
                  className="btn btn-fillter-main"
                  name={
                    <div className="Plus">
                      <FaPlus />
                    </div>
                  }
                ></Button>

                {/* changes by sakshi */}
                {/* <Button
                  data-bs-toggle="tooltip"
                  title="View Orders"
                  name="View"
                  onClick={() => handleViewOrderModal()}
                ></Button> */}
              </div>
            </div>
            {isToggled && <Filter page="order"></Filter>}

            <div className="main-box-color">
              {order &&
                order?.items &&
                order.items.length > 0 &&
                tableData.length > 0 && (
                  <div className="boxes-container">
                    <div
                      className={`box Pending ${
                        orderStatusFilter &&
                        orderStatusFilter.length > 0 &&
                        orderStatusFilter.filter((item) => item === "0")
                          .length > 0
                          ? "box-active"
                          : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickStatusFilter("0")}
                    >
                      <h6>Pending</h6>
                      <span>
                        {" "}
                        {
                          tableData.filter((item) => item.orderStatus === "0")
                            .length
                        }
                      </span>
                    </div>
                    <div
                      className={`box Confirmed ${
                        orderStatusFilter &&
                        orderStatusFilter.length > 0 &&
                        orderStatusFilter.filter((item) => item === "1")
                          .length > 0
                          ? "box-active"
                          : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickStatusFilter("1")}
                    >
                      <h6>Confirmed</h6>
                      <span>
                        {
                          tableData.filter((item) => item.orderStatus === "1")
                            .length
                        }
                      </span>
                    </div>
                    <div
                      className={`box In-progress ${
                        orderStatusFilter &&
                        orderStatusFilter.length > 0 &&
                        orderStatusFilter.filter((item) => item === "2")
                          .length > 0
                          ? "box-active"
                          : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickStatusFilter("2")}
                    >
                      <h6 style={{ textAlign: "left" }}>In progress</h6>
                      <span>
                        {
                          tableData.filter((item) => item.orderStatus === "2")
                            .length
                        }
                      </span>
                    </div>
                    <div
                      className={`box Completed ${
                        orderStatusFilter &&
                        orderStatusFilter.length > 0 &&
                        orderStatusFilter.filter((item) => item === "3")
                          .length > 0
                          ? "box-active"
                          : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickStatusFilter("3")}
                    >
                      <h6>Completed</h6>
                      <span>
                        {
                          tableData.filter((item) => item.orderStatus === "3")
                            .length
                        }
                      </span>
                    </div>
                    <div
                      className={`box Cancelled ${
                        orderStatusFilter &&
                        orderStatusFilter.length > 0 &&
                        orderStatusFilter.filter((item) => item === "4")
                          .length > 0
                          ? "box-active"
                          : ""
                      }`}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickStatusFilter("4")}
                    >
                      <h6>Cancelled</h6>
                      <span>
                        {
                          tableData.filter((item) => item.orderStatus === "4")
                            .length
                        }
                      </span>
                    </div>
                  </div>
                )}
            </div>
            {order &&
            order?.items &&
            order.items.length > 0 &&
            tableData.length > 0 &&
            filteredTableData.length > 0 &&
            groupedTableData.length > 0 ? (
              <>
                {groupedTableData.map((orders, index) => (
                  <Accordion
                    key={index}
                    activeKey={expandedItem}
                    onSelect={toggleAccordionItem}
                    className={`product-accordions order-bg-remove`}
                  >
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header
                        className={`p-relative  ${
                          orders.length > 0 && orders[0].orderStatus
                            ? getOrderStatus(orders[0].orderStatus)
                            : ""
                        }`}
                      >
                        <div className={`product-detail`}>
                          <div className="product-name">
                            {orders[0].brand_name} - {orders[0].orderNumber}
                          </div>
                          <div className="gif-iocn">
                            {orders[0]?.orderModifiedAt &&
                              orders[0].orderCreatedAt &&
                              orders[0]?.orderModifiedAt >
                                orders[0].orderCreatedAt &&
                              orders[0].orderStatus !== "3" &&
                              orders[0].orderStatus !== "4" && (
                                <img src={newOrderGif} alt="new order"></img>
                              )}
                          </div>
                        </div>

                        {!expandedItem.includes(index) ? (
                          // <MdRemove className='arrow-icon' />
                          <FaAngleDown className="arrow-icon" />
                        ) : (
                          // <MdAdd className='arrow-icon' />
                          <FaAngleUp className="arrow-icon" />
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <span>
                            {orders[0]?.brand_gst_number &&
                              orders[0].brand_gst_number !== "" && (
                                <span>
                                  <b>GSTIN -&nbsp;</b>
                                  {orders[0].brand_gst_number}
                                </span>
                              )}
                          </span>
                          <span className="table-type">
                            <b>Type -&nbsp;</b>
                            {orders[0].tableType}
                          </span>
                        </div>
                        <CustomTable
                          page="order"
                          // showId={true}
                          // checkBool={true}
                          showKey={showKey}
                          showActions={showActionBool ? showActions : false}
                          showTooltip={true}
                          data={orders}
                          heading={columns}
                        />
                        <div>
                          {orders[0]?.address_id &&
                            orders[0].address_id !== "" && (
                              <>
                                <b>Address -&nbsp;</b>
                                {orders[0].address_line1},{" "}
                                {orders[0].address_line2},{" "}
                                {orders[0].address_city},{" "}
                                {orders[0].address_postal_code} (Type -{" "}
                                {orders[0].address_title})
                              </>
                            )}
                        </div>
                        <div>
                          {orders[0]?.instructions &&
                            orders[0].instructions !== "" &&
                            orders[0].instructions !== "null" &&
                            orders[0].instructions !== "NULL" && (
                              <>
                                <HiMiniInformationCircle className="i-icon-class" />
                                &nbsp;{orders[0].instructions}
                              </>
                            )}
                        </div>
                        {orders[0].productData &&
                          orders[0].productData.length > 0 &&
                          orders.map((order, productIndex) => {
                            // Print order.productData to the console
                            // changes by sakshi
                            // console.log(`Order ${productIndex + 1} productData:`, handleSelectedAttributes(order.productData[0].product_attribute));

                            return (
                              <div className="col-12" key={productIndex}>
                                <Accordion
                                  defaultActiveKey={["0"]}
                                  className="product-accordion"
                                >
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                      <IoIosArrowForward />
                                      Product Details
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <CustomTable
                                        page="order"
                                        showKey={showProductKeys}
                                        showActions={showProductActions}
                                        showTooltip={true}
                                        data={order.productData}
                                        heading={productHeading}
                                      />
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            );
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </>
            ) : (
              <div className="input-search">
                <div className="no-record">
                  <h3 data-bs-toggle="tooltip" title="No records!">
                    No records!
                  </h3>
                </div>
              </div>
            )}
            {order.totalRecords > 0 &&
              order.totalRecords > order.items.length &&
              order.totalRecords > 10 && (
                <CustomPagination
                  totalRecords={order.totalRecords}
                  currentPage={currentPageNumber}
                  onPageChange={onPageChange}
                />
              )}
          </div>
          <Footer />
        </div>
      </div>

      {/* changes by sakshi */}
      {viewOrderModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div>
              <h5>Orders review</h5>
              <button
                className="modal-close"
                onClick={() => setViewOrderModal(!viewOrderModal)}
              >
                <MdOutlineCancel />
              </button>
            </div>
            {pendingOrders && pendingOrders.length > 0 ? (
              <table border="1">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Attributes</th>
                    <th>Jain/regular</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingOrders.map((order, index) => (
                    <tr key={index}>
                      <td>{order.product_name}</td>
                      <td>{order.number_of_orders}</td>
                      <td>dough: cheese burst, size: regular</td>
                      <td>Regular</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <b>There are no pending orders!</b>
            )}
          </div>
        </div>
      )}
    </>
  );
}
