const notificationInitialState = { 
    items: []
};

/**
 * Notification list reducer
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const notificationReducer = (state = notificationInitialState, action) => {
    switch (action.type) {
        case 'GET_NOTIFICATION_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_NOTIFICATION_DATA':
            return notificationInitialState
        default:
            return state;
      };
}