import React, { useEffect, useState } from "react";
import UtilsData, {
  unitOptionsArray,
  ValidationMessage,
} from "../../../Utils/Utils";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import { Breadcrumb } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import Input from "../../../components/input/Input";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import Button from "../../../components/Button/Button";
import Footer from "../../../components/Footer/Footer";
import { getAllInventoryItemService } from "../../InventoryItems/InventoryItemService/InventoryItemService";
import {
  convertToBaseUnit,
  GramToKg,
  GramToMg,
  KgToGram,
  LiterToMl,
  MgToGram,
  MlToLitre,
} from "../../../services/Regular-services/ConvertUnitService";
import { addUpdateRemainingStockService } from "./AddRemainingStockService/AddRemainingStockService/AddRemainingStockService";
import {
  handleCancleClick,
  handlesaveClick,
} from "../../../components/Alertmessage/Alertmessage";
import { clearStockPageData } from "../../../redux/Actions/StocksAction/StockPageAction";
import { modifyString } from "../../../services/Regular-services/StringService";

const AddRemainingStock = () => {
  const initialValues = {
    name: "",
    quantity: "",
    unit: "",
    stockTypeOption: "",
    instruction: "",
    // status: 1,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(ValidationMessage.NAME_REQUIRED),
    quantity: Yup.string().required(ValidationMessage.QUANTITY_REQUIRED),
    unit: Yup.string().required(ValidationMessage.UNIT_REQUIRED),
    stockTypeOption: Yup.string().required(ValidationMessage.STOCK_TYPE),
  });

  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  // const userData = useSelector((state) => state.auth.user);
  const token = UtilsData();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [stockActionData, setStockActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "stock"
      );
      setStockActionData(actionData);
    }
  }, [sideBarData]);
  const totalUnitsDropdown = useSelector((state) => state.totalUnitsDropdown);

  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    if (
      totalUnitsDropdown &&
      totalUnitsDropdown?.items &&
      totalUnitsDropdown.items.length > 0 &&
      totalUnitsDropdown.items[0]?.data &&
      totalUnitsDropdown.items[0].data.length > 0
    ) {
      const unitData = totalUnitsDropdown.items[0].data.map((unit) => ({
        value: unit.unit_value,
        label: unit.unit,
        id: unit.id,
      }));
      setUnitOptions(unitData);
    } else {
      setUnitOptions([]);
    }
  }, [totalUnitsDropdown]);


  const stockTypeOption = [
    { value: "0", label: "Add Stock" },
    { value: "1", label: "Use Stock" },
    { value: "2", label: "Waste Stock" },
  ];
  const totalInventoryItem = useSelector((state) => state.totalInventoryItem);
  const [inventoryItemOptions, setInventoryItemOptions] = useState([]);

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const fetchInventoryItem = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 1000000,
      p_is_active: "1",
    };
    getAllInventoryItemService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalInventoryItem &&
      totalInventoryItem.items &&
      totalInventoryItem.items.length === 0
    ) {
      fetchInventoryItem();
    } else {
      if (
        totalInventoryItem.items[0]?.data &&
        totalInventoryItem.items[0].data.length > 0
      ) {
        const newInventoryItem = totalInventoryItem.items[0].data.map(
          (item) => ({
            label: item?.title,
            value: item?.IngredientId,
            quantity: item?.thresoldValue,
            unit: item?.thresoldUnit,
          })
        );
        setInventoryItemOptions(newInventoryItem);
      }
    }
    // eslint-disable-next-line
  }, [totalInventoryItem]);

  const handleIngredientChange = (selectedOption, setFieldValue) => {
    if (!selectedOption) return;
    else if (selectedOption) {
      let quantityInOriginalUnit = parseFloat(selectedOption.quantity);
      let unit = selectedOption.unit;

      if (
        unit === "gm" ||
        unit === "kg" ||
        unit === "l" ||
        unit === "ml" ||
        unit === "mg"
      ) {
        if (unit === "gm" && quantityInOriginalUnit > 999) {
          quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
          unit = "kg";
        } else if (unit === "gm" && quantityInOriginalUnit < 1) {
          quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
          unit = "mg";
        } else if (unit === "ml" && quantityInOriginalUnit > 999) {
          quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
          unit = "l";
        }
      }

      setFieldValue("name", selectedOption.value);
      setFieldValue("unit", unit);
      setFieldValue("quantity", "");
    }
  };

  /**
   * Handling form submission
   * @param {*} values
   */
  const handleSubmit = (values) => {
    let { quantityInBaseUnit, unit } = convertToBaseUnit(
      values.quantity,
      values.unit
    );
    const requestBody = {
      p_ingredient_id: values.name.toString(),
      p_value:
        values.stockTypeOption === "0"
          ? quantityInBaseUnit
          : "-" + quantityInBaseUnit,
      p_unit: unit,
      p_type: values.stockTypeOption,
      p_instruction: values.instruction,
    };
    dispatch(clearStockPageData());
    if (!Data) {
      dispatch(
        addUpdateRemainingStockService(requestBody, token, "add", navigate)
      );
    } else {
      handlesaveClick(
        dispatch,
        addUpdateRemainingStockService(requestBody, token, "update", navigate),
        "update"
      );
    }
  };

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {stockActionData && stockActionData.length > 0
                      ? modifyString(stockActionData[0].name)
                      : "Stock"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/stock")}>
                    {stockActionData && stockActionData.length > 0
                      ? stockActionData[0].name
                      : "Stock"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {stockActionData && stockActionData.length > 0
                      ? modifyString(stockActionData[0].name)
                      : "Stock"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                {Data && Data.isEdit === true
                  ? "Edit "
                  : Data && Data.isEdit === false
                  ? "View "
                  : "Add "}{" "}
                {stockActionData && stockActionData.length > 0
                  ? modifyString(stockActionData[0].name)
                  : "Stock"}
              </h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-4">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Name <span className="required-label"></span>
                          </h4>
                        </div>
                        <Field
                          className="select-menu mb-2"
                          name="name"
                          options={inventoryItemOptions}
                          component={CustomSelect}
                          placeholder="Select Ingredient..."
                          isMulti={false}
                          value={values.name}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                          onchangedata={(selectedOption) =>
                            handleIngredientChange(
                              selectedOption,
                              setFieldValue
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Quantity <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="quantity"
                          label="Quantity"
                          placeholder="Enter quantity"
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Unit <span className="required-label"></span>
                          </h4>
                        </div>
                        <Field
                          className="select-menu mb-2"
                          name="unit"
                          options={unitOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.unit}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Stock Type <span className="required-label"></span>
                          </h4>
                        </div>
                        <Field
                          className="select-menu mb-2"
                          name="stockTypeOption"
                          options={stockTypeOption}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.unit}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className="pb-3 col-md-4">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Instructions</h4>
                        </div>
                        <div className="message-input">
                          <textarea
                            value={message}
                            placeholder="Would you like to add generalized instruction🧐? "
                            onChange={(event) => setMessage(event.target.value)}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || !Data) && (
                            <>
                              <Button
                                type="submit"
                                name="Save"
                                className="btn login-btn"
                              />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() =>
                                  handleCancleClick("/stock", navigate)
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AddRemainingStock;
