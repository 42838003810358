/**
 * Set discoutn catalogue list data into reducer
 * @param {*} discount 
 * @returns {*}
 */
export const getDiscountCatalogueData = (discount) => ({
    type: 'GET_DISCOUNT_CATALOGUE_DATA',
    payload: discount
})

/**
 * Clear discount catalogue list data
 * @returns {*}
 */
export const clearDiscountCatalogueData = () => ({
    type: 'CLEAR_DISCOUNT_CATALOGUE_DATA'
})

/**
 * Set total discount catalogue list data into reducer (No pagination) 
 * @param {*} discount 
 * @returns {*}
 */
export const getTotalDiscountCatalogueData = (discount) => ({
    type: 'GET_TOTAL_CATALOGUE_DISCOUNT',
    payload: discount
})

/**
 * Clear total discount catalogue list data (No pagination) 
 * @returns {*}
 */
export const clearTotalDiscountCatalogueData = () => ({
    type: 'CLEAR_TOTAL_CATALOGUE_DISCOUNT'
})