const branchPageInitialState = { 
    items: 1
};

/**
 * Store branch page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const branchPageReducer = (state = branchPageInitialState, action) => {
    switch (action.type) {
        case 'GET_BRANCH_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_BRANCH_PAGE':
            return branchPageInitialState;
        default:
            return state;
      };
}