import React from 'react'
import './Loginbanner.scss'
import LoginImage from '../../assets/images/eco-bg.jpg.png';
import Logo from '../../assets/images/Bespeakk-logo.png';

export default function Loginbanner() {
  return (
    <>
        {/* left side image section start  */}
            <div className='left-side'>
              <img src={LoginImage} alt=''/>
              <div className='logo'>
              <img src={Logo} alt=''/>
              </div>
            </div>
          {/* left side image section end  */}
     </>
  )
}