const InventoryInitialState = { 
    items: []
};

/**
 * Store inventory list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const InventoryReducer = (state = InventoryInitialState, action) => {
    switch (action.type) {
        case 'GET_INVENTORY_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_INVENTORY_DATA':
            return InventoryInitialState;
        default:
            return state;
      };
}

const totalInventoryInitialState = {
    items: []
}

/**
 * Store total inventory list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalInventoryReducer = (state = totalInventoryInitialState, action) => {
    switch (action.type) {
        case 'TOTAL_INVENTORY_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_INVENTORY':
            return totalInventoryInitialState
        default:
            return state;
      };
}