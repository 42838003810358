import React, { useEffect, useState } from "react";
import "../OrderCart/OrderCart.scss";
import { parseImage } from "../../services/Regular-services/ImageService";
import { BiDish } from "react-icons/bi";
import Jain from "../../assets/images/hand.png";
import onion from "../../assets/images/onion.png";
import { useSelector } from "react-redux";
import { MdOutlineCancel } from "react-icons/md";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa6";

export default function OrderCart(props) {
  const {
    showImage = true,
    showAddButton = true,
    showMessageInput = false,
    productData,
    setAddedProducts,
    addedProducts,
    showRadio = false,
    disabled = false,
    setFieldValue,
    discountID,
    onSelectDiscount,
  } = props;

  const [quantity, setQuantity] = useState(1);
  const [imageData, setImageData] = useState("");
  const userData = useSelector((state) => state.auth?.user);
  const [attributeModal, setAttributeModal] = useState(false);
  const [groupedData, setGroupedData] = useState({});
  const [selectedAttributes, setSelectedAttributes] = useState(null);
  const [totalPrize, setTotalPrize] = useState();

  useEffect(() => {
    if (productData.attribute_data) {
      const parsedData = parseImage(productData.attribute_data);
      initializeSelectedAttributes(parsedData);
    } else {
      setSelectedAttributes(null);
    }
  }, [productData]);

  useEffect(() => {
    if (productData?.attribute_data) {
      const attrData = productData?.product_attribute
        ? productData.product_attribute
        : parseImage(productData?.attribute_data);
      const data = groupByCategory(attrData);
      setGroupedData(data);
    }
  }, [productData?.attribute_data, productData?.product_attribute]);

  useEffect(() => {
    let newTotalPrize = parseFloat(productData.price) || 0;
    if (selectedAttributes) {
      // Object.values(selectedAttributes).forEach((attr) => {
      //   attr.map((data) => {
      //     const numericValue = parseFloat(data.value);
      //     if (!isNaN(numericValue)) {
      //       newTotalPrize += numericValue;
      //     }
      //   });
      // });
      Object.values(selectedAttributes).forEach((attr) => {
        attr.forEach((data) => {
          const numericValue = parseFloat(data.value);
          if (!isNaN(numericValue)) {
            newTotalPrize += numericValue;
          }
        });
      });
    }
    setTotalPrize(newTotalPrize);
    // eslint-disable-next-line
  }, [selectedAttributes, productData.price]);

  const groupByCategory = (products) => {
    return products.reduce((acc, product) => {
      const categories = product.cat_name
        .split(",")
        .map((category) => category.trim());

      categories.forEach((category) => {
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(product);
      });

      return acc;
    }, {});
  };
  const handleAttributeModalClick = () => {
    if (productData.attribute_data) {
      setAttributeModal(true);
    }
  };

  const initializeSelectedAttributes = (data) => {
    const initialSelected = {};
    const groupedAttributes = data.reduce((acc, attr) => {
      if (!acc[attr.cat_name]) {
        acc[attr.cat_name] = [];
      }
      acc[attr.cat_name].push(attr);
      return acc;
    }, {});

    Object.keys(groupedAttributes).forEach((cat_name) => {
      const filteredAttributes = groupedAttributes[cat_name].filter(
        (attr) => attr.cat_selection_type === "1"
      );

      if (filteredAttributes.length > 0) {
        const lowestValueAttribute = filteredAttributes.reduce(
          (lowest, current) => {
            const currentValue = parseFloat(current.value);
            const lowestValue = parseFloat(lowest.value);
            return currentValue < lowestValue ? current : lowest;
          }
        );

        initialSelected[cat_name] = [
          {
            id: lowestValueAttribute.id,
            name: lowestValueAttribute.name,
            value: lowestValueAttribute.value,
            cat_id: lowestValueAttribute.cat_id,
            cat_name: lowestValueAttribute.cat_name,
            cat_selection_type: lowestValueAttribute.cat_selection_type,
          },
        ];
      }
    });

    setSelectedAttributes(initialSelected);
  };

  const handleRadioChange = (
    id,
    name,
    value,
    cat_id,
    cat_name,
    cat_selection_type
  ) => {
    setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [cat_name]: [
        {
          id,
          name,
          value,
          cat_id,
          cat_name,
          cat_selection_type,
        },
      ],
    }));
  };
  const handleCheckboxChange = (
    event,
    id,
    name,
    value,
    cat_id,
    cat_name,
    cat_selection_type
  ) => {
    const { checked } = event.target;
    setSelectedAttributes((prevAttributes) => {
      const categoryAttributes = Array.isArray(prevAttributes[cat_name])
        ? prevAttributes[cat_name]
        : [];

      if (checked) {
        // Add item if checkbox is checked
        return {
          ...prevAttributes,
          [cat_name]: [
            ...categoryAttributes,
            {
              id,
              name,
              value,
              cat_id,
              cat_name,
              cat_selection_type,
            },
          ],
        };
      } else {
        // Remove item if checkbox is unchecked
        return {
          ...prevAttributes,
          [cat_name]: categoryAttributes.filter((item) => item.id !== id),
        };
      }
    });
  };

  // useEffect(() => {
  //   if ((addedProducts && addedProducts.length === 0) || !addedProducts) {
  //     if (setFieldValue) {
  //       setFieldValue("discount", "");
  //       setDiscountAmt(0);
  //       setUpdatedTotalAmt(0);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [addedProducts]);

  useEffect(() => {
    if (setFieldValue && discountID) {
      onSelectDiscount(discountID, setFieldValue);
    }
    // eslint-disable-next-line
  }, [setFieldValue, discountID, addedProducts]);

  const handleIncrement = () => {
    if (productData?.status && productData.status === "1") {
      toast.warn("This product is already delivered!");
      return;
    }
    setQuantity(quantity + 1);
    const prevProduct = addedProducts.map((p) =>
      p.map_table_id === productData.map_table_id &&
      p.productId === productData.productId
        ? { ...p, quantity: Number(p.quantity) + 1 }
        : p
    );
    setAddedProducts(prevProduct);
    // if (setFieldValue && discountID) {
    //   onSelectDiscount(discountID, setFieldValue);
    // }
  };

  useEffect(() => {
    if (addedProducts && addedProducts.length > 0) {
      const quantityData =
        addedProducts.find(
          (item) =>
            item.map_table_id === productData.map_table_id &&
            item.productId === productData.productId
        )?.quantity || 1;
      setQuantity(Number(quantityData));
    }
    // eslint-disable-next-line
  }, [addedProducts]);

  const handleDecrement = () => {
    if (productData?.status && productData.status === "1") {
      toast.warn("This product is already delivered!");
      return;
    }
    if (quantity > 1) {
      setQuantity(quantity - 1);
      const prevProduct = addedProducts.map((p) =>
        p.map_table_id === productData.map_table_id &&
        p.productId === productData.productId
          ? { ...p, quantity: Number(p.quantity) - 1 }
          : p
      );
      setAddedProducts(prevProduct);
    } else {
      const productFilter = addedProducts.filter(
        (item) =>
          item.map_table_id !== productData.map_table_id ||
          item.productId !== productData.productId
      );
      setAddedProducts(productFilter);
    }
  };

  // sakshi
  const addProductClick = (productData) => {
    handleAttributeModalClick();
    if (!productData.attribute_data || attributeModal) {
      setAttributeModal(false);
      const selectedProduct = {
        ...productData,
        quantity: 1,
        food_type_jain_regular: "2",
        product_instruction: "",
        selectedAttributes: selectedAttributes,
        price: totalPrize || productData.price,
        map_table_id:
          addedProducts && addedProducts.length > 0
            ? addedProducts.length - 1
            : 0,
        status: "0",
      };
      setAddedProducts((addedProducts) => [...addedProducts, selectedProduct]);
      toast.success(`${productData?.name} added to cart!`);
    }
  };
  const handleQuantityChange = (e) => {
    const value = Math.max(1, parseInt(e.target.value) || 0);
    setQuantity(value);
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    const prevProduct = addedProducts.map((p) =>
      p.map_table_id === productData.map_table_id &&
      p.productId === productData.productId
        ? { ...p, product_instruction: value }
        : p
    );
    setAddedProducts(prevProduct);
  };
  useEffect(() => {
    if (productData?.image_data) {
      const productImage = parseImage(productData.image_data);
      setImageData(productImage[0].url);
    }
    // eslint-disable-next-line
  }, [productData]);

  const handleChangeRadio = (e) => {
    const value = e.target.value;
    const prevProduct = addedProducts.map((p) =>
      p.map_table_id === productData.map_table_id &&
      p.productId === productData.productId
        ? { ...p, food_type_jain_regular: value }
        : p
    );
    setAddedProducts(prevProduct);
  };

  const currentRadioValue =
    addedProducts.find(
      (p) =>
        p.map_table_id === productData.map_table_id &&
        p.productId === productData.productId
    )?.food_type_jain_regular || "";

  const currentMessage =
    addedProducts.find(
      (p) =>
        p.map_table_id === productData.map_table_id &&
        p.productId === productData.productId
    )?.product_instruction || "";

  const handleAttributeModal = () => {
    setAttributeModal(false);
    // initializeSelectedAttributes()
  };

  return (
    <div className="order-from-customer">
      <div className="new-product-card">
        {/* {showImage && (
          <div className="new-product-img">
            <img src={API_URL.TOKEN_URL + "/" + imageData} alt="Product" />
          </div>
        )} */}
        <div className="new-product-detail">
          <h6>{productData?.name}</h6>
          <div className="new-product-detail-price">
            <span>
              {userData?.data?.symbol ? userData.data.symbol : "₹"}{" "}
              {productData?.price}
            </span>
            {showAddButton && (
              <div className="plus-btn-new">
                <FaPlus
                  className={`add-new-btn ${disabled ? "disabled" : ""}`}
                  onClick={() => {
                    if (!disabled) {
                      addProductClick(productData);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {/* {showAddButton ? (
          // <button
          //   type="button"
          //   className="add-button"
          //   onClick={() => addProductClick(productData)}
          //   disabled={disabled}
          // >
          //   Add
          // </button>
          <>
          
          </>
        ) : (
          <div className="quantity-input">
            <button
              type="button"
              onClick={handleDecrement}
              className="quantity-button"
              disabled={disabled}
            >
              -
            </button>
            <input
              type="text"
              value={quantity}
              onChange={handleQuantityChange}
              disabled={true}
              className="quantity-field"
              min="1"
            />
            <button
              type="button"
              onClick={handleIncrement}
              className="quantity-button"
              disabled={disabled}
            >
              +
            </button>
          </div>
        )} */}

        {!showAddButton && (
          <div className="quantity-input">
            <button
              type="button"
              onClick={handleDecrement}
              className="quantity-button"
              disabled={disabled}
            >
              -
            </button>
            <input
              type="text"
              value={quantity}
              onChange={handleQuantityChange}
              disabled={true}
              className="quantity-field"
              min="1"
            />
            <button
              type="button"
              onClick={handleIncrement}
              className="quantity-button"
              disabled={disabled}
            >
              +
            </button>
          </div>
        )}

        {showRadio &&
          (productData?.jain_or_not === "1" ||
            productData?.jain_regular === "0") &&
          productData?.veg_nonveg !== "1" && (
            <div>
              <div className="radio-btn">
                <br />
                <label
                  className={`radio-label ${
                    currentRadioValue === "2" ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name={`Regular-${
                      productData.map_table_id !== 0 &&
                      productData.map_table_id !== "0"
                        ? productData.map_table_id
                        : productData.productId
                    }`}
                    value="2"
                    checked={currentRadioValue === "2"}
                    onChange={handleChangeRadio}
                  />
                  &nbsp;
                  <BiDish />
                  Regular
                </label>
                {productData?.jain_or_not === "1" && (
                  <label
                    className={`radio-label ${
                      currentRadioValue === "1" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name={`Jain-${
                        productData.map_table_id !== 0 &&
                        productData.map_table_id !== "0"
                          ? productData.map_table_id
                          : productData.productId
                      }`}
                      value="1"
                      checked={currentRadioValue === "1"}
                      onChange={handleChangeRadio}
                    />
                    &nbsp;
                    <img src={Jain} alt="onion garli" />
                    Jain
                  </label>
                )}
                {productData?.jain_regular === "0" && (
                  <label
                    className={`radio-label ${
                      currentRadioValue === "0" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name={`No Onion/Garlic-${
                        productData.map_table_id !== 0 &&
                        productData.map_table_id !== "0"
                          ? productData.map_table_id
                          : productData.productId
                      }`}
                      value="0"
                      checked={currentRadioValue === "0"}
                      onChange={handleChangeRadio}
                    />
                    &nbsp;
                    <img src={onion} alt="onion garli" />
                    No Onion/Garlic
                  </label>
                )}
              </div>
            </div>
          )}
        {/* changes by sakshi */}
        {addedProducts.length > 0 && productData.selectedAttributes && (
          <div className="selected-attributes">
            <h6>Selected Preference:</h6>
            {Object.keys(productData.selectedAttributes).map(
              (cat_name, index) => {
                const attrArray = productData?.selectedAttributes[cat_name];
                return (
                  <div
                    key={index}
                    className={
                      attrArray && attrArray.length === 1
                        ? "Preference"
                        : "multi-options-container Preference"
                    }
                  >
                    <p>{cat_name} </p>
                    {attrArray && attrArray.length === 1 ? (
                      attrArray &&
                      attrArray.length > 0 &&
                      attrArray.map((data, idx) => (
                        <div key={idx}>
                          <p>{data.name}</p>
                          {/* <span></span> */}
                        </div>
                      ))
                    ) : (
                      <div className="multi-options">
                        {attrArray &&
                          attrArray.length > 0 &&
                          attrArray.map((data, idx) => (
                            <span key={idx}>
                              {data.name}
                              {idx < attrArray.length - 1 ? ",   " : ""}
                            </span>
                          ))}
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        )}

        {showMessageInput && (
          <div className="message-input">
            <textarea
              value={currentMessage}
              onChange={handleMessageChange}
              placeholder="Would you like to add instruction to chef 🧐?"
              disabled={disabled}
            />
          </div>
        )}
      </div>

      {/* sakshi */}
      {attributeModal && (
        <div className="modal-overlay">
          <div className="modal-content-order">
            <h6>₹{totalPrize}</h6>
            <div>
              <button
                className="modal-close"
                onClick={() => handleAttributeModal()}
              >
                <MdOutlineCancel />
              </button>
              <h6>Select your choices</h6>
              <div className="attr-container">
                {Object.keys(groupedData).map((cat_name, index) => (
                  <div key={index}>
                    <p className="cat-name">{cat_name}</p>
                    <div>
                      {groupedData[cat_name].map((attr) =>
                        attr.cat_selection_type === "1" ? (
                          <div key={attr.id} className="attr-row">
                            <label htmlFor={attr.id}>{attr.name}</label>
                            <div>
                              <label htmlFor={attr.id}>₹{attr.value}</label>
                              <input
                                type="radio"
                                id={attr.id}
                                value={attr.value}
                                checked={
                                  Array.isArray(
                                    selectedAttributes[attr.cat_name]
                                  ) &&
                                  selectedAttributes[attr.cat_name].some(
                                    (item) => item.id === attr.id
                                  )
                                }
                                onChange={() =>
                                  handleRadioChange(
                                    attr.id,
                                    attr.name,
                                    attr.value,
                                    attr.cat_id,
                                    attr.cat_name,
                                    attr.cat_selection_type
                                  )
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div key={attr.id} className="attr-row">
                            <label htmlFor={attr.id}>{attr.name}</label>
                            <div>
                              <label htmlFor={attr.id}>₹{attr.value}</label>
                              <input
                                type="checkbox"
                                id={attr.id}
                                name={attr.name}
                                value={attr.value}
                                checked={
                                  Array.isArray(
                                    selectedAttributes[attr.cat_name]
                                  ) &&
                                  selectedAttributes[attr.cat_name].some(
                                    (item) => item.id === attr.id
                                  )
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    attr.id,
                                    attr.name,
                                    attr.value,
                                    attr.cat_id,
                                    attr.cat_name,
                                    attr.cat_selection_type
                                  )
                                }
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <button
                type="button"
                className="add-button"
                onClick={() => addProductClick(productData)}
                disabled={disabled}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
