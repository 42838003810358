/**
 * Setting attribute list data into reducer
 * @param {*} attribute 
 * @returns {*}
 */
export const getAttributeData = (attribute) => ({
    type: 'GET_ATTRIBUTE_DATA',
    payload: attribute
})

/**
 * clearing attribute list data
 * @returns {*}
 */
export const clearAttributeData = () => ({
    type: 'CLEAR_ATTRIBUTE_DATA'
})

/**
 * setting total attribute data into reducer (No pagination)
 * @param {*} attribute 
 * @returns {*}
 */
export const getTotalAttributeData = (attribute) => ({
    type: 'GET_TOTAL_ATTRIBUTE',
    payload: attribute
})

/**
 * clearing total attribute data into reducer
 * @returns {*}
 */
export const clearTotalAttributeData = () => ({
    type: 'CLEAR_TOTAL_ATTRIBUTE'
})