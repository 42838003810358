import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Toggle from '../../../components/Toggle/Toggle';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from '../../../Utils/Utils';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import { useDispatch, useSelector } from 'react-redux';
import { addUpdateBranchService } from '../BranchService/BranchService';

//Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
  address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
  pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED)
})


export default function AddBranches() {

  const navigate = useNavigate();
  const token = UtilsData();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const branchPage = useSelector(state => state.branchPage?.items);

  /**
   * Getting data from branch list table
   */
  const location = useLocation();
  const { Data } = location.state || {};

  const [initialValues, setInitialValues] = useState({
    name: '',
    address: '',
    pincode: '',
    active: true
  })

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        name: Data.data.name,
        address: Data.data.address,
        pincode: Data.data.pincode,
        active: Data.data.isActive === '1' ? true : false
      })
    }
  }, [Data])

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    if (!Data) {
      const requestBody = {
        p_name: values.name,
        p_address: values.address,
        p_pincode: values.pincode,
        p_isActive: (values.active === '1' || values.active === 1 || values.active === true) ? '1' : '0',
        p_created_by: user.data.userId
      }
      dispatch(addUpdateBranchService(requestBody, token, 'add', navigate));
    }
    else {
      const requestBody = {
        p_id: Data.data.branchId,
        p_name: values.name,
        p_address: values.address,
        p_pincode: values.pincode,
        p_isActive: (values.active === '1' || values.active === 1 || values.active === true) ? '1' : '0',
        p_IsDeleted: Data.data.isDeleted,
        p_created_by: Data.data.createdBy,
        p_modified_by: user.data.userId
      }
      if (Data?.length && branchPage > 1) {
        if ((values.active === '0' || values.active === 0 || values.active === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateBranchService(requestBody, token, 'update', navigate, branchPage), 'update');
          }
          else {
            // clearBranchPageData();
            handlesaveClick(dispatch, addUpdateBranchService(requestBody, token, 'update', navigate, branchPage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateBranchService(requestBody, token, 'update', navigate, branchPage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateBranchService(requestBody, token, 'update', navigate), 'update');
      }
    }
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Branches</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate('/branch')}>Branches</Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Branches</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Branches</h4>
            </div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Name<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="name"
                          label="Name"
                          placeholder="Enter Name"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.name}
                          maxLength={20}
                          disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Address<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="address"
                          label="Address"
                          placeholder="Enter Address"
                          className="add-roles"
                          Regex={/[^a-zA-Z0-9\-/\s]/g}
                          value={values.address}
                          maxLength={140}
                          disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Pincode<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="pincode"
                          label="Pin code"
                          placeholder="Enter Pincode"
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          maxLength={6}
                          hidelength={'true'}
                          value={values.pincode}
                          disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field name='active' setFieldValue={setFieldValue} className='toggle' component={Toggle} disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || (!Data)) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/branch', navigate)} />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}