import { Dropdown } from "react-bootstrap";

export default function DownloadExcel ({ excelFile, fileName }) {
    const downloadExcel = () => {
        const a = document.createElement('a');
        a.href = excelFile;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <Dropdown.Item onClick={downloadExcel}>Download</Dropdown.Item>
    );
};