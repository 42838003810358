import { Dispatch } from "redux";
import {
  addUpdateSubCategoryRequest,
  getAllSubCategoryRequest,
  getAllSubCategoryResponse,
  getSubCategoryByIdResponse,
} from "./SubCategoryModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import {
  API_URL,
  ValidationMessage,
  removePersistStorage,
  toastClass,
} from "../../../Utils/Utils";
import { toast } from "react-toastify";
import {
  clearSubCategoryData,
  clearTotalSubCategoryData,
  getSubCategoryData,
  getTotalSubCategoryData,
} from "../../../redux/Actions/subCategoryAction/SubCategoryAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import {
  clearSubCategoryPageData,
  getSubCategoryPageData,
} from "../../../redux/Actions/subCategoryAction/SubCategoryPageAction";
import { clearNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";

let errorShow = false;

/*
 * Service to get sub category list
 */
export const getAllSubCategoryService = (
  subCategoryListRequest: getAllSubCategoryRequest,
  token: string,
  dispatch: Dispatch,
  total?: any
): Promise<getAllSubCategoryResponse> => {
  return new Promise<getAllSubCategoryResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      let queryParams = "";
      // Adding query parameters if they are provided
      if (
        subCategoryListRequest.p_skip !== undefined &&
        subCategoryListRequest.p_skip !== null
      ) {
        queryParams += `p_skip=${subCategoryListRequest.p_skip}&`;
      }
      if (
        subCategoryListRequest.p_take !== undefined &&
        subCategoryListRequest.p_take !== null
      ) {
        queryParams += `p_take=${subCategoryListRequest.p_take}&`;
      }
      if (
        subCategoryListRequest.p_name !== undefined &&
        subCategoryListRequest.p_name !== null
      ) {
        queryParams += `p_name=${subCategoryListRequest.p_name}&`;
      }
      if (
        subCategoryListRequest.p_categoryIds !== undefined &&
        subCategoryListRequest.p_categoryIds !== null
      ) {
        queryParams += `p_categoryIds=${subCategoryListRequest.p_categoryIds}&`;
      }
      if (
        subCategoryListRequest.p_category_code !== undefined &&
        subCategoryListRequest.p_category_code !== null
      ) {
        queryParams += `p_category_code=${subCategoryListRequest.p_category_code}&`;
      }
      if (
        subCategoryListRequest.p_type !== undefined &&
        subCategoryListRequest.p_type !== null
      ) {
        queryParams += `p_type=${subCategoryListRequest.p_type}&`;
      }
      if (
        subCategoryListRequest.p_is_active !== undefined &&
        subCategoryListRequest.p_is_active !== null
      ) {
        queryParams += `p_is_active=${subCategoryListRequest.p_is_active}&`;
      }
      if (
        subCategoryListRequest.p_isDeleted !== undefined &&
        subCategoryListRequest.p_isDeleted !== null
      ) {
        queryParams += `p_isDeleted=${subCategoryListRequest.p_isDeleted}&`;
      }
      const response = await getApiService(
        API_URL.BASE_URL +
          API_URL.SUB_CATEGORY +
          (queryParams && queryParams !== "" ? `?${queryParams}` : ""),
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        if (total && total === true) {
          dispatch(getTotalSubCategoryData(response));
        } else if (total && total === "filter") {
          toast.success("Filter success!");
          dispatch(getSubCategoryData(response));
        } else {
          dispatch(getSubCategoryData(response));
        }
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message === "Permission does not exists."
                ? "Sub category permission does not exists."
                : response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating sub category
 */
export const addUpdateSubCategoryService = (
  addUpdateSubCategoryRequest: addUpdateSubCategoryRequest,
  token: string,
  type: string,
  navigate?: any,
  pageNumber?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === "add") {
        response = await postApiService(
          API_URL.BASE_URL + API_URL.SUB_CATEGORY,
          token,
          addUpdateSubCategoryRequest
        );
      }
      if (type === "update") {
        response = await putApiService(
          API_URL.BASE_URL + API_URL.SUB_CATEGORY,
          token,
          addUpdateSubCategoryRequest
        );
      }
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearSubCategoryData());
        dispatch(clearTotalSubCategoryData());
        if (type === "add") {
          dispatch(clearSubCategoryPageData());
          toast.success("Sub category added successfully!");
          navigate("/sub-category");
        }
        if (type === "update") {
          if (navigate && navigate === "undo") {
            toast.success("Sub category undeleted successfully!");
          } else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getSubCategoryPageData(pageNumber + 1));
              } else {
                dispatch(clearSubCategoryPageData());
              }
              Swal.fire({
                title: "Saved!",
                text: "Sub category updated successfully!",
                icon: "success",
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then(() => {
                navigate("/sub-category");
              });
            } else {
              toast.success("Sub category status updated successfully!");
            }
          }
        }
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getSubCategoryPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for deleting sub category
 */
export const deleteSubCategoryService = (
  deleteId: number,
  token: string,
  pageNumber: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(
        API_URL.BASE_URL + API_URL.SUB_CATEGORY + "/" + deleteId,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        dispatch(clearSubCategoryData());
        dispatch(clearTotalSubCategoryData());
        toast.success("Sub Category deleted successfully!");
      } else {
        toast.error(
          response?.message
            ? response.message
            : ValidationMessage.SOMETHING_WENT_WRONG
        );
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getSubCategoryPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service to get individual sub category
 */
export const getSubCategoryById = (
  id: string,
  token: string,
  dispatch: Dispatch
): Promise<getSubCategoryByIdResponse> => {
  return new Promise<getSubCategoryByIdResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader());
      const response = await getApiService(
        API_URL.BASE_URL + API_URL.SUB_CATEGORY + "/" + id,
        token
      );
      if (
        response &&
        (response.status === 200 ||
          response.status === "200" ||
          response.status === "success")
      ) {
        resolve(response);
      } else {
        if (!document.querySelector(toastClass)) {
          toast.error(
            response?.message
              ? response.message
              : ValidationMessage.SOMETHING_WENT_WRONG
          );
        }
        errorShow = true;
        if (response?.message && response.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for uploading excel
 */
export const uploadSubCategoryExcelService = (
  excelRequest: any,
  token: string,
  setIsModalOpen: any
) => {
  return async (dispatch: Dispatch) => {
    const formData = new FormData();
    formData.append("excel", excelRequest);

    try {
      dispatch(setLoader());
      const response = await fetch(
        API_URL.BASE_URL + API_URL.SUB_CATEGORY_UPLOAD,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        }
      );
      if (!response.ok) {
        const data = await response.json();
        toast.error(
          data?.message ? data.message : ValidationMessage.SOMETHING_WENT_WRONG
        );
        if (data?.message && data.message === "Unauthorized access!") {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        return data;
      } else {
        const data = await response.json();
        toast.success(
          data?.message ? data.message : "Sub category imported successfully"
        );
        dispatch(clearNotificationData());
        setIsModalOpen(false);
        return data;
      }
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};