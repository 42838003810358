const DiscountInitialState = { 
    items: []
};

/**
 * Store discount list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const DiscountReducer = (state = DiscountInitialState, action) => {
    switch (action.type) {
        case 'GET_DISCOUNT_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_DISCOUNT_DATA':
            return DiscountInitialState;
        default:
            return state;
      };
}

const totalDiscountInitialState = {
    items: []
}

/**
 * Store total discount list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalDiscountReducer = (state = totalDiscountInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_DISCOUNT':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_DISCOUNT':
            return totalDiscountInitialState
        default:
            return state;
      };
}