const imageFilterInitialState = {
    type: '',
    isDelete: ''
};

/**
 * Store filtered image data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const imageFilterReducer = (state = imageFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_IMAGE_FILTER':
            return {
                ...state,
                type: action.payload.type,
                isDelete: action.payload.isDelete
            }
        case 'CLEAR_IMAGE_FILTER':
            return imageFilterInitialState;
        default:
            return state;
      };
}