import React from "react";
export default function Invoice() {
  return (
    <>
      <table style={{ width: "100%", margin: "0 auto" , background:'#FFF' , padding:"10px" }}>
        <tbody>
            <tr>
                <td style={{padding:"10px" }}>
                    <table style={{padding:"10px" , width:"100%" }} >
                        <tbody>
                            <tr>
                            <td colSpan={8} style={{ fontSize:"20px" , fontWeight:"bold" , textAlign:"center" }} >Invoice</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                
            </tr>
          <tr>
            <td style={{padding:"10px" }}>
              <table
                style={{
                  width: "100%",
                  padding: "10px",
                  border:"1px solid #000",
                  background:'#FFF'
                }}
              >
                <tbody>
                  <tr>
                    <td style={{padding:"10px" }}>
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td style={{ color: "#747474", fontSize: "15px" }}>
                              Order Number
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                            123456789
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td style={{ color: "#747474", fontSize: "15px" }}>
                              Order Date
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                            20-02-2024
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style={{padding:"10px" }}>
                      <table style={{ textAlign: "right", width: "100%" }}>
                        <tbody>
                          <tr>
                            <td style={{ color: "#747474", fontSize: "15px" }}>
                            Invoice number
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              123456
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td style={{ color: "#747474", fontSize: "15px" }}>
                            Invoice date
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                             20-02-2024
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{padding:"10px" }}>
              <table
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  padding: "10px",
                  border:"1px solid #000"
                }}
              >
                <tbody>
                    <tr>
                        <td style={{padding:"10px" }}>
                            <table>
                                <tr>
                                    <td
                                    style={{
                                        fontSize: "16px",
                                        color: "#000",
                                        fontWeight: 500,
                                        textTransform: "capitalize",
                                    }}
                                    >
                                    Shipping Address
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                    style={{
                                        fontSize: "16px",
                                        color: "#000",
                                        fontWeight: 500,
                                        margin: "20px 0 5px",
                                        padding: "15px 0",
                                    }}
                                    >
                                    Topnotch
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                    style={{
                                        color: "#747474",
                                        fontSize: "15px",
                                        textTransform: "capitalize",
                                        paddingBottom: "10px",
                                    }}
                                    >
                                    
                                    530 Florence Excellence, Besides<br /> Bright Day School, <br /> Vasna - Bhayli Main Rd, 
                                    <br /> Vadodara-390015, Gujarat
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: "15px", color: "#747474" }}>
                                    1234567890
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td style={{padding:"10px" }}>
                            <table style={{textAlign: "right", width:"100%"}} >
                                <tr>
                                    <td
                                    style={{
                                        fontSize: "16px",
                                        color: "#000",
                                        fontWeight: 500,
                                        textTransform: "capitalize",
                                    }}
                                    >
                                    Billing address
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                    style={{
                                        fontSize: "16px",
                                        color: "#000",
                                        fontWeight: 500,
                                        margin: "20px 0 5px",
                                        padding: "15px 0",
                                    }}
                                    >
                                    Topnotch
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                    style={{
                                        color: "#747474",
                                        fontSize: "15px",
                                        textTransform: "capitalize",
                                        paddingBottom: "10px",
                                    }}
                                    >
                                    530 Florence Excellence, Besides <br /> Bright Day School, <br /> Vasna - Bhayli Main Rd, 
                                    <br /> Vadodara-390015, Gujarat
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: "15px", color: "#747474" }}>
                                    1234567890
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{padding:"10px" }}> 
              <table
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <tbody style={{fontWeight:"600" , border:"1px solid #000" , padding:"10px"}}>
                  <tr style={{fontWeight:"600" , border:"1px solid #000" , padding:"10px"}} >
                    <td style={{padding:"10px" }}>Product</td>
                    <td style={{padding:"10px" }}>Description</td>
                    <td style={{padding:"10px" }}>Qty</td>
                    <td style={{padding:"10px" }}>Unit</td>
                    <td style={{padding:"10px" }}>Tax</td>
                    <td style={{padding:"10px" }}>Shipping fee</td>
                    <td style={{padding:"10px" }}>Discount</td>
                    <td style={{padding:"10px" , textAlign:"right" , width:"200px" }}>Payment information</td>
                  </tr>
                  <tr style={{border:"1px solid #000"}} >
                    <td style={{padding:"10px" , width:"20%"}}>VINCENT CHASE by Lenskart Polarized, UV Protection Cat-eye Sunglasses (54)  (For Women, Grey)</td>
                    <td style={{padding:"10px", width:"20%" }}>HSN:871495220</td>
                    <td style={{padding:"10px" }}>1</td>
                    <td style={{padding:"10px" }}>1</td>
                    <td style={{padding:"10px" }}>5%</td>
                    <td style={{padding:"10px" }}>₹20</td>
                    <td style={{padding:"10px" }}>₹50</td>
                    <td style={{padding:"10px" , textAlign:"right" , width:"200px"}}>₹250</td>
                  </tr>
                  <tr>
                    <td style={{padding:"10px" , borderTop:"1px solid #000" , textAlign:"right"}} colSpan={8}>
                        Total : ₹250
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{paddingBottom:"20px"}}>
                <table style={{paddingBottom:"10px" , width:"100%"}}>
                    <tbody>
                        <tr>
                            <td style={{fontSize:"15px", fontWeight:"bold", padding:"0 10px"}}>
                                Teams & Condition :-
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding:"0 10px" , width:"100%"}}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui, velit.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding:"0 10px" , width:"100%"}}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui, velit.
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding:"0 10px" , width:"100%"}}>
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui, velit.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}