export const faqAdd = ({id, question, answer, category, tags, date, isActive, isDelete}) => ({
    type: 'ADD_FAQ_DATA',
    payload: {id, question, answer, category, tags, date, isActive, isDelete}
})

export const faqUpdate = ({id, question, answer, category, tags, date, isActive, isDelete}) => ({
    type: 'UPDATE_FAQ_DATA',
    payload: {id, question, answer, category, tags, date, isActive, isDelete}
})

export const faqRemoveItem = (id) => ({
    type: 'DELETE_FAQ_DATA',
    payload: id
});