// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-letter {
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 991px) {
  .news-letter {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Subscribers/Subscribers.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,gBAAA;AACD;AACC;EAJD;IAKE,eAAA;EAEA;AACF","sourcesContent":[".news-letter {\n\tfont-size: 20px;\n\tfont-weight: 500;\n\n\t@media(max-width:991px){\n\t\tfont-size: 16px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
