import { toast } from "react-toastify";
import { API_URL, ValidationMessage } from "../../Utils/Utils";
import { postApiService } from "../../services/API-services/PostService";
import { encryptData } from "../../services/Regular-services/EncryptionService";
import { clearLoader, setLoader } from "../../redux/Actions/LoaderAction";

//Service for reset password
export const resetPasswordService = (email, password, token, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader());
      const encryptPassword = encryptData(password);
      const bodyData = {
        email: email,
        password: encryptPassword
      }
      const response = await postApiService(API_URL.BASE_URL + API_URL.RESET_PASSWORD, token, bodyData)
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        toast.success('Password changed successfully!');
        navigate('/login');
      }
      else {
        if (response === 'unauthenticated') {
          toast.error('Unauthenticated user!');
        }
        else {
          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        }
      }
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    } finally {
      dispatch(clearLoader());
    }
  };
};