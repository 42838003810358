/**
 * set discount page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getDiscountPageData = (page) => ({
    type: 'GET_DISCOUNT_PAGE',
    payload: page
})

/**
 * Clear discount page data
 * @returns {*}
 */
export const clearDiscountPageData = () => ({
    type: 'CLEAR_DISCOUNT_PAGE'
})