// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-time {
  margin-top: 5px;
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/services/Regular-services/DateTimeSplitterService/DateTimeSplitterService.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".date-time{\n    margin-top: 5px;\n    margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
