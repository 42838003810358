import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Button from '../../../components/Button/Button'
import Header from "../../../components/Header/Header";
import Footer from '../../../components/Footer/Footer'
import Input from '../../../components/input/Input'
import Toggle from '../../../components/Toggle/Toggle';
import CKEditorCpmponent from '../../../components/CKEditor-component/CKEditorCpmponent';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from '../../../Utils/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import { getAllCategoryService } from '../../Category/Category-service/CategoryService';
import { addUpdateSubCategoryService, getSubCategoryById } from '../SubCategoryService/SubCategoryService.tsx';
import UploadImage from '../../../components/UploadImage/UploadImage';
import ShowImage from '../../../components/ShowImage/ShowImage';
import { parseImage } from '../../../services/Regular-services/ImageService';
import { toast } from 'react-toastify';

//Validation schema
const validationSchema = Yup.object({
    subCategoryName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
    subCategoryCode: Yup.string().required(ValidationMessage.CODE_REQUIRED),
    categorySelect: Yup.string().required(ValidationMessage.SINGLE_CATEGORY_SELECT),
    subCategorytype: Yup.string().required(ValidationMessage.TYPE_SELECT),
    description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED),
    subCategoryImage: Yup.array().min(1, ValidationMessage.UPLOAD_IMAGE)
})

export default function AddSubCategory() {

    //Setting initial values
    const [initialValues, setInitialValues] = useState({
        subCategoryName: '',
        subCategoryCode: '',
        subCategoryImage: [],
        categorySelect: '',
        subCategorytype: '',
        description: '',
        status: true
    })

    const [errorImages, setErrorImages] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const categoryListData = useSelector(state => state.totalCategory);
    const [categoryListOptions, setCategoryListOptions] = useState([]);
    const [categoryNewOptionsData, setCategoryNewOptionsData] = useState([]);

    const token = UtilsData();
    const userData = useSelector(state => state.auth.user);
    const [showImage, setShowImage] = useState(false);
    const SubCategoryPage = useSelector(state => state.SubCategoryPage?.items);

    //Data on Edit and View
    const location = useLocation();
    const { Data } = location.state || {};

    /**
     * Fetching sub category detail data
     * @returns {*}
     */
    const fetchSubCategory = async () => {
        try {
            // Call the getRoleById function
            const subCategoryData = await getSubCategoryById(Data.data.id, token, dispatch);

            const subCategoryImage = subCategoryData.data.image_data ? parseImage(JSON.stringify(subCategoryData.data.image_data)) : null;
            const subCategoryImageData = subCategoryImage ? subCategoryImage.map(item => ({
                imageId: parseInt(item.id),
                url: item.url,
                imageType: parseInt(item.type)
            })) : [];
            setShowImage(true);

            setInitialValues({
                subCategoryName: Data.data.name,
                subCategoryCode: Data.data.code,
                subCategoryImage: subCategoryImageData,
                categorySelect: Data.data.categoryId,
                subCategorytype: Data.data.typeId,
                description: Data.data.description,
                status: (Data.data.isActive === '1' || Data.data.isActive === true || Data.data.isActive === 1) ? true : false
            })
            return subCategoryData;
        } catch (error) {
            toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
        }
    };

    //Reinitializing initial values
    useEffect(() => {
        if (Data && Data.data) {
            fetchSubCategory();
        }
        // eslint-disable-next-line
    }, [Data])

    /**
     * Fetch category data
     */
    const fetchCategoryData = () => {
        const categoryRequestBody = {
            p_skip: 0,
            p_take: 100000000
        }
        getAllCategoryService(categoryRequestBody, token, dispatch, true);
    }

    useEffect(() => {
        if (categoryListData && categoryListData.items && categoryListData.items.length === 0) {
            fetchCategoryData();
        }
        else {
            if (categoryListData.items[0]?.data && categoryListData.items[0].data.length > 0) {
                const categoryOptions = categoryListData.items[0].data.map(item => ({
                    value: item.categoryId,
                    label: item.name,
                    is_active: item.is_active,
                    type: item.type
                }));
                if (Data && Data.data) {
                    handleSubCategoryTypeChange(Data.data.typeId, '', categoryOptions)
                }
                setCategoryListOptions(categoryOptions)
            }
        }
        // eslint-disable-next-line
    }, [categoryListData, Data]);

    // Custom styles for React Select
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.data.is_active === '0' ? '#F00' : '#000', // Set color to red if is_active is '0'
        }),
    };

    /**
     * Handling form submission
     */
    const handleSubmit = (values) => {
        const imageId = values.subCategoryImage.map(item => ({
            id: item.imageId,
            type_id: item.imageType
        }))
        if (!Data) {
            const requestBody = {
                p_categoryid: values.categorySelect,
                p_name: values.subCategoryName,
                p_description: values.description,
                p_subcategorycode: values.subCategoryCode,
                p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
                p_type: values.subCategorytype,
                p_createdBy: userData.data.userId,
                p_imageIds: imageId
            }
            dispatch(addUpdateSubCategoryService(requestBody, token, 'add', navigate));
        }
        else {
            const requestBody = {
                p_id: Data.data.id,
                p_categoryid: values.categorySelect,
                p_name: values.subCategoryName,
                p_description: values.description,
                p_subcategorycode: values.subCategoryCode,
                p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
                p_type: values.subCategorytype,
                p_deleted: Data.data.isDeleted,
                p_createdBy: Data.data.createdBy,
                p_modifiedBy: userData.data.userId,
                p_imageIds: imageId
            }
            if (Data?.length && SubCategoryPage > 1) {
                if ((values.status === '0' || values.status === 0 || values.status === false)) {
                    if (Data.length > 1) {
                        handlesaveClick(dispatch, addUpdateSubCategoryService(requestBody, token, 'update', navigate, SubCategoryPage), 'update');
                    }
                    else {
                        handlesaveClick(dispatch, addUpdateSubCategoryService(requestBody, token, 'update', navigate, SubCategoryPage - 1), 'update');
                    }
                }
                else {
                    handlesaveClick(dispatch, addUpdateSubCategoryService(requestBody, token, 'update', navigate, SubCategoryPage), 'update');
                }
            }
            else {
                handlesaveClick(dispatch, addUpdateSubCategoryService(requestBody, token, 'update', navigate), 'update');
            }
        }
    }


    const subCategorytypeOptions = [
        { value: "0", label: "Product" },
        { value: "1", label: "Blog" },
        { value: "2", label: "Service" }
    ];

    const handleSubCategoryTypeChange = (option, setFieldValue, catData) => {
        if (catData) {
            if (option) {
                const categoryOption = catData.filter((item) => item.type === option);
                setCategoryNewOptionsData(categoryOption);  
            }
        }
        else {
            if (option && categoryListOptions && categoryListOptions.length > 0) {
                const categoryOption = categoryListOptions.filter((item) => item.type === option.value);
                setCategoryNewOptionsData(categoryOption);
                setFieldValue('categorySelect', '');
            }
        }
    }

    return (
        <>
            <div className="main-section add-image-section">
                <div className="sidebar-content">
                    <Sidebar />
                </div>
                <div className="body-content">
                    <Header />
                    <div className="table-body-content">
                        <div className="roles">
                            <div>
                                <div className="title">
                                    <h4>Sub Categories</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item onClick={() => navigate("/sub-category")}>Sub Categories</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Sub Category</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                        <div className="add-roles-title">
                            <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Sub Category</h4>
                        </div>
                        <div className="category-section add-roles-form">
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
                                {({ setFieldValue, values }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="name-type">
                                                    <div className="paragraph-editor ">
                                                        <h4 className="paragraph-title">Sub Category Name<span className="required-label"></span></h4>
                                                    </div>
                                                    <Input
                                                        type="text"
                                                        name="subCategoryName"
                                                        label="Category Name"
                                                        placeholder="Sub Category Name"
                                                        className="add-roles"
                                                        Regex={/[^a-zA-Z\s]/g}
                                                        value={values.subCategoryName}
                                                        disabled={Data && Data.isEdit === false}
                                                        maxLength={20}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="name-type">
                                                    <div className="paragraph-editor ">
                                                        <h4 className="paragraph-title">Sub Category Code<span className="required-label"></span></h4>
                                                    </div>
                                                    <Input
                                                        type="text"
                                                        name="subCategoryCode"
                                                        label="Category Code"
                                                        placeholder="Category Code"
                                                        className="add-roles"
                                                        value={values.subCategoryCode}
                                                        disabled={Data && Data.isEdit === false}
                                                        maxLength={50}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="paragraph-editor">
                                                    <h4 className="paragraph-title">Select Type<span className="required-label"></span></h4>
                                                    <Field
                                                        className="select-menu mb-2"
                                                        name="subCategorytype"
                                                        options={subCategorytypeOptions}
                                                        component={CustomSelect}
                                                        placeholder="Select..."
                                                        isMulti={false}
                                                        value={values.subCategorytype}
                                                        onchangedata={(option) => handleSubCategoryTypeChange(option, setFieldValue)}
                                                        disabled={Data && Data.isEdit === false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="paragraph-editor">
                                                    <h4 className="paragraph-title">Select Category<span className="required-label"></span></h4>
                                                    <Field
                                                        className='select-menu mb-2'
                                                        name="categorySelect"
                                                        options={categoryNewOptionsData}
                                                        component={CustomSelect}
                                                        placeholder="Select..."
                                                        isMulti={false}
                                                        value={values.categorySelect}
                                                        disabled={Data && Data.isEdit === false}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="paragraph-editor">
                                                    <h4 className="paragraph-title">Sub Category Image<span className="required-label"></span></h4>
                                                </div>
                                                <UploadImage name='subCategoryImage'
                                                    onChange={setFieldValue} value={values.subCategoryImage}
                                                    disabled={Data && Data.isEdit === false} filterByType={6} setErrorImages={setErrorImages} setShowImage={setShowImage}></UploadImage>
                                                <ShowImage value={values.subCategoryImage} errorImages={errorImages} showImage={showImage} ></ShowImage>
                                            </div>
                                            <div className="col-12">
                                                <div className="paragraph-editor">
                                                    <h4 className="paragraph-title">Description<span className="required-label"></span></h4>
                                                    <Field name='description' component={CKEditorCpmponent} disabled={Data && Data.isEdit === false} maxLength={255} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="paragraph-editor">
                                                    <h4 className="paragraph-title">Status</h4>
                                                    <Field name='status' setFieldValue={setFieldValue} component={Toggle} disabled={Data && Data.isEdit === false} />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="btn-submit mt-3">
                                                    {((Data && Data.isEdit === true) || (!Data)) && (
                                                        <>
                                                            <Button type="submit" name="Save" className="btn login-btn" />
                                                            <Button type="button"
                                                                name="Cancel"
                                                                className="btn clear-btn login-btn"
                                                                onClick={() => handleCancleClick('/sub-category', navigate)}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}