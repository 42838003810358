import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import ErrorComp from "../Error/ErrorComp";
import "../CKEditor-component/CKEditorCpmponent.scss"

export default function CKEditorComponent({ field, maxLength, disabled, value }) {
  const { setFieldValue } = useFormikContext();
  const characterCountRef = useRef(0);

  //Initial Data
  const [editorContent, setEditorContent] = useState(field.value || value || '');

  //Reinitializating initial Data and character count
  useEffect(() => {
    // Update character count on initial load
    const initialData = field.value || value || '';
    characterCountRef.current = countInnerCharacters(initialData);
  }, [field, value]);


  /**
   * Count characters on Input
   */
  const countInnerCharacters = (content) => {
    const innerHTML = new DOMParser().parseFromString(content, 'text/html').body.textContent;
    return innerHTML ? innerHTML.length : 0;
  };


  /**
   * Handle change on Input
   * @param {*} event 
   * @param {*} editor 
   */
  const handleCKEditorChange = (event, editor) => {
    const data = editor.getData();
    const textContent = new DOMParser().parseFromString(data, 'text/html').body.textContent;
    if (maxLength) {
      if (textContent.length <= maxLength) {
        setEditorContent(data);
        characterCountRef.current = countInnerCharacters(data);
        setFieldValue(field.name, data);
      } else {
        // Reset content if exceeded limit
        editor.setData(editorContent);
      }
    }
    else {
      setEditorContent(data);
      setFieldValue(field.name, data);
    }
  };

  return (
    <>
      <div className="p-relative">
        <CKEditor
          editor={ClassicEditor}
          data={field.value || value || ""}
          disabled={disabled}
          config={{
            toolbar: [
              'heading',
              '|',
              'bold',
              'italic',
              'link',
              '|',
              'bulletedList',
              'numberedList',
              '|',
              // Remove only the image and video options
              'blockQuote',
              'insertTable',
              '|',
              'undo',
              'redo'
            ],
            image: false, // Disable the image plugin
            mediaEmbed: false, // Disable the media embed plugin
          }}
          onReady={(editor) => { }}
          onChange={handleCKEditorChange}
        />
        {maxLength && (
          <div className="character-count">
            {characterCountRef.current} / {maxLength}
          </div>
        )}
      </div>
      <ErrorMessage name={field.name} component={ErrorComp}></ErrorMessage>
    </>
  );
}