import React from 'react'
import './Error.scss'
import ErrorImage from '../../assets/images/404.png';
import { Link } from 'react-router-dom';

export default function ErrorPage() {
    return (
        <>
            <div className="error-message">
                <div className="mx-600">
                    <div className="error-detail">
                        <img src={ErrorImage} alt='404' />
                        <span>404</span>
                        <div className="oops">Oops, Page not found!</div>
                        <Link to='/'>Back to Home</Link>
                    </div>
                    <div className="copyright-message">
                        <span>COPYRIGHT ©2024 Ecommerce, All rights Reserved</span>
                    </div>
                </div>
            </div>
        </>
    )
}