/**
 * Set company data into reducer
 * @param {*} company
 * @returns {*}
 */
export const getCompanyData = (company) => ({
    type: 'SET_COMPANY_DATA',  
    payload: company
});

/**
 * Clear company data
 * @returns {*}
 */
export const clearCompanyData = () => ({
    type: 'CLEAR_COMPANY_DATA'
});

/**
 * Set total company data into reducer (No pagination)
 * @param {*} company
 * @returns {*}
 */
export const getTotalCompanyData = (company) => ({
    type: 'GET_TOTAL_COMPANY_DATA',  
    payload: company
});

/**
 * Clear total company data (No pagination)
 * @returns {*}
 */
export const clearTotalCompanyData = () => ({
    type: 'CLEAR_TOTAL_COMPANY_DATA'
});
