import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import './Addbrand.scss'
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import { useLocation, useNavigate } from "react-router-dom";
import { handleCancleClick, handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "../../../components/Toggle/Toggle";
import { getAllCategoryService } from "../../Category/Category-service/CategoryService";
import { getAllSubCategoryService } from "../../SubCategory/SubCategoryService/SubCategoryService";
import { getAllLeafLevelCategoryService } from "../../LeafLevelCategory/LeafLevelService/LeafLevelService";
import { getAllTagsService } from "../../Tag/TagService/TagService";
import { addUpdateBrandService, getBrandById } from "../BrandService/BrandService";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";
import { parseImage } from "../../../services/Regular-services/ImageService";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";




export default function Addbrand() {

  const token = UtilsData();
  const [categoryOptionValue, setCategoryOptionValue] = useState([]);
  const [subCategoryOptionValue, setSubCategoryOptionValue] = useState([]);
  const [categoryNewOptionsData, setCategoryNewOptionsData] = useState([]);
  const [fields, setFields] = useState([]);
  const authuserData = useSelector(state => state.auth?.user);

  const [initialValues, setInitialValues] = useState({
    brandName: '',
    upload1: [],
    upload2: [],
    brandCategory: [],
    brandSubCategory: [],
    brandLeafCategory: [],
    brandType: '',
    brandTags: [],
    description: '',
    active: true,
    brandCode: "",
    visible: 1,
    taxType: "no tax",
    cgst: "",
    sgst: "",
    gst_number: "",
    vat: "",
    veg_nonVeg: "",
    primaryColor: "",
    secondaryColor: "",
    delivery: 0,
    notification: 0,
    delivery_distance: '',
    delivery_charge: '',
    paymentUrl: '',
    smsAllow: 0
  })

  const taxTypeOption = [
    {
      value: 'no tax',
      label: "No tax"
    },
    {
      value: 'GST',
      label: "GST"
    },
    {
      value: 'VAT',
      label: "VAT"
    }
  ]

  const [validationSchema, setValidationSchema] = useState(Yup.object({
    brandName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
    brandCategory: Yup.array().min(1, ValidationMessage.MULTI_CATEGORY_SELECT),
    brandSubCategory: Yup.array().min(1, ValidationMessage.MULTI_SUB_CATEGORY_SELECT),
    brandType: Yup.string().required(ValidationMessage.TYPE_SELECT),
    brandCode: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    veg_nonVeg: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED)
  }))

  const [errorLogoImages, setLogoErrorImages] = useState([]);
  const [showLogoImage, setShowLogoImage] = useState(false);

  const [errorBannerImages, setBannerErrorImages] = useState([]);
  const [showBannerImage, setShowBannerImage] = useState(false);
  const brandPage = useSelector(state => state.brandPage?.items);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  /**
   * Getting data from brand table
   */
  const location = useLocation();
  const { Data } = location.state || {};

  const user = useSelector(state => state.auth.user);

  /**
   * Fetching brand detail data
   * @returns {*}
   */
  const fetchBrand = async () => {
    try {
      // Call the getRoleById function
      const brandData = await getBrandById(Data.data.brandId, token, dispatch);
      const brandImage = brandData.data.image_data ? parseImage(JSON.stringify(brandData.data.image_data)) : null;
      const brandImageData = brandImage ? brandImage.map(item => ({
        imageId: parseInt(item.id),
        url: item.url,
        imageType: parseInt(item.type)
      })) : [];
      let brandLogo = [];
      let brandBanner = [];
      if (brandImageData && brandImageData.length > 0) {
        brandLogo = brandImageData.filter(item => item.imageType === 3);
        brandBanner = brandImageData.filter(item => item.imageType === 4);
      }

      const tagData = brandData.data.tag_ids ? brandData.data.tag_ids.split(',').map(tagId => parseInt(tagId.trim())) : [];

      const categoryIds = brandData.data.category_ids ? brandData.data.category_ids.split(',').map(categoryId => parseInt(categoryId.trim())) : [];

      const subCategoryIds = brandData.data.sub_category_ids ? brandData.data.sub_category_ids.split(',').map(subCategoryId => parseInt(subCategoryId.trim())) : [];

      const leafData = brandData.data.leaf_level_category_ids ? brandData.data.leaf_level_category_ids.split(',').map(leafCategoryId => parseInt(leafCategoryId.trim())) : [];

      setShowLogoImage(true);
      setShowBannerImage(true);
      setCategoryOptionValue(categoryIds);
      setSubCategoryOptionValue(subCategoryIds);

      const distanceData = brandData.data?.distance_data ? JSON.parse(brandData.data.distance_data) : null;

      const distanceArray = distanceData && distanceData.length > 0 ? distanceData.map((item, index) => ({
        id: index + 1,
        delivery_distance: item.distance,
        delivery_charge: item.value
      })) : []

      setFields(distanceArray)


      const color = Data.data?.color ? JSON.parse(Data.data?.color) : null

      setInitialValues({
        brandName: brandData.data.name,
        upload1: brandLogo,
        upload2: brandBanner,
        brandCategory: categoryIds,
        brandSubCategory: subCategoryIds,
        brandLeafCategory: leafData,
        brandType: brandData.data.type,
        brandTags: tagData,
        description: brandData.data.description,
        active: (brandData.data.is_active === true || brandData.data.is_active === '1' || brandData.data.is_active === 1) ? true : false,
        brandCode: Data.data.brandCode,
        visible: (Data.data.publicly_available === '1' || Data.data.publicly_available === 1 || Data.data.publicly_available === true) ? 1 : 0,
        taxType: Data.data.tax,
        cgst: Data.data.tax !== "no tax" ? Data.data.cgst : "",
        sgst: Data.data.tax !== "no tax" ? Data.data.sgst : "",
        gst_number: Data.data.tax !== "no tax" ? Data.data.gst : "",
        vat: Data.data.tax !== "no tax" ? Data.data.vat : "",
        veg_nonVeg: Data.data.food_type,
        primaryColor: color && color?.mainColor ? color.mainColor : "",
        secondaryColor: color && color?.primaryColor ? color.primaryColor : "",
        delivery: (brandData.data.delivery_available === '1' || brandData.data.delivery_available === 1 || brandData.data.delivery_available === true) ? 1 : 0,
        notification: brandData.data.multipal_beep === '1' || brandData.data.multipal_beep === 1 ? 1 : 0,
        delivery_distance: '',
        delivery_charge: '',
        paymentUrl: brandData.data.payment_url ? brandData.data.payment_url : '',
        smsAllow: brandData.data.wp_sms_allow === '1' || brandData.data.wp_sms_allow === 1 || brandData.data.wp_sms_allow === true ? 1 : 0
      })
      return brandData;
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchBrand();
    }
    // eslint-disable-next-line
  }, [Data])

  /**
   * Setting category options
   */
  const categoryListData = useSelector(state => state.totalCategory);

  const [categoryListOptions, setCategoryListOptions] = useState([]);

  useEffect(() => {
    if (categoryListData && categoryListData.items && categoryListData.items.length === 0) {
      const categoryRequestBody = {
        p_skip: 0,
        p_take: 1000000
      }
      getAllCategoryService(categoryRequestBody, token, dispatch, true);
    }
    else {
      if (categoryListData.items[0]?.data && categoryListData.items[0].data.length > 0) {
        const categoryOptions = categoryListData.items[0].data.map(item => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active,
          type: item.type
        }));
        setCategoryListOptions(categoryOptions);
        if (categoryOptionValue && categoryOptionValue.length > 0) {
          const subCategoryOptions = categoryOptions.filter(item2 =>
            categoryOptionValue.some(item1 => item1 === item2.value)
          );
          if (Data && Data.data) {
            handleBrandTypeChange(Data.data.type, '', categoryOptions)
          }
          getFilterOptionData(subCategoryOptions, 'category');
        }
      }
    }
    // eslint-disable-next-line
  }, [categoryListData, categoryOptionValue, Data])

  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === '0' ? '#F00' : '#000', // Set color to red if is_active is '0'
    }),
  };


  /**
   * Setting sub category options
   */
  const subCategoryListData = useSelector(state => state.totalSubCategory);

  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);

  useEffect(() => {
    if (subCategoryListData && subCategoryListData.items && subCategoryListData.items.length === 0) {
      const subCategoryRequestBody = {
        p_skip: 0,
        p_take: 100000000
      }
      getAllSubCategoryService(subCategoryRequestBody, token, dispatch, true);
    }
    else {
      if (subCategoryListData.items && subCategoryListData.items[0]?.data && subCategoryListData.items[0].data.length > 0) {
        if (subCategoryOptionValue && subCategoryOptionValue.length > 0) {
          const subCategoryOptions = subCategoryListData.items[0].data.map(item => ({
            value: item.id,
            label: item.name,
            is_active: item.is_active
          }));

          const filteredSubCategoryOptions = subCategoryOptions.filter(item2 =>
            subCategoryOptionValue.some(item1 => item1 === item2.value)
          );
          getFilterOptionData(filteredSubCategoryOptions, 'subCategory');
        }
      }
    }
    // eslint-disable-next-line
  }, [subCategoryListData, subCategoryOptionValue])


  /**
   * Setting leaf level category options
   */
  const totalLeaf = useSelector(state => state.totalLeaf);
  const [leafCategoryListOptions, setLeafCategoryListOptions] = useState([]);

  useEffect(() => {
    if (totalLeaf && totalLeaf.items && totalLeaf.items.length === 0) {
      const requestBody = {
        p_skip: 0,
        p_take: 100000000
      }
      getAllLeafLevelCategoryService(requestBody, token, dispatch, true)
    }
    // eslint-disable-next-line
  }, [totalLeaf])

  /**
   * Setting tags options
   */
  const totalTag = useSelector(state => state.totalTag);
  const [tagListOptions, setTagListOptions] = useState([]);

  const vegListData = [
    {
      value: '0',
      label: 'Veg'
    },
    {
      value: '1',
      label: 'Non Veg'
    },
    {
      value: '2',
      label: 'Both'
    }
  ]

  useEffect(() => {
    if (totalTag && totalTag.items && totalTag.items.length === 0) {
      const requestBody = {
        p_skip: 0,
        p_limit: 100000000
      }
      getAllTagsService(requestBody, token, dispatch, true)
    }
    else {
      if (totalTag.items[0]?.data && totalTag.items[0].data.length > 0) {
        const tagOptions = totalTag.items[0].data.map(item => ({
          value: item.tagId,
          label: item.title,
          is_active: item.isActive
        }));
        setTagListOptions(tagOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalTag])

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    const newImageArray = [...values.upload1, ...values.upload2];
    const imageData = newImageArray.map(item => ({
      id: item.imageId,
      type_id: item.imageType
    }));

    let distanceData = fields.length > 0 ? fields.map(item => ({
      distance: item.delivery_distance,
      value: item.delivery_charge
    })) : [];

    if (distanceData.length < 1) {
      if (values.delivery_distance !== '' && values.delivery_charge !== '') {
        distanceData.push({});
        distanceData[0].distance = values.delivery_distance;
        distanceData[0].value = values.delivery_charge;
      }
    }
    else {
      if (values.delivery_distance !== '' && values.delivery_charge !== '') {
        distanceData.push({
          distance: values.delivery_distance,
          value: values.delivery_charge
        });
      }
    }
    const requestBody = {
      ...(Data && Data.data && { p_id: Data.data.brandId }),
      p_name: values.brandName,
      p_description: values.description,
      p_brand_type_id: values.brandType,
      p_category_id: values.brandCategory && values.brandCategory.length > 0 ? values.brandCategory.map(item => ({ id: item })) : [],
      p_sub_category_id: values.brandSubCategory && values.brandSubCategory.length > 0 ? values.brandSubCategory.map(item => ({ id: item })) : [],
      p_leaf_level_category_id: values.brandLeafCategory && values.brandLeafCategory.length > 0 ? values.brandLeafCategory.map(item => ({ id: item })) : [],
      p_imageIds: imageData,
      p_tag_id: values.brandTags && values.brandTags.length > 0 ? values.brandTags.map(item => ({ id: item })) : [],
      p_isActive: (values.active === true || values.active === '1' || values.active === 1) ? '1' : '0',
      ...(Data && Data.data && { p_IsDeleted: Data.data.isDeleted }),
      p_created_by: Data && Data.data ? Data.data.createdBy : user.data.userId,
      p_modified_by: user.data.userId,
      p_brand_code: values.brandCode,
      p_tax: values.taxType,
      p_gst_number: values.taxType === 'GST' ? values.gst_number : null,
      p_cgst: values.taxType === 'GST' ? values.cgst : null,
      p_sgst: values.taxType === 'GST' ? values.sgst : null,
      p_vat: values.taxType === 'VAT' ? values.vat : null,
      p_food_type: values.veg_nonVeg,
      p_color: { mainColor: values.primaryColor && values.primaryColor !== '' ? values.primaryColor : null, primaryColor: values.secondaryColor && values.secondaryColor !== '' ? values.secondaryColor : null },
      p_publicly_available: values.visible === '1' || values.visible === 1 || values.visible === true ? '1' : '0',
      p_delivery_available: values.delivery === '1' || values.delivery === 1 || values.delivery === true ? '1' : '0',
      p_multipal_beep: values.notification === '1' || values.notification === 1 || values.notification === true ? '1' : '0',
      p_wp_sms_allow: values.smsAllow === '1' || values.smsAllow === 1 || values.smsAllow === true ? '1' : '0',
      p_distance_data: distanceData && distanceData.length > 0 ? distanceData : null,
      p_payment_url: values?.paymentUrl && values.paymentUrl !== '' ? values.paymentUrl : null
    }

    if (!Data) {
      dispatch(addUpdateBrandService(requestBody, token, 'add', navigate));
    }
    else {
      if (Data?.length && brandPage > 1) {
        if ((values.active === '0' || values.active === 0 || values.active === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateBrandService(requestBody, token, 'update', navigate, brandPage), 'update');
          }
          else {
            handlesaveClick(dispatch, addUpdateBrandService(requestBody, token, 'update', navigate, brandPage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateBrandService(requestBody, token, 'update', navigate, brandPage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateBrandService(requestBody, token, 'update', navigate), 'update');
      }
    }
  }

  /**
   * Setting sub-category and leaf level category data on category option select
   * @param {*} option 
   * @param {*} data 
   * @param {*} setFieldValue 
   */
  const getFilterOptionData = (option, data, setFieldValue) => {
    if (data === 'category') {
      if (subCategoryListData.items && subCategoryListData.items[0]?.data && subCategoryListData.items[0].data.length > 0) {
        const filteredArr2 = subCategoryListData.items[0].data.filter(item2 =>
          option.some(item1 => item1.value === item2.category_id)
        );
        const subCategoryOptionData = filteredArr2.map(item => ({
          value: item.id,
          label: item.name,
          is_active: item.is_active
        }))
        setSubCategoryListOptions(subCategoryOptionData);
      }
      if (setFieldValue) {
        if (option.length === 0) {
          setFieldValue('brandSubCategory', []);
          setLeafCategoryListOptions([]);
        }
        setFieldValue('brandLeafCategory', []);
      }
    }
    else if (data === 'subCategory') {
      if (totalLeaf.items[0]?.data && totalLeaf.items[0].data.length > 0) {
        const filterLeafData = totalLeaf.items[0].data.filter(item2 =>
          option.some(item1 => item1.value === item2.SubcategoryId)
        );
        const leafOptions = filterLeafData.map(item => ({
          value: item.id,
          label: item.Title,
          is_active: item.is_active
        }))
        setLeafCategoryListOptions(leafOptions);
      }
      if (setFieldValue) {
        if (option.length === 0) {
          setFieldValue('brandLeafCategory', []);
        }
      }
    }
  }


  const brandtypeOptions = [
    { value: "0", label: "Product" },
    { value: "1", label: "Blog" },
    { value: "2", label: "Service" }
  ];

  const handleBrandTypeChange = (option, setFieldValue, categoryData) => {
    if (categoryData && categoryData.length > 0) {
      if (option) {
        const categoryOption = categoryData.filter((item) => item.type === option);
        setCategoryNewOptionsData(categoryOption);
      }
    }
    else {
      if (option && categoryListOptions && categoryListOptions.length > 0) {
        const categoryOption = categoryListOptions.filter((item) => item.type === option.value);
        setCategoryNewOptionsData(categoryOption);
        setSubCategoryListOptions([]);
        setLeafCategoryListOptions([]);
        setFieldValue('brandSubCategory', []);
        setFieldValue('brandLeafCategory', []);
        setFieldValue('brandCategory', []);
      }
    }
  }

  const setTaxValidation = (value, setFieldValue) => {
    if (value?.value) {
      const type = value.value;

      if (type === 'GST') {
        setValidationSchema(Yup.object({
          brandName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
          brandCategory: Yup.array().min(1, ValidationMessage.MULTI_CATEGORY_SELECT),
          brandSubCategory: Yup.array().min(1, ValidationMessage.MULTI_SUB_CATEGORY_SELECT),
          brandType: Yup.string().required(ValidationMessage.TYPE_SELECT),
          brandCode: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          veg_nonVeg: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          gst_number: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          sgst: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          cgst: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED)
        }))
        setFieldValue("vat", "");
      }
      else if (type === 'VAT') {
        setValidationSchema(Yup.object({
          brandName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
          brandCategory: Yup.array().min(1, ValidationMessage.MULTI_CATEGORY_SELECT),
          brandSubCategory: Yup.array().min(1, ValidationMessage.MULTI_SUB_CATEGORY_SELECT),
          brandType: Yup.string().required(ValidationMessage.TYPE_SELECT),
          brandCode: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          veg_nonVeg: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          vat: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED)
        }))
        setFieldValue("gst_number", "");
        setFieldValue("sgst", "");
        setFieldValue("cgst", "");
      }
      else {
        setValidationSchema(Yup.object({
          brandName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
          brandCategory: Yup.array().min(1, ValidationMessage.MULTI_CATEGORY_SELECT),
          brandSubCategory: Yup.array().min(1, ValidationMessage.MULTI_SUB_CATEGORY_SELECT),
          brandType: Yup.string().required(ValidationMessage.TYPE_SELECT),
          brandCode: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          veg_nonVeg: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED)
        }))
        setFieldValue("gst_number", "");
        setFieldValue("sgst", "");
        setFieldValue("cgst", "");
        setFieldValue("vat", "");
      }
    }
    else {
      setValidationSchema(Yup.object({
        brandName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
        brandCategory: Yup.array().min(1, ValidationMessage.MULTI_CATEGORY_SELECT),
        brandSubCategory: Yup.array().min(1, ValidationMessage.MULTI_SUB_CATEGORY_SELECT),
        brandType: Yup.string().required(ValidationMessage.TYPE_SELECT),
        brandCode: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
        taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
        veg_nonVeg: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
        description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED)
      }))
      setFieldValue("gst_number", "");
      setFieldValue("sgst", "");
      setFieldValue("cgst", "");
      setFieldValue("vat", "");
    }
  }

  /**
 * Append attribute data
 * @param {*} values 
 * @param {*} setFieldValue 
 */
  const handleAddField = (values, setFieldValue) => {
    if (values.delivery_distance === '' || values.delivery_charge === '') {
      toast.error('Please select delivery distance and delivery charges before adding!');
    }
    else {
      let newId;
      if (fields.length === 0) {
        newId = fields.length + 1;
      } else {
        const lastKey = fields[fields.length - 1].id;
        newId = lastKey + 1;
      }
      const newField = {
        id: newId,
        delivery_distance: values.delivery_distance,
        delivery_charge: values.delivery_charge
      };
      setFieldValue('delivery_distance', '');
      setFieldValue('delivery_charge', '');
      setFields([...fields, newField]);
    }
  };

  const onChangeDelivery = (event, setFieldValue) => {
    const { checked } = event.target
    setFieldValue('delivery', checked);
    if(checked === 0 || checked === false) {
      setFields([]);
    }
  }

  /**
  * Remove already added attribute data
  * @param {*} idToRemove 
  */
  const handleRemoveField = (idToRemove) => {
    const list = [...fields];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFields(filterField);
  };

  /**
  * Updating attribute values in reverse order
  */
  const changeInputData = (index, value, setFieldValue, fieldKey) => {
    let listData = [...fields];
    setFieldValue(value)
    listData[fields.length - index - 1][fieldKey] = value;
    setFields(listData);
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Brand</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate('/brand-list')}>Brand</Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Brand</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Brand</h4>
            </div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Add Logo<span className="required-label"></span></h4>
                        </div>
                        <UploadImage name='upload1' onChange={setFieldValue} value={values.upload1} disabled={Data && Data.isEdit === false} filterByType={3} restrictOne={true}
                          setErrorImages={setLogoErrorImages} setShowImage={setShowLogoImage}></UploadImage>
                        <ShowImage value={values.upload1} errorImages={errorLogoImages} showImage={showLogoImage} ></ShowImage>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="upload-image">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Add Image<span className="required-label"></span></h4>
                        </div>
                        <UploadImage name='upload2' onChange={setFieldValue} value={values.upload2} disabled={Data && Data.isEdit === false} filterByType={4} restrictOne={true}
                          setErrorImages={setBannerErrorImages} setShowImage={setShowBannerImage}></UploadImage>
                        <ShowImage value={values.upload2} errorImages={errorBannerImages} showImage={showBannerImage} ></ShowImage>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Brand Name<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="brandName"
                          label="Brand name"
                          placeholder="Enter Brand name"
                          className="add-roles"
                          maxLength={20}
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.brandName}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Brand Code<span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="brandCode"
                          label="Brand code"
                          placeholder="Enter Brand code"
                          className="add-roles"
                          maxLength={20}
                          value={values.brandCode}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Brand type<span className="required-label"></span></h4>
                        <Field
                          className="select-menu mb-2"
                          name="brandType"
                          options={brandtypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.brandType}
                          onchangedata={(option) => handleBrandTypeChange(option, setFieldValue)}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Select Category<span className="required-label"></span></h4>
                        <Field
                          className="select-menu mb-2"
                          name="brandCategory"
                          options={categoryNewOptionsData}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={true}
                          value={values.brandCategory}
                          disabled={Data && Data.isEdit === false}
                          onchangedata={(option) => getFilterOptionData(option, 'category', setFieldValue)}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Select Sub Category<span className="required-label"></span></h4>
                        <Field
                          className="select-menu mb-2"
                          name="brandSubCategory"
                          options={subCategoryListOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={true}
                          value={values.brandSubCategory}
                          disabled={Data && Data.isEdit === false}
                          onchangedata={(option) => getFilterOptionData(option, 'subCategory', setFieldValue)}
                          styles={customStyles}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Select Leaf level category
                          {/* <span className="required-label"></span> */}
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="brandLeafCategory"
                          options={leafCategoryListOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={true}
                          value={values.brandLeafCategory}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Primary Color
                            {/* <span className="required-label"></span> */}
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="primaryColor"
                          label="Primary Color"
                          placeholder="Enter primary color code"
                          className="add-roles"
                          maxLength={20}
                          value={values.primaryColor}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Secondary Color
                            {/* <span className="required-label"></span> */}
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="secondaryColor"
                          label="Secondary Color"
                          placeholder="Enter secondary color code"
                          className="add-roles"
                          maxLength={20}
                          value={values.secondaryColor}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Tax Type
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="taxType"
                          options={taxTypeOption}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.taxType}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                          onchangedata={(value) => setTaxValidation(value, setFieldValue)}
                        />
                      </div>
                    </div>

                    {values.taxType === 'GST' ? (
                      <>
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">GST Number<span className="required-label"></span></h4>
                            </div>
                            <Input
                              type="text"
                              name="gst_number"
                              label="GST Number"
                              placeholder="Enter GST Number"
                              className="add-roles"
                              maxLength={20}
                              Regex={/[^A-Za-z0-9\s]/g}
                              value={values.gst_number}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">CGST<span className="required-label"></span></h4>
                            </div>
                            <Input
                              type="text"
                              name="cgst"
                              label="CGST"
                              placeholder="Enter CGST"
                              className="add-roles"
                              maxLength={20}
                              Regex={/[^0-9.\s]/g}
                              value={values.cgst}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">SGST<span className="required-label"></span></h4>
                            </div>
                            <Input
                              type="text"
                              name="sgst"
                              label="Enter SGST"
                              placeholder="Enter SGST"
                              className="add-roles"
                              maxLength={20}
                              Regex={/[^0-9.\s]/g}
                              value={values.sgst}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                      </>
                    ) : values.taxType === "VAT" && (
                      <div className="col-md-6">
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">VAT<span className="required-label"></span></h4>
                          </div>
                          <Input
                            type="text"
                            name="vat"
                            label="VAT"
                            placeholder="Enter VAT"
                            className="add-roles"
                            maxLength={20}
                            Regex={/[^0-9.\s]/g}
                            value={values.vat}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                    )}

                    <div className={values.taxType === 'no tax' ? "col-md-6" : 'col-md-4'}>
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Veg/Non Veg
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="veg_nonVeg"
                          options={vegListData}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.veg_nonVeg}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>

                    <div className={values.taxType === 'no tax' ? "col-md-6" : 'col-md-4'}>
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Tags
                          {/* <span className="required-label"></span> */}
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="brandTags"
                          options={tagListOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={true}
                          value={values.brandTags}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className={values.taxType === 'no tax' ? "col-md-6" : 'col-md-4'}>
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Payment URL
                          {/* <span className="required-label"></span> */}
                        </h4>
                        <Input
                          type="text"
                          name='paymentUrl'
                          label="Payment URL"
                          placeholder="Enter Payment URL"
                          className="add-roles"
                          hidelength="true"
                          value={values.paymentUrl}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="paragraph-editor public-visible">
                        <h4 className="paragraph-title">Public visible</h4>
                        <Field name='visible' setFieldValue={setFieldValue} className='toggle' component={Toggle} disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>


                    <div className="col-3">
                      <div className="paragraph-editor public-visible">
                        <h4 className="paragraph-title">Delivery available</h4>
                        <Field name='delivery' setFieldValue={setFieldValue} className='toggle' component={Toggle} disabled={Data && Data.isEdit === false} onChangeData={(event) => onChangeDelivery(event, setFieldValue)}/>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="paragraph-editor public-visible">
                        <h4 className="paragraph-title">Multiple beep</h4>
                        <Field name='notification' setFieldValue={setFieldValue} className='toggle' component={Toggle} disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="paragraph-editor public-visible">
                        <h4 className="paragraph-title">WhatsApp message</h4>
                        <Field name='smsAllow' setFieldValue={setFieldValue} className='toggle' component={Toggle} disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>

                    {(values.delivery === '1' || values.delivery === 1 || values.delivery === true) && (
                      <>
                        <div className="row">
                          <div className="col-md-5">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                Delivery Distance (KM)
                                {/* <span className="required-label"></span> */}
                              </h4>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                Delivery charges ({authuserData?.data?.symbol ? authuserData.data.symbol : '₹'})
                                {/* <span className="required-label"></span> */}
                              </h4>
                            </div>
                          </div>
                          <div className="col-1">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                Add
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            <Input
                              type="text"
                              name='delivery_distance'
                              label="Delivery distance"
                              placeholder="Enter Delivery distance"
                              className="add-roles"
                              maxLength={5}
                              hidelength="true"
                              Regex={/[^0-9\s]/g}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                          <div className="col-md-5">
                            <Input
                              type="text"
                              name='delivery_charge'
                              label="Delivery charges"
                              placeholder="Enter Delivery charges"
                              className="add-roles"
                              maxLength={5}
                              hidelength="true"
                              Regex={/[^0-9\s]/g}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                          <div className="col-1">
                            <Button
                              className="btn btn-fillter-main"
                              type="button"
                              onClick={() => handleAddField(values, setFieldValue)}
                              name={<div className="Plus"><FaPlus /></div>}
                            ></Button>
                          </div>
                        </div>
                        {fields && fields.length > 0 && fields.slice().reverse().map((field, index) => (
                          <div className="row" key={index}>
                            <div className="col-md-5">
                              <Input
                                type="text"
                                name={`delivery_distance${index}`}
                                label="Delivery distance"
                                placeholder="Enter Delivery distance"
                                className="add-roles"
                                value={field.delivery_distance}
                                changeInputData={(event) => changeInputData(index, event.target.value, setFieldValue, 'delivery_distance')}
                                disabled={
                                  Data && Data.isEdit === false
                                }
                                maxLength={5}
                                Regex={/[^0-9\s]/g}
                                hidelength="true"
                              />
                            </div>

                            <div className="col-md-5">
                              <Input
                                type="text"
                                name={`delivery_charge${index}`}
                                label="Delivery charges"
                                placeholder="Enter Delivery charges"
                                className="add-roles"
                                value={field.delivery_charge}
                                changeInputData={(event) => changeInputData(index, event.target.value, setFieldValue, 'delivery_charge')}
                                disabled={
                                  Data && Data.isEdit === false
                                }
                                Regex={/[^0-9\s]/g}
                                maxLength={5}
                                hidelength="true"
                              />
                            </div>

                            <div className="col-1">
                              <Button
                                className="btn btn-fillter-main"
                                type="button"
                                onClick={() => handleRemoveField(field.id)}
                                name={
                                  <div className="Plus"><FaMinus /></div>
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Description
                          <span className="required-label"></span>
                        </h4>
                        <Field name="description" component={CKEditorCpmponent} disabled={Data && Data.isEdit === false} maxLength={255} />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field name='active' setFieldValue={setFieldValue} className='toggle' component={Toggle} disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {
                            ((Data && Data.isEdit === true) || (!Data)) && (
                              <>
                                <Button type="submit" name="Save" className="btn login-btn" />
                                <Button
                                  type="button"
                                  name="Cancel"
                                  className="btn clear-btn login-btn"
                                  onClick={() => handleCancleClick('/brand-list', navigate)}
                                />
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}