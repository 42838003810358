import { toast } from "react-toastify";
import { ValidationMessage } from "../../Utils/Utils";

export const deleteApiService = async (Url, token, bodyData) => {
  try {
    const response = await fetch(Url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }, body: JSON.stringify(bodyData),
    });
    if (!response.ok) {
      const data = await response.json();
      return data;
      // throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
  }
}