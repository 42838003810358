import React, { useEffect } from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../UploadImage/UploadImage.scss'
import CustomTable from '../Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import Addimage from '../../pages/Imagemanager/Add-image/Addimage';
import Button from '../Button/Button';
import { getAllImageService } from '../../pages/Imagemanager/ImageService/ImageService';
import UtilsData, { imageTypeOptions } from '../../Utils/Utils';
import ErrorComp from '../Error/ErrorComp';
import { ErrorMessage } from 'formik';

const columns = [
  {
    name: 'Banner image',
    selector: row => row.banner,
  },
  {
    name: 'Image type',
    selector: row => row.Imagetype,
  }
];

//Show keys which are to be displayed in table
const showKey = {
  imageTypeName: true
}

export default function UploadImage(props) {

  const dispatch = useDispatch();
  const [isToggled, setIsToggled] = useState(false);

  const [image, setImage] = useState([]);

  const tokenData = UtilsData();

  const [restrictOneImage, setRestrictOntImage] = useState(false);

  useEffect(() => {
    if (props.restrictOne && props.restrictOne === true) {
      setRestrictOntImage(true)
    }
    else {
      setRestrictOntImage(false);
    }
  }, [props])

  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const imageData = useSelector(state => state.totalImage);
  const [Data, setData] = useState(null);

  const [errorData, setErrorData] = useState([]);

  //Setting data on toggle click
  const handleButtonClick = (data) => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
    if (data) {
      setData(data)
    }
  };

  useEffect(() => {
    if (imageData && imageData.items && imageData.items.length === 0) {
      const requestBody = {
        p_skip: 0,
        p_take: 100000000
      }
      getAllImageService(requestBody, tokenData, dispatch, true);
    }

    if (imageData.items[0]?.data && imageData.items[0].data.length > 0) {
      const transformedImageData = imageData.items[0].data.map(item => ({
        imageId: item.imageId,
        imagePath: item.imagePath,
        imageType: item.imageType,
        imageTypeName: '',
        createdBy: item.createdBy,
        createdAt: item.createdAt,
        modifiedBy: item.modifiedBy,
        modifiedAt: item.modifiedAt,
        isDeleted: item.isDeleted
      }));
      for (let i = 0; i < imageData.items[0].data.length; i++) {
        const typeName = imageData.items[0].data[i].imageType;
        const matchingObject = imageTypeOptions.find(obj => obj.value === typeName);
        if (matchingObject) {
          transformedImageData[i].imageTypeName = matchingObject.label;
        }
      }

      if (props.filterByType) {
        const filteredData = transformedImageData.filter(item => item.imageType === props.filterByType);
        setTableData(filteredData)
      }
      else {
        setTableData(transformedImageData)
      }
    }
    else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [imageData])

  /**
   * If more than five images are checked, then show toast
   * @param {*} param0 
   */
  const handleCheckChange = ({ filterData: filter, errorImageList: error }) => {
    setImage(filter);
    setErrorData(error)
  }

  /**
   * Handle upload image
   */
  const handleUploadImage = () => {
    if (props.onChange) {
      props.onChange(props.name, image);
    }
    if (props.changeImageData) {
      props.changeImageData(image);
    }
    if (props.setErrorImages) {
      props.setErrorImages(errorData)
    }
    if (props.setShowImage) {
      props.setShowImage(false);
    }
    setShow(false);
  }

  if (props.disabled) {
    return (
      <div className='image-border'>
        <div className='upload-btn disabled'>
          Upload
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='image-border' onClick={() => setShow(true)}>
        <div className='upload-btn' >
          Upload
        </div>
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title" className='media-library'>
            Media Library
          </Modal.Title>
          <div className="media-g">
              <div className='media-btn' onClick={handleButtonClick}>
                Add New File
              </div>
              {image && image.length > 0 && (
                <Button className="btn login-btn upload" type='button' name='Upload' onClick={handleUploadImage}></Button>
              )}
          </div>
        </Modal.Header>
        {isToggled &&
          <div className='open-image'>
            <div>
              <Addimage handleToggle={handleButtonClick} Data={Data} filterByType={props.filterByType ? props.filterByType : 'filter'} ></Addimage>
            </div>
          </div>
        }
        <Modal.Body>
          {(imageData && imageData.items && imageData.items[0]?.data && imageData.items[0].data) && (
            <CustomTable restrictFive={restrictOneImage === true ? false : true} restrictOneImage={restrictOneImage === true ? true : false}
              uploadImageValue={props.value}
              page='uploadImage' checkBool={true} showKey={showKey} showTooltip={true} data={tableData} heading={columns} onCheckChange={handleCheckChange} showImage={true} pageName={props.name} />
          )}
        </Modal.Body>
      </Modal>
      <ErrorMessage name={props.name} component={ErrorComp} />
    </>
  )
}