import { Dispatch } from "redux";
import { addUpdateLeafLevelRequest, getAllLeafLevelRequest, getAllLeafLevelResponse, getLeafByIdResponse } from "./LeafLevelModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearLeafLevelData, clearTotalLeafLevelData, getLeafLevelData, getTotalLeafLevelData } from "../../../redux/Actions/leafLevelCategoryAction/LeafLevelCategoryAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearLeafPageData, getLeafPageData } from "../../../redux/Actions/leafLevelCategoryAction/LeafPageAction";
import { clearNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";

let errorShow = false;

/*
 * Service to get leaf level category list
*/
export const getAllLeafLevelCategoryService = (
  leafLevelCategoryListRequest: getAllLeafLevelRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<getAllLeafLevelResponse> => {
  return new Promise<getAllLeafLevelResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (leafLevelCategoryListRequest.p_skip !== undefined && leafLevelCategoryListRequest.p_skip !== null) {
        queryParams += `p_skip=${leafLevelCategoryListRequest.p_skip}&`;
      }
      if (leafLevelCategoryListRequest.p_take !== undefined && leafLevelCategoryListRequest.p_take !== null) {
        queryParams += `p_take=${leafLevelCategoryListRequest.p_take}&`;
      }
      if (leafLevelCategoryListRequest.p_name !== undefined && leafLevelCategoryListRequest.p_name !== null) {
        queryParams += `p_name=${leafLevelCategoryListRequest.p_name}&`;
      }
      if (leafLevelCategoryListRequest.p_sub_categoryIds !== undefined && leafLevelCategoryListRequest.p_sub_categoryIds !== null) {
        queryParams += `p_sub_categoryIds=${leafLevelCategoryListRequest.p_sub_categoryIds}&`;
      }
      if (leafLevelCategoryListRequest.p_is_active !== undefined && leafLevelCategoryListRequest.p_is_active !== null) {
        queryParams += `p_is_active=${leafLevelCategoryListRequest.p_is_active}&`
      }
      if (leafLevelCategoryListRequest.p_isDeleted !== undefined && leafLevelCategoryListRequest.p_isDeleted !== null) {
        queryParams += `p_isDeleted=${leafLevelCategoryListRequest.p_isDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.LEAF_LEVEL_CATEGORY + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(getTotalLeafLevelData(response));
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getLeafLevelData(response));
        }
        else {
          dispatch(getLeafLevelData(response))
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Leaf level catagory permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
}

/*
 * Service for adding and updating leaf level category
*/
export const addUpdateLeafLevelService = (
  addUpdateLeafCategoryRequest: addUpdateLeafLevelRequest,
  token: string, type: string, navigate?: any, pageNumber?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.LEAF_LEVEL_CATEGORY, token, addUpdateLeafCategoryRequest);
      }
      if (type === 'update') {
        response = await putApiService(API_URL.BASE_URL + API_URL.LEAF_LEVEL_CATEGORY, token, addUpdateLeafCategoryRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearLeafLevelData());
        dispatch(clearTotalLeafLevelData());
        if (type === 'add') {
          dispatch(clearLeafPageData());
          toast.success('Leaf level category added successfully!');
          navigate('/leaf-level-category');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            toast.success('Leaf level category undeleted successfully!');
          }
          else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getLeafPageData(pageNumber + 1));
              }
              else {
                dispatch(clearLeafPageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: "Leaf level category updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/leaf-level-category');
              });
            }
            else {
              toast.success('Leaf level category status updated successfully!');
            }
          }
        }
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getLeafPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
}

/*
 * Service for deleting sub category
*/
export const deleteLeafLevelService = (deleteId: number, token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.LEAF_LEVEL_CATEGORY + '/' + deleteId, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearLeafLevelData());
        dispatch(clearTotalLeafLevelData());
        toast.success('Leaf level category deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getLeafPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
}

/*
 * Service to get individual leaf level category
*/
export const getLeafCategoryById = (
  id: string, token: string,
  dispatch: Dispatch): Promise<getLeafByIdResponse> => {
  return new Promise<getLeafByIdResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      const response = await getApiService(API_URL.BASE_URL + API_URL.LEAF_LEVEL_CATEGORY + '/' + id, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        resolve(response);
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
}

/*
 * Service for uploading excel
*/
export const uploadLeafExcelService = (
  excelRequest: any,
  token: string,
  setIsModalOpen: any
) => {
  return async (dispatch: Dispatch) => {
    const formData = new FormData();
    formData.append('excel', excelRequest);

    try {
      dispatch(setLoader());
      const response = await fetch(API_URL.BASE_URL + API_URL.LEAF_FILE_UPLOAD, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token
        }, body: formData,
      });
      if (!response.ok) {
        const data = await response.json();
        toast.error(data?.message ? data.message : ValidationMessage.SOMETHING_WENT_WRONG);
        if (data?.message && data.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        return data;
      }
      else {
        const data = await response.json();
        toast.success(data?.message ? data.message : 'Leaf level category imported successfully');
        dispatch(clearNotificationData());
        setIsModalOpen(false);
        return data;
      }
    } catch (error) {
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  }
}