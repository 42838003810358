import React from 'react'
import { IoIosSearch } from 'react-icons/io'
import "../SearchBar/SearchBar.scss"
import { Link } from 'react-router-dom'
export default function SearchBar(props) {
  const {inputData, setInputData} = props;

  return (
    <>
      <form className="search-container">
        <input type="text" id="search-bar" placeholder="What are you looking for 🤔 ?" value={inputData} onChange={(event) => setInputData(event.target.value)} 
        onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          />
        <Link to="" className="search-icon">
          <IoIosSearch />
        </Link>
      </form>
    </>
  )
}