import React, { useEffect, useRef, useState } from "react";
import UtilsData from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import { clearNewVendorFilterData } from "../../redux/Actions/NewVendorAction/NewVendorFilterAction";
import {
  clearNewVendorPageData,
  getNewVendorPageData,
} from "../../redux/Actions/NewVendorAction/NewVendorPageAction";
import { clearVendorData } from "../../redux/Actions/VendorAction/VendorAction";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Breadcrumb, Dropdown } from "react-bootstrap";
import ExcelData from "../../services/Regular-services/ExcelExport";
import Button from "../../components/Button/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import Filter from "../../components/Filter/Filter";
import CustomTable from "../../components/Table/Table";
import CustomPagination from "../../components/Pagination/Pagination";
import Footer from "../../components/Footer/Footer";
import { GrMoreVertical } from "react-icons/gr";
import { getAllNewVendorPersonService } from "./NewVendorPersonService/NewVendorPersonService";
import { clearNewVendorData } from "../../redux/Actions/NewVendorAction/NewVendorAction";

const NewVendorPerson = () => {
  const newVendorData = useSelector((state) => state.newVendor);
  const tokenData = UtilsData();
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const newVendorPage = useSelector((state) => state.newVendorPage?.items);
  const newVendorFilter = useSelector((state) => state.newVendorFilter);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [newVendorActionData, setNewVendorActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setNewVendorActionData(actionData);
    }
  }, [sideBarData]);

  const showKey = {
    name: true,
    mobile: true,
    shopName: true,
  };
  const showToggle = {
    status: true,
  };
  // const [showActions] = {
  //   showEdit: true,
  //   showDelete:true,
  //   showView: true,
  //   // showPrint: true,
  //   showUndo: true,
  //   navigate: "/edit-vendor-person",
  // };
  const showActions = {
    showView:
      (newVendorActionData[0]?.view && newVendorActionData[0].view === "1") ||
      !newVendorActionData
        ? true
        : false,
    showEdit:
      (newVendorActionData[0]?.edit && newVendorActionData[0].edit === "1") ||
      !newVendorActionData
        ? true
        : false,
    showDelete:
      (newVendorActionData[0]?.delete &&
        newVendorActionData[0].delete === "1") ||
      !newVendorActionData
        ? true
        : false,
    showUndo:
      (newVendorActionData[0]?.edit && newVendorActionData[0].edit === "1") ||
      !newVendorActionData
        ? true
        : false,
    navigate:
      (newVendorActionData[0]?.edit && newVendorActionData[0].edit === "1") ||
      !newVendorActionData
        ? "/edit-vendor-person"
        : false,
  };

  const [columns, setColumns] = useState([
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Mobile Number",
      selector: (row) => row.mobile,
    },
    {
      name: "Shop Name",
      selector: (row) => row.shopeName,
    },
    {
      name: "Status",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
    {
      name: "Actions",
      cell: (row) => <Toggle checked={row.toggle} />,
    },
  ]);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      newVendorActionData[0]?.view &&
      newVendorActionData[0].view === "0" &&
      newVendorActionData[0]?.edit &&
      newVendorActionData[0].edit === "0" &&
      newVendorActionData[0]?.add &&
      newVendorActionData[0].add === "0" &&
      newVendorActionData[0]?.delete &&
      newVendorActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Name",
          selector: (row) => row.name,
        },
        {
          name: "Mobile Number",
          selector: (row) => row.mobile,
        },
        {
          name: "Shop Name",
          selector: (row) => row.shopeName,
        },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Name",
          selector: (row) => row.name,
        },
        {
          name: "Mobile Number",
          selector: (row) => row.mobile,
        },
        {
          name: "Shop Name",
          selector: (row) => row.shopeName,
        },
        {
          name: "Status",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
        {
          name: "Actions",
          cell: (row) => <Toggle checked={row.toggle} />,
        },
      ]);
    }
  }, [newVendorActionData]);

  /**
   * Fetch new Vendor item data
   */
  const fetchNewVendors = () => {
    const requestBody = {
      p_skip:
        newVendorPage > 1 ? (newVendorPage - 2) * 10 : (newVendorPage - 1) * 10,
      p_take: 10,
    };
    if (
      newVendorFilter.firstName !== "" &&
      newVendorFilter.firstName !== null
    ) {
      requestBody.p_first_name = newVendorFilter.firstName;
    }
    if (newVendorFilter.isActive !== "" && newVendorFilter.isActive !== null) {
      requestBody.p_isActive = newVendorFilter.isActive;
    } else {
      requestBody.p_isActive = "1";
    }
    if (newVendorFilter.isDelete !== "" && newVendorFilter.isDelete !== null) {
      requestBody.p_IsDeleted = newVendorFilter.isDelete;
    }
    if (newVendorFilter.lastName !== "" && newVendorFilter.lastName !== null) {
      requestBody.p_last_name = newVendorFilter.lastName;
    }
    if (newVendorFilter.phNum !== "" && newVendorFilter.phNum !== null) {
      requestBody.p_phone_number = newVendorFilter.phNum;
    }

    // return
    getAllNewVendorPersonService(requestBody, tokenData, dispatch);
    if (newVendorPage > 1) {
      setCurrentPage(newVendorPage - 1); // Update the current page state
      dispatch(getNewVendorPageData(newVendorPage - 1));
    }
  };

  useEffect(() => {
    if (
      newVendorData &&
      newVendorData.items &&
      newVendorData.items.length === 0
    ) {
      fetchNewVendors();
    } else {
      if (
        newVendorFilter?.firstName !== "" ||
        newVendorFilter?.lastName !== "" ||
        newVendorFilter?.phNum !== "" ||
        newVendorFilter?.isActive !== "" ||
        newVendorFilter?.isDelete !== ""
      ) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
      }
    }

    if (
      newVendorData.items[0]?.data &&
      newVendorData.items[0].data.length > 0
    ) {
      const transformedNewVendorData = newVendorData.items[0].data.map(
        (item) => {
          const ownerName = item.first_name + " " + item.last_name;
          return {
            id: item.id,
            name: ownerName,
            mobile: item.phone_number,
            shopName: item.shopeName,
            status: item.is_active,
            is_delete: item.isDeleted,
            categoryData: item.categoryData,
            first_name:item.first_name,
            last_name:item.last_name,
            wp_sms_allow:item.wp_sms_allow,
            isDeleted:item.is_deleted
          };
        }
      );

      setTableData(transformedNewVendorData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [newVendorData, newVendorFilter]);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * Integrate listing API on page change
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      setCurrentPage(pageNumber);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (
        newVendorFilter.firstName !== "" &&
        newVendorFilter.firstName !== null
      ) {
        requestBody.p_first_name = newVendorFilter.firstName;
      }
      if (
        newVendorFilter.isActive !== "" &&
        newVendorFilter.isActive !== null
      ) {
        requestBody.p_isActive = newVendorFilter.isActive;
      } else {
        requestBody.p_isActive = "1";
      }
      if (
        newVendorFilter.isDelete !== "" &&
        newVendorFilter.isDelete !== null
      ) {
        requestBody.p_IsDeleted = newVendorFilter.isDelete;
      }
      if (
        newVendorFilter.lastName !== "" &&
        newVendorFilter.lastName !== null
      ) {
        requestBody.p_last_name = newVendorFilter.lastName;
      }
      if (newVendorFilter.phNum !== "" && newVendorFilter.phNum !== null) {
        requestBody.p_phone_number = newVendorFilter.phNum;
      }
      getAllNewVendorPersonService(requestBody, tokenData, dispatch);
      dispatch(getNewVendorPageData(pageNumber));
    }
  };

  /**
   * Clear filterrd data
   */
  const clearFilters = () => {
    dispatch(clearNewVendorFilterData());
    dispatch(clearNewVendorPageData());
    dispatch(clearNewVendorData());
    setIsToggled(false);
  };

  return (
    <div>
      <>
        <div className="main-section">
          <div className="sidebar-content">
            <Sidebar />
          </div>
          <div className="body-content">
            <Header />
            <div className="table-body-content">
              <div className="roles">
                <div>
                  <div className="title">
                    <h4>
                      {newVendorActionData && newVendorActionData.length > 0
                        ? newVendorActionData[0].name
                        : "Vendors"}
                    </h4>
                  </div>
                  <Breadcrumb>
                    <Breadcrumb.Item onClick={() => navigate("/home")}>
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      {newVendorActionData && newVendorActionData.length > 0
                        ? newVendorActionData[0].name
                        : "Vendors"}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div className="btn-groups">
                  {newVendorFilter.firstName !== "" ||
                  newVendorFilter.lastName !== "" ||
                  newVendorFilter.phNum !== "" ||
                  newVendorFilter.isActive !== "" ||
                  newVendorFilter.isDelete !== "" ? (
                    <Dropdown className="header-dropdown action-btn">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        data-bs-toggle="tooltip"
                        title="Action"
                      >
                        <GrMoreVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={clearFilters}>
                          Clear Filter
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                  <Button
                    data-bs-toggle="tooltip"
                    title="Filter"
                    onClick={handleButtonClick}
                    className="btn btn-fillter-main"
                    name={
                      <div className="filter">
                        <FaFilter className="filter-icon" />
                      </div>
                    }
                  ></Button>

                  {newVendorData &&
                    newVendorData.items[0]?.data &&
                    newVendorData.items[0].data.length > 0 && (
                      <ExcelData
                        excelData={newVendorData.items[0].data}
                        fileName={"Vendor"}
                      ></ExcelData>
                    )}
                  <Button
                    data-bs-toggle="tooltip"
                    title="Add Vendor Person"
                    onClick={() => navigate("/add-vendor-person")}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                </div>
              </div>
              {isToggled && <Filter page="new-vendor-person"></Filter>}

              {newVendorData &&
                newVendorData.items[0]?.data &&
                newVendorData.items[0].data && (
                  <>
                    <CustomTable
                      page="new-vendor-person"
                      showToggle={showToggle}
                      checkBool={false}
                      showKey={showKey}
                      showActions={showActionBool ? showActions : false}
                      // showActions={ showActions }
                      showTooltip={true}
                      data={tableData}
                      heading={columns}
                      disabled={
                        newVendorActionData &&
                        newVendorActionData[0]?.edit &&
                        newVendorActionData[0].edit === "0"
                      }
                      currentPage={currentPageNumber}
                      onPageChange={onPageChange}
                    />

                    {/* pagination */}
                    {newVendorData.items[0].total_records >
                      newVendorData.items[0].data.length &&
                      newVendorData.items[0].total_records > 10 && (
                        <CustomPagination
                          totalRecords={newVendorData.items[0].total_records}
                          currentPage={currentPageNumber}
                          onPageChange={onPageChange}
                        />
                      )}
                  </>
                )}
            </div>
            <Footer />
          </div>
        </div>
      </>
    </div>
  );
};

export default NewVendorPerson;
