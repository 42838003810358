import React from 'react'
import Loginbanner from '../../components/Loginbanner/Loginbanner';
import Button from '../../components/Button/Button';
import Input from '../../components/input/Input';
import { FormGroup } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationMessage } from '../../Utils/Utils';
import { useDispatch } from 'react-redux';
import fetchToken from '../../services/API-services/generateToken';


const initialValues = {
  OTP: '',
}

const validationSchema = Yup.object({
  OTP: Yup.string().required(ValidationMessage.OTP_REQUIRED).min(6, ValidationMessage.OTP_LENGTH)
})


export default function OTP() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Getting email data from forgot password page
  const location = useLocation();
  const { email } = location.state || {};

  //Handling form submission
  const onSubmit = (values) => {
    let value = {
      email: email,
      otp: values.OTP
    }
    dispatch(fetchToken(value, 'verifyOtp', navigate));
  }

  return (
    <>
      <section className='login-form'>
        <div className="row w-100 m-0">
          <div className="col-lg-6 p-0 d-lg-flex d-none">
            {/* left side image section start  */}
            <Loginbanner></Loginbanner>
            {/* left side image section end  */}
          </div>
          <div className="col-lg-6 col-12 p-0">
            {/* Right side login form section end  */}

            <div className='right-side-form'>
              <div className='logo d-lg-none d-flex'>
                Ecommerce
              </div>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ values }) => (
                  <Form className="wrapper label-p-0 bg-layer">
                    <h2>Verification</h2>
                    <span className='forgotpassword'>
                      You will get an OTP on email
                    </span>

                    <FormGroup>
                      <Input
                        type='text'
                        name='OTP'
                        label='OTP'
                        placeholder="Enter OTP"
                        isRequired={true}
                        Regex={/[^0-9]/g}
                        value={values.OTP}
                        maxLength={6}
                        hidelength={"true"} />
                      <div className='back'>
                        <Button type="submit" name="Verify" className=" btn login-btn" />
                      </div>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </div>
            {/* Right side login form section end  */}
          </div>
        </div>
      </section>
    </>
  )
}