import React from 'react'
import './Resetpassword.scss'
import Loginbanner from '../../components/Loginbanner/Loginbanner';
import Input from '../../components/input/Input';
import Button from '../../components/Button/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Regex, ValidationMessage } from '../../Utils/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import fetchToken from '../../services/API-services/generateToken';

//Password Regex
const passwordRegex = Regex.PasswordRegex;

//Initial Values
const initialValues = {
  password: '',
  confirmPassword: ''
}

//Validation schema
const validationSchema = Yup.object({
  password: Yup.string().required(ValidationMessage.PASSWORD_REQUIRED).test(
    'password',
    ValidationMessage.INVALID_PASSWORD,
    value => {
      return passwordRegex.test(value)
    }
  ),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], ValidationMessage.CONFIRM_PASSWORD)
    .required(ValidationMessage.CONFIRM_PASSWORD_REQUIRED)
})

export default function Resetpassword() {

  const navigate = useNavigate(); //Navigation
  const dispatch = useDispatch();

  const location = useLocation();
  const { email } = location.state || {};


  /**
   * Handling Form Submission 
   */
  const onSubmit = (values) => {
    const value = {
      email: email,
      password: values.password
    }
    dispatch(fetchToken(value, 'resetPassword', navigate));
  }

  return (
    <>
      <section className="login-form reset">
        <div className="row w-100">
          <div className="col-lg-6 p-0 d-lg-flex d-none">
            <Loginbanner />
          </div>
          <div className="col-lg-6 col-12 p-0">

            <div className='right-side-form'>
              <div className='logo d-lg-none d-flex'>
                Ecommerce
              </div>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                <Form className="wrapper bg-layer">
                  <h2>Reset Password</h2>
                  <Input type='password'
                    name='password'
                    label='New password'
                    placeholder="Enter New password"
                    isRequired={true}
                    isPassword={true}
                    maxLength={15} hidelength={"true"}
                  />
                  <Input type='password'
                    name='confirmPassword'
                    label='Confirm Password'
                    placeholder="Enter Confirm Password"
                    isRequired={true}
                    isPassword={true}
                    maxLength={15} hidelength={"true"} />
                  <Button type="submit" name="Reset" />
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}