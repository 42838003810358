import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/Button/Button';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/Table/Table';
import { FaFilter, FaPlus } from 'react-icons/fa';
import Filter from '../../components/Filter/Filter';
import { GrMoreVertical } from "react-icons/gr";
import { Dropdown } from 'react-bootstrap';
import Toggle from '../../components/Toggle/Toggle';
import ExcelData from '../../services/Regular-services/ExcelExport';
import { useDispatch, useSelector } from 'react-redux'
import { getAllWarehouseService } from './WarehouseService/WarehouseService';
import UtilsData from '../../Utils/Utils';
import CustomPagination from '../../components/Pagination/Pagination';
import { clearWarehouseFilterData } from '../../redux/Actions/WarehouseAction/WarehouseFilterAction';
import { clearWarehouseData } from '../../redux/Actions/WarehouseAction/WarehouseAction';
import { clearWarehousePageData, getWarehousePageData } from '../../redux/Actions/WarehouseAction/WarehousePageAction';

//Show keys which is to be displayed in table
const showKey = {
  name: true,
  address: true
}
const showToggle = {
  status: true,
  delete: false
}

export default function Warehouse() {

  const warehouse = useSelector(state => state.warehouse);

  const dispatch = useDispatch();
  const tokenData = UtilsData();
  const warehouseFilter = useSelector(state => state.warehouseFilter);
  const warehousePage = useSelector(state => state.warehousePage?.items);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const sideBarData = useSelector(state => state.sideBar.items);
  const [warehouseActionData, setwarehouseActionData] = useState([]);

  const [columns, setColumns] = useState([])

  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(item => item.router_link === "warehouse");
      setwarehouseActionData(actionData)
    }
  }, [sideBarData]);

  useEffect(() => {
    if ((warehouseActionData[0]?.view && warehouseActionData[0].view === '0') &&
      (warehouseActionData[0]?.edit && warehouseActionData[0].edit === '0') &&
      (warehouseActionData[0]?.add && warehouseActionData[0].add === '0') &&
      (warehouseActionData[0]?.delete && warehouseActionData[0].delete === '0')) {
      setColumns([
        {
          name: 'Warehouse Name',
          selector: row => row.Name
        },
        {
          name: 'location',
          selector: row => row.location
        },
        {
          name: 'Status',
          cell: (row) => <Toggle checked={row.toggle} />
        }
      ])
      setShowActionBool(false);
    }
    else {
      setShowActionBool(true);
      setColumns([
        {
          name: 'Warehouse Name',
          selector: row => row.Name
        },
        {
          name: 'location',
          selector: row => row.location
        },
        {
          name: 'Status',
          cell: (row) => <Toggle checked={row.toggle} />
        },
        {
          name: 'Action',
          selector: row => row.action
        }
      ])
    }
  }, [warehouseActionData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView: ((warehouseActionData[0]?.view && warehouseActionData[0].view === '1') || (!warehouseActionData)) ? true : false,
    showEdit: ((warehouseActionData[0]?.edit && warehouseActionData[0].edit === '1') || (!warehouseActionData)) ? true : false,
    showDelete: ((warehouseActionData[0]?.delete && warehouseActionData[0].delete === '1') || (!warehouseActionData)) ? true : false,
    showUndo: ((warehouseActionData[0]?.edit && warehouseActionData[0].edit === '1') || (!warehouseActionData)) ? true : false,
    navigate: ((warehouseActionData[0]?.edit && warehouseActionData[0].edit === '1') || (!warehouseActionData)) ? '/add-warehouse' : false
  }

  useEffect(() => {
    setCurrentPageNumber(warehousePage)
  }, [warehousePage])

  /**
   * Fetch warehouse data
   */
  const fetchWarehouse = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 10
    }
    if (warehousePage > 1) {
      requestBody.p_skip = (warehousePage - 2) * 10;
      dispatch(getWarehousePageData(warehousePage - 1));
    }
    else {
      requestBody.p_skip = (warehousePage - 1) * 10;
      dispatch(getWarehousePageData(warehousePage));
    }

    if (warehouseFilter.name !== '' && warehouseFilter.name !== null) {
      requestBody.p_name = warehouseFilter.name;
    }
    if (warehouseFilter.isActive !== '' && warehouseFilter.isActive !== null) {
      requestBody.p_is_active = warehouseFilter.isActive;
    }
    else {
      requestBody.p_is_active = '1';
    }
    if (warehouseFilter.isDelete !== '' && warehouseFilter.isDelete !== null) {
      requestBody.p_IsDeleted = warehouseFilter.isDelete;
    }
    getAllWarehouseService(requestBody, tokenData, dispatch);
  }

  useEffect(() => {
    if (warehouse && warehouse.items && warehouse.items.length === 0) {
      fetchWarehouse();
    }
    else {
      if (warehouseFilter.name !== '' || warehouseFilter.isActive !== '' || warehouseFilter.isDelete !== '') {
        setIsToggled(true);
      }
    }
    // eslint-disable-next-line
  }, [warehouse]);

  /**
   * On page change
   * @param {*} pageNumber 
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPageNumber) {
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10
      }
      if (warehouseFilter.name !== '' && warehouseFilter.name !== null) {
        requestBody.p_name = warehouseFilter.name;
      }
      if (warehouseFilter.isActive !== '' && warehouseFilter.isActive !== null) {
        requestBody.p_is_active = warehouseFilter.isActive;
      }
      else {
        requestBody.p_is_active = '1';
      }
      if (warehouseFilter.isDelete !== '' && warehouseFilter.isDelete !== null) {
        requestBody.p_IsDeleted = warehouseFilter.isDelete;
      }
      dispatch(getWarehousePageData(pageNumber));
      getAllWarehouseService(requestBody, tokenData, dispatch)
      setCurrentPageNumber(pageNumber);
    }
  }


  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  /**
   * Handle filter togglr
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  };

  /**
   * cleared filtered data
   */
  const clearFilters = () => {
    dispatch(clearWarehouseFilterData());
    dispatch(clearWarehouseData());
    dispatch(clearWarehousePageData());
    setIsToggled(false);
  }

  return (
    <>
      <div className='main-section'>
        <div className='sidebar-content'>
          <Sidebar />
        </div>
        <div className='body-content'>
          <Header />
          <div className='table-body-content'>
            <div className='roles'>
              <div>
                <div className='title'>
                  <h4>Warehouse</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Warehouse</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className='btn-groups'>
                <Dropdown className='header-dropdown action-btn' >
                  <Dropdown.Toggle variant="success" id="dropdown-basic" data-bs-toggle='tooltip' title='Action'>
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item >Delete</Dropdown.Item>
                    <Dropdown.Item >Active</Dropdown.Item>
                    <Dropdown.Item >Inactive</Dropdown.Item>
                    <Dropdown.Item >Undo</Dropdown.Item>
                    {(warehouseFilter?.name !== '' || warehouseFilter?.isActive !== '' || warehouseFilter?.isDelete !== '') && (
                      <Dropdown.Item onClick={clearFilters}>Clear Filter</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <Button data-bs-toggle='tooltip' title='Filter' onClick={handleButtonClick} className="btn btn-fillter-main" name={<div className="filter">
                  <FaFilter className="filter-icon" />
                </div>}></Button>
                {warehouse && warehouse.items && warehouse.items[0]?.data && warehouse.items[0].data.length > 0 && (
                  <ExcelData excelData={warehouse.items[0].data} fileName='warehouse'></ExcelData>
                )}
                {((warehouseActionData[0]?.add && warehouseActionData[0].add === '1') || (!warehouseActionData)) && (
                  <Button data-bs-toggle='tooltip' title='Add Tag' onClick={() => navigate('/add-warehouse')} className="btn btn-fillter-main" name={<div className="Plus">
                    <FaPlus />
                  </div>}>
                  </Button>
                )}
              </div>
            </div>
            {
              isToggled &&
              <Filter page='warehouse'></Filter>
            }
            {warehouse && warehouse.items && warehouse.items[0]?.data && warehouse.items[0].data && (
              <>
                <CustomTable page='warehouse' showToggle={showToggle} showKey={showKey} showActions={showActionBool ? showActions : false} showTooltip={true} data={warehouse.items[0].data} heading={columns} checkBool={true} disabled={warehouseActionData[0]?.edit && warehouseActionData[0].edit === '0'} />
                {(warehouse.items[0].total_records > warehouse.items[0].data.length && warehouse.items[0].total_records > 10) && (
                  <CustomPagination totalRecords={warehouse.items[0].total_records} currentPage={currentPageNumber} onPageChange={onPageChange} />
                )}
              </>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}