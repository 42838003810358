/**
 * Setting user data into reducer after login
 * @param {*} user 
 * @param {*} rememberMe 
 * @param {*} loginTime 
 * @returns {*}
 */

export const login = (user, loginTime) => ({
    type: 'LOGIN',
    payload: { user, loginTime },
});

// export const login = (user, rememberMe, loginTime) => ({
//     type: 'LOGIN',
//     payload: { user, rememberMe, loginTime },
// });
    
/**
 * action for logout user
 * @returns {*}
 */
export const logout = () => ({
    type: 'LOGOUT',
}); 
  
/**
 * Action for login fail, if failed to login!
 * @returns {*}
 */
export const loginFail = () =>({
    type: 'LOGIN_FAILURE',
})