import React, { useEffect, useState } from "react";
import "./AddOrder.scss";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import * as Yup from "yup";
import UtilsData, { API_URL, ValidationMessage } from "../../../Utils/Utils";
import Button from "../../../components/Button/Button";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";
import { handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import { getAllProductService } from "../../Product/ProductService/ProductService";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetailService,
  placeOrderService,
} from "../OrderService/OrderService";
import OrderCart from "../../../components/OrderCart/OrderCart";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { getAllBrandService } from "../../BrandList/BrandService/BrandService";
import {
  getAllDiscountService,
  getDiscountById,
} from "../../Discount/DiscountService/DiscountService";

export default function AddOrder() {
  /**
   * Getting data from brand table
   */
  const location = useLocation();
  const { Data } = location.state || {};
  const navigate = useNavigate();
  const totalProduct = useSelector((state) => state.totalProduct);
  const orderPage = useSelector((state) => state.orderPage?.items);
  const [addedProducts, setAddedProducts] = useState([]);
  const [totalAmountData, setTotalAmount] = useState(0);
  // const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [appliedDiscountCode, setAppliedDiscountCode] = useState(null);
  const [updatedTotalAmt, setUpdatedTotalAmt] = useState(null);
  const [discountAmt, setDiscountAmt] = useState(null);
  const [discountID, setDiscountID] = useState(null);
  const [discountDetail, setDiscountDetail] = useState(null);

  const [message, setMessage] = useState("");
  const authuserData = useSelector((state) => state.auth?.user);
  const totalBrand = useSelector((state) => state.totalBrand);
  const [activeIndex, setActiveIndex] = useState(null);

  const [discountOption, setDiscountOption] = useState([]);

  const totalDiscount = useSelector((state) => state.totalDiscount);
  const dispatch = useDispatch();
  const token = UtilsData();

  const fetchTotalDiscount = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 50000,
    };
    getAllDiscountService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalDiscount &&
      totalDiscount.items &&
      totalDiscount.items.length === 0
    ) {
      fetchTotalDiscount();
    } else {
      if (
        totalDiscount.items &&
        totalDiscount.items[0] &&
        totalDiscount.items[0]?.data &&
        totalDiscount.items[0].data.length > 0
      ) {
        const optionData = totalDiscount.items[0].data.map((item) => ({
          value: item.discountId,
          label: item.discountCode,
        }));
        setDiscountOption(optionData);
      } else {
        setDiscountOption([]);
      }
    }
    // eslint-disable-next-line
  }, [totalDiscount]);

  const handleSubmit = (values) => {
    if (addedProducts.length < 1) {
      toast.error("Please add product!");
      return;
    }

    // request body for add and
    const requestProductData = addedProducts.map((item) => {
      // changes by sakshi
      const attributeData = item.selectedAttributes
        ? JSON.stringify(
            Object.keys(item.selectedAttributes).flatMap((cat_name) =>
              item.selectedAttributes[cat_name].map((attr) => ({
                id: attr.id,
                name: attr.name,
                value: attr.value,
                cat_id: attr.cat_id,
                cat_selection_type: attr.cat_selection_type,
                cat_name: cat_name,
              }))
            )
          )
        : null;

      return {
        product_id: Number(item.productId),
        brand_id: item.brand_id,
        brand_cgst: item.brand_cgst,
        brand_sgst: item.brand_sgst,
        brand_vat: item.brand_vat,
        product_size: item.size,
        quantity: Number(item.quantity),
        product_instruction:
          item.product_instruction && item.product_instruction.trim() !== ""
            ? item.product_instruction
            : null,
        food_type_one:
          item.food_type_one &&
          item.food_type_one.trim() !== "" &&
          item.food_type_one.toUpperCase() !== "NULL"
            ? item.food_type_one
            : "0",
        food_type_two:
          item.food_type_jain_regular &&
          item.food_type_jain_regular.trim() !== "" &&
          item.food_type_jain_regular.toUpperCase() !== "NULL"
            ? item.food_type_jain_regular
            : null,
        product_price: Number(item.price),
        attribute_data: attributeData,
        map_table_id:
          Data && Data?.data && item.map_table_id > addedProducts.length
            ? item.map_table_id
            : 0,
        purchase_price: item?.purchase_price ? item.purchase_price : 0,
      };
    });

    const groupedByBrandId = requestProductData.reduce((acc, product) => {
      const {
        brand_id,
        brand_cgst,
        brand_sgst,
        product_price,
        quantity,
        brand_vat,
        ...productData
      } = product;
      let brandEntry = acc.find(
        (entry) => entry.brand_id.toString() === brand_id.toString()
      );

      let totalPriceForProduct = quantity * product_price;
      let totalTax =
        (totalPriceForProduct *
          (Number(brand_cgst) + Number(brand_sgst) + Number(brand_vat))) /
        100;
      let totalPriceWithTax = totalPriceForProduct + totalTax;

      if (!brandEntry) {
        brandEntry = {
          brand_id: brand_id.toString(),
          p_total_amount: 0,
          p_total_tax_amount: 0,
          p_delivery_charges:
            deliveryCharge && deliveryCharge !== "" && deliveryCharge > 0
              ? deliveryCharge
              : 0,
          p_discount_id: 0,
          p_discount_code: "",
          p_discount_amount: 0,
          product_data: []
        };
        acc.push(brandEntry);
      }

      brandEntry.product_data.push({ ...productData, quantity, product_price });

      brandEntry.p_total_amount += totalPriceWithTax;
      brandEntry.p_total_tax_amount += totalTax;

      return acc;
    }, []);

    let discountPerBrand = 0;

    if (discountDetail?.brand_ids) {
      const brandIdArray = discountDetail?.brand_ids.split(",").map((id) => id.trim());
      const matchingBrandsCount = groupedByBrandId.filter((brandEntry) =>
        brandIdArray.includes(brandEntry.brand_id)
      ).length;
      discountPerBrand = discountAmt / matchingBrandsCount || 0;
    } else {
      discountPerBrand = discountAmt / groupedByBrandId.length || 0;
    }

    // Apply delivery charges and discounts once per brand
    groupedByBrandId.forEach((brandEntry) => {
      if (brandEntry.p_delivery_charges) {
        brandEntry.p_total_amount += brandEntry.p_delivery_charges;
      }

      const isEligibleForDiscount =
      !discountDetail?.brand_ids || discountDetail?.brand_ids.split(",").includes(brandEntry.brand_id);
  
    if (isEligibleForDiscount) {
      brandEntry.p_total_amount -= discountPerBrand;
      brandEntry.p_discount_amount = discountPerBrand;
      brandEntry.p_discount_id = discountID && discountID ? discountID : 0;
      brandEntry.p_discount_code = appliedDiscountCode && appliedDiscountCode !== "" ? appliedDiscountCode : "";
      brandEntry.p_discount_amount = discountPerBrand;
    }
      // const discountPerBrand = discountAmt / groupedByBrandId.length || 0;
      // if (discountPerBrand) {
      //   brandEntry.p_total_amount -= discountPerBrand;
      //   brandEntry.p_discount_amount = discountPerBrand;
      // }
    });

    //here
    const requestBody = {
      ...(Data && Data.data && { p_id: Data.data.id }),
      p_mobile_no: Number(values.mobile),
      p_first_name: values.firstName,
      p_last_name: values.lastName,
      ...(values.tableNo !== "" &&
        values.tableNo !== null && { p_table_no: Number(values.tableNo) }),
      p_instruction: message ? message : "",
      p_address_id:
        Data && Data?.data && Data.data.address_id
          ? Data.data.address_id
          : null,
      p_orderData: groupedByBrandId,
      p_status: values.status,
      p_type: values.dineIn,
      p_payment: values.payment,
      p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // p_delivery_charges: deliveryCharge,
      // p_discount_id: discountID,
      // p_discount_code: appliedDiscountCode,
      // p_discount_amount: discountAmt,
      // p_total_amount:totalAmountData,
      // p_total_tax_amount: totalTaxAmount,
    };
    
    if (Data && Data.data) {
      handlesaveClick(
        dispatch,
        placeOrderService(requestBody, token, "update", navigate, orderPage),
        "update"
      );
    } else {
      dispatch(placeOrderService(requestBody, token, "add", navigate));
    }
  };

  const [productOptions, setProductOptions] = useState([]);

  const quantityOptions = [];

  for (let i = 1; i <= 100; i++) {
    quantityOptions.push({ value: i.toString(), label: i.toString() });
  }

  const dineOptions = [
    { value: "0", label: "Dine In" },
    { value: "1", label: "Take Away" },
    { value: "2", label: "Delivery" },
  ];

  const [inputData, setInputData] = useState("");
  const [brandData, setBrandData] = useState([]);

  const [initialValues, setInitialValues] = useState({
    mobile: "",
    firstName: "",
    lastName: "",
    tableNo: "1",
    product: "",
    size: "",
    price: "",
    quantity: "",
    dineIn: "0",
    status: "1",
    payment: "0",
    discount: "",
  });

  const paymentOption = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Paid" },
  ];

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      mobile: Yup.string()
        .required(ValidationMessage.MOBILE_REQUIRED)
        .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
      firstName: Yup.string().required(ValidationMessage.FIRST_NAME_REQUIRED),
      lastName: Yup.string().required(ValidationMessage.LAST_NAME_REQUIRED),
      dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
      payment: Yup.string().required("Payment is required!"),
    })
  );

  // changes by sakshi
  const handleSelectedAttributes = (data) => {
    // Function to check if data is grouped
    const isGrouped = (data) => {
      return (
        typeof data === "object" &&
        !Array.isArray(data) &&
        Object.keys(data).length > 0
      );
    };

    // Group data if it's not already grouped
    const groupedAttributes = isGrouped(data)
      ? data
      : data.reduce((acc, attr) => {
          const { cat_name } = attr;

          if (!acc[cat_name]) {
            acc[cat_name] = [];
          }

          acc[cat_name].push(attr);
          return acc;
        }, {});

    return groupedAttributes;
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchOrderDetail();
    }
    // eslint-disable-next-line
  }, [Data, totalProduct]);

  const fetchOrderDetail = () => {
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      // changes by sakshi discount
      setAppliedDiscountCode(Data.data.Discount_Code);
      setDiscountAmt(Data.data.total_discount_amount);
      setDiscountID(Data.data.discount_id);

      const filteredProductData = Data.data.productData.map((addedProduct) => {
        const product = totalProduct.items[0].data.find(
          (product) => product.productId === addedProduct.productId
        );

        return {
          ...product,
          quantity: addedProduct.quantity,
          food_type_one:
            addedProduct?.food_type_1 &&
            addedProduct?.food_type_1 !== "" &&
            addedProduct?.food_type_1 !== "null" &&
            addedProduct?.food_type_1 !== "NULL"
              ? addedProduct.food_type_1
              : "0",
          food_type_jain_regular:
            addedProduct?.food_type_2 &&
            addedProduct.food_type_2 !== "" &&
            addedProduct.food_type_2 !== "null" &&
            addedProduct.food_type_2 !== "NULL"
              ? addedProduct.food_type_2
              : "2",
          product_instruction:
            addedProduct?.product_instruction &&
            addedProduct.product_instruction !== "" &&
            addedProduct.product_instruction !== "NULL" &&
            addedProduct.product_instruction !== "null"
              ? addedProduct.product_instruction
              : null,
          // changes by sakshi
          selectedAttributes: addedProduct?.product_attribute
            ? handleSelectedAttributes(addedProduct.product_attribute)
            : null,
          price: addedProduct.price,
          map_table_id: addedProduct?.map_table_id
            ? addedProduct.map_table_id
            : 0,
        };
      });
      setAddedProducts(filteredProductData);

      setMessage(Data.data.instructions);

      setInitialValues({
        mobile: Data.data.phoneNumber,
        firstName: Data.data.firstName,
        lastName: Data.data.lastName,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Data.data.tableNo : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: Data.data.payment === "1" ? "1" : "0",
        discount: Data.data.discount_id
      });
    } else {
      setInitialValues({
        mobile: Data.data.phoneNumber,
        firstName: Data.data.firstName,
        lastName: Data.data.lastName,
        dineIn: Data.data.type,
        tableNo: Data.data.type === "0" ? Data.data.tableNo : "",
        status: Data.data.orderStatus,
        product: "",
        size: "",
        price: "",
        quantity: "",
        payment: "0",
        discount: ""
      });
      // setTotalAmount(0);
      setUpdatedTotalAmt(0);
    }
  };

  const changeDineInData = (value, setFieldValue) => {
    if (value === "0") {
      setValidationSchema(
        Yup.object({
          mobile: Yup.string()
            .required(ValidationMessage.MOBILE_REQUIRED)
            .max(ValidationMessage.MOBILE_LENGTH)
            .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
          firstName: Yup.string().required(
            ValidationMessage.FIRST_NAME_REQUIRED
          ),
          lastName: Yup.string().required(ValidationMessage.LAST_NAME_REQUIRED),
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!")
        })
      );
    } else {
      setValidationSchema(
        Yup.object({
          mobile: Yup.string()
            .required(ValidationMessage.MOBILE_REQUIRED)
            .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
          firstName: Yup.string().required(
            ValidationMessage.FIRST_NAME_REQUIRED
          ),
          lastName: Yup.string().required(ValidationMessage.LAST_NAME_REQUIRED),
          dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          payment: Yup.string().required("Payment is required!")
        })
      );
      setFieldValue("tableNo", "");
    }
  };

  useEffect(() => {
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_is_active: "1",
      ...(Data && Data.data && { p_brand_id: Data.data.brand_id }),
    };
    getAllProductService(requestBody, token, dispatch, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      totalProduct &&
      totalProduct.items &&
      totalProduct.items.length > 0 &&
      totalProduct.items[0].data &&
      totalProduct.items[0].data.length > 0
    ) {
      if (addedProducts && addedProducts.length > 0) {
        const filteredProductData = totalProduct.items[0].data.filter(
          (product) => {
            const isNotInAddedProducts = !addedProducts.some(
              (addedProduct) => addedProduct.productId === product.productId
            );
            const hasAttributeData =
              product.attribute_data !== null &&
              product.attribute_data !== undefined &&
              product.attribute_data !== "null" &&
              product.attribute_data !== "NULL";

            const jainRegular =
              (product?.jain_or_not === "1" || product?.jain_regular === "0") &&
              product?.veg_nonveg !== "1";

            return hasAttributeData || jainRegular || isNotInAddedProducts;
          }
        );
        if (inputData && inputData !== "") {
          const filteredInputProduct = filteredProductData.filter((product) =>
            product.name.toLowerCase().includes(inputData.toLowerCase())
          );
          setProductOptions(filteredInputProduct);
        } else {
          setProductOptions(filteredProductData);
        }
        const totalAmount = addedProducts.reduce(
          (sum, product) =>
            sum + Number(product.price) * Number(product.quantity),
          0
        );

        setTotalAmount(totalAmount);
        setUpdatedTotalAmt(totalAmount - discountAmt);
      } else {
        if (inputData && inputData !== "") {
          const filteredInputProduct = totalProduct.items[0].data.filter(
            (product) =>
              product.name.toLowerCase().includes(inputData.toLowerCase())
          );
          setProductOptions(filteredInputProduct);
        } else {
          setProductOptions(totalProduct.items[0].data);
        }
        // setProductOptions(totalProduct.items[0].data);
        // setTotalAmount(0);
        setUpdatedTotalAmt(0);
      }
    }
    // eslint-disable-next-line
  }, [totalProduct, addedProducts, inputData]);

  /**
   * Fetching brand data from API
   */
  const fetchBrand = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
      p_is_active: "1",
    };
    getAllBrandService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (totalBrand && totalBrand.items && totalBrand.items.length === 0) {
      fetchBrand();
    } else {
      if (totalBrand.items[0]?.data && totalBrand.items[0].data.length > 0) {
        const newBrand = totalBrand.items[0].data.map((item) => ({
          ...item,
          brandImage: JSON.parse(item.image_data).filter(
            (item) => item.type === 3
          ),
        }));
        setBrandData(newBrand);
      }
    }
    // eslint-disable-next-line
  }, [totalBrand]);

  const onChangeTableNo = (event, values) => {
    if (event.target.value === "") {
      if (values.dineIn === "0") {
        setValidationSchema(
          Yup.object({
            mobile: Yup.string()
              .required(ValidationMessage.MOBILE_REQUIRED)
              .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
            firstName: Yup.string().required(
              ValidationMessage.FIRST_NAME_REQUIRED
            ),
            lastName: Yup.string().required(
              ValidationMessage.LAST_NAME_REQUIRED
            ),
            dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            tableNo: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            payment: Yup.string().required("Payment is required!")
          })
        );
      } else {
        setValidationSchema(
          Yup.object({
            mobile: Yup.string()
              .required(ValidationMessage.MOBILE_REQUIRED)
              .matches(/^[9876]\d{9}$/, "Mobile number is invalid!"),
            firstName: Yup.string().required(
              ValidationMessage.FIRST_NAME_REQUIRED
            ),
            lastName: Yup.string().required(
              ValidationMessage.LAST_NAME_REQUIRED
            ),
            dineIn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            payment: Yup.string().required("Payment is required!")
          })
        );
      }
    }
  };

  const handlePhoneChange = (event, setFieldValue) => {
    const newValue = event.target.value.replace(/[^0-9\s]/g, "");
    if (newValue) {
      const trimmedValue = newValue.slice(0, 10);
      if (trimmedValue.length === 10) {
        getUserDetailService(trimmedValue, dispatch).then((res) => {
          if (
            res &&
            (res.status === 200 ||
              res.status === "200" ||
              res.status === "success")
          ) {
            setFieldValue("firstName", res.data.firstName);
            setFieldValue("lastName", res.data.lastName);
            // setFieldValue('email', res.data.email);
          }
        });
      } else {
        setFieldValue("firstName", "");
        setFieldValue("lastName", "");
      }
    }
  };

  const clickBrand = (brand) => {
    if (activeIndex === brand?.brandId) {
      const requestBody = {
        p_skip: 0,
        p_take: 5000,
        p_is_active: "1",
      };
      getAllProductService(requestBody, token, dispatch, true);
      setActiveIndex("");
      return;
    }
    const requestBody = {
      p_skip: 0,
      p_take: 5000,
      p_is_active: "1",
      p_brand_id: brand?.brandId,
    };
    getAllProductService(requestBody, token, dispatch, true);
    setActiveIndex(brand?.brandId);
  };

  useEffect(() => {
    if (addedProducts && addedProducts.length > 0) {
      const total = addedProducts.reduce(
        (sum, product) => sum + product.price * product.quantity,
        0
      );
      const totalTax = addedProducts.reduce(
        (sum, product) =>
          sum +
          (product.price *
            product.quantity *
            ((product.brand_cgst ? Number(product.brand_cgst) : 0) +
              (product.brand_sgst ? Number(product.brand_sgst) : 0) +
              (product.brand_vat ? Number(product.brand_vast) : 0))) /
            100,
        0
      );

      let discount = 0;

      // if (discountAmt && discountAmt > 0) {
      //   discount = discountAmt;
      // } else {
      //   discount = 0;
      // }

      if (discountAmt && discountAmt > 0) {
        if(discountDetail?.brand_ids){
          const brandIdArray = discountDetail?.brand_ids.split(",").map(id => id.trim());
  
          // Apply discount only to the products of the specific brands
          const brandSpecificTotal = addedProducts
            .filter((product) => brandIdArray.includes(product.brand_id.toString()))
            .reduce(
              (sum, product) => sum + product.price * product.quantity,
              0
            );
    
          discount = Math.min(discountAmt, brandSpecificTotal);
        }
        else {
          discount = discountAmt
        }
      }
      else {
        discount = 0;
      }

      setTotalAmount(total + totalTax + deliveryCharge - discount);
      setUpdatedTotalAmt(total + totalTax + deliveryCharge - discount);
      // setTotalTaxAmount(totalTax);
      setDeliveryCharge(deliveryCharge);
      // setDiscountAmt(discount);
    } else {
      setTotalAmount(0);
      setUpdatedTotalAmt(0);
      // setTotalTaxAmount(0);
      setDeliveryCharge(deliveryCharge);
      setDiscountAmt(0);
    }
    // eslint-disable-next-line
  }, [addedProducts, discountAmt]);
  
  /**
   * Set discount
   * @param {*} value 
   * @param {*} setFieldValue 
   * @returns 
   */
  const onSelectDiscount = async (value, setFieldValue) => {
    if (addedProducts.length > 0) {
      // let amt = totalAmountData;
      const discountData = await getDiscountById(value, token, dispatch);
      if(discountData?.status && (discountData.status === 200 || discountData.status === '200' || discountData.status === 'success' || discountData.status === 'Success')) {
        setDiscountDetail(discountData.data); 
        setDiscountID(discountData.data?.id || null);
        const discountCode = discountData.data?.code || null;
        let discountAmt = 0;
        if (
          discountData.data?.discount_type &&
          totalAmountData >= discountData.data?.min_cart_value
        ) {
          if (discountData.data.discount_type === "1") {
            let discountCalc =
              (totalAmountData * discountData.data.discount_amount) / 100 >
              discountData.data.max_discount_ammount
                ? discountData.data.max_discount_ammount
                : (totalAmountData * discountData.data.discount_amount) / 100;
  
            discountAmt = discountCalc;
          } else {
            discountAmt = discountData.data.discount_amount;
          }
        } else {
          toast.warn(
            `Discount is only applicable for minimum amount of ₹${discountData.data?.min_cart_value}`
          );
          setFieldValue("discount", '');
          return;
        }
        setDiscountAmt(discountAmt);
        setAppliedDiscountCode(discountCode);
      }
    } else {
      setFieldValue("discount", "");
      setTotalAmount(0);
      setUpdatedTotalAmt(0);
    }
  };

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    Order
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/orders")}>
                    Order
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    Order
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              Customer Mobile
                              <span className="required-label"></span>
                            </h4>
                          </div>
                          <Input
                            type="text"
                            name="mobile"
                            label="Mobile Number"
                            placeholder="Enter Mobile Number"
                            className="add-roles"
                            maxLength={10}
                            Regex={/[^0-9\s]/g}
                            value={values.mobile}
                            hidelength="true"
                            disabled={Data && Data.isEdit === false}
                            onInput={(event) =>
                              handlePhoneChange(event, setFieldValue)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              First Name
                              <span className="required-label"></span>
                            </h4>
                          </div>
                          <Input
                            type="text"
                            name="firstName"
                            label="Name"
                            placeholder="Enter First Name"
                            className="add-roles"
                            Regex={/[^a-zA-z\s]/g}
                            value={values.firstName}
                            hidelength="true"
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              Last Name
                              <span className="required-label"></span>
                            </h4>
                          </div>
                          <Input
                            type="text"
                            name="lastName"
                            label="Name"
                            placeholder="Enter Last Name"
                            className="add-roles"
                            Regex={/[^a-zA-z\s]/g}
                            value={values.lastName}
                            hidelength="true"
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          values.dineIn === "0" ? "col-md-3" : "col-md-4"
                        }
                      >
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              Dine In / Take Away
                              <span className="required-label"></span>
                            </h4>
                          </div>
                          <Field
                            className="select-menu mb-2"
                            name="dineIn"
                            options={dineOptions}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            changeData={(value) =>
                              changeDineInData(value, setFieldValue)
                            }
                            value={
                              values.dineIn === "" || values.dineIn === null
                                ? false
                                : values.dineIn
                            }
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                      {values.dineIn === "0" && (
                        <div className="col-md-3">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">
                                Table Number
                                <span className="required-label"></span>
                              </h4>
                            </div>
                            <Input
                              type="text"
                              name="tableNo"
                              label="Table"
                              placeholder="Enter Table Number"
                              isRequired={true}
                              Regex={/[^0-9\s]/g}
                              maxLength={7}
                              hidelength={"true"}
                              onInput={(event) =>
                                onChangeTableNo(event, values)
                              }
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          values.dineIn === "0" ? "col-md-3" : "col-md-4"
                        }
                      >
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              Payment
                              <span className="required-label"></span>
                            </h4>
                          </div>
                          <Field
                            className="select-menu mb-2"
                            name="payment"
                            options={paymentOption}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            changeData={(value) =>
                              setFieldValue("payment", value)
                            }
                            value={
                              values.payment === "" || values.payment === null
                                ? false
                                : values.payment
                            }
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          values.dineIn === "0" ? "col-md-3" : "col-md-4"
                        }
                      >
                        <div className="name-type">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              Discount
                              {/* <span className="required-label"></span> */}
                            </h4>
                          </div>
                          <Field
                            className="select-menu mb-2"
                            name="discount"
                            options={discountOption}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            changeData={(value) =>
                              onSelectDiscount(value, setFieldValue)
                            }
                            value={
                              values.discount === "" || values.discount === null
                                ? false
                                : values.discount
                            }
                            disabled={Data && Data.isEdit === false}
                          />
                          {discountID ? (
                            <Button name="Remove discount" type="button" onClick={() => {setFieldValue('discount', ''); setDiscountID(null); setDiscountDetail(null); setDiscountAmt(0)}}></Button>
                          ): ''}
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            {/* SearchBar */} <br />
                          </h4>
                        </div>
                        <div className="search-bar">
                          <SearchBar
                            inputData={inputData}
                            setInputData={setInputData}
                          />
                        </div>
                      </div>
                    </div>

                    <br />

                    <div className="header-logo">
                      {brandData && brandData.length > 1 && !Data && (
                        <>
                          {brandData.map((item, index) => (
                            <Link key={index} onClick={() => clickBrand(item)}>
                              {item?.brandImage &&
                                item.brandImage.length > 0 && (
                                  <>
                                    <div className="brand-img">
                                      <img
                                        src={
                                          API_URL.TOKEN_URL +
                                          item.brandImage[0]?.url
                                        }
                                        alt={item?.name}
                                        className={
                                          activeIndex === item?.brandId
                                            ? "brand-active"
                                            : "brand-logo"
                                        }
                                      />
                                    </div>
                                  </>
                                )}
                              {totalBrand.items[0].data.length > 1 && (
                                <p className="name-class">{item?.name}</p>
                              )}
                            </Link>
                          ))}
                        </>
                      )}
                    </div>
                    {productOptions && productOptions.length > 0 ? (
                      <div className={`col-lg-8 col-md-6 col-sm-12 col-12`}>
                        <div className="product-order">
                          <div className="row">
                            {productOptions.map((item, index) => (
                              <div
                                className="col-lg-4 col-md-6  col-12"
                                key={index}
                              >
                                {/* OrderCart */}
                                <OrderCart
                                  productData={item}
                                  setAddedProducts={setAddedProducts}
                                  showAddButton={true}
                                  addedProducts={addedProducts}
                                  productOptions={productOptions}
                                  setProductOptions={setProductOptions}
                                  disabled={Data && Data.isEdit === false}
                                  setFieldValue={setFieldValue}
                                  setUpdatedTotalAmt={setUpdatedTotalAmt}
                                  setDiscountAmt={setDiscountAmt}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={`col-lg-8 col-md-6 col-sm-12 col-12`}>
                        <div className="product-order">
                          <div className="row">
                            <div className="col-lg-4 col-md-6  col-12">
                              <b>No Product Found!</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 position-relative">
                      {addedProducts && addedProducts.length > 0 && (
                        <>
                          <div className="new-order-detail">
                            <h6>Order Details</h6>
                            {addedProducts.map((item, index) => (
                              <div key={index}>
                                <OrderCart
                                  productData={item}
                                  showImage={false}
                                  showAddButton={false}
                                  showMessageInput={true}
                                  addedProducts={addedProducts}
                                  setAddedProducts={setAddedProducts}
                                  productOptions={productOptions}
                                  setProductOptions={setProductOptions}
                                  showRadio={true}
                                  disabled={Data && Data.isEdit === false}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="pb-3">
                            <div className="message-input">
                              <textarea
                                value={message}
                                placeholder="Would you like to add generalized instruction to chef 🧐?"
                                onChange={(event) =>
                                  setMessage(event.target.value)
                                }
                                disabled={Data && Data.isEdit === false}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div className="row total-amount-btn">
                        <div className="col-lg-7 col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h5 className="paragraph-title">
                                Total Amount :{" "}
                                {authuserData?.data?.symbol
                                  ? authuserData.data.symbol
                                  : "₹"}
                                {updatedTotalAmt !== 0
                                  ? updatedTotalAmt
                                  : totalAmountData}
                              </h5>
                            </div>
                          </div>
                        </div>
                        {((Data && Data.isEdit === true) || !Data) && (
                          <div className="col-lg-5 col-md-6">
                            <div className="paragraph-editor">
                              <div className="btn-submit">
                                <Button
                                  type="submit"
                                  name="place Order"
                                  className="btn login-btn place-order-btn"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
