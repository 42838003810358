const ServiceProviderInitialState = { 
    items: []
};

/**
 * Store list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const ServiceProviderReducer = (state = ServiceProviderInitialState, action) => {
    switch (action.type) {
            case 'GET_SERVICE_PROVIDER_DATA':
                return {
                    ...state,
                    items: [action.payload]
                }
            case 'CLEAR_SERVICE_PROVIDER_DATA':
                return ServiceProviderInitialState;
        default:
            return state;
      };
}

const totalServiceProviderInitialState = {
    items: []
}

/**
 * Store total list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalServiceProviderReducer = (state = totalServiceProviderInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_SERVICE_PROVIDER':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_SERVICE_PROVIDER':
            return totalServiceProviderInitialState
        default:
            return state;
      };
}