import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ProfileIcon from "../../assets/images/profile-icon.jpg";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogoutClick } from "../Alertmessage/Alertmessage";
import Notification from "../Notification/Notification";
import { store } from "../../redux/Store";
import { FaShoppingCart } from "react-icons/fa";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebar = useSelector(state => state.sidebar?.items);
  const order = useSelector(state => state.order);

  /**
   * Dispatching logout action
   */
  const onLogout = () => {
    handleLogoutClick(dispatch, navigate, store);
  };

  return (
    <>
      <div className="header-dropdown">
        <div>
          {!sidebar &&
            <></>
            // <h1 className="logo">
            //   <img src={Logo} alt='Logo' />
            // </h1>
          }
        </div>
        {/*Notification Data show*/}
        <div className="d-flex align-items-center">
          <div className="shopping-icon">
            <FaShoppingCart onClick={() => navigate('/orders')}/>
            {order?.dataCount > 0 && (
              <div className="shopping-count">
                <span>{order.dataCount}</span>
              </div>
            )}
          </div>
          <Notification />
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="profile">
                <img src={ProfileIcon} alt="" />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate("/profile")}>
                My Profile
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate("/settings")}>
                Settings
              </Dropdown.Item>
              <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}