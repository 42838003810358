const customerInitialState = { 
    items: []
};

/**
 * Store CUSTOMER list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const CustomerReducer = (state = customerInitialState, action) => {
    switch (action.type) {
        case 'GET_CUSTOMER_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_CUSTOMER_DATA':
            return customerInitialState;
        default:
            return state;
      };
}

const totalCustomerInitialState = {
    items: []
}

/**
 * Store total CUSTOMER list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalCustomerReducer = (state = totalCustomerInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_CUSTOMER':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_CUSTOMER':
            return totalCustomerInitialState
        default:
            return state;
      };
}