import { Dispatch } from "redux";
import { AddUpdateProductRequest, getAllProductRequest, getAllProductResponse, getProductByIdResponse } from "./ProductModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearProductData, clearTotalProductData, getProductData, getTotalProductData } from "../../../redux/Actions/productAction/ProductAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { clearProductPageData, getProductPageData } from "../../../redux/Actions/productAction/ProductPageAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearNotificationData } from "../../../redux/Actions/NotificationAction/NotificationAction";

let errorShow = false;

/*
 * Service to get product list
*/
export const getAllProductService = (
  productListRequest: getAllProductRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<getAllProductResponse> => {
  return new Promise<getAllProductResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (productListRequest.p_skip !== undefined && productListRequest.p_skip !== null) {
        queryParams += `p_skip=${productListRequest.p_skip}&`;
      }
      if (productListRequest.p_take !== undefined && productListRequest.p_take !== null) {
        queryParams += `p_take=${productListRequest.p_take}&`;
      }
      if (productListRequest.p_name !== undefined && productListRequest.p_name !== null) {
        queryParams += `p_name=${productListRequest.p_name}&`;
      }
      if (productListRequest.p_brand_id !== undefined && productListRequest.p_brand_id !== null) {
        queryParams += `p_brand_id=${productListRequest.p_brand_id}&`;
      }
      if (productListRequest.p_category_id !== undefined && productListRequest.p_category_id !== null) {
        queryParams += `p_category_id=${productListRequest.p_category_id}&`;
      }
      if (productListRequest.p_sub_category_id !== undefined && productListRequest.p_sub_category_id !== null) {
        queryParams += `p_sub_category_id=${productListRequest.p_sub_category_id}&`;
      }
      if (productListRequest.p_leaf_level_category_id !== undefined && productListRequest.p_leaf_level_category_id !== null) {
        queryParams += `p_leaf_level_category_id=${productListRequest.p_leaf_level_category_id}&`;
      }
      if (productListRequest.p_tag_id !== undefined && productListRequest.p_tag_id !== null) {
        queryParams += `p_tag_id=${productListRequest.p_tag_id}&`;
      }
      if (productListRequest.p_sku_code !== undefined && productListRequest.p_sku_code !== null) {
        queryParams += `p_sku_code=${productListRequest.p_sku_code}&`;
      }
      if (productListRequest.p_is_active !== undefined && productListRequest.p_is_active !== null) {
        queryParams += `p_is_active=${productListRequest.p_is_active}&`
      }
      if (productListRequest.p_IsDeleted !== undefined && productListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${productListRequest.p_IsDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.PRODUCT + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(getTotalProductData(response));
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getProductData(response));
        }
        else {
          dispatch(getProductData(response));
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Product permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};


/*
 * Service for adding and updating product
*/
export const addUpdateProductService = (
  addUpdateProductRequest: AddUpdateProductRequest,
  token: string, type: string, navigate?: any, pageNumber?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.PRODUCT, token, addUpdateProductRequest);
      }
      if (type === 'update') {
        response = await putApiService(API_URL.BASE_URL + API_URL.PRODUCT, token, addUpdateProductRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success') && response.message !== 'Product SKU code should be Unique') {
        dispatch(clearProductData());
        dispatch(clearTotalProductData());
        if (type === 'add') {
          dispatch(clearProductPageData());
          toast.success('Product added successfully!');
          navigate('/product');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            toast.success('Product undeleted successfully!');
          }
          else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getProductPageData(pageNumber+1));
              }
              else {
                dispatch(clearProductPageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: "Product updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/product');
              });
            }
            else {
              toast.success('Product status updated successfully!');
            }
          }
        }
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getProductPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service to get individual product
*/
export const getProductById = (
  id: string, token: string,
  dispatch: Dispatch): Promise<getProductByIdResponse> => {
  return new Promise<getProductByIdResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      const response = await getApiService(API_URL.BASE_URL + API_URL.PRODUCT + '/' + id, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        resolve(response);
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for deleting product
*/
export const deleteProductService = (deleteId: number, token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.PRODUCT + '/' + deleteId, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearProductData());
        dispatch(clearTotalProductData());
        toast.success('Product deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getProductPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for uploading excel
*/
export const uploadProductExcelService = (
    excelRequest: any,
    token: string,
    setIsModalOpen: any
  ) => {
    return async (dispatch: Dispatch) => {
      const formData = new FormData();
      formData.append('excel', excelRequest);
  
      try {
        dispatch(setLoader());
        const response = await fetch(API_URL.BASE_URL + API_URL.PRODUCT_UPLOAD, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + token
          }, body: formData,
        });
        if (!response.ok) {
          const data = await response.json();
          toast.error(data?.message ? data.message : ValidationMessage.SOMETHING_WENT_WRONG);
          if(data?.message && data.message === 'Unauthorized access!'){
            removePersistStorage(dispatch);
          }
          return data;
          // throw new Error('Network response was not ok');
        }
        else {
          const data = await response.json();
          toast.success(data?.message ? data.message : 'Product imported successfully');
          dispatch(clearNotificationData());
          setIsModalOpen(false);
          return data;
        }
      } catch (error) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      } finally {
        errorShow = false;
        dispatch(clearLoader());
      }
    }
  }