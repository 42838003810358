/**
 * Set CUSTOMER page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getCustomerPageData = (page) => ({
    type: 'GET_CUSTOMER_PAGE',
    payload: page
})

/**
 * Clear CUSTOMER page data
 * @returns {*}
 */
export const clearCustomerPageData = () => ({
    type: 'CLEAR_CUSTOMER_PAGE'
})