import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTable from '../../components/Table/Table';
import ExcelData from '../../services/Regular-services/ExcelExport';


export default function ImportData() {

    const [columns, setColumns] = useState([]);

    const navigate = useNavigate();

    //Getting data from disount-list and setting accordingly
    const location = useLocation();
    const { Data } = location.state || {};

    //Show keys which is to be displayed in table
    const [showKey, setShowKey] = useState({});

    const [heading, setHeading] = useState('');

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (Data && Data.data && Data.data.description) {
            let newData = [];
            try {
                newData = JSON.parse(Data.data.description);
            } catch (error) {
                newData = [];
            }
            const columnNames = newData && newData.length > 0 ? Object.keys(newData[0]) : [];
            const formattedData = columnNames.map(key => ({ name: key }));
            setColumns(formattedData);
            const showKey = {};
            formattedData.forEach(column => {
                showKey[column.name] = true;
            });
            setShowKey(showKey);

            let text = Data.data.title;
            const myArray = text.split(" Imported Successfully." || " imported successfully.");
            let word = myArray[0];
            setHeading(word);
            setTableData(newData);
        }
    }, [Data])

    return (
        <>
            <div className='main-section'>
                <div className='sidebar-content'>
                    <Sidebar />
                </div>
                <div className='body-content'>
                    <Header />
                    <div className='table-body-content'>
                        <div className='roles'>
                            <div>
                                <div className='title'>
                                    <h4>Result Data of {heading} Import</h4>
                                </div>
                                <Breadcrumb>
                                    <Breadcrumb.Item onClick={() => navigate('/home')}>Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Result Data of {heading} Import</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className='btn-groups'>
                                <ExcelData excelData={tableData} fileName={`import-${Data.data.Name}-data`} />
                            </div>
                        </div>
                        <CustomTable showId={true} showDescription={true} page='import' showKey={showKey} showTooltip={true} data={tableData} heading={columns} />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}