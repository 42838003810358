import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/Table/Table";
import "../Imagemanager/Imagemanager.scss";
import { useDispatch, useSelector } from "react-redux";
import Addimage from "./Add-image/Addimage";
import Filter from "../../components/Filter/Filter";
import { FaFilter, FaPlus } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { GrMoreVertical } from "react-icons/gr";
import ExcelData from "../../services/Regular-services/ExcelExport";
import { getAllImageService } from "./ImageService/ImageService";
import UtilsData, { imageTypeOptions } from "../../Utils/Utils";
import CustomPagination from "../../components/Pagination/Pagination";
import {
  clearImagePageData,
  getImagePageData,
} from "../../redux/Actions/imageGalleryAction/ImageGalleryPageAction";
import { clearImageFilterData } from "../../redux/Actions/imageGalleryAction/ImageFilterAction";
import { clearImageData } from "../../redux/Actions/imageGalleryAction/ImageGallaryAction";

//Show keys which is to be displayed in table
const showKey = {
  imageTypeName: true,
};

export default function Imagemanager() {
  const navigate = useNavigate();
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [imageActionData, setimageActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === window.location.pathname.slice(1)
      );
      setimageActionData(actionData);
    }
  }, [sideBarData]);

  const [columns, setColumns] = useState([]);
  const [showActionBool, setShowActionBool] = useState(false);

  useEffect(() => {
    if (
      imageActionData[0]?.view &&
      imageActionData[0].view === "0" &&
      imageActionData[0]?.edit &&
      imageActionData[0].edit === "0" &&
      imageActionData[0]?.add &&
      imageActionData[0].add === "0" &&
      imageActionData[0]?.delete &&
      imageActionData[0].delete === "0"
    ) {
      setColumns([
        {
          name: "Banner image",
          selector: (row) => row.banner,
        },
        {
          name: "Image type",
          selector: (row) => row.Imagetype,
        },
      ]);
      setShowActionBool(false);
    } else {
      setShowActionBool(true);
      setColumns([
        {
          name: "Banner image",
          selector: (row) => row.banner,
        },
        {
          name: "Image type",
          selector: (row) => row.Imagetype,
        },
        {
          name: "Actions",
          selector: (row) => row.edit,
        },
      ]);
    }
  }, [imageActionData]);

  //Show actions which is to be displayed in table
  const showActions = {
    showView:
      (imageActionData[0]?.view && imageActionData[0].view === "1") ||
      !imageActionData
        ? false
        : false,
    showEdit:
      (imageActionData[0]?.edit && imageActionData[0].edit === "1") ||
      !imageActionData
        ? true
        : false,
    showDelete:
      (imageActionData[0]?.delete && imageActionData[0].delete === "1") ||
      !imageActionData
        ? true
        : false,
    showUndo:
      (imageActionData[0]?.edit && imageActionData[0].edit === "1") ||
      !imageActionData
        ? true
        : false,
    navigate:
      (imageActionData[0]?.edit && imageActionData[0].edit === "1") ||
      !imageActionData
        ? "/edit-leaf-level-category"
        : false,
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  //Getting data from reducer
  const imageData = useSelector((state) => state.imageGallery); //Reducer for image data
  const imagePage = useSelector((state) => state.imagePage.items); //Reducer for image page data
  const imageFilter = useSelector((state) => state.imageFilter); //Reducer for image filter data

  const [isToggled, setIsToggled] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const [Data, setData] = useState(null);
  const tokenData = UtilsData();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPage(imagePage);
  }, [imagePage]);

  /**
   * Fetch image detail data
   */
  const fetchImageData = () => {
    if (imagePage > 1) {
      const requestBody = {
        p_skip: (imagePage - 2) * 10,
        p_take: 10,
      };
      if (imageFilter.type !== "" && imageFilter.type !== null) {
        requestBody.p_type_id = imageFilter.type;
      }
      if (imageFilter.isDelete !== "" && imageFilter.isDelete !== null) {
        requestBody.p_IsDeleted = imageFilter.isDelete;
      }
      dispatch(getImagePageData(imagePage - 1));
      getAllImageService(requestBody, tokenData, dispatch);
    } else {
      const requestBody = {
        p_skip: (imagePage - 1) * 10,
        p_take: 10,
      };
      if (imageFilter.type !== "" && imageFilter.type !== null) {
        requestBody.p_type_id = imageFilter.type;
      }
      if (imageFilter.isDelete !== "" && imageFilter.isDelete !== null) {
        requestBody.p_IsDeleted = imageFilter.isDelete;
      }
      dispatch(getImagePageData(imagePage));
      getAllImageService(requestBody, tokenData, dispatch);
    }
  };

  useEffect(() => {
    if (imageData && imageData.items && imageData.items.length === 0) {
      fetchImageData();
    } else {
      if (imageFilter.type !== "" || imageFilter.isDelete !== "") {
        setIsFilter(true);
      }
    }

    if (imageData.items[0]?.data && imageData.items[0].data.length > 0) {
      const transformedImageData = imageData.items[0].data.map((item) => ({
        imageId: item.imageId,
        imagePath: item.imagePath,
        imageType: item.imageType,
        imageTypeName: "",
        createdBy: item.createdBy,
        createdAt: item.createdAt,
        modifiedBy: item.modifiedBy,
        modifiedAt: item.modifiedAt,
        isDeleted: item.isDeleted,
      }));
      for (let i = 0; i < imageData.items[0].data.length; i++) {
        const typeName = imageData.items[0].data[i].imageType;
        const matchingObject = imageTypeOptions.find(
          (obj) => obj.value === typeName
        );
        if (matchingObject) {
          transformedImageData[i].imageTypeName = matchingObject.label;
        }
      }
      setTableData(transformedImageData);
    } else {
      setTableData([]);
    }
    // eslint-disable-next-line
  }, [imageData]);

  /**
   * Setting data on toggle click
   * @param {*} data
   */
  const handleButtonClick = (data) => {
    if (data && data.imageId) {
      setData(data);
      if (isToggled === false) {
        setIsToggled(true);
      } else {
        setIsToggled(false);
        setTimeout(() => {
          setIsToggled(true);
        }, 100);
      }
      document.querySelector(".table-body-content").scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setData(null);
      setIsToggled((prevIsToggled) => !prevIsToggled);
    }
    if (isToggled === false) {
      setIsFilter(false);
    }
  };

  /**
   * Handle filter toggle
   */
  const handleFilterClick = () => {
    if (isFilter === false) {
      setIsToggled(false);
    }
    setIsFilter((prevIsToggled) => !prevIsToggled);
  };

  /**
   * On page change
   * @param {*} pageNumber
   */
  const onPageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setIsToggled(false);
      const requestBody = {
        p_skip: (pageNumber - 1) * 10,
        p_take: 10,
      };
      if (imageFilter.type !== "" && imageFilter.type !== null) {
        requestBody.p_type_id = imageFilter.type;
      }
      if (imageFilter.isDelete !== "" && imageFilter.isDelete !== null) {
        requestBody.p_isDeleted = imageFilter.isDelete;
      }
      setCurrentPage(pageNumber);
      dispatch(getImagePageData(pageNumber));
      getAllImageService(requestBody, tokenData, dispatch);
    }
  };

  /**
   * Clear filter data
   */
  const clearFilters = () => {
    dispatch(clearImageFilterData());
    dispatch(clearImagePageData());
    dispatch(clearImageData());
    setIsFilter(false);
    setIsToggled(false);
  };

  return (
    <>
      <div className="main-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {imageActionData && imageActionData.length > 0
                      ? imageActionData[0].name
                      : "Image Manager"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {imageActionData && imageActionData.length > 0
                      ? imageActionData[0].name
                      : "Image Manager"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-groups">
                {(imageFilter.type !== "" ||
                      imageFilter.isDelete !== "") ? (
                        <Dropdown className="header-dropdown action-btn">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    data-bs-toggle="tooltip"
                    title="Action"
                  >
                    <GrMoreVertical />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {(imageFilter.type !== "" ||
                      imageFilter.isDelete !== "") && (
                      <Dropdown.Item onClick={clearFilters}>
                        Clear Filters
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                      ): <></>}
                <Button
                  onClick={handleFilterClick}
                  className="btn btn-fillter-main"
                  name={
                    <div className="filter">
                      <FaFilter className="filter-icon" />
                    </div>
                  }
                ></Button>
                {imageData &&
                  imageData.items &&
                  imageData.items[0]?.data &&
                  imageData.items[0].data.length > 0 && (
                    <ExcelData
                      excelData={imageData.items[0].data}
                      fileName={"image"}
                    ></ExcelData>
                  )}
                {((imageActionData[0]?.add && imageActionData[0].add === "1") ||
                  !imageActionData) && (
                  <Button
                    onClick={handleButtonClick}
                    className="btn btn-fillter-main"
                    name={
                      <div className="Plus">
                        <FaPlus />
                      </div>
                    }
                  ></Button>
                )}
              </div>
            </div>
            {isToggled && (
              <div>
                <Addimage handleToggle={handleButtonClick} Data={Data} />
              </div>
            )}
            {isFilter && <Filter page="imageManager"></Filter>}
            {imageData &&
              imageData.items &&
              imageData.items[0]?.data &&
              imageData.items[0].data && (
                <>
                  <CustomTable
                    handleToggle={handleButtonClick}
                    page="imageManager"
                    checkBool={false}
                    showKey={showKey}
                    showActions={showActionBool ? showActions : false}
                    showTooltip={true}
                    data={tableData}
                    heading={columns}
                    showImage={true}
                  />
                  {imageData.items[0].total_records >
                    imageData.items[0].data.length && (
                    <>
                      <div className="custom-pagination">
                        <CustomPagination
                          totalRecords={imageData.items[0].total_records}
                          currentPage={currentPage}
                          onPageChange={onPageChange}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}