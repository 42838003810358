// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-image-section .form-floating .upload {
  width: 100% !important;
}
.add-image-section .add-roles-form {
  margin: 0;
}

.name-type {
  width: 100% !important;
}

.add-roles {
  width: 100% !important;
  box-sizing: border-box !important; /* Ensure padding and border are included in the width */
}

.paragraph-editor {
  width: 100% !important;
}

.input-group {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
}

.input-group input {
  flex-grow: 1 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/BrandList/Add-brand/Addbrand.scss"],"names":[],"mappings":"AAGQ;EAEI,sBAAA;AAHZ;AAMI;EACI,SAAA;AAJR;;AAQA;EACI,sBAAA;AALJ;;AAQE;EACE,sBAAA;EACA,iCAAA,EAAA,wDAAA;AALJ;;AAQE;EACE,sBAAA;AALJ;;AAQE;EACE,wBAAA;EACA,8BAAA;EACA,sBAAA;AALJ;;AAQE;EACE,uBAAA;AALJ","sourcesContent":["\n.add-image-section {\n    .form-floating {\n        .upload {\n            \n            width: 100% !important;\n        }\n    }\n    .add-roles-form{\n        margin: 0;\n    }\n}\n\n.name-type {\n    width: 100% !important;\n  }\n  \n  .add-roles {\n    width: 100% !important;\n    box-sizing: border-box !important; /* Ensure padding and border are included in the width */\n  }\n  \n  .paragraph-editor {\n    width: 100% !important;\n  }\n  \n  .input-group {\n    display: flex !important;\n    align-items: center !important;\n    width: 100% !important;\n  }\n  \n  .input-group input {\n    flex-grow: 1 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
