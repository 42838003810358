// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-section .hide-btn {
  right: 5px !important;
  display: flex;
  top: 13px !important;
}

.capital-class {
  text-transform: capitalize;
}

.error-msg {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminUsers/Add-user/Adduser.scss"],"names":[],"mappings":"AACI;EACI,qBAAA;EACA,aAAA;EACA,oBAAA;AAAR;;AAGA;EACI,0BAAA;AAAJ;;AAGA;EACI,UAAA;AAAJ","sourcesContent":[".main-section{\n    .hide-btn{\n        right: 5px !important;\n        display: flex;\n        top: 13px !important;\n    }\n}\n.capital-class{\n    text-transform: capitalize;\n}\n\n.error-msg{\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
