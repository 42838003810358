import { Dispatch } from "redux";
import { DiscountBrandResponse, DiscountListResponse, addUpdateDiscountRequest, discountListRequest, individualDiscountResponse } from "./DiscountModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearDiscountData, clearTotalDiscountData, getDiscountData, getTotalDiscountData } from "../../../redux/Actions/DiscountAction/DiscountAction";
import { getDiscountBrandData } from "../../../redux/Actions/DiscountAction/DiscountBrandAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { clearDiscountPageData, getDiscountPageData } from "../../../redux/Actions/DiscountAction/DiscountPageAction";
import { deleteApiService } from "../../../services/API-services/DeleteService";

let errorShow = false;

/*
 * Service to get discount list
*/
export const getAllDiscountService = (
  discountListRequest: discountListRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<DiscountListResponse> => {
  return new Promise<DiscountListResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (discountListRequest.p_skip !== undefined && discountListRequest.p_skip !== null) {
        queryParams += `p_skip=${discountListRequest.p_skip}&`;
      }
      if (discountListRequest.p_limit !== undefined && discountListRequest.p_limit !== null) {
        queryParams += `p_limit=${discountListRequest.p_limit}&`;
      }
      if (discountListRequest.p_title !== undefined && discountListRequest.p_title !== null) {
        queryParams += `p_title=${discountListRequest.p_title}&`;
      }
      if (discountListRequest.p_discount_code !== undefined && discountListRequest.p_discount_code !== null) {
        queryParams += `p_discount_code=${discountListRequest.p_discount_code}&`;
      }
      if (discountListRequest.p_discount_type !== undefined && discountListRequest.p_discount_type !== null) {
        queryParams += `p_discount_type=${discountListRequest.p_discount_type}&`;
      }
      if (discountListRequest.p_start_date !== undefined && discountListRequest.p_start_date !== null) {
        queryParams += `p_start_date=${discountListRequest.p_start_date}&`;
      }
      if (discountListRequest.p_end_date !== undefined && discountListRequest.p_end_date !== null) {
        queryParams += `p_end_date=${discountListRequest.p_end_date}&`;
      }
      if (discountListRequest.p_discount_type_on !== undefined && discountListRequest.p_discount_type_on !== null) {
        queryParams += `p_discount_type_on=${discountListRequest.p_discount_type_on}&`;
      }
      if (discountListRequest.p_spacific_item !== undefined && discountListRequest.p_spacific_item !== null) {
        queryParams += `p_spacific_item=${discountListRequest.p_spacific_item}&`;
      }
      if (discountListRequest.p_discount_apply_on !== undefined && discountListRequest.p_discount_apply_on !== null) {
        queryParams += `p_discount_apply_on=${discountListRequest.p_discount_apply_on}&`;
      }
      if (discountListRequest.p_IsActive !== undefined && discountListRequest.p_IsActive !== null) {
        queryParams += `p_IsActive=${discountListRequest.p_IsActive}&`
      }
      if (discountListRequest.p_isDeleted !== undefined && discountListRequest.p_isDeleted !== null) {
        queryParams += `p_isDeleted=${discountListRequest.p_isDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.DISCOUNT + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(getTotalDiscountData(response));
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getDiscountData(response));
        }
        else {
          dispatch(getDiscountData(response));
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Discount permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service to get discount brand list
*/
export const getAllDiscountBrandService = (
  token: string, dispatch: Dispatch
): Promise<DiscountBrandResponse> => {
  return new Promise<DiscountBrandResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      const response = await getApiService(API_URL.BASE_URL + API_URL.DISCOUNT_BRAND, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(getDiscountBrandData(response));
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating discount
*/
export const addUpdateDiscountService = (
  addUpdateDiscountRequest: addUpdateDiscountRequest,
  token: string, type: string, navigate?: any, pageNumber?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.DISCOUNT, token, addUpdateDiscountRequest);
      }
      if (type === 'update') {
        response = await putApiService(API_URL.BASE_URL + API_URL.DISCOUNT, token, addUpdateDiscountRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearDiscountData());
        dispatch(clearTotalDiscountData());
        if (type === 'add') {
          dispatch(clearDiscountPageData());
          toast.success('Discount added successfully!');
          navigate('/discount');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            toast.success('Discount undeleted successfully!');
          }
          else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getDiscountPageData(pageNumber + 1))
              }
              else {
                dispatch(clearDiscountPageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: "Discount updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/discount');
              });
            }
            else {
              toast.success('Discount status updated successfully!');
            }
          }
        }
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getDiscountPageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
* Service to get individual discount
*/
export const getDiscountById = (
  id: string, token: string,
  dispatch: Dispatch): Promise<individualDiscountResponse> => {
  return new Promise<individualDiscountResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      const response = await getApiService(API_URL.BASE_URL + API_URL.DISCOUNT + '/' + id, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        resolve(response);
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for deleting discount
*/
export const deleteDiscountService = (deleteId: number, token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.DISCOUNT + '/' + deleteId, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearDiscountData());
        dispatch(clearTotalDiscountData());
        toast.success('Discount deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getDiscountPageData(pageNumber))
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};