// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swal2-actions .swal2-cancel {
  margin: 0 5px;
  background-color: #FF4F2B !important;
}
.swal2-actions .swal2-cancel:focus {
  box-shadow: none;
}
.swal2-actions .btn-success {
  background-color: #1D3B42;
}
.swal2-actions .btn-success:focus {
  box-shadow: none;
}
.swal2-actions .btn {
  width: unset;
}

.swal2-confirm.swal2-styled:focus {
  box-shadow: unset;
}`, "",{"version":3,"sources":["webpack://./src/components/Alertmessage/Alertmessage.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,oCAAA;AADR;AAEQ;EACI,gBAAA;AAAZ;AAGI;EACI,yBCVI;ADSZ;AAEQ;EACI,gBAAA;AAAZ;AAGI;EACI,YAAA;AADR;;AAIA;EACC,iBAAA;AADD","sourcesContent":["@import '../../variables';\n.swal2-actions{\n    .swal2-cancel{\n        margin: 0 5px;\n        background-color: $primary-color !important;\n        &:focus {\n            box-shadow: none;\n        }\n    }\n    .btn-success{\n        background-color: $main-color;\n        &:focus {\n            box-shadow: none;\n        }\n    }\n    .btn{\n        width: unset;\n    }\n}\n.swal2-confirm.swal2-styled:focus {\n\tbox-shadow: unset;\n}","$main-color:#1D3B42;\n$primary-color:#FF4F2B;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$successColor:#28a745;\n$dangerColor:#dc3545;\n$border-color:#DEE2E6;\n$red-color: #ff0000;\n$star-rating: #e1c100;\n$disabled-bg:#e9ecef;\n$gray:#E6E6E6;\n$lightLIGHTGRAY:#aaa;\n$orange-color:#008dff;\n$completed:#22bb33;\n$Confirmed:#fcaa11;\n$In-progress:#5bc0de;\n$Pending:#deb887;\n$Cancelled:#bb2124;\n$bespeakk-color:#e25233;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
