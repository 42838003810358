import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Toggle from "../../../components/Toggle/Toggle";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AddDiscount.scss";
import ErrorComp from "../../../components/Error/ErrorComp";
import Modal from "react-bootstrap/Modal";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateDiscountService, getAllDiscountBrandService, getDiscountById } from "../DiscountService/DiscountService";
import { getAllCategoryService } from "../../Category/Category-service/CategoryService";
import { getAllSubCategoryService } from "../../SubCategory/SubCategoryService/SubCategoryService";
import { getAllLeafLevelCategoryService } from "../../LeafLevelCategory/LeafLevelService/LeafLevelService";
import { getAllProductService } from "../../Product/ProductService/ProductService";
import { format } from 'date-fns';
import { handleCancleClick, handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import { toast } from "react-toastify";

const today = new Date(); //Today's date
//Yesterday date
const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

export default function AddDiscount() {

  const validationSchema = Yup.object({
    discountTitle: Yup.string().required().max(20),
    discountCode: Yup.string().required(),
    discountType: Yup.string().required(),
    discountValue: Yup.number().required(ValidationMessage.FIELD_REQUIRED),
    maxDiscountAmount: Yup.string().nullable().when('discountType', {
      is: val =>  val === '1' || val === 'Yes',
      then: schema => schema.required(ValidationMessage.FIELD_REQUIRED)
      }),
    minimumCartValue: Yup.number().required(ValidationMessage.FIELD_REQUIRED),
    startDate: Yup.date().required(ValidationMessage.FIELD_REQUIRED),
    endDate: Yup.date().required(ValidationMessage.FIELD_REQUIRED),
    specificItems: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    discountApplyOn: Yup.string().nullable().when('specificItems', {
      is: val => val === '1' || val === 'Yes',
      then: schema => schema.required(ValidationMessage.FIELD_REQUIRED)
    }),
    cartDiscount: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
    status: Yup.boolean().required()
  });

  //Getting data from disount-list and setting accordingly
  const location = useLocation();
  const { Data } = location.state || {};

  const [initialValues, setInitialValues] = useState({
    discountTitle: '',
    discountCode: '',
    discountType: '',
    discountValue: '',
    maxDiscountAmount: '',
    minimumCartValue: '',
    firstOrderDiscount: '',
    cartDiscount: '',
    specificItems: '',
    startDate: '',
    endDate: '',
    discountApplyOn: '',
    status: true,
  });

  const [brandInitialValue, setBrandInitialValues] = useState({
    brandSearchBar: ''
  })

  const [categoryInitialValue, setCategoryInitialValue] = useState({
    categorySearchBar: ''
  })

  const [subCategoryInitialValue, setSubCategoryInitialValue] = useState({
    subCategorySearchBar: ''
  })

  const [leafCategoryInitialValue, setLeafCategoryInitialValue] = useState({
    leafCategorySearchBar: ''
  })


  const [productInitialValue, setProductInitialValue] = useState({
    productSearchBar: ''
  })

  const navigate = useNavigate();
  const discountPage = useSelector(state => state.discountPage?.items);
  const discountBrand = useSelector(state => state.discountBrand);
  const productData = useSelector(state => state.totalProduct);
  const categoryListData = useSelector(state => state.totalCategory);
  const totalLeaf = useSelector(state => state.totalLeaf);
  const subCategoryListData = useSelector(state => state.totalSubCategory);
  

  const token = UtilsData();
  const dispatch = useDispatch();

  // modal open 

  const [lgShow, setLgShow] = useState(false);
  const [CategoriesShow, setCategoriesShow] = useState(false);
  const [subCategoriesShow, setsubCategoriesShow] = useState(false);
  const [leafCategoriesShow, setleafCategoriesShow] = useState(false);
  const [itproductShow, productShow] = useState(false);


  //On selecting discount type
  const [showBrand, setShowBrand] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [showLeafCategories, setShowLeafCategories] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const user = useSelector(state => state.auth.user);


  const [showBrandData, setShowBrandData] = useState([]);
  const [showBrandName, setShowBrandName] = useState([]);

  const [showCategoryData, setShowCategoryData] = useState([]);
  const [showCategoryName, setShowCategoryName] = useState([]);

  const [showSubCategoryData, setShowSubCategoryData] = useState([]);
  const [showSubCategoryName, setShowSubCategoryName] = useState([]);

  const [showLeafCategoryData, setShowLeafCategoryData] = useState([]);
  const [showLeafCategoryName, setShowLeafCategoryName] = useState([]);

  const [showProductData, setShowProductData] = useState([]);
  const [showProductName, setShowProductName] = useState([]);
  const authuserData = useSelector(state => state.auth?.user);



  /**
   * Setting category options
   */
  useEffect(() => {
    if (categoryListData && categoryListData.items && categoryListData.items.length === 0) {
      const categoryRequestBody = {
        p_skip: 0,
        p_take: 1000000,
        p_is_active: '1'
      }
      getAllCategoryService(categoryRequestBody, token, dispatch, true);
    }
    else {
      const initialValues = categoryListData.items[0]?.data.reduce((acc, category) => {
        acc[`category${category.categoryId}`] = false; // Set all brands initially unchecked
        return acc;
      }, {});
      showCategoryData && showCategoryData.length > 0 && showCategoryData.forEach((item) => {

        if (initialValues[`category${item.category_id}`] !== undefined) {
          initialValues[`category${item.category_id}`] = true;
        }
      });
      setCategoryInitialValue({
        categorySearchBar: '',
        ...initialValues
      })
    }
    // eslint-disable-next-line
  }, [categoryListData, showCategoryData])


  /**
   * Call get discount by Id fuction and set values in form
   */
  const fetchDiscount = async () => {
    try {
      const discountData = await getDiscountById(Data.data.discountId, token, dispatch);
      if(discountData && discountData?.data) {
        setInitialValues({
          discountTitle: discountData.data.title,
          discountCode: discountData.data.code,
          discountType: discountData.data.discount_type,
          discountValue: discountData.data.discount_amount,
          maxDiscountAmount: discountData.data.max_discount_ammount,
          minimumCartValue: discountData.data.min_cart_value,
          firstOrderDiscount: discountData.data.is_first_order_discount,
          cartDiscount: discountData.data.discount_type_on,
          specificItems: discountData.data.spacific_item,
          startDate: discountData.data.start_date,
          endDate: discountData.data.end_date,
          discountApplyOn: discountData.data.discount_apply_on,
          status: discountData.data.is_active === '1' ? true : false,
        })
        if (discountData.data.discount_apply_on === '0') {
          setShowCategories(true);
  
          const initialValues = categoryListData.items[0]?.data.reduce((acc, category) => {
            acc[`category${category.categoryId}`] = false;
            return acc;
          }, {});
          const categoryIdsArray = discountData.data.category_ids ? discountData.data.category_ids.split(',').map(id => id.trim()) : null;
  
          if (categoryIdsArray !== null) {
            categoryIdsArray.forEach(brandId => {
              const key = `category${brandId}`;
              if (initialValues !== null && initialValues !== undefined && initialValues[key] !== null && initialValues[key] !== undefined) {
                initialValues[key] = true;
              }
            });
          }
          if (categoryIdsArray) {
            handleCategorySubmit(initialValues)
          }
        }
        else if (discountData.data.discount_apply_on === '1') {
          setShowSubCategories(true);
  
          const initialValues = subCategoryListData.items[0]?.data.reduce((acc, subCategory) => {
            acc[`subCategory${subCategory.id}`] = false;
            return acc;
          }, {});
  
          const subCategoryIdsArray = discountData.data.sub_category_ids ? discountData.data.sub_category_ids.split(',').map(id => id.trim()) : null;
  
          if (subCategoryIdsArray !== null) {
            subCategoryIdsArray.forEach(subCategoryId => {
              const key = `subCategory${subCategoryId}`;
              if (initialValues !== null && initialValues !== undefined && initialValues[key] !== null && initialValues[key] !== undefined) {
                initialValues[key] = true;
              }
            });
          }
          if (subCategoryIdsArray) {
            handleSubCategorySubmit(initialValues)
          }
        }
        else if (discountData.data.discount_apply_on === '2') {
          setShowLeafCategories(true);
  
          const initialValues = totalLeaf.items[0]?.data.reduce((acc, leaf) => {
            acc[`leaf${leaf.id}`] = false;
            return acc;
          }, {});
  
          const leafCategoryIdsArray = discountData.data.leaf_level_category_ids ? discountData.data.leaf_level_category_ids.split(',').map(id => id.trim()) : null;
  
          if (leafCategoryIdsArray !== null) {
            leafCategoryIdsArray.forEach(leafCategoryId => {
              const key = `leaf${leafCategoryId}`;
              if (initialValues !== null && initialValues !== undefined && initialValues[key] !== null && initialValues[key] !== undefined) {
                initialValues[key] = true;
              }
            });
          }
          if (leafCategoryIdsArray) {
            handleLeafSubmit(initialValues)
          }
        }
  
        else if (discountData.data.discount_apply_on === '3') {
          setShowBrand(true);
  
          const initialValues = discountBrand.items[0]?.data.reduce((acc, brand) => {
            acc[`brand${brand.id}`] = false;
            return acc;
          }, {});
          const brandIdsArray = discountData.data.brand_ids ? discountData.data.brand_ids.split(',').map(id => id.trim()) : null;
          
  
          if (brandIdsArray !== null) {
            brandIdsArray.forEach(brandId => {
              const key = `brand${brandId}`;
              if (initialValues !== null && initialValues !== undefined && initialValues[key] !== null && initialValues[key] !== undefined) {
                initialValues[key] = true;
              }
            });
          }
          
          if (brandIdsArray) {
            handleBrandSubmit(initialValues)
          }
        }
        else if (discountData.data.discount_apply_on === '4') {
          setShowProduct(true);
  
          const initialValues = productData.items[0]?.data.reduce((acc, product) => {
            acc[`product${product.productId}`] = false;
            return acc;
          }, {});
          const productIdsArray = discountData.data.product_ids ? discountData.data.product_ids.split(',').map(id => id.trim()) : null;
  
          if (productIdsArray !== null) {
            productIdsArray.forEach(productId => {
              const key = `product${productId}`;
              if (initialValues !== null && initialValues !== undefined && initialValues[key] !== null && initialValues[key] !== undefined) {
                initialValues[key] = true;
              }
            });
          }
  
          if (productIdsArray) {
            handleProductSubmit(initialValues)
          }
        }
      }
      return discountData;
    } catch (error) {
      console.log("erro: ", error);
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    if (Data && Data.data) {
      fetchDiscount();
    }
    // eslint-disable-next-line
  }, [Data, discountBrand, productData, subCategoryListData, categoryListData, totalLeaf])


  /**
  * Setting sub category options
  */
  useEffect(() => {
    if (subCategoryListData && subCategoryListData.items && subCategoryListData.items.length === 0) {
      const roleRequestBody = {
        p_skip: 0,
        p_take: 1000000,
        p_is_active: '1'
      }
      getAllSubCategoryService(roleRequestBody, token, dispatch, true);
    }
    else {
      const initialValues = subCategoryListData.items[0]?.data.reduce((acc, subCategory) => {
        acc[`subCategory${subCategory.id}`] = false; // Set all brands initially unchecked
        return acc;
      }, {});
      showSubCategoryData && showSubCategoryData.length > 0 && showSubCategoryData.forEach((item) => {

        if (initialValues[`subCategory${item.sub_category_id}`] !== undefined) {
          initialValues[`subCategory${item.sub_category_id}`] = true;
        }
      });
      setSubCategoryInitialValue({
        subCategorySearchBar: '',
        ...initialValues
      })
    }
    // eslint-disable-next-line
  }, [subCategoryListData, showSubCategoryData])


  /**
   * Setting leaf level category options
   */

  useEffect(() => {
    if (totalLeaf && totalLeaf.items && totalLeaf.items.length === 0) {
      const requestBody = {
        p_skip: 0,
        p_take: 1000000,
        p_is_active: '1'
      }
      getAllLeafLevelCategoryService(requestBody, token, dispatch, true)
    }
    else {
      const initialValues = totalLeaf.items[0]?.data.reduce((acc, leaf) => {
        acc[`leaf${leaf.id}`] = false; // Set all brands initially unchecked
        return acc;
      }, {});
      showLeafCategoryData && showLeafCategoryData.length > 0 && showLeafCategoryData.forEach((item) => {
        if (initialValues[`leaf${item.leaf_level_category_id}`] !== undefined) {
          initialValues[`leaf${item.leaf_level_category_id}`] = true;
        }
      });
      setLeafCategoryInitialValue({
        leafCategorySearchBar: '',
        ...initialValues
      })
    }
    // eslint-disable-next-line
  }, [totalLeaf, showLeafCategoryData])



  /**
   * Discount apply on product initial values
   */
  useEffect(() => {
    if (productData && productData.items && productData.items.length === 0) {
      const requestBody = {
        p_skip: 0,
        p_take: 1000000,
        p_is_active: '1'
      }
      getAllProductService(requestBody, token, dispatch, true)
    }
    else {
      const initialValues = productData.items[0]?.data.reduce((acc, product) => {
        acc[`product${product.productId}`] = false; // Set all brands initially unchecked
        return acc;
      }, {});
      showProductData && showProductData.length > 0 && showProductData.forEach((item) => {

        if (initialValues[`product${item.product_id}`] !== undefined) {
          initialValues[`product${item.product_id}`] = true;
        }
      });
      setProductInitialValue({
        subCategorySearchBar: '',
        ...initialValues
      })
    }
    // eslint-disable-next-line
  }, [productData, showProductData])


  /**
   * Discount apply on brand initial values
   */
  useEffect(() => {
    if (discountBrand && discountBrand.items && discountBrand.items.length === 0) {
      getAllDiscountBrandService(token, dispatch)
    }
    else {
      const initialValues = discountBrand.items[0]?.data.reduce((acc, brand) => {
        acc[`brand${brand.id}`] = false; // Set all brands initially unchecked
        return acc;
      }, {});
      showBrandData && showBrandData.length > 0 && showBrandData.forEach((item) => {
        const { brand_id } = item;
        if (initialValues[`brand${brand_id}`] !== undefined) {
          initialValues[`brand${brand_id}`] = true;
        }
      });

      setBrandInitialValues({
        brandSearchBar: '',
        ...initialValues
      })
    }
    // eslint-disable-next-line
  }, [discountBrand, showBrandData]);


  /**
   * Check all data on checking the checkbox
   */
  const changeFields = (event, setFieldValue, field) => {
    const { checked } = event.target;
    if (field === 'brand') {
      discountBrand.items[0].data.forEach(brand => {
        setFieldValue(`brand${brand.id}`, checked);
      });
    }
    else if (field === 'category') {
      categoryListData.items[0].data.forEach(category => {
        setFieldValue(`category${category.categoryId}`, checked)
      })
    }
    else if (field === 'subCategory') {
      subCategoryListData.items[0].data.forEach(subCategory => {
        setFieldValue(`subCategory${subCategory.id}`, checked)
      })
    }
    else if (field === 'leafCategory') {
      totalLeaf.items[0].data.forEach(leaf => {
        setFieldValue(`leaf${leaf.id}`, checked)
      })
    }
    else if (field === 'product') {
      productData.items[0].data.forEach(product => {
        setFieldValue(`product${product.productId}`, checked)
      })
    }
  }

  const typeOptions = [
    { value: "0", label: authuserData?.data?.symbol ? authuserData.data.symbol : '₹' },
    { value: "1", label: "%" },
  ];

  const specificOptions = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];
  const DiscountOptions = [
    { value: "0", label: "Categories" },
    { value: "1", label: "Sub Categories" },
    { value: "2", label: "Leaf Level Categories" },
    { value: "3", label: "Brand" },
    { value: "4", label: "Product" },
  ];
  const AmounttypeOptions = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];
  const DiscounttypeOptions = [
    { value: "0", label: "Cart discount" },
    { value: "1", label: "Slashed discount" },
  ];

  /**
   * Show option 
   */
  const handleOptionSelect = (selectedOptions) => {
    const selectedValues = [selectedOptions].map(option => option.value);
    setShowBrand(selectedValues && selectedValues.includes('3'));
    setShowCategories(selectedValues && selectedValues.includes('0'));
    setShowSubCategories(selectedValues && selectedValues.includes('1'));
    setShowLeafCategories(selectedValues && selectedValues.includes('2'));
    setShowProduct(selectedValues && selectedValues.includes('4'));
  };

  /**
   * Handle brand submit
   */
  const handleBrandSubmit = (values) => {
    // Filter out the checked brands
    const checkedBrands = discountBrand && discountBrand?.items && discountBrand.items.length > 0 && discountBrand.items[0]?.data && discountBrand.items[0].data.length > 0 ? discountBrand.items[0].data.filter(brand => values[`brand${brand.id}`]): [];

    const checkedBrandDetails = checkedBrands.map(brand => {
      if (values[`brand${brand.id}`]) {
        return {
          brand_id: brand.id
        };
      }
      return null; // Return null for unchecked brands
    }).filter(Boolean); // Filter out any null values
    setShowBrandData(checkedBrandDetails);

    const checkedBrandName = checkedBrands.map(brand => {
      if (values[`brand${brand.id}`]) {
        return {
          id: brand.id,
          name: brand.name,
          // Add other properties of the brand if needed
        };
      }
      return null; // Return null for unchecked brands
    }).filter(Boolean); // Filter out any null values
    setShowBrandName(checkedBrandName);
    setLgShow(false);
  };

  /**
   * Handle category submit
   */
  const handleCategorySubmit = (values) => {
    const checkedCategory = categoryListData && categoryListData?.items && categoryListData.items.length > 0 && categoryListData.items[0]?.data && categoryListData.items[0].data.length > 0 ? categoryListData.items[0].data.filter(category => values[`category${category.categoryId}`]): [];

    const checkedCategoryDetails = checkedCategory.map(category => {
      if (values[`category${category.categoryId}`]) {
        return {
          category_id: parseInt(category.categoryId)
        };
      }
      return null;
    }).filter(Boolean);
    setShowCategoryData(checkedCategoryDetails);

    const checkedCategoryName = checkedCategory.map(category => {
      if (values[`category${category.categoryId}`]) {
        return {
          id: category.categoryId,
          name: category.name
        };
      }
      return null;
    }).filter(Boolean);
    setShowCategoryName(checkedCategoryName);
    setCategoriesShow(false);
  };

  /**
   * Handle sub category submit
   */
  const handleSubCategorySubmit = (values) => {
    const checkedSubCategory = subCategoryListData && subCategoryListData?.items && subCategoryListData.items.length > 0 && subCategoryListData.items[0]?.data && subCategoryListData.items[0].data.length > 0 ? subCategoryListData.items[0].data.filter(subCategory => values[`subCategory${subCategory.id}`]): [];

    const checkedSubCategoryDetails = checkedSubCategory.map(subCategory => {
      if (values[`subCategory${subCategory.id}`]) {
        return {
          sub_category_id: parseInt(subCategory.id)
        };
      }
      return null;
    }).filter(Boolean);
    setShowSubCategoryData(checkedSubCategoryDetails);

    const checkedCategoryName = checkedSubCategory.map(subCategory => {
      if (values[`subCategory${subCategory.id}`]) {
        return {
          id: subCategory.id,
          name: subCategory.name
        };
      }
      return null;
    }).filter(Boolean);
    setShowSubCategoryName(checkedCategoryName);
    setsubCategoriesShow(false);
  };

  /**
   * Handle leaf level category submit
   */
  const handleLeafSubmit = (values) => {
    const checkedLeafCategory = totalLeaf && totalLeaf?.items && totalLeaf.items.length > 0 && totalLeaf.items[0]?.data && totalLeaf.items[0].data.length > 0 ? totalLeaf.items[0].data.filter(leaf => values[`leaf${leaf.id}`]): [];

    const checkedLeafDetails = checkedLeafCategory.map(leaf => {
      if (values[`leaf${leaf.id}`]) {
        return {
          leaf_level_category_id: parseInt(leaf.id)
        };
      }
      return null;
    }).filter(Boolean);
    setShowLeafCategoryData(checkedLeafDetails);

    const checkedLeafCategoryName = checkedLeafCategory.map(leaf => {
      if (values[`leaf${leaf.id}`]) {
        return {
          id: leaf.id,
          name: leaf.Title
        };
      }
      return null;
    }).filter(Boolean);
    setShowLeafCategoryName(checkedLeafCategoryName);
    setleafCategoriesShow(false);
  };

  /**
   * Handle product submit
   */
  const handleProductSubmit = (values) => {
    const checkedProduct = productData && productData?.items && productData.items.length > 0 && productData.items[0]?.data && productData.items[0].data.length > 0 ? productData.items[0].data.filter(product => values[`product${product.productId}`]): [];

    const checkedProductDetails = checkedProduct.map(product => {
      if (values[`product${product.productId}`]) {
        return {
          product_id: parseInt(product.productId)
        };
      }
      return null;
    }).filter(Boolean);
    setShowProductData(checkedProductDetails);

    const checkedProductyName = checkedProduct.map(product => {
      if (values[`product${product.productId}`]) {
        return {
          id: product.productId,
          name: product.name
        };
      }
      return null;
    }).filter(Boolean);
    setShowProductName(checkedProductyName);
    productShow(false);
  };


  /**
   * Handling form submission
   */
  // const handleSubmit = (values) => {
  //   try {
  //     if (!Data) {
  //       const requestBody = {
  //         p_title: values.discountTitle,
  //         p_code: values.discountCode,
  //         p_discount_type: values.discountType,
  //         p_discount_amount: Number(values.discountValue),
  //         p_max_discount_amount: values.maxDiscountAmount && values.maxDiscountAmount !== '' ? Number(values.maxDiscountAmount) : null,
  //         p_min_cart_value: Number(values.minimumCartValue),
  //         p_is_first_order_discount: values.firstOrderDiscount,
  //         p_discount_type_on: values.cartDiscount,
  //         p_discount_apply_on: null,
  //         p_specific_item: values.specificItems,
  //         p_start_date: format(new Date(values.startDate), 'yyyy-MM-dd'),
  //         p_end_date: format(new Date(values.endDate), 'yyyy-MM-dd'),
  //         p_created_by: user.data.userId,
  //         p_is_active: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0'
  //       }
  
  //       if (values.specificItems === '1') {
  //         requestBody.p_discount_apply_on = values.discountApplyOn;
  //         if (values.discountApplyOn === '3') {
  //           if (showBrandData.length === 0) {
  //             toast.error('Please select brand discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showBrandData;
  //           }
  //         }
  //         else if (values.discountApplyOn === '0') {
  //           if (showCategoryData.length === 0) {
  //             toast.error('Please select category discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showCategoryData;
  //           }
  //         }
  //         else if (values.discountApplyOn === '1') {
  //           if (showSubCategoryData.length === 0) {
  //             toast.error('Please select sub-category discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showSubCategoryData;
  //           }
  //         }
  //         else if (values.discountApplyOn === '2') {
  //           if (showLeafCategoryData.length === 0) {
  //             toast.error('Please select leaf level category discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showLeafCategoryData;
  //           }
  //         }
  //         else if (values.discountApplyOn === '4') {
  //           if (showProductData.length === 0) {
  //             toast.error('Please select product discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showProductData;
  //           }
  //         }
  //       }
  //       dispatch(addUpdateDiscountService(requestBody, token, 'add', navigate))
  //     }
  //     else {
  //       const requestBody = {
  //         p_id: Data.data.discountId,
  //         p_title: values.discountTitle,
  //         p_code: values.discountCode,
  //         p_discount_type: values.discountType,
  //         p_discount_amount: Number(values.discountValue),
  //         p_max_discount_amount: values.maxDiscountAmount && values.maxDiscountAmount !== '' ? Number(values.maxDiscountAmount) : null,
  //         p_min_cart_value: Number(values.minimumCartValue),
  //         p_is_first_order_discount: values.firstOrderDiscount,
  //         p_discount_type_on: values.cartDiscount,
  //         p_discount_apply_on: null,
  //         p_specific_item: values.specificItems,
  //         p_start_date: format(new Date(values.startDate), 'yyyy-MM-dd'),
  //         p_end_date: format(new Date(values.endDate), 'yyyy-MM-dd'),
  //         p_modified_by: user.data.userId,
  //         p_is_active: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
  //         p_IsDeleted: Data.data.IsDeleted
  //       }
  
  //       if (values.specificItems === '1') {
  //         requestBody.p_discount_apply_on = values.discountApplyOn;
  //         if (values.discountApplyOn === '3') {
  //           if (showBrandData.length === 0) {
  //             toast.error('Please select brand discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showBrandData;
  //           }
  //         }
  //         else if (values.discountApplyOn === '0') {
  //           if (showCategoryData.length === 0) {
  //             toast.error('Please select category discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showCategoryData;
  //           }
  //         }
  //         else if (values.discountApplyOn === '1') {
  //           if (showSubCategoryData.length === 0) {
  //             toast.error('Please select sub-category discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showSubCategoryData;
  //           }
  //         }
  //         else if (values.discountApplyOn === '2') {
  //           if (showLeafCategoryData.length === 0) {
  //             toast.error('Please select leaf level category discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showLeafCategoryData;
  //           }
  //         }
  //         else if (values.discountApplyOn === '4') {
  //           if (showProductData.length === 0) {
  //             toast.error('Please select product discount');
  //             return;
  //           }
  //           else {
  //             requestBody.p_discount_data = showProductData;
  //           }
  //         }
  //       }
  //       if (Data?.length && discountPage > 1) {
  //         if ((values.status === '0' || values.status === 0 || values.status === false)) {
  //           if (Data.length > 1) {
  //             handlesaveClick(dispatch, addUpdateDiscountService(requestBody, token, 'update', navigate, discountPage), 'update');
  //           }
  //           else {
  //             handlesaveClick(dispatch, addUpdateDiscountService(requestBody, token, 'update', navigate, discountPage-1), 'update');
  //           }
  //         }
  //         else {
  //           handlesaveClick(dispatch, addUpdateDiscountService(requestBody, token, 'update', navigate, discountPage), 'update');
  //         }
  //       }
  //       else {
  //         handlesaveClick(dispatch, addUpdateDiscountService(requestBody, token, 'update', navigate), 'update');
  //       }
  //     }
  //   } catch (error) {
  //     console.log("err: ", error);
      
  //   }
  // };


  
  const prepareRequestBody = (values, userId, additionalFields = {}) => ({
    p_title: values.discountTitle,
    p_code: values.discountCode,
    p_discount_type: values.discountType,
    p_discount_amount: Number(values.discountValue),
    p_max_discount_amount: values.maxDiscountAmount && values.maxDiscountAmount !== '' ? Number(values.maxDiscountAmount) : null,
    p_min_cart_value: Number(values.minimumCartValue),
    p_is_first_order_discount: values.firstOrderDiscount,
    p_discount_type_on: values.cartDiscount,
    p_discount_apply_on: values.specificItems === '1' ? values.discountApplyOn : null,
    p_specific_item: values.specificItems,
    p_start_date: format(new Date(values.startDate), 'yyyy-MM-dd'),
    p_end_date: format(new Date(values.endDate), 'yyyy-MM-dd'),
    p_is_active: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
    ...additionalFields,
  });
  
  const handleDiscountDataCheck = (discountApplyOn, showData, errorMessage) => {
    if (showData.length === 0) {
      toast.error(errorMessage);
      return false;
    }
    return showData;
  };
  
  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    try {
      const userId = user.data.userId;
      let requestBody;
      
      if (!Data) {
        requestBody = prepareRequestBody(values, userId, { p_created_by: userId });
      } else {
        requestBody = prepareRequestBody(values, userId, {
          p_id: Data.data.discountId,
          p_modified_by: userId,
          p_IsDeleted: Data.data.IsDeleted,
        });
      }
  
      if (values.specificItems === '1') {
        const dataMapping = {
          '3': { showData: showBrandData, errorMessage: 'Please select brand discount' },
          '0': { showData: showCategoryData, errorMessage: 'Please select category discount' },
          '1': { showData: showSubCategoryData, errorMessage: 'Please select sub-category discount' },
          '2': { showData: showLeafCategoryData, errorMessage: 'Please select leaf level category discount' },
          '4': { showData: showProductData, errorMessage: 'Please select product discount' },
        };
  
        const selectedData = handleDiscountDataCheck(values.discountApplyOn, dataMapping[values.discountApplyOn].showData, dataMapping[values.discountApplyOn].errorMessage);
        
        if (!selectedData) return;
  
        requestBody.p_discount_data = selectedData;
      }
  
      const action = Data ? 'update' : 'add';
      const page = discountPage > 1 && (values.status === '0' || values.status === 0 || values.status === false)
        ? (Data.length > 1 ? discountPage : discountPage - 1)
        : discountPage;
  
      handlesaveClick(dispatch, addUpdateDiscountService(requestBody, token, action, navigate, page), action);
  
    } catch (error) {
      console.log("err: ", error);
    }
  };
  

  const onChangeSpecificItems = (value, setFieldValue) => {
    if(value === '0') {
      setFieldValue('discountApplyOn', '')
      setShowBrand(false);
      setShowCategories(false);
      setShowSubCategories(false);
      setShowLeafCategories(false);
      setShowProduct(false);
    }
  }

  const onChangeDiscountType = (value, setFieldValue) => {    
    if(value === '0') {
      setFieldValue('maxDiscountAmount', '')
    }
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Discount</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/discount")}>
                    Discount
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Discount</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Discount</h4>
            </div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Discount Title
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="discountTitle"
                          label="Discount Title"
                          placeholder="Enter Discount Title"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          maxLength={20}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Discount Code
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="discountCode"
                          label="Discount Code"
                          placeholder="Enter Discount Code"
                          className="add-roles"
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Discount Type <span className="required-label"></span></h4>
                        <Field
                          className="basic-single"
                          name="discountType"
                          options={typeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.discountType}
                          changeData={(value) => onChangeDiscountType(value, setFieldValue)}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Discount Value
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="discountValue"
                          label="Discount Value"
                          placeholder="Enter Discount Value"
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    {values.discountType === '1' && (
                      <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Max Discount Amount
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="maxDiscountAmount"
                          label="Max Discount Amount"
                          placeholder="Enter Max Discount Amount"
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    )}
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Minimum Cart Value
                            <span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="minimumCartValue"
                          label="Minimum Cart Value"
                          placeholder="Enter Minimum Cart Value"
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Is First Order Discount <span className="required-label"></span></h4>
                        <Field
                          className="basic-single"
                          name="firstOrderDiscount"
                          options={AmounttypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.firstOrderDiscount}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Discount Type <span className="required-label"></span></h4>
                        <Field
                          className="basic-single"
                          name="cartDiscount"
                          options={DiscounttypeOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.cartDiscount}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Specific Items <span className="required-label"></span></h4>
                        <Field
                          className="basic-single"
                          name="specificItems"
                          options={specificOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          changeData={(value) => onChangeSpecificItems(value, setFieldValue)}
                          value={values.specificItems}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <div className="date-picker">
                          <div className="paragraph-editor ">
                            <h4 className="paragraph-title">
                              Date<span className="required-label"></span>
                            </h4>
                          </div>
                          <div className="row">
                            <div className="col-md-5 datepicker-width">
                              <DatePicker
                                selected={values.startDate}
                                onChange={(date) => {
                                  // Check if the selected start date is greater than the current end date
                                  if (date > values.endDate) {
                                    // If the start date is greater than the end date, set the end date to null
                                    setFieldValue("endDate", null);
                                  }
                                  // Update the start date value
                                  setFieldValue("startDate", date);
                                }}
                                selectsStart
                                startDate={values.startDate}
                                endDate={values.endDate}
                                placeholderText="Start Date"
                                showIcon
                                minDate={new Date()}
                                disabled={Data && Data.isEdit === false}
                              />
                              <ErrorMessage
                                name="startDate"
                                component={ErrorComp}
                              ></ErrorMessage>
                            </div>
                            <div className="col-md-2">
                              <label className="text-center w-100">To</label>
                            </div>
                            <div className="col-md-5 datepicker-width">
                              <DatePicker
                                selected={values.endDate}
                                onChange={(date) =>
                                  setFieldValue("endDate", date)
                                }
                                selectsEnd
                                startDate={values.startDate}
                                endDate={values.endDate}
                                placeholderText="End Date"
                                showIcon
                                minDate={values.startDate}
                                disabled={Data && Data.isEdit === false}
                              />
                              <ErrorMessage
                                name="endDate"
                                component={ErrorComp}
                              ></ErrorMessage>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(values.specificItems === '1' || values.specificItems === 'Yes') && (
                      <div className="col-md-6">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">Discount apply on <span className="required-label"></span></h4>
                          <Field
                            className="basic-single"
                            name="discountApplyOn"
                            options={DiscountOptions}
                            component={CustomSelect}
                            placeholder="Select..."
                            isMulti={false}
                            onSelectChange={handleOptionSelect}
                            value={values.discountApplyOn}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                    )}
                    {(values.specificItems === '1' || values.specificItems === 'Yes') && showBrand && (
                      <>
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Brand Discount <span className="required-label"></span></h4>
                            <div className="select-discount" onClick={() => setLgShow(true)} >
                              <span className="black-color">{(showBrandName && showBrandName.length > 1) ? showBrandName[0].name + ' and ' + (showBrandName.length - 1) + ' more' : (showBrandName && showBrandName.length === 1 ? showBrandName[0].name : 'Select Brand Discount')}</span>
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {(values.specificItems === '1' || values.specificItems === 'Yes') && showCategories && (
                      <>
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Categories Discount <span className="required-label"></span></h4>
                            <div
                              className="select-discount"
                              onClick={() => setCategoriesShow(true)}>
                              <span className='black-color'>{(showCategoryName && showCategoryName.length > 1) ? showCategoryName[0].name + ' and ' + (showCategoryName.length - 1) + ' more' : (showCategoryName && showCategoryName.length === 1 ? showCategoryName[0].name : 'Select Category Discount')}</span>
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {(values.specificItems === '1' || values.specificItems === 'Yes') && showSubCategories && (
                      <>
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Sub Categories Discount <span className="required-label"></span></h4>
                            <div className="select-discount" onClick={() => setsubCategoriesShow(true)}>
                              <span className="black-color">{(showSubCategoryName && showSubCategoryName.length > 1) ? showSubCategoryName[0].name + ' and ' + (showSubCategoryName.length - 1) + ' more' : (showSubCategoryName && showSubCategoryName.length === 1 ? showSubCategoryName[0].name : 'Select Sub Category Discount')}</span>
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {(values.specificItems === '1' || values.specificItems === 'Yes') && showLeafCategories && (
                      <>
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Leaf Level Categories Discount <span className="required-label"></span></h4>
                            <div
                              className="select-discount"
                              onClick={() => setleafCategoriesShow(true)}>
                              <span className="black-color">{(showLeafCategoryName && showLeafCategoryName.length > 1) ? showLeafCategoryName[0].name + ' and ' + (showLeafCategoryName.length - 1) + ' more' : (showLeafCategoryName && showLeafCategoryName.length === 1 ? showLeafCategoryName[0].name : 'Select Leaf Level Categories Discount')}</span>
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {(values.specificItems === '1' || values.specificItems === 'Yes') && showProduct && (
                      <>
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">Product Discount <span className="required-label"></span></h4>
                            <div
                              className="select-discount"
                              onClick={() => productShow(true)}>
                              <span className="black-color">{(showProductName && showProductName.length > 1) ? showProductName[0].name + ' and ' + (showProductName.length - 1) + ' more' : (showProductName && showProductName.length === 1 ? showProductName[0].name : 'Select Product Discount')}</span>
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                      </div>
                      <Field
                        name="status"
                        setFieldValue={setFieldValue}
                        component={Toggle}
                        disabled={Data && Data.isEdit === false}
                      ></Field>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || (!Data)) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/discount', navigate)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>

      {/* Brand modal open start*/}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="brnad-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Select Brand Discount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={brandInitialValue} onSubmit={handleBrandSubmit} enableReinitialize={true}>
            {({ values, setFieldValue }) => (
              <Form>
                <div className="serchbar-detail">
                </div>
                <div className="list-of-brand">
                  {(discountBrand && discountBrand.items && discountBrand.items[0]?.data && discountBrand.items[0].data.length > 0) ? (
                    <>
                      <span>
                        <Checkbox value={
                          discountBrand &&
                          discountBrand.items &&
                          discountBrand.items[0]?.data &&
                          discountBrand.items[0].data.every((brand) => values[`brand${brand.id}`])
                        } id="brand0" onChange={(event) => changeFields(event, setFieldValue, 'brand')} disabled={Data && Data.isEdit === false} />
                        Brands</span>
                      <ul>
                        {discountBrand && discountBrand.items && discountBrand.items[0]?.data && discountBrand.items[0].data.map(brand => (
                          <li key={brand.id}>
                            <Checkbox value={values[`brand${brand.id}`]} name={`brand${brand.id}`} id={`brand${brand.id}`} onChange={(event) => setFieldValue(`brand${brand.id}`, event.target.checked)} disabled={Data && Data.isEdit === false} />
                            {brand.name}
                          </li>
                        ))}
                      </ul>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <div className="btn-submit mt-3">
                            {((Data && Data.isEdit === true) || (!Data)) && (
                              <Button
                                type="submit"
                                name="Save"
                                className="login-btn btn"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : <><span>No Brand Records</span></>}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Brand modal open End*/}

      {/* Categories modal open start*/}
      <Modal
        size="lg"
        show={CategoriesShow}
        onHide={() => setCategoriesShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="brnad-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Select Categories Discount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={categoryInitialValue} enableReinitialize={true} onSubmit={handleCategorySubmit}>
            {({ values, setFieldValue }) => (
              <Form >
                <div className="serchbar-detail">
                </div>
                <div className="list-of-brand">
                  {categoryListData && categoryListData.items && categoryListData.items[0]?.data && categoryListData.items[0].data.length > 0 ? (
                    <>
                      <span><Checkbox value={categoryListData &&
                        categoryListData.items &&
                        categoryListData.items[0]?.data &&
                        categoryListData.items[0].data.every((category) => values[`category${category.categoryId}`])} id="category0" onChange={(event) => changeFields(event, setFieldValue, 'category')} disabled={Data && Data.isEdit === false} />Categories</span>
                      <ul>
                        {categoryListData && categoryListData.items && categoryListData.items[0]?.data && categoryListData.items[0].data.map(category => (
                          <li key={category.categoryId}>
                            <Checkbox checked={values[`category${category.categoryId}`]} name={`category${category.categoryId}`} id={`category${category.categoryId}`} onChange={(event) => setFieldValue(`category${category.categoryId}`, event.target.checked)} disabled={Data && Data.isEdit === false} />
                            {category.name}
                          </li>
                        ))}
                      </ul>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <div className="btn-submit mt-3">
                            {((Data && Data.isEdit === true) || (!Data)) && (
                              <Button
                                type="submit"
                                name="Save"
                                className="login-btn btn"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : <><span>No category records</span></>}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Categories modal open End*/}

      {/* Sub Categories modal open start*/}
      <Modal
        size="lg"
        show={subCategoriesShow}
        onHide={() => setsubCategoriesShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="brnad-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Select Sub Categories Discount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={subCategoryInitialValue} enableReinitialize={true} onSubmit={handleSubCategorySubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                <div className="serchbar-detail">
                </div>
                <div className="list-of-brand">

                  {(subCategoryListData.items && subCategoryListData.items[0]?.data && subCategoryListData.items[0].data.length > 0) ? (
                    <>
                      <span><Checkbox value={subCategoryListData.items &&
                        subCategoryListData.items[0]?.data &&
                        subCategoryListData.items[0].data.every((subCategory) => values[`subCategory${subCategory.id}`])} id="subCategory0" onChange={(event) => changeFields(event, setFieldValue, 'subCategory')} disabled={Data && Data.isEdit === false} />Sub Categories</span>
                      <ul>
                        {subCategoryListData && subCategoryListData.items && subCategoryListData.items[0]?.data && subCategoryListData.items[0].data.map(subCategory => (
                          <li key={subCategory.id}>
                            <Checkbox value={values[`subCategory${subCategory.id}`]} name={`subCategory${subCategory.id}`} id={`subCategory${subCategory.id}`} onChange={(event) => setFieldValue(`subCategory${subCategory.id}`, event.target.checked)} disabled={Data && Data.isEdit === false} />
                            {subCategory.name}
                          </li>
                        ))}
                      </ul>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <div className="btn-submit mt-3">
                            {((Data && Data.isEdit === true) || (!Data)) && (
                              <Button
                                type="submit"
                                name="Save"
                                className="login-btn btn"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : <><span>No Sub-category records</span></>}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Sub Categories modal open End*/}

      {/* Leaf Level Categories modal open start*/}
      <Modal
        size="lg"
        show={leafCategoriesShow}
        onHide={() => setleafCategoriesShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="brnad-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Select Leaf Level Categories Discount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={leafCategoryInitialValue} enableReinitialize={true} onSubmit={handleLeafSubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                <div className="serchbar-detail">
                </div>
                <div className="list-of-brand">
                  {(totalLeaf && totalLeaf.items && totalLeaf.items[0]?.data && totalLeaf.items[0].data.length > 0) ? (
                    <>
                      <span><Checkbox value={totalLeaf.items &&
                        totalLeaf.items[0]?.data &&
                        totalLeaf.items[0].data.every((leaf) => values[`leaf${leaf.id}`])} id="leafCategory0" onChange={(event) => changeFields(event, setFieldValue, 'leafCategory')} disabled={Data && Data.isEdit === false} />Leaf Level Categories</span>
                      <ul>
                        {totalLeaf && totalLeaf.items && totalLeaf.items[0]?.data && totalLeaf.items[0].data.map(leaf => (
                          <li key={leaf.id}>
                            <Checkbox value={values[`leaf${leaf.id}`]} name={`leaf${leaf.id}`} id={`subCategory${leaf.id}`} onChange={(event) => setFieldValue(`leaf${leaf.id}`, event.target.checked)} disabled={Data && Data.isEdit === false} />
                            {leaf.Title}
                          </li>
                        ))}
                      </ul>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <div className="btn-submit mt-3">
                            {((Data && Data.isEdit === true) || (!Data)) && (
                              <Button
                                type="submit"
                                name="Save"
                                className="login-btn btn"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : <><span>No Leaf Level Category Records</span></>}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Leaf Level Categories modal open End*/}

      {/* Product modal open start*/}
      <Modal
        size="lg"
        show={itproductShow}
        onHide={() => productShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="brnad-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Select Product Discount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={productInitialValue} enableReinitialize={true} onSubmit={handleProductSubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                <div className="serchbar-detail">
                </div>
                <div className="list-of-brand">
                  {(productData && productData.items && productData.items[0]?.data && productData.items[0].data.length > 0) ? (
                    <>
                      <span><Checkbox value={productData.items &&
                        productData.items[0]?.data &&
                        productData.items[0].data.every((product) => values[`product${product.productId}`])} id="product0" onChange={(event) => changeFields(event, setFieldValue, 'product')} disabled={Data && Data.isEdit === false} /> Product</span>
                      <ul>
                        {productData && productData.items && productData.items[0]?.data && productData.items[0].data.map(product => (
                          <li key={product.productId}>
                            <Checkbox value={values[`product${product.productId}`]} name={`product${product.productId}`} id={`product${product.productId}`} onChange={(event) => setFieldValue(`product${product.productId}`, event.target.checked)} disabled={Data && Data.isEdit === false} />
                            {product.name}
                          </li>
                        ))}
                      </ul>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <div className="btn-submit mt-3">
                            {((Data && Data.isEdit === true) || (!Data)) && (
                              <Button
                                type="submit"
                                name="Save"
                                className="login-btn btn"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : <><span>No Product Records</span></>}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Product modal open End*/}
    </>
  );
}