import { Dispatch } from "redux";
import { AddUpdateTagRequest, TagResponse, getAllTagsRequest } from "./TagModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearTagData, clearTotalTagData, getTagData, getTotalTagData } from "../../../redux/Actions/tagAction/TagActions";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import { clearTagPageData, getTagPageData } from "../../../redux/Actions/tagAction/TagPageAction";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";

let errorShow = false;

/*
 * Service to get leaf level category list
*/
export const getAllTagsService = (
  tagsListRequest: getAllTagsRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<TagResponse> => {
  return new Promise<TagResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (tagsListRequest.p_skip !== undefined && tagsListRequest.p_skip !== null) {
        queryParams += `p_skip=${tagsListRequest.p_skip}&`;
      }
      if (tagsListRequest.p_limit !== undefined && tagsListRequest.p_limit !== null) {
        queryParams += `p_limit=${tagsListRequest.p_limit}&`;
      }
      if (tagsListRequest.p_title !== undefined && tagsListRequest.p_title !== null) {
        queryParams += `p_title=${tagsListRequest.p_title}&`;
      }
      if (tagsListRequest.p_isActive !== undefined && tagsListRequest.p_isActive !== null) {
        queryParams += `p_isActive=${tagsListRequest.p_isActive}&`
      }
      if (tagsListRequest.p_IsDeleted !== undefined && tagsListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${tagsListRequest.p_IsDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.TAGS + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(getTotalTagData(response))
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getTagData(response));
        }
        else {
          dispatch(getTagData(response));
        }
        resolve(response);
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Tags permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        resolve(response);
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating tags
*/
export const addUpdateTagService = (
  addUpdateTagRequest: AddUpdateTagRequest,
  token: string, type: string, navigate?: any, pageNumber?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.TAGS, token, addUpdateTagRequest);
      }
      if (type === 'update') {
        response = await putApiService(API_URL.BASE_URL + API_URL.TAGS, token, addUpdateTagRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (type === 'add') {
          dispatch(clearTagPageData());
          toast.success('Tag added successfully!');
          navigate('/tags');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            toast.success('Tag undeleted successfully!');
          }
          else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getTagPageData(pageNumber + 1));
              }
              else {
                dispatch(clearTagPageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: "Tag updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/tags');
              });
            }
            else {
              if (addUpdateTagRequest.p_isActive === '1') {
                dispatch(clearTagPageData());
              }
              toast.success('Tag status updated successfully!');
            }
          }
        }
        dispatch(clearTagData());
        dispatch(clearTotalTagData());
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getTagPageData(pageNumber))
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};


/*
 * Service for deleting tags
*/
export const deleteTagService = (deleteId: number, token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.TAGS + '/' + deleteId, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearTagData());
        dispatch(clearTotalTagData());
        toast.success('Tag deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getTagPageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};