import React from 'react';
import Pagination from "react-bootstrap/Pagination";

function CustomPagination({ totalRecords, currentPage, onPageChange }) {
  const totalPages = Math.ceil(totalRecords / 10); // Assuming 10 records per page

  /**
   * Get page number
   * @returns {*}
   */
  const renderPaginationItems = () => {
    const items = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage === 1) {
      startPage = 1;
      endPage = Math.min(3, totalPages);
    } else if (currentPage === totalPages) {
      startPage = Math.max(1, totalPages - 2);
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <div className="custom-pagination">
      <Pagination className="d-flex justify-content-end mb-0">
        <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => onPageChange(Math.max(1, currentPage - 1))} disabled={currentPage === 1} />
        {/* <Pagination.Ellipsis disabled={currentPage === 1} /> */}

        {renderPaginationItems()}
        {/* <Pagination.Ellipsis  disabled={currentPage === totalPages}/> */}
        <Pagination.Next onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    </div>
  );
}

export default CustomPagination;