const ServiceProviderPageInitialState = { 
    items: 1
};

/**
 * Store page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const ServiceProviderPageReducer = (state = ServiceProviderPageInitialState, action) => {
    switch (action.type) {
        case 'GET_SERVICE_PROVIDER_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_SERVICE_PROVIDER_PAGE':
            return ServiceProviderPageInitialState;
        default:
            return state;
      };
}