import { Dispatch } from "redux";
import { addUpdateAttributeRequest, getAllAttributeRequest, getAllAttributeResponse } from "./AtributeModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearAttributeData, clearTotalAttributeData, getAttributeData, getTotalAttributeData } from "../../../redux/Actions/AttributeAction/AttributeAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearAttributePageData, getAttributePageData } from "../../../redux/Actions/AttributeAction/AttributePageAction";

let errorShow = false;

/*
 * Service to get Attribute list
*/
export const getAllAttributeService = (
  attributeListRequest: getAllAttributeRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<getAllAttributeResponse> => {
  return new Promise<getAllAttributeResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (attributeListRequest.p_skip !== undefined && attributeListRequest.p_skip !== null) {
        queryParams += `p_skip=${attributeListRequest.p_skip}&`;
      }
      if (attributeListRequest.p_limit !== undefined && attributeListRequest.p_limit !== null) {
        queryParams += `p_limit=${attributeListRequest.p_limit}&`;
      }
      if (attributeListRequest.p_title !== undefined && attributeListRequest.p_title !== null) {
        queryParams += `p_title=${attributeListRequest.p_title}&`;
      }
      if (attributeListRequest.p_isActive !== undefined && attributeListRequest.p_isActive !== null) {
        queryParams += `p_isActive=${attributeListRequest.p_isActive}&`
      }
      if (attributeListRequest.p_IsDeleted !== undefined && attributeListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${attributeListRequest.p_IsDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.ATTRIBUTES + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(getTotalAttributeData(response));
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getAttributeData(response));
        }
        else {
          dispatch(getAttributeData(response))
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Attribute permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating attribute
*/
export const addUpdateAttributeService = (
  addUpdateAttributeRequest: addUpdateAttributeRequest,
  token: string, type: string, navigate?: any, pageNumber?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.ATTRIBUTES, token, addUpdateAttributeRequest);
      }
      if (type === 'update') {
        response = await putApiService(API_URL.BASE_URL + API_URL.ATTRIBUTES, token, addUpdateAttributeRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearAttributeData());
        dispatch(clearTotalAttributeData());
        if (type === 'add') {
          dispatch(clearAttributePageData());
          toast.success('Attribute added successfully!');
          navigate('/attributes');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            toast.success('Attribute undeleted successfully!');
          }
          else {
            if (navigate) {
              if (pageNumber) {
                dispatch(getAttributePageData(pageNumber + 1))
              }
              else {
                dispatch(clearAttributePageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: "Attribute updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/attributes');
              });
            }
            else {
              toast.success('Attribute status updated successfully!');
            }
          }
        }
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getAttributePageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};

/*
 * Service for deleting brand
*/
export const deleteAttributeService = (deleteId: number, token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.ATTRIBUTES + '/' + deleteId, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearAttributeData());
        dispatch(clearTotalAttributeData());
        toast.success('Attribute deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getAttributePageData(pageNumber))
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};