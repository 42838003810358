import { Dispatch } from "redux";
import { addUpdateWarehouseRequest, getAllWarehouseRequest, getAllWarehouseResponse } from "./WarehouseModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { toast } from "react-toastify";
import { clearWarehouseData, getTotalWarehouseData, getWarehouseData } from "../../../redux/Actions/WarehouseAction/WarehouseAction";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";
import { clearWarehousePageData, getWarehousePageData } from "../../../redux/Actions/WarehouseAction/WarehousePageAction";

let errorShow = false;

/*
 * Service to get Warehouse list
*/
export const getAllWarehouseService = (
  warehouseListRequest: getAllWarehouseRequest,
  token: string, dispatch: Dispatch, total?: any
): Promise<getAllWarehouseResponse> => {
  return new Promise<getAllWarehouseResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      let queryParams = '';
      // Adding query parameters if they are provided
      if (warehouseListRequest.p_skip !== undefined && warehouseListRequest.p_skip !== null) {
        queryParams += `p_skip=${warehouseListRequest.p_skip}&`;
      }
      if (warehouseListRequest.p_take !== undefined && warehouseListRequest.p_take !== null) {
        queryParams += `p_take=${warehouseListRequest.p_take}&`;
      }
      if (warehouseListRequest.p_name !== undefined && warehouseListRequest.p_name !== null) {
        queryParams += `p_name=${warehouseListRequest.p_name}&`;
      }
      if (warehouseListRequest.p_is_active !== undefined && warehouseListRequest.p_is_active !== null) {
        queryParams += `p_is_active=${warehouseListRequest.p_is_active}&`
      }
      if (warehouseListRequest.p_IsDeleted !== undefined && warehouseListRequest.p_IsDeleted !== null) {
        queryParams += `p_IsDeleted=${warehouseListRequest.p_IsDeleted}&`
      }
      const response = await getApiService(API_URL.BASE_URL + API_URL.WAREHOUSE + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        if (total && total === true) {
          dispatch(getTotalWarehouseData(response));
        }
        else if (total && total === 'filter') {
          toast.success('Filter success!');
          dispatch(getWarehouseData(response));
        }
        else {
          dispatch(getWarehouseData(response))
        }
      }
      else {
        if (!document.querySelector(toastClass)) {
          toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Warehouse permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
        }
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response)
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};

/*
 * Service for adding and updating warehouse
*/
export const addUpdateWarehouseService = (
  addUpdateWarehouseRequest: addUpdateWarehouseRequest,
  token: string, type: string, navigate?: any, pageNumber?: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      let response: any;
      if (type === 'add') {
        response = await postApiService(API_URL.BASE_URL + API_URL.WAREHOUSE, token, addUpdateWarehouseRequest);
      }
      if (type === 'update') {
        response = await putApiService(API_URL.BASE_URL + API_URL.WAREHOUSE, token, addUpdateWarehouseRequest);
      }
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearWarehouseData());
        if (type === 'add') {
          dispatch(clearWarehousePageData());
          toast.success('Warehouse added successfully!');
          navigate('/warehouse');
        }
        if (type === 'update') {
          if (navigate && navigate === 'undo') {
            toast.success('Warehouse undeleted successfully!');
          }
          else {
            if (navigate) {
              if(pageNumber){
                dispatch(getWarehousePageData(pageNumber+1));
              }
              else{
                dispatch(clearWarehousePageData());
              }
              Swal.fire(
                {
                  title: "Saved!",
                  text: "Warehouse updated successfully!",
                  icon: "success",
                  timer: 2000,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  }
                }
              ).then(() => {
                navigate('/warehouse');
              });
            }
            else {
              toast.success('Warehouse status updated successfully!');
            }
          }
        }
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        if (pageNumber) {
          dispatch(getWarehousePageData(pageNumber));
        }
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};


/*
 * Service for deleting warehouse
*/
export const deleteWarehouseService = (deleteId: number, token: string, pageNumber: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoader());
      const response = await deleteApiService(API_URL.BASE_URL + API_URL.WAREHOUSE + '/' + deleteId, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(clearWarehouseData());
        toast.success('Warehouse deleted successfully!');
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
        dispatch(getWarehousePageData(pageNumber));
      }
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
      
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  };
};