const adminFilterInitialState = {
    phone:'', 
    isActive: '',
    isDelete: ''
};

/**
 * Store admin user filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const adminFilterReducer = (state = adminFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_ADMIN_FILTER':
            return {
                ...state,
                phone: action.payload.phone,
                isActive: action.payload.isActive,
                isDelete: action.payload.isDelete
            }
        case 'CLEAR_ADMIN_FILTER':
            return adminFilterInitialState;
        default:
            return state;
      };
}