/**
 * storing admin user filterd data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getAdminFilterData = ({phone, isActive, isDelete}) => ({
    type: 'GET_ADMIN_FILTER',
    payload: {phone, isActive, isDelete}
})

/**
 * clearing admin user filterd data
 * @returns {*}
 */
export const clearAdminFilterData = () => ({
    type: 'CLEAR_ADMIN_FILTER'
})