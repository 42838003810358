/**
 * Set leaf level category list data into reducer
 * @param {*} leaf 
 * @returns {*}
 */
export const getLeafLevelData = (leaf) =>({
    type: 'GET_LEAF_DATA',
    payload: leaf
})

/**
 * clear leaf level category list data
 * @returns {*}
 */
export const clearLeafLevelData = () =>({
    type: 'CLEAR_LEAF_DATA'
})

/**
 * set total leaf level category list data into reducer (No pagination)
 * @param {*} leafCategory 
 * @returns {*}
 */
export const getTotalLeafLevelData = (leafCategory) => ({
    type: 'GET_TOTAL_LEAF_DATA',
    payload: leafCategory
})

/**
 * clear total leaf level category list data (No pagination)
 * @returns {*}
 */
export const clearTotalLeafLevelData = () => ({
    type: 'CLEAR_TOTAL_LEAF_DATA'
})