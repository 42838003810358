import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import Toggle from '../../../components/Toggle/Toggle';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationMessage } from '../../../Utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { handleCancleClick, handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import { formatDate } from '../../../services/Regular-services/DateService';
import { toast } from 'react-toastify';
import { policyAdd, policyUpdate } from '../../../redux/Actions/policyAction/PolicyAction';

const validationSchema = Yup.object({
  PolicyName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
  PolicyType: Yup.string().required(ValidationMessage.TYPE_SELECT)
})

export default function Addpolicy() {

  const [initialValues, setInitialValues] = useState({
    PolicyName: '',
    PolicyType:'',
    PolicyContent:'',
    status: true
  })

  //Data on Edit and View
  const location = useLocation();
  const { Data } = location.state || {};

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        PolicyName: Data.data.name || '',
        PolicyType: Data.data.type || '',
        PolicyContent: Data.data.content || '',
        status: Data.data.isActive || false,
      });
    }
  }, [Data])

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Getting Data from reducer
  const policyData = useSelector(state => state.policy.items);

  //Handling form submission
  const handleSubmit = (values) => {
    // handlesaveClick();
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);
    if(!Data){
      let newId;
      if (policyData.length === 0) {
        newId = policyData.length + 1;
      } else {
        const lastKey = policyData[policyData.length - 1].id;
        newId = lastKey + 1;
      }
      dispatch(policyAdd({id: newId, name: values.PolicyName, type: values.PolicyType, isActive: values.status, date: formattedDate, description: values.PolicyContent, isDelete: false}));
      toast.success('Policy added successfully!');
      setTimeout(()=>{
        navigate('/policy-list');
      }, 400)
    }

    else{
      handlesaveClick(dispatch,
        policyUpdate({id: Data.data.id,
           name: values.PolicyName, 
           type: values.PolicyType, 
           isActive: values.status, 
           date: formattedDate, 
           description: values.PolicyContent, 
           isDelete: Data.data.isDelete}),
          navigate, '/policy-list'
        );
    }
  }

  const brandtypeOptions = [
    { value: "red", label: "Red" },
    { value: "blue", label: "Blue" },
  ];

  return (
    <>
    <div className="main-section add-image-section">
      <div className="sidebar-content">
        <Sidebar />
      </div>
      <div className="body-content">
        <Header />
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>{(Data && Data.isEdit===true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ': 'Add ')} Policy</h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={()=>navigate('/home')}>Home</Breadcrumb.Item>
                <Breadcrumb.Item onClick={()=>navigate('/policy-list')}>Policy list</Breadcrumb.Item>
                <Breadcrumb.Item active>{(Data && Data.isEdit===true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ': 'Add ')} Policy</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>{(Data && Data.isEdit===true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ': 'Add ')} Policy</h4>
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
            {({setFieldValue, values})=>(
              <Form>
              <div className="add-roles-form row">
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">Policy Name<span className="required-label"></span></h4>
                    </div>
                    <Input
                      type="text"
                      name="PolicyName"
                      label="Policy Name"
                      placeholder="Policy Name Title"
                      className="add-roles"
                      maxLength={50}
                      disabled={Data && Data.isEdit === false}
                      Regex={/[^a-zA-Z\s]/g}
                      value={values.PolicyName}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Select Policy Type<span className="required-label"></span></h4>
                    <Field
                    className="select-menu mb-2"
                    name="PolicyType"
                    options={brandtypeOptions}
                    component={CustomSelect}
                    placeholder="Select..."
                    isMulti={false}
                    disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Policy Content</h4>
                    <Field maxLength={255} value={(Data && Data.data)? Data.data.description : null} name="PolicyContent" disabled={Data && Data.isEdit === false} component={CKEditorCpmponent} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Status</h4>
                    <Field name='status' disabled={Data && Data.isEdit === false} setFieldValue={setFieldValue} component={Toggle}/>
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <div className="btn-submit mt-3">
                      {
                        ((Data && Data.isEdit === true) || (!Data)) && (
                          <>
                            <Button type="submit" name="Save" className="btn login-btn"/>
                            <Button
                              type="button"
                              name="Cancel"
                              className="btn clear-btn login-btn" 
                              onClick={() => handleCancleClick('/policy-list', navigate)}
                            />
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            )}
          </Formik>
        </div>
        <Footer />
      </div>
    </div>
  </>
  )
}
