import { Dispatch } from "redux";
import { addUpdateBranchRequest, getBranchListRequest, getBranchListResponse } from "./BranchModal";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage, toastClass } from "../../../Utils/Utils";
import { clearBranchData, clearTotalBranchData, getBranchData, getTotalBranchData } from "../../../redux/Actions/BranchAction/BranchAction";
import { toast } from "react-toastify";
import { postApiService } from "../../../services/API-services/PostService";
import { putApiService } from "../../../services/API-services/PutService";
import { clearBranchPageData, getBranchPageData } from "../../../redux/Actions/BranchAction/BranchPageAction";
import Swal from "sweetalert2";
import { deleteApiService } from "../../../services/API-services/DeleteService";

let errorShow = false;

/*
 * Get branch list service
*/
export const getAllBranchService = (
    branchListRequest: getBranchListRequest,
    token: string, dispatch: Dispatch, total?: any
): Promise<getBranchListResponse> => {
    return new Promise<getBranchListResponse>(async (resolve, reject) => {
        try {
            dispatch(setLoader())
            let queryParams = '';
            // Adding query parameters if they are provided
            if (branchListRequest.p_skip !== undefined && branchListRequest.p_skip !== null) {
                queryParams += `p_skip=${branchListRequest.p_skip}&`;
            }
            if (branchListRequest.p_limit !== undefined && branchListRequest.p_limit !== null) {
                queryParams += `p_limit=${branchListRequest.p_limit}&`;
            }
            if (branchListRequest.p_name !== undefined && branchListRequest.p_name !== null) {
                queryParams += `p_name=${branchListRequest.p_name}&`;
            }
            if (branchListRequest.p_pincode !== undefined && branchListRequest.p_pincode !== null) {
                queryParams += `p_pincode=${branchListRequest.p_pincode}&`;
            }
            if (branchListRequest.p_is_active !== undefined && branchListRequest.p_is_active !== null) {
                queryParams += `p_is_active=${branchListRequest.p_is_active}&`
            }
            if (branchListRequest.p_IsDeleted !== undefined && branchListRequest.p_IsDeleted !== null) {
                queryParams += `p_IsDeleted=${branchListRequest.p_IsDeleted}&`
            }
            const response = await getApiService(API_URL.BASE_URL + API_URL.BRANCH + (queryParams && queryParams !== '' ? `?${queryParams}` : ''), token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                if (total && total === true) {
                    dispatch(getTotalBranchData(response));
                }
                else if (total && total === 'filter') {
                    toast.success('Filter success!');
                    dispatch(getBranchData(response));
                }
                else {
                    dispatch(getBranchData(response));
                }
            }
            else {
                if (!document.querySelector(toastClass)) {
                    toast.error(response?.message ? (response.message === 'Permission does not exists.' ? 'Branch permission does not exists.' : response.message) : ValidationMessage.SOMETHING_WENT_WRONG);
                }
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
            }
            resolve(response)
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }
            
        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    });
};

/*
 * Service for adding and updating branch
*/
export const addUpdateBranchService = (
    addUpdateBranchRequest: addUpdateBranchRequest,
    token: string, type: string, navigate?: any, pageNumber?: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setLoader());
            let response: any;
            if (type === 'add') {
                response = await postApiService(API_URL.BASE_URL + API_URL.BRANCH, token, addUpdateBranchRequest);
            }
            if (type === 'update') {
                response = await putApiService(API_URL.BASE_URL + API_URL.BRANCH, token, addUpdateBranchRequest);
            }
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                dispatch(clearBranchData());
                dispatch(clearTotalBranchData());
                if (type === 'add') {
                    dispatch(clearBranchPageData());
                    toast.success('Branch added successfully!');
                    navigate('/branch');
                }
                if (type === 'update') {
                    if (navigate && navigate === 'undo') {
                        toast.success('Branch undeleted successfully!');
                    }
                    else {
                        if (navigate) {
                            if (pageNumber) {
                                dispatch(getBranchPageData(pageNumber + 1));
                            }
                            else {
                                dispatch(clearBranchPageData());
                            }
                            Swal.fire(
                                {
                                    title: "Saved!",
                                    text: "Branch updated successfully!",
                                    icon: "success",
                                    timer: 2000,
                                    showClass: {
                                        popup: 'animate__animated animate__fadeInDown'
                                    },
                                    hideClass: {
                                        popup: 'animate__animated animate__fadeOutUp'
                                    }
                                }
                            ).then(() => {
                                navigate('/branch');
                            });
                        }
                        else {
                            toast.success('Branch status updated successfully!');
                        }
                    }
                }
            }
            else {
                toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
                if (pageNumber) {
                    dispatch(getBranchPageData(pageNumber));
                }
            }
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }
            
        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    };
};

/*
 * Service for deleting branch
*/
export const deleteBranchService = (deleteId: number, token: string, pageNumber: number) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setLoader());
            const response = await deleteApiService(API_URL.BASE_URL + API_URL.BRANCH + '/' + deleteId, token);
            if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
                dispatch(clearBranchData());
                dispatch(clearTotalBranchData());
                toast.success('Branch deleted successfully!');
            }
            else {
                toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
                errorShow = true;
                if (response?.message && response.message === 'Unauthorized access!') {
                    removePersistStorage(dispatch);
                    // dispatch(logout());
                }
                dispatch(getBranchPageData(pageNumber));
            }
        } catch (error) {
            if (errorShow === false) {
                toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
            }
            
        } finally {
            errorShow = false;
            dispatch(clearLoader());
        }
    };
};