import React from 'react';
import { useSelector } from 'react-redux'
import './UtilsClass.scss'
import persistStore from 'redux-persist/es/persistStore';
import { store } from '../redux/Store';
import storage from 'redux-persist/lib/storage';
import { clearSidebarData } from '../redux/Actions/SidebarAction/SidebarAction';
import { logout } from '../redux/Actions/authAction/AuthAction';

/**
 * Validation messages
 */
export const ValidationMessage = {
    EMAIL_REQUIRED: 'Email is required!',
    MAX_EMAIL_LIMIT: 'Email must be at most 64 characters!',
    INVALID_EMAIL: 'Email is invalid!',
    PASSWORD_REQUIRED: 'Password is required!',
    OTP_REQUIRED: 'OTP is required!',
    INVALID_PASSWORD_LENGTH: 'Password must be at least 8 characters!',
    INVALID_PASSWORD: (
        <div className='passwordValidate'>
            <p>Password must match the following criteria:</p>
            <ul>
                <li>Minimum 8 characters allowed!</li>
                <li>Maximum 15 characters allowed!</li>
                <li>Must contain at least one uppercase letter!</li>
                <li>Must contain at least one lowercase letter!</li>
                <li>Must contain at least one special character!</li>
            </ul>
        </div>
    ),
    CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required!',
    CONFIRM_PASSWORD: "The confirmed password doesn't match with password!",
    USERNAME_REQUIRED: 'Username is required!',
    NAME_REQUIRED: 'Name is required!',
    FIRST_NAME_REQUIRED: 'First name is required!',
    LAST_NAME_REQUIRED: 'Last name is required!',
    TYPE_REQUIRED: 'Type is required!',
    TYPE_SELECT: 'Please select type!',
    CATEGORY_REQUIRED: 'Category is required!',
    SINGLE_CATEGORY_SELECT: 'Please select category!',
    MULTI_CATEGORY_SELECT: 'Please select at least 1 category!',
    SUB_CATEGORY_REQUIRED: 'Sub Category is required!',
    SINGLE_SUB_CATEGORY_SELECT: 'Please select sub category!',
    MULTI_SUB_CATEGORY_SELECT: 'Please select at least 1 sub category!',
    LEAF_LEVEL_CATEGORY_REQUIRED: 'Leaf Level Category is required!',
    SINGLE_LEAF_CATEGORY_SELECT: 'Please select leaf level category!',
    MULTI_LEAF_CATEGORY_SELECT: 'Please select at least 1 leaf level category!',
    CATEGORY_NAME_REQUIRED: 'Category name is required!',
    CATEGORY_TYPE_REQUIRED: 'Category type is required!',
    ATTRIBUTE_TYPE_REQUIRED:'Attribute type is required',
    CATEGORY_CODE_REQUIRED: 'Category code is required!',
    TITLE_REQUIRED: 'Title is required!',
    PRICE_REQUIRED: 'Price is required!',
    STOCK_REQUIRED: 'Stock is required!',
    DATE_REQUIRED: 'Date is required!',
    CODE_REQUIRED: 'Code is required!',
    SINGLE_IMAGE_TYPE: 'Please select image type!',
    MULTI_IMAGE_TYPE: 'Please select at least 1 image type!',
    UPLOAD_IMAGE: 'Please upload image!',
    TAG_REQUIRED: 'Tag is required!',
    SINGLE_TAG_SELECT: 'Please select tag!',
    MULTI_TAG_SELECT: 'Please select at least 1 tag!',
    AUTHOR_NAME_REQUIRED: 'Author name is required!',
    TIME_REQUIRED: 'Time is required!',
    FIELD_REQUIRED: 'This field is required!',
    PRODUCTS_CATEGORIES_SELECT: 'Please select at least 1 product or category!',
    START_DATE_SELECT: 'Please select start date!',
    END_DATE_SELECT: 'Please select end date!',
    URL_REQUIRED: 'URL is required!',
    DESCRIPTION_REQUIRED: 'Description is required!',
    OTP_LENGTH: 'Please enter a 6 digit OTP!',
    OTP_4_LENGTH: 'Please enter a 4 digit OTP!',
    GENDER_SELECT: 'Please select gender!',
    ADDRESS_REQUIRED: 'Address is required!',
    PINCODE_REQUIRED: 'Pincode is required!',
    SINGLE_BRAND_SELECT: 'Please select brand!',
    MULTI_BRAND_SELECT: 'Please select at least 1 brand!',
    SOMETHING_WENT_WRONG: 'Something went wrong!',
    MOBILE_REQUIRED: 'Mobile number is required!',
    MOBILE_LENGTH: 'Please enter a 10 digit mobile number!',
    STATUS_REQUIRED: 'Status is required'
}

/**
 * Regex constants
 */
export const Regex = {
    EmailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    PasswordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s]).{8,15}$/
}

/**
 * Constants for API calling
 */
export const API_URL = {
    BASE_URL: process.env.REACT_APP_API_URL + 'api/',
    TOKEN_URL: process.env.REACT_APP_API_URL,
    LOGIN: 'auth/login',
    ROLE: 'roles',
    COMPANY:'company',
    CATEGORY: 'categories',
    SUB_CATEGORY: 'subCategory',
    LEAF_LEVEL_CATEGORY: 'leafLevelCategory',
    TAGS: 'tags',
    REQUEST_OTP: 'auth/forgot-password?email=',
    VERIFY_OTP: 'auth/verify-otp?',
    RESET_PASSWORD: 'auth/reset-password',
    USERS: 'users',
    CUSTOMER: 'users/customer',
    VERIFY_EMAIL: 'auth/verifyEmail',
    SIDEBAR: 'sideMenu',
    USER_TYPE: 'users/types/all',
    IMAGEMANAGER: 'imagemanager',
    BRANDS: 'brands',
    PRODUCT: 'product',
    ATTRIBUTES: 'attributes',
    WAREHOUSE: 'warehouse',
    PACKAGER: 'packagerandmanufacturer',
    DISCOUNT: 'discount',
    DISCOUNT_BRAND: 'discount/brands/all',
    DISCOUNT_CATALOGUE: 'discountCatalogue',
    INVENTORY: 'inventory',
    SEQUENCE: 'categories/sequence',
    BRANCH: 'branches',
    SERVICE_PROVIDER: 'serviceprovider',
    SERVICE: 'service',
    NOTIFICATION: 'notifications',
    LEAF_FILE_UPLOAD: 'leafLevelCategory/fileUpload',
    SUB_CATEGORY_UPLOAD: 'subCategory/fileUpload',
    CATEGORY_UPLOAD: 'categories/fileUpload',
    BRAND_UPLOAD: 'brands/fileUpload',
    PRODUCT_UPLOAD: 'product/fileUpload',
    SERVICE_UPLOAD: 'service/fileUpload',
    SERVICE_PROVIDER_UPLOAD: 'serviceprovider/fileUpload',
    ORDER: 'orders',
    PRODUCT_COUNT: "/product-count",
    P_ORDER_STATUS: 0,
    PLACE_ORDER: 'orders',
    USER_DETAIL: 'client/user',
    DASHBOARD: '/dashboard'
}

/**
 * Function for getting generated token from local storage
 * @returns {*}
 */
export const getToken = () => {
    return localStorage.getItem('generateToken');
}

/**
 * Function for setting generated token from local storage
 * @param {*} token 
 */
export const setToken = (token) => {
    localStorage.setItem('generateToken', token)
}

/**
 * Function for removing token
 */
export const removeToken = () => {
    localStorage.removeItem('generateToken');
}


/**
 * Getting Auth token for API calling
 */
export default function UtilsData() {
    const authData = useSelector(state => state.auth);
    return authData?.user?.token ? authData.user.token : null;
}


/**
 * Function to convert image URL to File object
 * @param {*} imageUrl 
 * @returns {*}
 */
export const imageUrlToFile = async (imageUrl) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const fileName = getFileNameFromUrl(imageUrl);
    return new File([blob], fileName);
}

/**
 * Function to extract file name from URL
 * @param {*} imageUrl 
 * @returns {*}
 */
const getFileNameFromUrl = (url) => {
    // Extract the last part of the URL after the last '/'
    const parts = url.split('/');
    return parts[parts.length - 1];
}

/**
 * Image type list
 */
export const imageTypeOptions = [
    { value: 1, label: 'Home Banner' },
    { value: 2, label: 'Home Banner Mobile' },
    { value: 3, label: 'Brand Logo' },
    { value: 4, label: 'Brand Banner' },
    { value: 5, label: 'Category' },
    { value: 6, label: 'Sub Category' },
    { value: 7, label: 'Leaf Level Category' },
    { value: 8, label: 'Product' },
    { value: 9, label: 'Blog Banner' },
    { value: 10, label: 'Blog' },
    { value: 11, label: 'Discount Catalogue' },
    { value: 12, label: 'Attribute' },
    { value: 13, label: 'Service' }
];

/**
 * Logout on unauthorized access
 * @param {*} dispatch 
 */
export const removePersistStorage = async (dispatch) => {
    await persistStore(store, { storage }).purge();
    dispatch(clearSidebarData());
    dispatch(logout());
}

// function to convert values on dashboard
export const convertNumbers = (number) => {
    if (!number) return '';

    if (number < 1000) {
        return number.toString();
    } else if (number >= 1000 && number < 1000000) {
        return ((Math.floor(number / 100) / 10) + "K").toString();
    } else if (number >= 1000000 && number < 1000000000) {
        return ((Math.floor(number / 100000) / 10) + "M").toString();
    } else {
        return ((Math.floor(number / 100000000) / 10) + "B").toString();
    }
}

export const restoreNumber = (str) => {
    if (!str) return 0;
    let value=str;
    if (str.includes('K') || str.includes('B') || str.includes('M')) {
        value = str.slice(0, -1);
        value = Number(value)
    }
    if (str.includes('K')) {
        return value * 1000;
    } else if (str.includes('M')) {
        return value * 1000000;
    } else if (str.includes('B')) {
        return value * 1000000000;
    } else {
        return Number(value);
    }
}

export const toastClass = '.Toastify__toast-body';