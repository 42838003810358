const companyPageInitialState = { 
    items: 1
};

/**
 * Store company page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const CompanyPageReducer = (state = companyPageInitialState, action) => {
    switch (action.type) {
        case 'GET_COMPANY_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_COMPANY_PAGE':
            return companyPageInitialState;
        default:
            return state;
      };
}