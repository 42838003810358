const imageInitialState = { 
    items: []
};

/**
 * Upload image reducer
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const uploadImageReducer = (state = imageInitialState, action) => {
    switch (action.type) {
        case 'UPLOAD_IMAGE_DATA':
            return {
                ...state,
                items: [...action.payload]
            };
        default:
            return state;
      };
}