const chechboxInitialState = { 
    items: []
};

/**
 * Store checkbox input data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const CheckboxReducer = (state = chechboxInitialState, action) => {
    switch (action.type) {
        case 'CHECBOX_ACTION':
            return {
                ...state,
                items: action.payload
            }
        default:
            return state;
      };
}