/**
 * Setting branch filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getBranchFilterData = ({name, pincode, isActive, isDelete}) => ({
    type: 'GET_BRANCH_FILTER',
    payload: {name, pincode, isActive, isDelete}
})

/**
 * Clearing branch filtered data
 * @returns {*}
 */
export const clearBranchFilterData = () => ({
    type: 'CLEAR_BRANCH_FILTER'
})