/**
 * Set order list data into reducer
 * @param {*} order 
 * @returns {*}
 */
export const getOrderData = ({order, time, totalRecords, api, dataCount}) =>({
    type: 'GET_ORDER_DATA',
    payload: {order, time, totalRecords, api, dataCount}
})

export const addOrderData = ({order, time, totalRecords, api, dataCount}) => ({
    type: 'ADD_ORDER',
    payload: {order, time, totalRecords, api, dataCount}
})

export const updateOrderStatus = ({orderId, status}) => ({
    type: 'UPDATE_ORDER_STATUS',
    payload: {orderId, status}
})

/**
 * clear order list data
 * @returns {*}
 */
export const clearOrderData = () =>({
    type: 'CLEAR_ORDER_DATA'
})

/**
 * set total order list data into reducer (No pagination)
 * @param {*} orderData 
 * @returns {*}
 */
export const getTotalOrderData = (orderData) => ({
    type: 'GET_TOTAL_ORDER_DATA',
    payload: orderData
})

/**
 * clear total order list data (No pagination)
 * @returns {*}
 */
export const clearTotalOrderData = () => ({
    type: 'CLEAR_TOTAL_ORDER_DATA'
})