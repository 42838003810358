const sidebarInitialState = { 
    items: false
};

/**
 * sidebar list reducer
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const sidebarReducer = (state = sidebarInitialState, action) => {
    switch (action.type) {
        case 'OPEN_SIDEBAR':
            return {
                ...state,
                items: action.payload
            }
        default:
            return state;
      };
}