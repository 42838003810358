import { Dropdown } from "react-bootstrap";
import { IoNotifications } from "react-icons/io5";
import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import "./Notification.scss"
import { useDispatch, useSelector } from "react-redux";
import { deleteNotificationById, getNotificationById, getNotificationListService } from "./Notification-service/NotificationService";
import UtilsData from "../../Utils/Utils";
import DateTimeSplitterService from "../../services/Regular-services/DateTimeSplitterService/DateTimeSplitterService";
import { toast } from "react-toastify";

export default function Notification() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tokenData = UtilsData();
    const notification = useSelector(state => state.notification);

    const notificationLoader = useSelector(state => state.notificationLoader?.items);

    /**
     * Fetching Notification data
     */
    const fetchNotification = () => {
        const requestBody = {
            p_skip: 0,
            p_take: 1000000
        }
        getNotificationListService(requestBody, tokenData, dispatch, navigate);
    }

    useEffect(() => {
        if (notification && notification.items && notification.items.length <= 0) {
            fetchNotification();
        }
        // eslint-disable-next-line
    }, [notification])


    /**
     * Handling Navigation
     */
    const handleNavigate = async (route, data) => {
        try {
            // Call the getNotificationById function
            const notificationDetail = await getNotificationById(data.NotificationId, tokenData, dispatch, navigate);
            // Execute the returned async function to fetch the data
            if (notificationDetail && notificationDetail.status === 'success') {
                const Data = {
                    data: data
                }
                navigate(route, { state: { Data } })
            }
            return notificationDetail;
        } catch (error) {
            toast.error('Something went wrong, please try again!');
        }
    }

    /**
     * Handling delete notification
     * @param {*} notificationData 
     * @param {*} event 
     */
    const handleDeleteClick = (notificationData, event) => {
        if (window.location.pathname === '/import-data') {
            dispatch(deleteNotificationById(notificationData.NotificationId, tokenData, navigate));
            // handledeletClick(notificationData, dispatch, 'notification', tokenData, navigate);
        }
        else {
            event.stopPropagation();
            dispatch(deleteNotificationById(notificationData.NotificationId, tokenData, navigate));
        }
    }
    return (
        <>
            <Dropdown className="notifications-detail">
                <Dropdown.Toggle variant="success" id="notifications-basic">
                    <div className="notifications">
                        <IoNotifications />
                        {(notification?.items && notification.items[0]?.unseen_records && notification.items[0].unseen_records > 0) ? (
                            <div className="notifications-number">
                                <span>{notification.items[0].unseen_records}</span>
                            </div>
                        ) : ''}
                    </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {notification?.items && notification.items.length > 0 && notification.items[0].data.length > 0 ? (notification.items[0].data.map((item, index) => (
                        <Dropdown.Item key={index}>
                            <div className="notification-detail-icon">
                                <div className="name-detail-notifications" onClick={() => handleNavigate("/import-data", item)}>
                                    {item.title}
                                    <DateTimeSplitterService dateTimeString={item.createdAt} />
                                </div>
                                <div className="notification-icon">
                                    <MdDeleteOutline onClick={(event) => handleDeleteClick(item, event)} />
                                </div>
                            </div>
                        </Dropdown.Item>
                    ))) : (
                        <>
                            {notificationLoader && (
                                <b className='no-notification'>...Loading Notifications</b>
                            )}
                            {!notificationLoader && (
                                <b className="no-notification">No new notifications</b>
                            )}
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}