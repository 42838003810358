/**
 * Setting admin user list data into reducer
 * @param {*} adminData 
 * @returns {*}
 */
export const getAdminData = (adminData) => ({
    type: 'GET_ADMIN_DATA',
    payload: adminData
})

/**
 * clearing admin user list data
 * @returns {*}
 */
export const clearAdminData = () => ({
    type: 'CLEAR_ADMIN_DATA'
})

/**
 * setting user type data into reducer
 * @param {*} user 
 * @returns {*}
 */
export const getUserType = (user) => ({
    type: 'GET_USER_TYPE',
    payload: user
})