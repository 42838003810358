import { toast } from "react-toastify";
import { API_URL, ValidationMessage } from "../../Utils/Utils";
import { getApiService } from "../../services/API-services/GetService";
import { clearLoader, setLoader } from "../../redux/Actions/LoaderAction";

export const verifyOtp = (email, otp, token, navigate) => {
    return async (dispatch) => {
      try {
        dispatch(setLoader());
        const response = await getApiService(API_URL.BASE_URL+API_URL.VERIFY_OTP+'email='+email+'&otp='+otp, token)
        if(response && (response.status === 200 || response.status === '200' || response.status === 'success')){
            toast.success('OTP verified successfully!');
            navigate('/reset-password', { state: { email: email }});
        }
        else{
            if(response === 'unauthenticated'){
                toast.error('Unauthenticated user!');
              }
              else{
                toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
              }
        }
      } catch (error) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      } finally {
        dispatch(clearLoader());
      }
    };
  };