/**
 * Set sidebar data into reducer
 * @param {*} role 
 * @returns {*}
 */
export const getSidebarData = (role) => ({
    type: 'GET_SIDEBAR_DATA',
    payload: role
})

/**
 * Clear sidebar data
 * @returns {*}
 */
export const clearSidebarData = () => ({
    type: 'CLEAR_SIDEBAR_DATA'
})