import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import './Sidebar'
const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0,
      when: "beforeChildren",
    },
  },
};
const menuItemAnimation = {
  hidden: (i) => ({
    padding: 0,
    y: "-100%",
    transition: {
      duration: 0,
    },
  }),
  show: (i) => ({
    y: 0,
    transition: {
      duration: 0,
    },
  }),
};

/**
 * Making active route menu open
 * @param {*} param0 
 * @returns {*}
 */
const SidebarMenu = ({ route, showAnimation, isOpen, setIsOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [activeUrl, setActiveUrl] = useState(null);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen && !isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen, isMenuOpen]);

  /**
   * Url of add/update form pages
   */
  const listPagePaths = [
    { pagePath: "/customer-view", listPath: "/customer" },
    { pagePath: "/order-prosessing", listPath: "/order" },
    { pagePath: "/cart-detail", listPath: "/cart" },
    { pagePath: "/add-brand", listPath: "/brand-list" },
    { pagePath: "/edit-brand", listPath: "/brand-list" },
    { pagePath: "/addcategory", listPath: "/category" },
    { pagePath: "/edit-category", listPath: "/category" },
    { pagePath: "/add-sub-category", listPath: "/sub-category" },
    { pagePath: "/edit-sub-category", listPath: "/sub-category" },
    { pagePath: "/add-product", listPath: "/product" },
    { pagePath: "/add-leaf-level-category", listPath: "/leaf-level-category" },
    { pagePath: "/edit-leaf-level-category", listPath: "/leaf-level-category" },
    { pagePath: "/add-tags", listPath: "/tags" },
    { pagePath: "/adduser", listPath: "/adminusers" },
    { pagePath: "/edit-user", listPath: "/adminusers" },
    { pagePath: "/addRoles", listPath: "/roles" },
    { pagePath: "/add-discount", listPath: "/discount" },
    { pagePath: "/edit-discount", listPath: "/discount" },
    { pagePath: "/add-discount-catalogue", listPath: "/discount-catalogue" },
    { pagePath: "/edit-discount-catalogue", listPath: "/discount-catalogue" },
    { pagePath: "/add-policy", listPath: "/policy-list" },
    { pagePath: "/edit-policy", listPath: "/policy-list" },
    { pagePath: "/add-faq", listPath: "/faq-list" },
    { pagePath: "/add-blog", listPath: "/blog-list" },
    { pagePath: "/edit-blog", listPath: "/blog-list" },
    { pagePath: "/add-tax", listPath: "/tax" },
    { pagePath: "/add-warehouse", listPath: "/warehouse" },
    { pagePath: "/add-tv", listPath: "/tv" },
    { pagePath: "/add-inventory", listPath: "/inventory" },
    { pagePath: "/add-packager", listPath: "/packager-and-manufacturer" },
    { pagePath: "/add-attribute", listPath: '/attributes' },
    { pagePath: "/add-branch", listPath: '/branch' },
    { pagePath: "/add-service", listPath: "/service" },
    { pagePath: "/add-service-provider", listPath: "/service-provider" },
    { pagePath: "/add-order", listPath: "/orders" },
    { pagePath: "/edit-order", listPath: "/orders" },
    { pagePath: "", listPath: "" }
    // Add more list page paths as needed
  ];

  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const newActiveData = route.subRoutes.some(subRoute => {
      // Check if window location pathname matches any subRoute path
      if (subRoute.path === window.location.pathname) {
        setActiveUrl(subRoute.path);
        return true; // If there's an exact match, return true
      }
      // Otherwise, check if any pagePath matches the window location pathname
      const matchedPagePath = listPagePaths.find(item => item.pagePath === window.location.pathname);
      if (matchedPagePath && matchedPagePath.listPath === subRoute.path) {
        setActiveUrl(subRoute.path)
        return true; // If listPath matches, return true
      }
      setActiveUrl(null)
      return false; // If no match found, return false
    });
    setIsActive(newActiveData);
    // eslint-disable-next-line
  }, [window.location.pathname])

  useEffect(() => {
    // If any subRoute is active, open the menu
    if (!isOpen) {
      setIsMenuOpen(false)
    }
    else {
      if (isActive) {
        setIsMenuOpen(true);
      }
    }
  }, [isOpen, isActive]);

  return (
    <>
      <div className="menu" onClick={toggleMenu}>
        <div className="menu-item">
          <div className="icon">{route.icon}</div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="link_text">
                {route.name}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {isOpen && (
          <motion.div
            animate={isMenuOpen
              ? {
                rotate: 0,
              }
              : { rotate: 0 }
            }>
            {!isMenuOpen && (
              <FaAngleDown />
            )}
            {isMenuOpen && (
              <FaAngleUp />
            )}
          </motion.div>
        )}
      </div>
      {isMenuOpen && (
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              variants={menuAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="menu_container">
              {route.subRoutes.map((subRoute, i) => {
                return (
                  <motion.div variants={menuItemAnimation} key={i} custom={i}>
                    <NavLink to={subRoute.path} className={`link${activeUrl === subRoute.path ? ' active' : ''}`}>
                      <div className="icon">{subRoute.icon}</div>
                      <motion.div className="link_text">{subRoute.name}</motion.div>
                    </NavLink>
                  </motion.div>
                )
              })}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default SidebarMenu;