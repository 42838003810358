const adminInitialState = { 
    items: []
};

/**
 * Store admin user list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const AdminUserReducer = (state = adminInitialState, action) => {
    switch (action.type) {
        case 'GET_ADMIN_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_ADMIN_DATA':
            return adminInitialState;
        default:
            return state;
      };
}

const initialUserType = {
    items: []
}

/**
 * Store total admin user list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const UserTypeReducer = (state = initialUserType, action) => {
    switch (action.type) {
        case 'GET_USER_TYPE':
            return {
                ...state,
                items: action.payload
            }
        default:
            return state;
    }
}