import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Button from "../../../components/Button/Button";
import * as Yup from "yup";
import UtilsData, {
  unitOptionsArray,
  ValidationMessage,
} from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  handleCancleClick,
  handlesaveClick,
} from "../../../components/Alertmessage/Alertmessage";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryItemService } from "../../InventoryItems/InventoryItemService/InventoryItemService";
import {
  convertToBaseUnit,
  GramToKg,
  GramToMg,
  MlToLitre,
} from "../../../services/Regular-services/ConvertUnitService";
import { clearVendorPageData } from "../../../redux/Actions/VendorAction/VendorPageAction";
import {
  addUpdateVendorService,
  getAllVendorService,
} from "../VendorsService.js/VendorService";
import { toast } from "react-toastify";
import "../AddVendors/AddVendors.scss";
import { modifyString } from "../../../services/Regular-services/StringService";
import { getAllNewVendorPersonService } from "../../NewVendorPerson/NewVendorPersonService/NewVendorPersonService";

const AddVendors = () => {
  const [initialValues, setInitialValues] = useState({
    vendor: "",
    payment: "0",
    status: "0",
    ingredients: [],
    allStatus: "0",
  });

  const paymentOption = [
    { value: "0", label: "Pending" },
    { value: "1", label: "UPI" },
    { value: "2", label: "Card" },
    { value: "3", label: "Cash" },
    { value: "4", label: "Other" },
  ];

  const validationSchema = Yup.object({
    payment: Yup.string().required("Payment is required!"),
  });

  const [message, setMessage] = useState("");
  const location = useLocation();
  const { Data } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = UtilsData();
  const totalInventoryItem = useSelector((state) => state.totalInventoryItem);
  const [selectedItems, setSelectedItems] = useState([]);
  const sideBarData = useSelector((state) => state.sideBar.items);
  const [vendorActionData, setVendorActionData] = useState([]);
  const totalVendorPerson = useSelector((state) => state.totalNewVendor);
  const [vendorPersonOptions, setVendorPersonOption] = useState([]);
  const totalUnitsDropdown = useSelector((state) => state.totalUnitsDropdown);
  const [unitOptions, setUnitOptions] = useState([]);

  useEffect(() => {
    if(totalUnitsDropdown && totalUnitsDropdown?.items && totalUnitsDropdown.items.length > 0 && totalUnitsDropdown.items[0]?.data && totalUnitsDropdown.items[0].data.length > 0) {
      const unitData = totalUnitsDropdown.items[0].data.map((unit) => ({
        value: unit.unit_value,
        label: unit.unit,
        id: unit.id,
      }));
      setUnitOptions(unitData)
    }
    else {
      setUnitOptions([]);
    }
  }, [totalUnitsDropdown])

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === "vendors"
      );
      setVendorActionData(actionData);
    }
  }, [sideBarData]);

  useEffect(() => {
    if (Data && Data.data) {
      const ingredientVals = JSON.parse(Data.data.ingredients);
      const selectedVals = ingredientVals.map((item, index) => {
        let quantityInOriginalUnit = parseFloat(item.value);
        let unit = item.unit;
        if (
          unit === "gm" ||
          unit === "kg" ||
          unit === "l" ||
          unit === "ml" ||
          unit === "mg"
        ) {
          if (unit === "gm" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
            unit = "kg";
          } else if (unit === "gm" && quantityInOriginalUnit < 1) {
            quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
            unit = "mg";
          } else if (unit === "ml" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
            unit = "l";
          }
        }
        return {
          ingredientName: item.ingredientName,
          ingredient_id: parseInt(item.ingredient_id),
          value: quantityInOriginalUnit,
          unit: unit,
          status: item.status,
          instruction: item.ingredient_instruction,
          ingredient_price: item.ingredient_price,
        };
      });
      setSelectedItems(selectedVals);
      setInitialValues({
        id: Data.data.id,
        vendor: Data.data.vendor_user_id,
        payment: Data.data.payment,
        status: Data.data.orderStatus,
        allStatus: selectedVals.every((item) => item.status === "1"),
      });
      setMessage(Data.data.instructions);
    }
  }, [Data]);
  const fetchInventoryItem = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 1000000,
      p_is_active: "1",
    };
    getAllInventoryItemService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalInventoryItem &&
      totalInventoryItem.items &&
      totalInventoryItem.items.length > 0
    ) {
      const newInventoryItem = totalInventoryItem.items[0].data.map((item) => {
        let quantityInOriginalUnit = parseFloat(item.thresoldValue);
        let unit = item.thresoldUnit;
        if (
          unit === "gm" ||
          unit === "kg" ||
          unit === "l" ||
          unit === "ml" ||
          unit === "mg"
        ) {
          if (unit === "gm" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = GramToKg(quantityInOriginalUnit);
            unit = "kg";
          } else if (unit === "gm" && quantityInOriginalUnit < 1) {
            quantityInOriginalUnit = GramToMg(quantityInOriginalUnit);
            unit = "mg";
          } else if (unit === "ml" && quantityInOriginalUnit > 999) {
            quantityInOriginalUnit = MlToLitre(quantityInOriginalUnit);
            unit = "l";
          }
        }
        return {
          ingredientName: item?.title,
          IngredientId: item?.IngredientId,
          quantity: 0,
          unit: unit,
          instruction: item?.instruction,
          status: "0", // Ensure `status` is included
          ingredient_price: "0",
          category_id: item?.category_id,
          category_name: item?.category_name,
        };
      });
      if (selectedItems) {
        const updatedInventoryItems = selectedItems.map((selectedItem) => {
          const inventoryItem = newInventoryItem.find(
            (item) => item.IngredientId === selectedItem.ingredient_id
          );
          if (inventoryItem) {
            return {
              ingredientName:
                selectedItem.ingredientName || inventoryItem.ingredientName,
              IngredientId: selectedItem.ingredient_id,
              quantity: parseFloat(selectedItem.value) || 0,
              unit: selectedItem.unit,
              instruction: selectedItem.instruction || "",
              status: selectedItem.status, // Ensure `status` is preserved
              ingredient_price: selectedItem.ingredient_price,
              category_id: inventoryItem.category_id,
              category_name: inventoryItem.category_name,
            };
          }
          return {
            ingredientName: selectedItem.ingredientName,
            IngredientId: selectedItem.ingredient_id,
            quantity: parseFloat(selectedItem.value) || 0,
            unit: selectedItem.unit,
            instruction: selectedItem.instruction || "",
            status: selectedItem.status,
            ingredient_price: selectedItem.ingredient_price,
            category_id: inventoryItem.category_id,
            category_name: inventoryItem.category_name,
          };
        });

        const selectedIngredientIds = selectedItems.map(
          (item) => item.ingredient_id
        );
        const unmatchedItems = newInventoryItem.filter(
          (item) => !selectedIngredientIds.includes(item.IngredientId)
        );

        const finalInventoryItems = [
          ...updatedInventoryItems,
          ...unmatchedItems,
        ];
        if (Data && Data.data.vendor_user_id) {
          const foundVendor = vendorPersonOptions.find(
            (vendor) => vendor.value === Data.data.vendor_user_id
          );
          if (foundVendor) {
            const categoryIds = foundVendor.categoryData.map(
              (cat) => cat.category_id
            );
            const newIngredients = finalInventoryItems.filter((item) =>
              categoryIds.includes(item.category_id)
            );
            setInitialValues((prevValues) => ({
              ...prevValues,
              ingredients: newIngredients,
            }));
          }
        } else {
          setInitialValues((prevValues) => ({
            ...prevValues,
            ingredients: finalInventoryItems,
          }));
        }
      } else {
        setInitialValues((prevValues) => ({
          ...prevValues,
          ingredients: newInventoryItem,
        }));
      }
    } else {
      fetchInventoryItem();
    }
    // eslint-disable-next-line
  }, [totalInventoryItem, selectedItems]);
  /**
   * Fetching vendor data
   */
  const fetchVendorPerson = () => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 100000000,
      p_isActive: "1",
    };
    getAllNewVendorPersonService(categoryRequestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (
      totalVendorPerson &&
      totalVendorPerson.items &&
      totalVendorPerson.items.length === 0
    ) {
      fetchVendorPerson();
    } else {
      if (
        totalVendorPerson.items[0]?.data &&
        totalVendorPerson.items[0].data.length > 0
      ) {
        const newVendorOptions = totalVendorPerson.items[0].data.map(
          (item) => ({
            value: item.id,
            label: item.first_name + " " + item.last_name,
            categoryData: JSON.parse(item.categoryData),
          })
        );
        setVendorPersonOption(newVendorOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalVendorPerson]);

  const tooltipRef = useRef(null);
  //Adding Tooltip
  useEffect(() => {
    if (tooltipRef.current) {
      const tooltip = new window.bootstrap.Tooltip(tooltipRef.current);
      return () => {
        tooltip.dispose();
      };
    }
  }, []);

  const handleIngredientChange = (
    index,
    field,
    value,
    setFieldValue,
    quantity,
    isDisable,
    values
  ) => {
    if (field === "status") {
      if (quantity > 0) {
        const updatedIngredients = [...values.ingredients];
        updatedIngredients[index] = {
          ...updatedIngredients[index],
          [field]: value,
        };
        setFieldValue("ingredients", updatedIngredients);
        setInitialValues((prevValues) => ({
          ...prevValues,
          vendor: values.vendor,
          payment: values.payment,
          status: values.status,
          ingredients: updatedIngredients,
        }));
        if (value && !isDisable) {
          toast.success("Ingredient fields are confirmed");
        }
      } else {
        toast.error("Quantity  fields is empty");
      }
    } else if (field === "allStatus") {
      // Check if there's at least one ingredient with quantity > 0
      const hasQuantity = values.ingredients.some(
        (ingredient) => ingredient.quantity > 0
      );

      if (hasQuantity) {
        const newStatus = value ? "1" : "0";

        // Update all status field
        setFieldValue("allStatus", newStatus);

        // Update status of all ingredients based on their quantity
        const updatedIngredients = values.ingredients.map((ingredient) => ({
          ...ingredient,
          status: ingredient.quantity > 0 ? newStatus : ingredient.status,
        }));

        // Update Formik fields and local state
        setFieldValue("ingredients", updatedIngredients);
        setInitialValues((prevValues) => ({
          ...prevValues,
          vendor: values.vendor,
          payment: values.payment,
          status: values.status,
          ingredients: updatedIngredients,
          allStatus: newStatus,
        }));

        // Display appropriate toast message
        toast.success(
          value
            ? "All ingredients are marked as checked"
            : "All ingredients are marked as unchecked"
        );
      } else {
        toast.error("No ingredients with quantity added");
      }
    } else {
      const updatedIngredients = [...values.ingredients];
      updatedIngredients[index] = {
        ...updatedIngredients[index],
        [field]: value,
      };
      setFieldValue("ingredients", updatedIngredients);
      setInitialValues((prevValues) => ({
        ...prevValues,
        ingredients: updatedIngredients,
      }));
    }
  };

  const shouldDisableIngredient = (ingredients) => {
    if (Data && Data.isEdit === false) return true;
    if (ingredients && ingredients.length > 0) {
      return ingredients.some((ingredient) => {
        if (ingredient !== undefined && ingredient) {
          if (
            (ingredient.status === true ||
              ingredient.status === "1" ||
              ingredient.status === 1) &&
            ingredient.quantity
          ) {
            return true;
          } else {
            return false;
          }
        }
      });
    }
    return false;
  };
  const lineLevelCheckBoxDisable = (ingredientId, value, ingredientsData) => {
    if (Data && Data.isEdit === false) return true;
    else if (Data && Data.data) {
      if (value === "status") {
        const ingredients = Array.isArray(Data.data.ingredients)
          ? Data.data.ingredients
          : JSON.parse(Data.data.ingredients);
        const isMatch = ingredients.some((ingredient) => {
          return (
            Number(ingredient.ingredient_id) === Number(ingredientId) &&
            ingredient.status === "1"
          );
        });

        return isMatch;
      } else if (value === "allStatus") {
        const ingredients = Array.isArray(Data.data.ingredients)
          ? Data.data.ingredients
          : JSON.parse(Data.data.ingredients);
        const isMatch = ingredients.every((ingredient) => {
          return ingredient.status === "1";
        });
        return isMatch;
      } else if (value === "ingredient_price") {
        const ingredients = Array.isArray(Data.data.ingredients)
          ? Data.data.ingredients
          : JSON.parse(Data.data.ingredients);
        const isMatch = ingredients.some((ingredient) => {
          if (
            ingredient.status === "1" ||
            ingredient.status === 1 ||
            ingredient.status === "true" ||
            ingredient.status === true
          ) {
            return (
              Number(ingredient.ingredient_id) === Number(ingredientId) &&
              ingredient.status === "1" &&
              Number(ingredient.ingredient_price) > 0
            );
          } else {
            return (
              Number(ingredient.ingredient_id) === Number(ingredientId) &&
              Number(ingredient.value) === 0
              // ingredient.status === "1" &&
              // Number(ingredient.ingredient_price) > 0
            );
          }
        });

        return isMatch;
      }
    } else {
      if (
        value === "quantity" ||
        value === "unit" ||
        value === "instruction" ||
        value === "ingredient_price"
      ) {
        const isMatch = ingredientsData.some((ingredient) => {
          return (
            Number(ingredient.IngredientId) === Number(ingredientId) &&
            (ingredient.status === "1" ||
              ingredient.status === true ||
              ingredient.status === 1)
          );
        });
        return isMatch;
      }
      // return ingredientsData.some(
      //   (ingredient) =>
      //     (ingredient.status === true ||
      //       ingredient.status === "1" ||
      //       ingredient.status === 1) &&
      //     ingredient.quantity
      // );
    }
  };
  const fetchCategoryIngredients = (option, setFieldValue) => {
    const categoryIds = option.categoryData.map((cat) => cat.category_id);
    const ingredients = initialValues.ingredients;
    const newIngredients = ingredients.filter((item) =>
      categoryIds.includes(item.category_id)
    );
    setFieldValue("ingredients", newIngredients);
    setFieldValue("vendor", option.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };

  const handleSubmit = (values) => {
    const filteredIngredients = values.ingredients
      .map((ingredient) => {
        const quantity = parseFloat(ingredient.quantity);
        return {
          ...ingredient,
          quantity: isNaN(quantity) ? 0 : quantity.toString(),
        };
      })
      .filter(
        (ingredient) =>
          ingredient.quantity > 0 &&
          // Number(ingredient.ingredient_price) > 0 &&
          (ingredient.status === "1" ||
            ingredient.status === true ||
            ingredient.status === "0" ||
            ingredient.status === false)
      );
    const ingredientData = filteredIngredients.map((item) => {
      let { quantityInBaseUnit, unit } = convertToBaseUnit(
        item.quantity,
        item.unit
      );
      return {
        ingredient_id: item.IngredientId,
        ingredient_instruction: item.instruction ? item.instruction : "",
        value: quantityInBaseUnit,
        unit: unit,
        status:
          item.status === true || item.status === 1 || item.status === "1"
            ? "1"
            : "0",
        ingredient_price: item.ingredient_price,
      };
    });

    const requestBody = {
      ...(Data && Data.data && { p_id: values.id }),
      p_vendor_id: values.vendor,
      p_instruction: message,
      p_product_data: ingredientData,
      p_payment: values.payment,
      p_status: values.status,
      p_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    dispatch(clearVendorPageData());
    if (!Data) {
      dispatch(addUpdateVendorService(requestBody, token, "add", navigate));
    } else {
      // toast.success("Please share updated values to vendor");
      handlesaveClick(
        dispatch,
        addUpdateVendorService(requestBody, token, "update", navigate),
        "update",
        null,
        `Do you want to save the changes? <br><span style="font-size: 15px;">(Please share updated values to vendor by clicking on the whatsapp icon in the list)</span>`
      );
    }
  };

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {vendorActionData && vendorActionData.length > 0
                      ? modifyString(vendorActionData[0].name)
                      : "Vendor Orders"}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/vendors")}>
                    {vendorActionData && vendorActionData.length > 0
                      ? vendorActionData[0].name
                      : "Vendor Orders"}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {vendorActionData && vendorActionData.length > 0
                      ? modifyString(vendorActionData[0].name)
                      : "Vendor Orders"}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                {Data && Data.isEdit === true
                  ? "Edit "
                  : Data && Data.isEdit === false
                  ? "View "
                  : "Add "}{" "}
                {vendorActionData && vendorActionData.length > 0
                  ? modifyString(vendorActionData[0].name)
                  : "Vendor Orders"}
              </h4>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => {
                const disableAllFields = shouldDisableIngredient(
                  values.ingredients
                );
                return (
                  <Form>
                    <div className="add-roles-form row">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              Vendor
                              <span className="required-label"></span>
                            </h4>
                            <Field
                              className="select-menu mb-2"
                              name="vendor"
                              options={vendorPersonOptions}
                              component={CustomSelect}
                              placeholder="Select..."
                              isMulti={false}
                              disabled={Data}
                              value={values.vendor}
                              Regex={/[^a-zA-Z\s]/g}
                              styles={customStyles}
                              onchangedata={(option) =>
                                fetchCategoryIngredients(option, setFieldValue)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">
                                Payment
                                <span className="required-label"></span>
                              </h4>
                            </div>
                            <Field
                              className="select-menu mb-2"
                              name="payment"
                              options={paymentOption}
                              component={CustomSelect}
                              placeholder="Select..."
                              isMulti={false}
                              changeData={(value) =>
                                setFieldValue("payment", value)
                              }
                              value={values.payment}
                              disabled={Data && Data.isEdit === false}
                              onChange={(e) =>
                                setFieldValue("payment", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="pb-3">
                          <div className="message-input">
                            <textarea
                              value={message}
                              placeholder="Would you like to add generalized instruction🧐? "
                              onChange={(event) =>
                                setMessage(event.target.value)
                              }
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>

                        {values.vendor && (
                          <div className="col-12">
                            <p className="note-msg">
                              Note: Confirm order via checkbox and update
                              vendor.
                            </p>
                            <div className="row">
                              {Data ? (
                                <div className="col-md-1">
                                  <div className="paragraph-editor">
                                    <div className="checkox-heading">
                                      <input
                                        data-bs-toggle="tooltip"
                                        title="Update all!"
                                        className="vendor-checkbox-input"
                                        type="checkbox"
                                        name={`allStatus`}
                                        value={values.allStatus}
                                        checked={
                                          values.allStatus === "1" ||
                                          values.allStatus === 1 ||
                                          values.allStatus === true
                                        }
                                        onChange={(e) =>
                                          handleIngredientChange(
                                            null, // No index needed for allStatus
                                            "allStatus",
                                            e.target.checked,
                                            setFieldValue,
                                            null, // No quantity needed
                                            disableAllFields,
                                            values
                                          )
                                        }
                                        disabled={
                                          (Data && Data.isEdit === false) ||
                                          disableAllFields
                                        }
                                      />
                                      {/* <h4 className="paragraph-title paragraph-titletwo">
                                      Update Stocks
                                    </h4> */}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="col-md-1">
                                  <div className="paragraph-editor">
                                    <div className="checkox-heading">
                                      <input
                                        data-bs-toggle="tooltip"
                                        title="Update all!"
                                        className="vendor-checkbox-input"
                                        type="checkbox"
                                        name={`allStatus`}
                                        value={values.allStatus}
                                        checked={
                                          values.allStatus === "1" ||
                                          values.allStatus === 1 ||
                                          values.allStatus === true
                                        }
                                        onChange={(e) =>
                                          handleIngredientChange(
                                            null, // No index needed for allStatus
                                            "allStatus",
                                            e.target.checked,
                                            setFieldValue,
                                            null, // No quantity needed
                                            disableAllFields,
                                            values
                                          )
                                        }
                                        disabled={lineLevelCheckBoxDisable(
                                          null,
                                          "allStatus"
                                        )}
                                      />
                                      {/* <h4 className="paragraph-title paragraph-titletwo ">
                                      Update Stocks
                                    </h4> */}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    Ingredient Name
                                    {/* <span></span> */}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    Ingredient Price
                                    {/* <span></span> */}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    Ingredient Quantity
                                    {/* <span></span> */}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    Ingredient Unit
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="paragraph-editor">
                                  <h4 className="paragraph-title">
                                    Ingredient Instruction{" "}
                                    {/* <span className="required-label"></span> */}
                                  </h4>
                                </div>
                              </div>
                            </div>

                            {values.ingredients &&
                              values.ingredients.map((item, index) => (
                                <div className="row vendor-item" key={index}>
                                  {Data ? (
                                    <div className="col-md-1 ">
                                      <input
                                        data-bs-toggle="tooltip"
                                        title="Update!"
                                        type="checkbox"
                                        className="vendor-checkbox-input"
                                        name={`ingredients[${index}].status`}
                                        value={item.status}
                                        checked={
                                          item.status === "1" ||
                                          item.status === 1 ||
                                          item.status === true
                                        }
                                        onChange={(e) =>
                                          handleIngredientChange(
                                            index,
                                            "status",
                                            e.target.checked,
                                            setFieldValue,
                                            item.quantity,
                                            disableAllFields,
                                            values,
                                            item.ingredient_price
                                          )
                                        }
                                        disabled={lineLevelCheckBoxDisable(
                                          item.IngredientId,
                                          "status"
                                        )} // Call the function directly
                                      />
                                    </div>
                                  ) : (
                                    <div className="col-md-1">
                                      <input
                                        data-bs-toggle="tooltip"
                                        title="Update!"
                                        className="paragraph-title vendor-checkbox-input"
                                        type="checkbox"
                                        name={`ingredients[${index}].status`}
                                        value={item.status}
                                        checked={
                                          item.status === "1" ||
                                          item.status === 1 ||
                                          item.status === true
                                            ? 1
                                            : 0
                                        }
                                        onChange={(e) =>
                                          handleIngredientChange(
                                            index,
                                            "status",
                                            e.target.checked,
                                            setFieldValue,
                                            item.quantity,
                                            disableAllFields,
                                            values,
                                            item.ingredient_price
                                          )
                                        }
                                        // disabled={lineLevelCheckBoxDisable(
                                        //   item.IngredientId,
                                        //   "status"
                                        // )}
                                      />
                                    </div>
                                  )}
                                  <div className="col-md-2">
                                    <div className="paragraph-editor ingredients-title">
                                      <h4 className="paragraph-title">
                                        {item.ingredientName}
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <Input
                                      type="text"
                                      name={`ingredients[${index}].ingredient_price`}
                                      placeholder="Enter Price"
                                      className="add-roles"
                                      value={item.ingredient_price}
                                      onChange={(e) =>
                                        handleIngredientChange(
                                          index,
                                          "ingredient_price",
                                          e.target.value,
                                          setFieldValue,
                                          item.quantity,
                                          disableAllFields,
                                          values
                                        )
                                      }
                                      disabled={
                                        // (Data && disableAllFields) ||
                                        lineLevelCheckBoxDisable(
                                          item.IngredientId,
                                          "ingredient_price",
                                          values.ingredients
                                        )
                                      }
                                      //  disabled={
                                      //   Data && Data.isEdit === false
                                      // }
                                      Regex={/[^0-9]/g}
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <Input
                                      type="text"
                                      name={`ingredients[${index}].quantity`}
                                      placeholder="Enter Quantity"
                                      className="add-roles"
                                      value={item.quantity}
                                      onChange={(e) =>
                                        handleIngredientChange(
                                          index,
                                          "quantity",
                                          e.target.value,
                                          setFieldValue,
                                          item.quantity,
                                          disableAllFields,
                                          values
                                        )
                                      }
                                      disabled={
                                        (Data && disableAllFields) ||
                                        lineLevelCheckBoxDisable(
                                          item.IngredientId,
                                          "quantity",
                                          values.ingredients
                                        )
                                      }
                                      Regex={/[^0-9]/g}
                                    />
                                  </div>

                                  <div className="col-md-2">
                                    <Field
                                      name={`ingredients[${index}].unit`}
                                      className="select-menu mb-2"
                                      component={CustomSelect}
                                      options={unitOptions}
                                      placeholder="Select Unit"
                                      value={
                                        unitOptions.find(
                                          (option) => option.value === item.unit
                                        ) && item.unit
                                      }
                                      onChange={(value) =>
                                        handleIngredientChange(
                                          index,
                                          "unit",
                                          value,
                                          setFieldValue,
                                          item.quantity,
                                          disableAllFields,
                                          values
                                        )
                                      }
                                      disabled={
                                        (Data && disableAllFields) ||
                                        lineLevelCheckBoxDisable(
                                          item.IngredientId,
                                          "unit",
                                          values.ingredients
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <Input
                                      type="text"
                                      name={`ingredients[${index}].instruction`}
                                      placeholder="Enter Instruction"
                                      className="add-roles"
                                      value={item.instruction}
                                      onChange={(e) =>
                                        handleIngredientChange(
                                          index,
                                          "instruction",
                                          e.target.value,
                                          setFieldValue,
                                          item.quantity,
                                          disableAllFields,
                                          values
                                        )
                                      }
                                      disabled={
                                        (Data && disableAllFields) ||
                                        lineLevelCheckBoxDisable(
                                          item.IngredientId,
                                          "instruction",
                                          values.ingredients
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}

                        <div className="col-12">
                          <div className="paragraph-editor">
                            <div className="btn-submit mt-3">
                              {((Data && Data.isEdit === true) || !Data) && (
                                <>
                                  <Button
                                    type="submit"
                                    name="Save"
                                    className="btn login-btn"
                                  />
                                  <Button
                                    type="button"
                                    name="Cancel"
                                    className="btn clear-btn login-btn"
                                    onClick={() =>
                                      handleCancleClick("/vendors", navigate)
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AddVendors;
