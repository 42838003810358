import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Button from "../../../components/Button/Button";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Input from "../../../components/input/Input";
import Toggle from "../../../components/Toggle/Toggle";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import * as Yup from "yup";
import UtilsData, { ValidationMessage } from "../../../Utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { handleCancleClick, handlesaveClick } from "../../../components/Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import UploadImage from "../../../components/UploadImage/UploadImage";
import ShowImage from "../../../components/ShowImage/ShowImage";
import { toast } from "react-toastify";
import { uploadImage } from "../../../redux/Actions/uploadAction/UploadAction";
import { Accordion } from "react-bootstrap";
import "../../Product/AddProduct/AddProduct.scss";
import "../../../App.scss";
import { FaMinus, FaPlus } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { getAllAttributeService } from "../../Attributes/AttributeService/AttributeService";
import { getAllCategoryService } from "../../Category/Category-service/CategoryService";
import { getAllSubCategoryService } from "../../SubCategory/SubCategoryService/SubCategoryService";
import { getAllLeafLevelCategoryService } from "../../LeafLevelCategory/LeafLevelService/LeafLevelService";
import { getAllTagsService } from "../../Tag/TagService/TagService";
import { getAllBrandService } from "../../BrandList/BrandService/BrandService";
import { addUpdateProductService, getProductById } from "../ProductService/ProductService";
import { getAllWarehouseService } from "../../Warehouse/WarehouseService/WarehouseService";
import { parseImage } from "../../../services/Regular-services/ImageService";

const validationSchema = Yup.object({
  productName: Yup.string().required(ValidationMessage.NAME_REQUIRED),
  code: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  sku: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  productImage: Yup.array().min(1, ValidationMessage.UPLOAD_IMAGE),
  brand: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  category: Yup.array().min(1, ValidationMessage.MULTI_CATEGORY_SELECT),
  subCategory: Yup.array().min(1, ValidationMessage.MULTI_SUB_CATEGORY_SELECT),
  // leafCategory: Yup.array().min(1, ValidationMessage.MULTI_LEAF_CATEGORY_SELECT),
  description: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED),
  additionalDescription: Yup.string().required(ValidationMessage.DESCRIPTION_REQUIRED),
  hsn: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  price: Yup.string().required(ValidationMessage.PRICE_REQUIRED),
  size: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // color_name: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // color_code: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  warehouse: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // country: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // skid: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // ean: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // manufacturer: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // packer: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // importer: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // length_of_outer_package: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // breadth_of_outer_package: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // height_of_outer_package: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // weight: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // tags: Yup.array().min(1, ValidationMessage.MULTI_TAG_SELECT),
  // Unit_Pack_Size: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  // Unit_Fill_Pack_Size: Yup.string().required(ValidationMessage.FIELD_REQUIRED)
})

export default function AddProduct() {
  const dispatch = useDispatch();

  const [fields, setFields] = useState([]);

  const [initialValues, setInitialValues] = useState({
    productName: '',
    code: '',
    sku: '',
    productImage: [],
    brand: '',
    category: [],
    subCategory: [],
    leafCategory: [],
    description: '',
    additionalDescription: '',
    hsn: '',
    price: '',
    purchase_amount: '',
    size: '',
    color_name: '',
    color_code: '',
    units: '',
    warehouse: '',
    country: '',
    skid: '',
    ean: '',
    manufacturer: '',
    packer: '',
    importer: '',
    length_of_outer_package: '',
    breadth_of_outer_package: '',
    height_of_outer_package: '',
    weight: '',
    Unit_Fill_Pack_Size: '',
    Unit_Pack_Size: '',
    tags: [],
    attributes: '',
    attributeValues: '',
    attributeImage: [],
    attributeCategory: '',
    status: true,
    veg: 1,
    excludeFilters: 0,
    jain: 0,
    vegan: 0,
    regular: '2'
  });

  const totalAttribute = useSelector((state) => state.totalAttribute);
  const productPage = useSelector(state => state.productPage?.items);

  const tokenData = UtilsData();

  const [categoryOptionValue, setCategoryOptionValue] = useState([]);
  const [subCategoryOptionValue, setSubCategoryOptionValue] = useState([]);

  const [attributeShowImage, setAttributeShowImage] = useState(false);
  const [showImage, setShowImage] = useState(false);

  /**
   * Setting Attribute Data
   */

  const [errorImages, setErrorImages] = useState([]);
  const [attributeErrorImages, setAttributeErrorImages] = useState([]);
  const [AttributesOptions, setAttributesOptions] = useState([]);

  /**
   * Fetch attribute data
   */
  const fetchAttributeData = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 100000000
    }
    getAllAttributeService(requestBody, tokenData, dispatch, true);
  }
  useEffect(() => {
    if (totalAttribute && totalAttribute.items.length === 0) {
      fetchAttributeData();
    }

    if (totalAttribute.items[0]?.data && totalAttribute.items[0].data.length > 0) {
      const transformedArray = totalAttribute.items[0].data.map((item) => ({
        value: item.attributeId,
        label: item.title,
        is_active: item.isActive
      }));
      setAttributesOptions(transformedArray)
    }
    // eslint-disable-next-line
  }, [totalAttribute])


  /**
   * Setting category options
   */
  const categoryListData = useSelector(state => state.totalCategory);
  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [attributeCategoryListOptions, setAttributeCategoryListOptions] = useState([]);

  /**
   * Fetching category data
   */
  const fetchCategoryData = () => {
    const categoryRequestBody = {
      p_skip: 0,
      p_take: 100000000
    }
    getAllCategoryService(categoryRequestBody, tokenData, dispatch, true);
  }

  useEffect(() => {
    if (categoryListData && categoryListData.items && categoryListData.items.length === 0) {
      fetchCategoryData();
    }
    else {
      if (categoryListData.items[0]?.data && categoryListData.items[0].data.length > 0) {
        const filterCategoryByType = categoryListData.items[0]?.data.filter(item => (item.type === '0' || item.type === 0))
        const categoryOptions = filterCategoryByType.map(item => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active
        }));
        setCategoryListOptions(categoryOptions);
        if (categoryOptionValue && categoryOptionValue.length > 0) {
          const subCategoryOptions = categoryOptions.filter(item2 =>
            categoryOptionValue.some(item1 => item1 === item2.value)
          );
          getFilterOptionData(subCategoryOptions, 'category');
        }

        const filterAttributeCategoryByType = categoryListData.items[0]?.data.filter(item => (item.type === '2' || item.type === 2))
        const attributeCategoryOptions = filterAttributeCategoryByType.map(item => ({
          value: item.categoryId,
          label: item.name,
          is_active: item.is_active
        }));
        setAttributeCategoryListOptions(attributeCategoryOptions);
      }
    }
    // eslint-disable-next-line
  }, [categoryListData, categoryOptionValue])


  /**
   * Custom styles for React Select
   */
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === '0' ? '#F00' : '#000', // Set color to red if is_active is '0'
    }),
  };


  /**
   * Setting sub category options
   */
  const subCategoryListData = useSelector(state => state.totalSubCategory);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);

  const fetchSubCategoryData = () => {
    const subCategoryRequestBody = {
      p_skip: 0,
      p_take: 100000000
    }
    getAllSubCategoryService(subCategoryRequestBody, tokenData, dispatch, true);
  }

  useEffect(() => {
    if (subCategoryListData && subCategoryListData.items && subCategoryListData.items.length === 0) {
      fetchSubCategoryData();
    }
    else {
      if (subCategoryListData.items && subCategoryListData.items[0]?.data && subCategoryListData.items[0].data.length > 0) {
        if (subCategoryOptionValue && subCategoryOptionValue.length > 0) {

          const filterSubCategoryByType = subCategoryListData.items[0].data.filter(item => (item.type === '0' || item.type === 0))
          const subCategoryOptions = filterSubCategoryByType.map(item => ({
            value: item.id,
            label: item.name,
            is_active: item.is_active
          }));

          const filteredSubCategoryOptions = subCategoryOptions.filter(item2 =>
            subCategoryOptionValue.some(item1 => item1 === item2.value)
          );
          getFilterOptionData(filteredSubCategoryOptions, 'subCategory');
        }
      }
    }
    // eslint-disable-next-line
  }, [subCategoryListData, subCategoryOptionValue])

  /**
   * Setting leaf level category options
   */
  const totalLeaf = useSelector(state => state.totalLeaf);
  const [leafCategoryListOptions, setLeafCategoryListOptions] = useState([]);

  /**
   * Fetching leaf level category data
   */
  const fetchLeafData = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 100000000
    }
    getAllLeafLevelCategoryService(requestBody, tokenData, dispatch, true)
  }

  useEffect(() => {
    if (totalLeaf && totalLeaf.items && totalLeaf.items.length === 0) {
      fetchLeafData();
    }
    // eslint-disable-next-line
  }, [totalLeaf])

  /**
   * Setting tags options
   */
  const totalTag = useSelector(state => state.totalTag);
  const [tagListOptions, setTagListOptions] = useState([]);

  /**
   * Fetching tag data
   */
  const fetchTagData = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 100000000
    }
    getAllTagsService(requestBody, tokenData, dispatch, true);
  }
  useEffect(() => {
    if (totalTag && totalTag.items && totalTag.items.length === 0) {
      fetchTagData();
    }
    else {
      if (totalTag.items[0]?.data && totalTag.items[0].data.length > 0) {
        const tagOptions = totalTag.items[0].data.map(item => ({
          value: item.tagId,
          label: item.title,
          is_active: item.isActive
        }));
        setTagListOptions(tagOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalTag])

  /**
   * Setting Brand Options
   */
  const totalBrand = useSelector(state => state.totalBrand);
  const [brandOptions, setBrandOptions] = useState([]);

  /**
   * Fetching brand data
   */
  const fetchBrandData = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10000000
    }
    getAllBrandService(requestBody, tokenData, dispatch, true);
  }

  useEffect(() => {
    if (totalBrand && totalBrand.items.length === 0) {
      fetchBrandData();
    }
    else {
      if (totalBrand.items[0]?.data && totalBrand.items[0].data.length > 0) {
        const filteredBrandData = totalBrand.items[0].data.filter(item => (item.brandTypeId === '0' || item.brandTypeId === 0))
        const brandOptions = filteredBrandData.map(item => ({
          value: item.brandId,
          label: item.name,
          is_active: item.isActive
        }));
        setBrandOptions(brandOptions);
      }
    }
    // eslint-disable-next-line
  }, [totalBrand])

  /**
   * Setting Packager and Monufacturer Options
   */
  const totalPackager = useSelector(state => state.totalPackager);
  const [packagerOptions, setPackagerOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [importerOptions, setImporterOptions] = useState([]);

  /**
   * Fetch packager data
   */
  // const fetchPackagerData = () => {
  //   const requestBody = {
  //     p_skip: 0,
  //     p_take: 10000000
  //   }
  //   getAllPackagerService(requestBody, tokenData, dispatch, true);
  // }

  useEffect(() => {
    if (totalPackager && totalPackager.items.length === 0) {
      // fetchPackagerData();
    }
    else {
      if (totalPackager.items[0]?.data && totalPackager.items[0].data.length > 0) {

        const manufacturer = totalPackager.items[0].data.filter(item => (item.type === 0 || item.type === '0'));

        const packager = totalPackager.items[0].data.filter(item => (item.type === 1 || item.type === '1'))

        const importer = totalPackager.items[0].data.filter(item => (item.type === 2 || item.type === '2'))
        if (manufacturer && manufacturer.length > 0) {
          const manufacturerData = manufacturer.map(item => ({
            value: item.productPackagerId,
            label: item.name,
            is_active: item.is_active
          }))
          setManufacturerOptions(manufacturerData);
        }
        if (packager && packager.length > 0) {
          const packagerData = packager.map(item => ({
            value: item.productPackagerId,
            label: item.name,
            is_active: item.is_active
          }))
          setPackagerOptions(packagerData);
        }
        if (importer && importer.length > 0) {
          const importerData = importer.map(item => ({
            value: item.productPackagerId,
            label: item.name,
            is_active: item.is_active
          }))
          setImporterOptions(importerData);
        }
      }
    }
    // eslint-disable-next-line
  }, [totalPackager])


  /**
   * Setting warehouse Options
   */
  const totalWarehouse = useSelector(state => state.totalWarehouse);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  /**
   * Warehouse API Integration
   */
  const fetchWarehouseData = () => {
    const requestBody = {
      p_skip: 0,
      p_take: 100000000
    }
    getAllWarehouseService(requestBody, tokenData, dispatch, true);
  }

  useEffect(() => {
    if (totalWarehouse && totalWarehouse.items.length === 0) {
      fetchWarehouseData();
    }
    else {
      if (totalWarehouse.items[0]?.data && totalWarehouse.items[0].data.length > 0) {
        const warehouse = totalWarehouse.items[0].data.map(item => ({
          value: item.warehouseId,
          label: item.name,
          is_active: item.is_active
        }));
        setWarehouseOptions(warehouse);
      }
    }
    // eslint-disable-next-line
  }, [totalWarehouse])

  const location = useLocation();
  const { Data } = location.state || {};

  /**
   * Fetching product detail data
   */
  const fetchProduct = async () => {
    try {
      // Call the getRoleById function
      const productData = await getProductById(Data.data.productId, tokenData, dispatch);

      const productImage = productData.data.image_data ? parseImage(JSON.stringify(productData.data.image_data)) : [];
      const productImageData = productImage && productImage.length > 0 ? productImage.map(item => ({
        imageId: parseInt(item.id),
        url: item.url,
        imageType: parseInt(item.type)
      })) : [];
      setAttributeShowImage(true);
      setShowImage(true);

      const attributeData = productData.data.attribute_data ? parseImage(productData.data.attribute_data) : [];
      const attributeImageData = productData.data.attribute_image_data ? parseImage(JSON.stringify(productData.data.attribute_image_data)) : [];

      const attributeImageMap = new Map(attributeImageData.map(item => [item.id, item]));

      // Create a new array with merged data
      const mergedData = attributeData.map(data => ({
        id: data.id,
        value: data.value,
        attributeImage: attributeImageMap.get(data.attribute_image) || null,
        category_id: parseInt(data.attribute_category)
      }));

      const updatedData = mergedData.map((item, index) => {
        return {
          id: index + 1,
          attributes: parseInt(item.id),
          attributeValues: item.value,
          attributeImage: item?.attributeImage ? [{
            imageId: parseInt(item.attributeImage.id),
            url: item.attributeImage.url,
            imageType: parseInt(item.attributeImage.type)
          }] : [],
          attributeCategory: item.category_id
        };
      });
      setFields(updatedData);

      const categoryOption = productData.data.category_ids ? productData.data.category_ids.split(",").map(Number) : [];
      const subCategoryOption = productData.data.sub_category_ids ? productData.data.sub_category_ids.split(",").map(Number) : [];
      setCategoryOptionValue(categoryOption)
      setSubCategoryOptionValue(subCategoryOption);

      setInitialValues({
        productName: productData.data.name ? productData.data.name : '',
        code: productData.data.product_code ? productData.data.product_code : '',
        sku: productData.data.sku ? productData.data.sku : '',
        productImage: productImageData,
        brand: productData.data.brand_id ? productData.data.brand_id : '',
        category: productData.data.category_ids ? productData.data.category_ids.split(",").map(Number) : [],
        subCategory: productData.data.sub_category_ids ? productData.data.sub_category_ids.split(",").map(Number) : [],
        leafCategory: productData.data.leaf_level_category_ids ? productData.data.leaf_level_category_ids.split(",").map(Number) : [],
        description: productData.data.description ? productData.data.description : '',
        additionalDescription: productData.data.additional_description ? productData.data.additional_description : '',
        hsn: productData.data.hsn ? productData.data.hsn : '',
        price: productData.data.price ? productData.data.price : '',
        purchase_amount: productData.data.purchase_price ? productData.data.purchase_price : '',
        size: productData.data.size ? productData.data.size : '',
        color_name: productData.data.color_name ? productData.data.color_name : '',
        color_code: productData.data.color_code ? productData.data.color_code : '',
        units: productData.data.unit ? productData.data.unit : '',
        warehouse: productData.data.warehouse_id ? productData.data.warehouse_id : '',
        country: productData.data.country_of_origin ? productData.data.country_of_origin : '',
        skid: productData.data.SKID ? productData.data.SKID : '',
        ean: productData.data.EAN_CODES ? productData.data.EAN_CODES : '',
        manufacturer: productData.data.manufacturer_id ? productData.data.manufacturer_id : '',
        packer: productData.data.packer_id ? productData.data.packer_id : '',
        importer: productData.data.importer_id ? productData.data.importer_id : '',
        length_of_outer_package: productData.data.Length_of_outer_package ? productData.data.Length_of_outer_package : '',
        breadth_of_outer_package: productData.data.Breadth_of_outer_package ? productData.data.Breadth_of_outer_package : '',
        height_of_outer_package: productData.data.Height_of_outer_package ? productData.data.Height_of_outer_package : '',
        weight: productData.data.Weight_including_packaging ? productData.data.Weight_including_packaging : '',
        Unit_Fill_Pack_Size: productData.data.Unit_Fill_Pack_Size ? productData.data.Unit_Fill_Pack_Size : '',
        Unit_Pack_Size: productData.data.Unit_Pack_Size ? productData.data.Unit_Pack_Size : '',
        tags: productData.data.tag_ids ? productData.data.tag_ids.split(",").map(Number) : [],
        attributes: '',
        attributeValues: '',
        attributeImage: [],
        status: (productData.data.is_active === '1' || productData.data.is_active === 1 || productData.data.is_active === true) ? true : false,
        veg: (Data.data.veg_nonveg === '1' || Data.data.veg_nonveg === 1) ? 0 : 1,
        excludeFilters: (Data.data.filter === '1' || Data.data.filter === 1) ? 1 : 0,
        jain: (Data.data.jain_or_not === '1' || Data.data.jain_or_not === 1) ? 1 : 0,
        vegan: (Data.data.vegan_or_not === '1' || Data.data.vegan_or_not === 1) ? 1 : 0,
        regular: Data?.data?.jain_regular ? Data.data.jain_regular : '2'
      })
      return productData;
    } catch (error) {
      console.log('error: ', error);
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  };

  const regularOnionOptions = [
    {
      value: '2',
      label: 'Regular'
    },
    {
      value: '0',
      label: 'No Onion/Garlic'
    }
  ]

  useEffect(() => {
    if (Data && Data.data) {
      fetchProduct();
    }
    // eslint-disable-next-line
  }, [Data]);

  const userData = useSelector(state => state.auth.user);
  const navigate = useNavigate();

  /**
   * Handle navigation
   * @param {*} route 
   */
  const handleNavigate = (route) => {
    dispatch(uploadImage([]));
    navigate(route);
  };

  const CountryoforigingenderOptions = [
    { value: "India", label: "India" },
    { value: "UK", label: "UK" },
    { value: "Iran", label: "Iran" },
  ];

  /**
   * Append attribute data
   * @param {*} values 
   * @param {*} setFieldValue 
   */
  const handleAddField = (values, setFieldValue) => {
    if (values.attributes === '' || values.attributeValues === '' || values.attributeCategory === '') {
      toast.error('Please select attribute, attribute category and its value before adding!');
    }
    else {
      let newId;
      if (fields.length === 0) {
        newId = fields.length + 1;
      } else {
        const lastKey = fields[fields.length - 1].id;
        newId = lastKey + 1;
      }
      const newField = {
        id: newId,
        attributes: values.attributes,
        attributeValues: values.attributeValues,
        attributeImage: values.attributeImage,
        attributeCategory: values.attributeCategory
      };
      setFieldValue('attributes', '');
      setFieldValue('attributeValues', '');
      setFieldValue('attributeImage', '');
      setFieldValue('attributeCategory', '');
      setFields([...fields, newField]);
    }
  };

  /**
   * Remove already added attribute data
   * @param {*} idToRemove 
   */
  const handleRemoveField = (idToRemove) => {
    const list = [...fields];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFields(filterField);
  };

  /**
   * Updating attributes in reverse order
   */
  const onChangeData = (index, value, category) => {
    if (category && category === 'attributeCategory') {
      let listData = [...fields];
      listData[fields.length - index - 1][category] = value;
      setFields(listData);
    }
    else {
      let listData = [...fields];
      listData[fields.length - index - 1]['attributes'] = value;
      setFields(listData);
    }
  }

  /**
   * Updating attribute values in reverse order
   */
  const changeInputData = (index, value, setFieldValue) => {
    let listData = [...fields];
    setFieldValue(value)
    listData[fields.length - index - 1]['attributeValues'] = value;
    setFields(listData);
  }


  /**
   * Updating attribute images in reverse order
   */
  const changeImageData = (index, value) => {
    let listData = [...fields];
    listData[fields.length - index - 1]['attributeImage'] = value;
    setFields(listData);
  }

  /**
   * Handle form submission
   * @param {*} values 
   * @returns {*}
   */
  const onSubmit = (values) => {
    const tagData = values.tags && values.tags.length > 0 ? values.tags.map(item => ({
      id: item
    })) : [];

    const categoryData = values.category && values.category.length > 0 ? values.category.map(item => ({
      id: item
    })) : [];

    const subCategoryData = values.subCategory && values.subCategory.length > 0 ? values.subCategory.map(item => ({
      id: item
    })) : [];

    const leafData = values.leafCategory && values.leafCategory.length > 0 ? values.leafCategory.map(item => ({
      id: item
    })) : [];

    let attributeData = fields.length > 0 ? fields.map(item => ({
      id: item.attributes,
      value: item.attributeValues,
      imageId: item.attributeImage && item.attributeImage[0] && item.attributeImage[0].imageId ? item.attributeImage[0].imageId : null,
      type_id: item.attributeImage && item.attributeImage[0] && item.attributeImage[0].imageType ? item.attributeImage[0].imageType : null,
      category_id: item.attributeCategory
    })) : [];

    if (attributeData.length < 1) {
      if (values.attributes !== '' && values.attributeValues !== '' && values.attributeCategory !== '') {
        attributeData.push({});
        attributeData[0].id = values.attributes;
        attributeData[0].value = values.attributeValues;
        attributeData[0].imageId = values.attributeImage && values.attributeImage[0] && values.attributeImage[0].imageId ? values.attributeImage[0].imageId : null;
        attributeData[0].type_id = values.attributeImage && values.attributeImage[0] && values.attributeImage[0].imageType ? values.attributeImage[0].imageType : null;
        attributeData[0].category_id = values.attributeCategory;
      }
      // else {
      //   toast.error("Please select attribute name, category and it's value!");
      //   return;
      // }
    }
    else {
      if (values.attributes !== '' && values.attributeValues !== '' && values.attributeCategory !== '') {
        attributeData.push({
          id: values.attributes,
          value: values.attributeValues,
          imageId: values.attributeImage && values.attributeImage[0] && values.attributeImage[0].imageId ? values.attributeImage[0].imageId : null,
          type_id: values.attributeImage && values.attributeImage[0] && values.attributeImage[0].imageType ? values.attributeImage[0].imageType : null,
          category_id: values.attributeCategory
        });
      }
    }

    const imageIds = values.productImage && values.productImage.length > 0 ? values.productImage.map(item => ({
      id: item.imageId,
      type_id: item.imageType
    })) : [];

    const requestBody = {
      ...(Data && Data.data && { id: Data.data.productId }),
      warehouse_id: values.warehouse,
      brand_id: values.brand,
      manufacturer_id: values.manufacturer,
      packer_id: values.packer,
      importer_id: values.importer,
      name: values.productName,
      description: values.description,
      additional_description: values.additionalDescription,
      product_code: values.code,
      hsn: values.hsn,
      price: Number(values.price),
      p_purchase_price: values.purchase_amount && values.purchase_amount !== '' ? Number(values.purchase_amount) : 0,
      unit: values.units.toString(),
      sku: values.sku,
      size: values.size,
      color_code: values.color_code,
      country_of_origin: values.country,
      color_name: values.color_name,
      Tags: tagData,
      SKID: values.skid,
      EAN_CODES: values.ean,
      imageIds: imageIds,
      category_ids: categoryData,
      sub_category_ids: subCategoryData,
      leaf_level_ids: leafData,
      attributeData: attributeData,
      Unit_Fill_Pack_Size: values.Unit_Fill_Pack_Size,
      Unit_Pack_Size: values.Unit_Pack_Size,
      Length_of_outer_package: values.length_of_outer_package,
      Breadth_of_outer_package: values.breadth_of_outer_package,
      Height_of_outer_package: values.height_of_outer_package,
      Weight_including_packaging: values.weight,
      created_by: userData.data.userId,
      is_active: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
      p_veg_nonveg: (values.veg === true || values.veg === 1 || values.veg === '1') ? '0' : '1',
      p_jain_regular: (values.veg === true || values.veg === 1 || values.veg === '1') ? values.regular: '2',
      p_exclude_filter: (values.excludeFilters === true || values.excludeFilters === 1 || values.excludeFilters === '1') ? '1' : '0',
      p_is_vegan:  ((values.veg === true || values.veg === 1 || values.veg === '1') && (values.vegan === true || values.vegan === 1 || values.vegan === '1')) ? '1' : '0',
      p_is_jain: ((values.veg === true || values.veg === 1 || values.veg === '1') && (values.jain === true || values.jain === 1 || values.jain === '1')) ? '1' : '0'
    }

    // if (fields.length < 1) {
    //   if (values.attributes === '' || values.attributeValues === '' || values.attributeCategory === '') {
    //     toast.error('Please add attributes!');
    //     return;
    //   }
    // }
    if (!Data) {
      dispatch(addUpdateProductService(requestBody, tokenData, 'add', navigate));
    } else {
      requestBody.IsDeleted = Data.data.isDeleted;
      if (Data?.length && productPage > 1) {
        if ((values.active === '0' || values.active === 0 || values.active === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateProductService(requestBody, tokenData, 'update', navigate, productPage), 'update');
          }
          else {
            handlesaveClick(dispatch, addUpdateProductService(requestBody, tokenData, 'update', navigate, productPage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateProductService(requestBody, tokenData, 'update', navigate, productPage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateProductService(requestBody, tokenData, 'update', navigate), 'update');
      }
    }
    dispatch(uploadImage([]));
  };

  /**
   * Setting sub-category and leaf level category options on selecting category value
   * @param {*} option 
   * @param {*} data 
   * @param {*} setFieldValue 
   */
  const getFilterOptionData = (option, data, setFieldValue) => {
    if (data === 'category') {
      if (subCategoryListData.items && subCategoryListData.items[0]?.data && subCategoryListData.items[0].data.length > 0) {
        const filterSubCategoryByType = subCategoryListData.items[0].data.filter(item => (item.type === '0' || item.type === 0))
        if (filterSubCategoryByType && filterSubCategoryByType.length > 0) {
          const filteredArr2 = filterSubCategoryByType.filter(item2 =>
            option.some(item1 => item1.value === item2.category_id)
          );
          const subCategoryOptionData = filteredArr2.map(item => ({
            value: item.id,
            label: item.name,
            is_active: item.is_active
          }))
          setSubCategoryListOptions(subCategoryOptionData);
        }
      }
      if (setFieldValue) {
        if (option.length === 0) {
          setFieldValue('subCategory', []);
          setLeafCategoryListOptions([]);
        }
        setFieldValue('leafCategory', []);
      }
    }
    else if (data === 'subCategory') {
      if (totalLeaf.items[0]?.data && totalLeaf.items[0].data.length > 0) {
        const filterLeafData = totalLeaf.items[0].data.filter(item2 =>
          option.some(item1 => item1.value === item2.SubcategoryId)
        );
        const leafOptions = filterLeafData.map(item => ({
          value: item.id,
          label: item.Title,
          is_active: item.is_active
        }))
        setLeafCategoryListOptions(leafOptions);
      }
      if (setFieldValue) {
        if (option.length === 0) {
          setFieldValue('leafCategory', []);
        }
      }
    }
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                        ? "View "
                        : "Add "}{" "}
                    Product
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => handleNavigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => handleNavigate("/product")}>
                    Product
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                        ? "View "
                        : "Add "}{" "}
                    Product
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                {Data && Data.isEdit === true
                  ? "Edit "
                  : Data && Data.isEdit === false
                    ? "View "
                    : "Add "}{" "}
                Product
              </h4>
            </div>
            <div className="category-section add-roles-form">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="row">
                      <div className="col-12">
                        <Accordion
                          defaultActiveKey={["0"]}
                          alwaysOpen
                          className="product-accordion"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header><IoIosArrowForward />Product details</Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Product Name
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="productName"
                                      label="Product Name"
                                      placeholder="Product Name"
                                      className="add-roles"
                                      Regex={/[^a-zA-Z0-9\s]/g}
                                      value={values.productName}
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={50}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Product code
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="code"
                                      label="Product code"
                                      placeholder="Enter Product code"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={50}
                                      value={values.code}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        SKU
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="sku"
                                      label="SKU"
                                      placeholder="Enter SKU"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={50}
                                      value={values.sku}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Product Image<span className="required-label"></span>
                                    </h4>
                                  </div>
                                  <UploadImage
                                    name="productImage"
                                    onChange={setFieldValue}
                                    value={values.productImage}
                                    disabled={Data && Data.isEdit === false}
                                    filterByType={8}
                                    setErrorImages={setErrorImages}
                                    setShowImage={setShowImage}
                                  ></UploadImage>
                                  <ShowImage value={values.productImage}
                                    errorImages={errorImages}
                                    showImage={showImage}
                                  ></ShowImage>
                                </div>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Brand Name
                                      <span className="required-label"></span>
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="brand"
                                      options={brandOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      disabled={Data && Data.isEdit === false}
                                      value={values.brand}
                                      Regex={/[^a-zA-Z\s]/g}
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Category Name
                                      <span className="required-label"></span>
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="category"
                                      options={categoryListOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={true}
                                      disabled={Data && Data.isEdit === false}
                                      value={values.category}
                                      Regex={/[^a-zA-Z\s]/g}
                                      onchangedata={(option) => getFilterOptionData(option, 'category', setFieldValue)}
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Sub category Name
                                      <span className="required-label"></span>
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="subCategory"
                                      options={subCategoryListOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={true}
                                      value={values.subCategory}
                                      disabled={Data && Data.isEdit === false}
                                      Regex={/[^a-zA-Z\s]/g}
                                      onchangedata={(option) => getFilterOptionData(option, 'subCategory', setFieldValue)}
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Leaf level category Name
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="leafCategory"
                                      options={leafCategoryListOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={true}
                                      value={values.leafCategory}
                                      disabled={Data && Data.isEdit === false}
                                      Regex={/[^a-zA-Z\s]/g}
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">Is Veg</h4>
                                  </div>
                                  <div className="paragraph-editor">
                                    <Field
                                      name="veg"
                                      setFieldValue={setFieldValue}
                                      component={Toggle}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">Exculude Filters</h4>
                                  </div>
                                  <div className="paragraph-editor">
                                    <Field
                                      name="excludeFilters"
                                      setFieldValue={setFieldValue}
                                      component={Toggle}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                                {(values.veg === true || values.veg === 1 || values.veg === '1') && (
                                  <>
                                    <div className="col-md-2">
                                      <div className="paragraph-editor">
                                        <h4 className="paragraph-title">Is Jain</h4>
                                      </div>
                                      <div className="paragraph-editor">
                                        <Field
                                          name="jain"
                                          setFieldValue={setFieldValue}
                                          component={Toggle}
                                          disabled={Data && Data.isEdit === false}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="paragraph-editor">
                                        <h4 className="paragraph-title">Is Vegan</h4>
                                      </div>
                                      <div className="paragraph-editor">
                                        <Field
                                          name="vegan"
                                          setFieldValue={setFieldValue}
                                          component={Toggle}
                                          disabled={Data && Data.isEdit === false}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="paragraph-editor">
                                        <h4 className="paragraph-title">
                                          Food Type
                                          <span className="required-label"></span>
                                        </h4>
                                        <Field
                                          className="select-menu mb-2"
                                          name="regular"
                                          options={regularOnionOptions}
                                          component={CustomSelect}
                                          placeholder="Select..."
                                          isMulti={false}
                                          value={values.regular}
                                          changeData={(value) => setFieldValue("regular", value)}
                                          disabled={Data && Data.isEdit === false}
                                          styles={customStyles}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div className="col-12">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Description<span className="required-label"></span>
                                    </h4>
                                    <Field
                                      name="description"
                                      component={CKEditorCpmponent}
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={255}
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Additional Description<span className="required-label"></span>
                                    </h4>
                                    <Field
                                      name="additionalDescription"
                                      component={CKEditorCpmponent}
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={255}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-12">
                        <Accordion
                          defaultActiveKey={["0"]}
                          className="product-accordion"
                        >
                          <Accordion.Item eventKey="1">
                            <Accordion.Header><IoIosArrowForward />Pricing</Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        HSN
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="hsn"
                                      label="HSN"
                                      placeholder="Enter HSN"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={50}
                                      value={values.hsn}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Purchase Amount
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      Regex={/[^0-9.]/g}
                                      name="purchase_amount"
                                      label="Purchase Amount"
                                      placeholder="Purchase Amount"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      value={values.purchase_amount}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Product MRP
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      Regex={/[^0-9.]/g}
                                      name="price"
                                      label="Product Price"
                                      placeholder="Product Price"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      value={values.price}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-12">
                        <Accordion
                          defaultActiveKey={["0"]}
                          className="product-accordion"
                        >
                          <Accordion.Item eventKey="2">
                            <Accordion.Header><IoIosArrowForward />Variants</Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Size
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="size"
                                      label="Size"
                                      placeholder="Enter Size"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      Regex={/[^0-9' 'a-zA-Z]/g}
                                      maxLength={20}
                                      value={values.size}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        {" "}
                                        Color name
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="color_name"
                                      label=" Colorname"
                                      placeholder="Enter Color name"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      Regex={/[^a-zA-Z/s]/g}
                                      value={values.color_name}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Color code
                                        <span className="required-label"></span>
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="color_code"
                                      label="Color code"
                                      placeholder="Enter Color code"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={50}
                                      value={values.color_code}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Units
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="units"
                                      label="Color codeUnits"
                                      placeholder="Enter Units"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      Regex={/[^0-9]/g}
                                      value={values.units}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-12">
                        <Accordion
                          defaultActiveKey={["0"]}
                          className="product-accordion"
                        >
                          <Accordion.Item eventKey="3">
                            <Accordion.Header><IoIosArrowForward />Location</Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Select warehouse
                                      <span className="required-label"></span>
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="warehouse"
                                      options={warehouseOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      value={values.warehouse}
                                      disabled={Data && Data.isEdit === false}
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Country of origin
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="country"
                                      options={CountryoforigingenderOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      value={values.country}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-12">
                        <Accordion
                          defaultActiveKey={["0"]}
                          className="product-accordion"
                        >
                          <Accordion.Item eventKey="4">
                            <Accordion.Header><IoIosArrowForward />Aditional codes</Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Skid
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="skid"
                                      label="Skid"
                                      placeholder="Enter Skid"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={50}
                                      value={values.skid}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        EAN code
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="ean"
                                      label="EAN code"
                                      placeholder="Enter EAN code"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={50}
                                      value={values.ean}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-12">
                        <Accordion
                          defaultActiveKey={["0"]}
                          className="product-accordion"
                        >
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              <IoIosArrowForward />Packers and Movers details
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Manufacturer
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="manufacturer"
                                      options={manufacturerOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      value={values.manufacturer}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">Packer
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="packer"
                                      options={packagerOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      value={values.packer}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Importer
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="importer"
                                      options={importerOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={false}
                                      value={values.importer}
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-12">
                        <Accordion
                          defaultActiveKey={["0"]}
                          className="product-accordion"
                        >
                          <Accordion.Item eventKey="6">
                            <Accordion.Header><IoIosArrowForward />Package size</Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Length of outer package
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="length_of_outer_package"
                                      label="Length of outer package"
                                      placeholder="Enter Length of outer package"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      Regex={/[^a-z0-9\s]/g}
                                      value={values.length_of_outer_package}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Breadth of outer package
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="breadth_of_outer_package"
                                      label="Breadth of outer package"
                                      placeholder="Enter Breadth of outer package"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      Regex={/[^a-z0-9\s]/g}
                                      value={values.breadth_of_outer_package}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Height of outer package
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="height_of_outer_package"
                                      label="Height of outer package"
                                      placeholder="Enter Height of outer package"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      Regex={/[^a-z0-9\s]/g}
                                      value={values.height_of_outer_package}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Weight
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="weight"
                                      label="Weight"
                                      placeholder="Enter Weight"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      Regex={/[^a-z0-9\s]/g}
                                      value={values.weight}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Unit Fill Pack Size
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="Unit_Fill_Pack_Size"
                                      label="Unit Fill Pack Size"
                                      placeholder="Enter Unit Fill Pack Size"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      Regex={/[^a-z0-9\s]/g}
                                      value={values.Unit_Fill_Pack_Size}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="name-type">
                                    <div className="paragraph-editor ">
                                      <h4 className="paragraph-title">
                                        Unit Pack Size
                                        {/* <span className="required-label"></span> */}
                                      </h4>
                                    </div>
                                    <Input
                                      type="text"
                                      name="Unit_Pack_Size"
                                      label="Unit Pack Size"
                                      placeholder="Enter Unit Pack Size"
                                      className="add-roles"
                                      disabled={Data && Data.isEdit === false}
                                      maxLength={20}
                                      Regex={/[^a-z0-9\s]/g}
                                      value={values.Unit_Pack_Size}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-12">
                        <Accordion
                          defaultActiveKey={["0"]}
                          className="product-accordion"
                        >
                          <Accordion.Item eventKey="6">
                            <Accordion.Header><IoIosArrowForward />Attributes</Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Tags
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                    <Field
                                      className="select-menu mb-2"
                                      name="tags"
                                      options={tagListOptions}
                                      component={CustomSelect}
                                      placeholder="Select..."
                                      isMulti={true}
                                      value={values.tags}
                                      disabled={Data && Data.isEdit === false}
                                      styles={customStyles}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Attributes
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Values
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Attribute Image
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Attribute Category
                                      {/* <span className="required-label"></span> */}
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="paragraph-editor">
                                    <h4 className="paragraph-title">
                                      Add
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <Field
                                    className="select-menu mb-2"
                                    name='attributes'
                                    options={AttributesOptions}
                                    component={CustomSelect}
                                    placeholder="Select..."
                                    isMulti={false}
                                    value={(values.attributes === '' || values.attributes === null) ? false : values.attributes}
                                    disabled={Data && Data.isEdit === false}
                                    styles={customStyles}
                                  />
                                </div>
                                <div className="col-md-2">
                                  <div className="name-type">
                                    <Input
                                      type="text"
                                      name='attributeValues'
                                      label="values"
                                      placeholder="Enter Values"
                                      className="add-roles"
                                      maxLength={50}
                                      hidelength="true"
                                      disabled={Data && Data.isEdit === false}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <UploadImage
                                    name="attributeImage"
                                    onChange={setFieldValue}
                                    value={values.attributeImage}
                                    disabled={Data && Data.isEdit === false}
                                    restrictOne={true}
                                    filterByType={12}
                                    setErrorImages={setAttributeErrorImages}
                                  />
                                  <ShowImage value={values.attributeImage} errorImages={attributeErrorImages} />
                                </div>
                                <div className="col-md-3">
                                  <Field
                                    className="select-menu mb-2"
                                    name="attributeCategory"
                                    options={attributeCategoryListOptions}
                                    component={CustomSelect}
                                    placeholder="Select..."
                                    isMulti={false}
                                    disabled={Data && Data.isEdit === false}
                                    value={(values.attributeCategory === '' || values.attributeCategory === null) ? false : values.attributeCategory}
                                    styles={customStyles}
                                  />
                                </div>
                                <div className="col-1">
                                  <Button
                                    className="btn btn-fillter-main"
                                    type="button"
                                    onClick={() => handleAddField(values, setFieldValue)}
                                    name={<div className="Plus"><FaPlus /></div>}
                                  ></Button>
                                </div>
                              </div>
                              <div>
                                {fields && fields.length > 0 && fields.slice().reverse().map((field, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-md-3">
                                      <Field
                                        className="select-menu mb-2"
                                        name={`attributes${index}`}
                                        options={AttributesOptions}
                                        changeData={(value) => onChangeData(index, value)}
                                        component={CustomSelect}
                                        placeholder="Select..."
                                        isMulti={false}
                                        value={field.attributes}
                                        disabled={Data && Data.isEdit === false}
                                        styles={customStyles}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <div className="name-type">
                                        <Input
                                          type="text"
                                          name={`attributeValues${index}`}
                                          label="values"
                                          placeholder="Enter Values"
                                          className="add-roles"
                                          value={field.attributeValues}
                                          changeInputData={(event) => changeInputData(index, event.target.value, setFieldValue)}
                                          disabled={
                                            Data && Data.isEdit === false
                                          }
                                          maxLength={50}
                                          hidelength="true"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <UploadImage
                                        name={`attributeImage${index}`}
                                        onChange={setFieldValue}
                                        changeImageData={(value) => changeImageData(index, value)}
                                        value={values[`attributeImage${index}`] ? values[`attributeImage${index}`] : field.attributeImage}
                                        disabled={Data && Data.isEdit === false}
                                        restrictOne={true}
                                        filterByType={12}
                                        setErrorImages={setAttributeErrorImages}
                                        setShowImage={setAttributeShowImage}
                                      />
                                      <ShowImage
                                        value={values[`attributeImage${index}`] ? values[`attributeImage${index}`] : field.attributeImage} errorImages={attributeErrorImages}
                                        showImage={attributeShowImage} />
                                    </div>
                                    <div className="col-md-3">
                                      <Field
                                        className="select-menu mb-2"
                                        name={`attributeCategory${index}`}
                                        options={attributeCategoryListOptions}
                                        changeData={(value) => onChangeData(index, value, 'attributeCategory')}
                                        component={CustomSelect}
                                        placeholder="Select..."
                                        isMulti={false}
                                        value={field.attributeCategory}
                                        disabled={Data && Data.isEdit === false}
                                        styles={customStyles}
                                      />
                                    </div>
                                    <div className="col-1">
                                      <Button
                                        className="btn btn-fillter-main"
                                        type="button"
                                        onClick={() => handleRemoveField(field.id)}
                                        name={
                                          <div className="Plus"><FaMinus /></div>
                                        }
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="col-12">
                        <div className="paragraph-editor">
                          <h4 className="paragraph-title">Status</h4>
                          <Field
                            name="status"
                            setFieldValue={setFieldValue}
                            component={Toggle}
                            disabled={Data && Data.isEdit === false}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || !Data) && (
                            <>
                              <Button
                                type="submit"
                                name="Save"
                                className="btn login-btn"
                              />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() =>
                                  handleCancleClick("/product", navigate)
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}