const sidebarInitialState = { 
    items: []
};

/**
 * Sidebar reducer
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const SidebarReducer = (state = sidebarInitialState, action) => {
    switch (action.type) {
        case 'GET_SIDEBAR_DATA':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_SIDEBAR_DATA':
            return sidebarInitialState;
        default:
            return state;
      };
}