const blogInitialState = { 
    items: []
};

export const BlogReducer = (state = blogInitialState, action) => {
    switch (action.type) {
        case 'ADD_BLOG_DATA':
            return {
                ...state,
                items: [...state.items, action.payload]
            };
        case 'UPDATE_BLOG_DATA':
            const index = state.items.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.items[index] = action.payload;
            }
            return {
                ...state
            };
        case 'DELETE_BLOG_DATA':
            state.items = state.items.filter(item => item.id !== action.payload);
            return {
                ...state
            };
        default:
            return state;
      };
}