import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Button from '../../../components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import Toggle from '../../../components/Toggle/Toggle';


const brandtypeOptions = [
  { value: "red", label: "Red" },
  { value: "blue", label: "Blue" },
];

export default function Customerview() {

  //Setting initial values
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName:'',
    email:'',
    phoneNo:'',
    userType:'',
    role: '',
    status: true
  })

  //Getting data from customer page
  const location = useLocation();
  const { Data } = location.state || {};

  //Reinitializing inital form data
  useEffect(()=>{
    if(Data && Data.data){
      setInitialValues({
        firstName: Data.data.name,
        lastName: Data.data.name,
        email: Data.data.email,
        phoneNo: Data.data.number,
        userType: Data.data.userType || '',
        role: Data.data.role || '',
        status: Data.data.isActive || false
      })
    }
  }, [Data])

  //Handling form submission
  const handleSubmit = (values) => {
    console.log("values: ", values);
  }
  const navigate = useNavigate();

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>Customer List</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={()=> navigate("/home")}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={()=> navigate("/customer")}>Customer</Breadcrumb.Item>
                  <Breadcrumb.Item active>Customer List</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>Customer List</h4>
            </div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
              {({setFieldValue})=>(
                <Form>
                <div className="add-roles-form row">
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">First Name<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="firstName"
                        label="First Name"
                        placeholder="First Name"
                        className="add-roles"
                        maxLength={50}
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Last Name<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="lastName"
                        label="Last Name"
                        placeholder="Last Name"
                        className="add-roles"
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Email<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="email"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        className="add-roles"
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="name-type">
                      <div className="paragraph-editor ">
                        <h4 className="paragraph-title">Phone no.<span className="required-label"></span></h4>
                      </div>
                      <Input
                        type="text"
                        name="phoneNo"
                        label="Number"
                        placeholder="Phone no."
                        className="add-roles"
                        disabled={Data && Data.isEdit === false}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">UserType<span className="required-label"></span></h4>
                    <Field
                    className="select-menu"
                    name="userType"
                    options={brandtypeOptions}
                    component={CustomSelect}
                    placeholder="Select..."
                    isMulti={false}
                    disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Role<span className="required-label"></span></h4>
                    <Field
                    className="select-menu"
                    name="role"
                    options={brandtypeOptions}
                    component={CustomSelect}
                    placeholder="Select..."
                    isMulti={false}
                    disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>
                <div className="col-12">
                    <div className="paragraph-editor">
                      <h4 className="paragraph-title">Status</h4>
                      <Field name='status' setFieldValue={setFieldValue} className='toggle' component={Toggle} disabled={Data && Data.isEdit === false}/>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="paragraph-editor">
                      <div className="btn-submit mt-3">
                        {((Data && Data.isEdit === true) || !Data) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                              />
                            </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
