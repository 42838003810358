import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Toggle from "../../../components/Toggle/Toggle";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import UtilsData, { toastClass, ValidationMessage } from "../../../Utils/Utils";
import {
  handleCancleClick,
  handlesaveClick,
} from "../../../components/Alertmessage/Alertmessage";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateBranchService } from "../BranchService/BranchService";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import { getAllBrandService } from "../../BrandList/BrandService/BrandService";
import { modifyString } from "../../../services/Regular-services/StringService";

export default function AddBranches() {
  const navigate = useNavigate();
  const token = UtilsData();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const branchPage = useSelector((state) => state.branchPage?.items);

  /**
   * Getting data from branch list table
   */
  const location = useLocation();
  const { Data } = location.state || {};
  const [fields, setFields] = useState([]);
  const [fieldArrays, setFieldArrays] = useState([]);
  const authuserData = useSelector((state) => state.auth?.user);
  const totalBrand = useSelector((state) => state.totalBrand);
  const [brandOptions, setBrandOptions] = useState([]);

  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      name: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
      address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
      pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
      taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
      brand: Yup.array().min(1, ValidationMessage.MULTI_BRAND_SELECT),
    })
  );

  const sideBarData = useSelector((state) => state.sideBar.items);
  const [branchActionData, setbranchActionData] = useState([]);

  useEffect(() => {
    if (sideBarData) {
      const actionData = sideBarData.filter(
        (item) => item.router_link === 'branch'
      );
      setbranchActionData(actionData);
    }
  }, [sideBarData]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    address: "",
    pincode: "",
    taxType: "no tax",
    vat_number: "",
    gst_number: "",
    visible: 1,
    day: "",
    start_time: "",
    end_time: "",
    active: true,
    delivery_distance: "",
    delivery_charge: "",
    paymentUrl: "",
    delivery: 0,
    // notification: 0, field for multiple beep
    smsAllow: 0,
    brands: [],
  });
  const taxTypeOption = [
    {
      value: "no tax",
      label: "No tax",
    },
    {
      value: "GST",
      label: "GST",
    },
    {
      value: "VAT",
      label: "VAT",
    },
  ];
  const daytypeOptions = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];

  const timeOptions = [
    { value: 0, label: "12 AM (00:00)" },
    { value: 1, label: "01 AM (01:00)" },
    { value: 2, label: "02 AM (02:00)" },
    { value: 3, label: "03 AM (03:00)" },
    { value: 4, label: "04 AM (04:00)" },
    { value: 5, label: "05 AM (05:00)" },
    { value: 6, label: "06 AM (06:00)" },
    { value: 7, label: "07 AM (07:00)" },
    { value: 8, label: "08 AM (08:00)" },
    { value: 9, label: "09 AM (09:00)" },
    { value: 10, label: "10 AM (10:00)" },
    { value: 11, label: "11 AM (11:00)" },
    { value: 12, label: "12 PM (12:00)" },
    { value: 13, label: "01 PM (13:00)" },
    { value: 14, label: "02 PM (14:00)" },
    { value: 15, label: "03 PM (15:00)" },
    { value: 16, label: "04 PM (16:00)" },
    { value: 17, label: "05 PM (17:00)" },
    { value: 18, label: "06 PM (18:00)" },
    { value: 19, label: "07 PM (19:00)" },
    { value: 20, label: "08 PM (20:00)" },
    { value: 21, label: "09 PM (21:00)" },
    { value: 22, label: "10 PM (22:00)" },
    { value: 23, label: "11 PM (23:00)" },
  ];

  useEffect(() => {
    if (Data && Data.data) {
      setInitialValues({
        name: Data.data.name,
        address: Data.data.address,
        pincode: Data.data.pincode,
        active: Data.data.isActive === "1" ? true : false,
        taxType: Data.data.tax_type,
        vat_number: Data.data.vat_number,
        gst_number: Data.data.gst_number,
        visible: 1,
        paymentUrl: Data.data.payment_url,
        smsAllow: Data.data.is_wp_sms_allow === "1" ? true : false,
        brand: Data.data?.brand_id
          ? JSON.parse(Data.data.brand_id).map((item) => item.id)
          : [],
        day: "",
        start_time: "",
        end_time: "",
        delivery: Data.data.is_delivery_available === "1" ? true : false,
        delivery_distance: "",
        delivery_charge: "",
      });
      const scheduleData = JSON.parse(Data.data.schedule);

      const newScheduleData = scheduleData
        ? scheduleData.map((item, index) => ({
            id: index + 1,
            day: parseInt(item.day_id),
            start_time: parseInt(item.start_time),
            end_time: parseInt(item.end_time)
          }))
        : [];
        

      setFieldArrays(newScheduleData);

      const deliveryData = JSON.parse(JSON.parse(Data.data.distance_data));
      const newDeliveryData = Array.isArray(deliveryData)
        ? deliveryData.map((item, index) => ({
            id: index + 1,
            delivery_charge: parseInt(item.value, 10),
            delivery_distance: parseInt(item.distance, 10),
          }))
        : [];

      setFields(newDeliveryData);
    }
  }, [Data]);


  // Custom styles for React Select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.is_active === "0" ? "#F00" : "#000", // Set color to red if is_active is '0'
    }),
  };
  /**
   * Fetching brand data from API
   */
  const fetchBrand = () => {
    const requestBody = {
      p_skip: 0,
      p_limit: 10,
      p_is_active: "1",
    };
    getAllBrandService(requestBody, token, dispatch, true);
  };

  useEffect(() => {
    if (totalBrand && totalBrand.items && totalBrand.items.length === 0) {
      fetchBrand();
    } else {
      if (totalBrand.items[0]?.data && totalBrand.items[0].data.length > 0) {
        const newBrand = totalBrand.items[0].data.map((item) => ({
          label: item?.name,
          value: item?.brandId,
        }));
        setBrandOptions(newBrand);
      }
    }
    // eslint-disable-next-line
  }, [totalBrand]);

  const setTaxValidation = (value, setFieldValue) => {
    if (value?.value) {
      const type = value.value;

      if (type === "GST") {
        setValidationSchema(
          Yup.object({
            name: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
            address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
            pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
            taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            gst_number: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            brand: Yup.array().min(1, ValidationMessage.MULTI_BRAND_SELECT)
          })
        );
        setFieldValue("vat_number", "");
      } else if (type === "VAT") {
        setValidationSchema(
          Yup.object({
            name: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
            address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
            pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
            taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            vat_number: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            brand: Yup.array().min(1, ValidationMessage.MULTI_BRAND_SELECT)
          })
        );
        setFieldValue("gst_number", "");
      } else {
        setValidationSchema(
          Yup.object({
            name: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
            address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
            pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
            taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
            brand: Yup.array().min(1, ValidationMessage.MULTI_BRAND_SELECT),
          })
        );
        setFieldValue("gst_number", "");
        setFieldValue("vat_number", "");
      }
    } else {
      setValidationSchema(
        Yup.object({
          name: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
          address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
          pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED),
          taxType: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
          brand: Yup.array().min(1, ValidationMessage.MULTI_BRAND_SELECT),
        })
      );
      setFieldValue("gst_number", "");
    }
  };

  /**
   * Adding fields for delivery
   */
  const handleAddField = (values, setFieldValue) => {
    if (values.delivery_distance === "" || values.delivery_charge === "") {
      toast.error(
        "Please select delivery distance and delivery charges before adding!"
      );
    } else {
      let newId;
      if (fields.length === 0) {
        newId = fields.length + 1;
      } else {
        const lastKey = fields[fields.length - 1].id;
        newId = lastKey + 1;
      }
      const newField = {
        id: newId,
        delivery_distance: values.delivery_distance,
        delivery_charge: values.delivery_charge,
      };
      setFieldValue("delivery_distance", "");
      setFieldValue("delivery_charge", "");
      setFields([...fields, newField]);
    }
  };

  /**
   * Removing fields for delivery
   */
  const handleRemoveField = (idToRemove) => {
    const list = [...fields];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFields(filterField);
  };
  /**
   * handle change delivery
   */
  const onChangeDelivery = (event, setFieldValue) => {
    const { checked } = event.target;
    setFieldValue("delivery", checked);
    if (checked === 0 || checked === false) {
      setFields([]);
    }
  };

  /**
   * Appending start time, end time, and day
   */
  const handleAddFieldArray = (values, setFieldValue) => {
    if (
      values.day === "" ||
      values.start_time === "" ||
      values.end_time === ""
    ) {
      toast.error("Please enter day, start time and end time!");
      return;
    } else {
      if (values.start_time > values.end_time) {
        toast.error("Start time should not be greater than end time!");
        return;
      }
      // Check if the new field conflicts with existing ones
      const conflicts =
        fieldArrays && fieldArrays.length > 0
          ? fieldArrays.some(
              (field) =>
                field.day === values.day &&
                field.start_time === values.start_time &&
                field.end_time === values.end_time
            )
          : false;

      if (conflicts) {
        toast.error("The schedule should be unique!");
        return;
      }
      let newId;
      if (fieldArrays.length === 0) {
        newId = fieldArrays.length + 1;
      } else {
        const lastKey = fieldArrays[fieldArrays.length - 1].id;
        newId = lastKey + 1;
      }
      const newField = {
        id: newId,
        day: values.day,
        end_time: values.end_time,
        start_time: values.start_time
      };
      setFieldValue("day", "");
      setFieldValue("end_time", "");
      setFieldValue("start_time", "");
      setFieldArrays([...fieldArrays, newField]);
    }
  };
  /**
   * Removing already appended day, start time, end time
   */
  const handleRemoveArrayField = (idToRemove) => {
    const list = [...fieldArrays];
    let filterField = list.filter((item) => item.id !== idToRemove);
    setFieldArrays(filterField);
  };

  /**
   * Updating days, start_time and end_time in reverse order
   */
  const onChangeData = (index, value, dataType, fieldValue) => {
    let listData = [...fieldArrays];

    if (dataType === "start_time") {
      if (
        value !== "" &&
        value > listData[fieldArrays.length - index - 1]["end_time"] &&
        listData[fieldArrays.length - index - 1]["end_time"] > 0 &&
        listData[fieldArrays.length - index - 1]["start_time"] > 0
      ) {
        if (!document.querySelector(toastClass)) {
          toast.error("Start time should not be greater than end time");
        }
        return;
      } else {
        if (fieldValue) {
          const conflicts =
            fieldArrays && fieldArrays.length > 0
              ? fieldArrays.some(
                  (field) =>
                    field.day === fieldValue.day &&
                    field.start_time === value &&
                    field.end_time === fieldValue.end_time
                )
              : false;

          if (conflicts) {
            toast.error("The schedule should be unique!");
            return;
          }
        }
        listData[fieldArrays.length - index - 1][dataType] = value;
        setFieldArrays(listData);
      }
    } else if (dataType === "end_time") {
      if (
        value !== "" &&
        value < listData[fieldArrays.length - index - 1]["start_time"] &&
        listData[fieldArrays.length - index - 1]["end_time"] > 0 &&
        listData[fieldArrays.length - index - 1]["start_time"] > 0
      ) {
        if (!document.querySelector(toastClass)) {
          toast.error("End time should not be lesser than start time!");
        }
        return;
      } else {
        if (fieldValue) {
          const conflicts =
            fieldArrays && fieldArrays.length > 0
              ? fieldArrays.some(
                  (field) =>
                    field.day === fieldValue.day &&
                    field.start_time === fieldValue.start_time &&
                    field.end_time === value
                )
              : false;

          if (conflicts) {
            toast.error("The schedule should be unique!");
            return;
          }
        }
        listData[fieldArrays.length - index - 1][dataType] = value;
        setFieldArrays(listData);
      }
    } else if (dataType === "day") {
      if (fieldValue) {
        const conflicts =
          fieldArrays && fieldArrays.length > 0
            ? fieldArrays.some(
                (field) =>
                  field.day === value &&
                  field.start_time === fieldValue.start_time &&
                  field.end_time === fieldValue.end_time
              )
            : false;

        if (conflicts) {
          toast.error("The schedule should be unique!");
          return;
        }
      }
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
    } else {
      listData[fieldArrays.length - index - 1][dataType] = value;
      setFieldArrays(listData);
    }
  };

  /**
   * Handling form submission
   */
  const handleSubmit = (values) => {
    let scheduleData =
      fieldArrays.length > 0
        ? fieldArrays.map((item) => ({
            day_id: item.day,
            start_time: parseInt(item.start_time),
            end_time: parseInt(item.end_time)
          }))
        : [];

    if (scheduleData.length < 1) {
      if (
        values.day !== "" &&
        values.start_time !== "" &&
        values.end_time !== ""
      ) {
        if (values.start_time < values.end_time) {
          scheduleData.push({});
          scheduleData[0].day_id = values.day;
          scheduleData[0].start_time = parseInt(values.start_time);
          scheduleData[0].end_time = parseInt(values.end_time);
        } else {
          toast.error("Start time should be less than end time!");
          return;
        }
      } else {
        toast.error(
          "Please enter day, start time and end time!"
        );
      }
    } else {
      if (
        values.day !== "" &&
        values.start_time !== "" &&
        values.end_time !== ""
      ) {
        if (values.start_time <= values.end_time) {
          // Check if the new field conflicts with existing ones
          const conflicts =
            fieldArrays && fieldArrays.length > 0
              ? fieldArrays.some(
                  (field) =>
                    field.day === values.day &&
                    field.start_time === values.start_time &&
                    field.end_time === values.end_time
                )
              : false;

          if (conflicts) {
            toast.error("The schedule should be unique!");
            return;
          }
          scheduleData.push({
            day_id: values.day,
            start_time: parseInt(values.start_time),
            end_time: parseInt(values.end_time),
          });
        } else {
          toast.error("Start time should be less than end time!");
          return;
        }
      }
    }
    let distanceData =
      fields.length > 0
        ? fields.map((item) => ({
            distance: item.delivery_distance,
            value: item.delivery_charge,
          }))
        : [];

    if (distanceData.length < 1) {
      if (values.delivery_distance !== "" && values.delivery_charge !== "") {
        distanceData.push({});
        distanceData[0].distance = values.delivery_distance;
        distanceData[0].value = values.delivery_charge;
      }
    } else {
      if (values.delivery_distance !== "" && values.delivery_charge !== "") {
        distanceData.push({
          distance: values.delivery_distance,
          value: values.delivery_charge,
        });
      }
    }
    const requestBody = {
      ...(Data && Data?.data && { p_id: Data.data.branchId }),
      p_name: values.name,
      p_address: values.address,
      p_pincode: values.pincode,
      p_isActive:
        values.active === "1" || values.active === 1 || values.active === true
          ? "1"
          : "0",
      ...(Data && Data?.data && { p_IsDeleted: Data.data.isDeleted }),
      p_created_by: Data && Data?.data ? Data.data.createdBy : user.data.userId,
      ...(Data && Data?.data && { p_modified_by: user.data.userId }),
      p_tax: values.taxType,
      p_gst_number: values.gst_number,
      p_vat_number: values.vat_number,
      p_Working_day: scheduleData,
      p_distance_data:
        distanceData && distanceData.length > 0
          ? JSON.stringify(distanceData)
          : null,
      p_payment_url:
        values?.paymentUrl && values.paymentUrl !== ""
          ? values.paymentUrl
          : null,
      p_public_avilable:
        values.visible === "1" ||
        values.visible === 1 ||
        values.visible === true
          ? "1"
          : "0",
      p_delivery_available:
        values.delivery === "1" ||
        values.delivery === 1 ||
        values.delivery === true
          ? "1"
          : "0",
      p_wp_sms_allow:
        values.smsAllow === "1" ||
        values.smsAllow === 1 ||
        values.smsAllow === true
          ? "1"
          : "0",
      p_brand_ids:
        values.brand?.length > 0
          ? Array.from(new Set(values.brand.map((item) => Number(item)))).map((id) => ({ id }))
          : null,
    };
    
    if (!Data) {
      dispatch(addUpdateBranchService(requestBody, token, "add", navigate));
    } else {
      if (Data?.length && branchPage > 1) {
        if (
          values.active === "0" ||
          values.active === 0 ||
          values.active === false
        ) {
          if (Data.length > 1) {
            handlesaveClick(
              dispatch,
              addUpdateBranchService(
                requestBody,
                token,
                "update",
                navigate,
                branchPage
              ),
              "update"
            );
          } else {
            handlesaveClick(
              dispatch,
              addUpdateBranchService(
                requestBody,
                token,
                "update",
                navigate,
                branchPage - 1
              ),
              "update"
            );
          }
        } else {
          handlesaveClick(
            dispatch,
            addUpdateBranchService(
              requestBody,
              token,
              "update",
              navigate,
              branchPage
            ),
            "update"
          );
        }
      } else {
        handlesaveClick(
          dispatch,
          addUpdateBranchService(requestBody, token, "update", navigate),
          "update"
        );
      }
    }
  };

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {branchActionData && branchActionData.length > 0 ? modifyString(branchActionData[0].name) : 'Branch'}
                  </h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/branch")}>
                  {branchActionData && branchActionData.length > 0 ? branchActionData[0].name : 'Branch'}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {Data && Data.isEdit === true
                      ? "Edit "
                      : Data && Data.isEdit === false
                      ? "View "
                      : "Add "}{" "}
                    {branchActionData && branchActionData.length > 0 ? modifyString(branchActionData[0].name) : 'Branch'}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>
                {Data && Data.isEdit === true
                  ? "Edit "
                  : Data && Data.isEdit === false
                  ? "View "
                  : "Add "}{" "}
                {branchActionData && branchActionData.length > 0 ? modifyString(branchActionData[0].name) : 'Branch'}
              </h4>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize={true}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Name<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="name"
                          label="Name"
                          placeholder="Enter Name"
                          className="add-roles"
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.name}
                          maxLength={20}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Address<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="address"
                          label="Address"
                          placeholder="Enter Address"
                          className="add-roles"
                          Regex={/[^a-zA-Z0-9\-/\s]/g}
                          value={values.address}
                          maxLength={140}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">
                            Pincode<span className="required-label"></span>
                          </h4>
                        </div>
                        <Input
                          type="text"
                          name="pincode"
                          label="Pin code"
                          placeholder="Enter Pincode"
                          className="add-roles"
                          Regex={/[^0-9]/g}
                          maxLength={6}
                          hidelength={"true"}
                          value={values.pincode}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Tax Type
                          <span className="required-label"></span>
                        </h4>
                        <Field
                          className="select-menu mb-2"
                          name="taxType"
                          options={taxTypeOption}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={false}
                          value={values.taxType}
                          disabled={Data && Data.isEdit === false}
                          styles={customStyles}
                          onchangedata={(value) =>
                            setTaxValidation(value, setFieldValue)
                          }
                        />
                      </div>
                    </div>
                    {values.taxType === "GST" ? (
                      <>
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">
                                GST Number
                                <span className="required-label"></span>
                              </h4>
                            </div>
                            <Input
                              type="text"
                              name="gst_number"
                              label="GST Number"
                              placeholder="Enter GST Number"
                              className="add-roles"
                              maxLength={20}
                              Regex={/[^A-Za-z0-9]/g}
                              value={values.gst_number}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      values.taxType === "VAT" && (
                        <div className="col-md-6">
                          <div className="name-type">
                            <div className="paragraph-editor ">
                              <h4 className="paragraph-title">
                                VAT Number
                                <span className="required-label"></span>
                              </h4>
                            </div>
                            <Input
                              type="text"
                              name="vat_number"
                              label="VAT Number"
                              placeholder="Enter VAT Number"
                              className="add-roles"
                              maxLength={20}
                              Regex={/[^A-Za-z0-9]/g}
                              value={values.vat_number}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                        </div>
                      )
                    )}

                    {/* working hrs */}
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="paragraph-editor">
                            <h4 className="paragraph-title">
                              Day
                              <span className="required-label"></span>
                            </h4>
                          </div>
                        </div>
                        {(Data && Data.isEdit === true) || !Data ? (
                          <div className="col-md-2">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                Start time
                                <span className="required-label"></span>
                              </h4>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-2">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                Start time
                                <span className="required-label"></span>
                              </h4>
                            </div>
                          </div>
                        )}
                        {(Data && Data.isEdit === true) || !Data ? (
                          <div className="col-md-2">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                End time{" "}
                                <span className="required-label"></span>
                              </h4>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-3">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                End time{" "}
                                <span className="required-label"></span>
                              </h4>
                            </div>
                          </div>
                        )}
                        {((Data && Data.isEdit === true) || !Data) && (
                          <div className="col-md-2">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">Add</h4>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {((Data && Data.isEdit === true) || !Data) && (
                          <div className="col-md-6">
                            <Field
                              className="select-menu mb-2"
                              name="day"
                              options={daytypeOptions}
                              component={CustomSelect}
                              placeholder="Select..."
                              isMulti={false}
                              value={
                                values.day === "" || values.day === null
                                  ? false
                                  : values.day
                              }
                            />
                          </div>
                        )}
                        {((Data && Data.isEdit === true) || !Data) && (
                          <div className="col-md-2">
                            <div className="name-type">
                              <Field
                                className="select-menu mb-2"
                                name="start_time"
                                options={timeOptions}
                                component={CustomSelect}
                                placeholder="Select..."
                                isMulti={false}
                                value={
                                  values.start_time === "" ||
                                  values.start_time === null
                                    ? false
                                    : values.start_time
                                }
                              />
                            </div>
                          </div>
                        )}
                        {((Data && Data.isEdit === true) || !Data) && (
                          <div className="col-md-2">
                            <div className="name-type">
                              <Field
                                className="select-menu mb-2"
                                name="end_time"
                                options={timeOptions}
                                component={CustomSelect}
                                placeholder="Select..."
                                isMulti={false}
                                value={
                                  values.end_time === "" ||
                                  values.end_time === null
                                    ? false
                                    : values.end_time
                                }
                              />
                            </div>
                          </div>
                        )}
                        {((Data && Data.isEdit === true) || !Data) && (
                          <div className="col-1">
                            <Button
                              className="btn btn-fillter-main"
                              type="button"
                              onClick={() =>
                                handleAddFieldArray(values, setFieldValue)
                              }
                              name={
                                <div className="Plus">
                                  <FaPlus />
                                </div>
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        {fieldArrays &&
                          fieldArrays.length > 0 &&
                          fieldArrays
                            .slice()
                            .reverse()
                            .map((field, index) => (
                              <div className="row" key={index}>
                                <div className="col-md-6">
                                  <Field
                                    className="select-menu mb-2"
                                    name={`day${index}`}
                                    options={daytypeOptions}
                                    changeData={(value) =>
                                      onChangeData(index, value, "day", field)
                                    }
                                    component={CustomSelect}
                                    placeholder="Select..."
                                    isMulti={false}
                                    value={field.day}
                                    disabled={Data && Data.isEdit === false}
                                  />
                                </div>
                                {(Data && Data.isEdit === true) || !Data ? (
                                  <div className="col-md-2">
                                    <div className="name-type">
                                      <Field
                                        className="select-menu mb-2"
                                        name={`start_time${index}`}
                                        options={timeOptions}
                                        component={CustomSelect}
                                        placeholder="Select..."
                                        isMulti={false}
                                        changeData={(value) =>
                                          onChangeData(
                                            index,
                                            value,
                                            "start_time",
                                            field
                                          )
                                        }
                                        value={field.start_time}
                                        disabled={Data && Data.isEdit === false}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-3">
                                    <div className="name-type">
                                      <Field
                                        className="select-menu mb-2"
                                        name={`start_time${index}`}
                                        options={timeOptions}
                                        component={CustomSelect}
                                        placeholder="Select..."
                                        isMulti={false}
                                        changeData={(value) =>
                                          onChangeData(
                                            index,
                                            value,
                                            "start_time",
                                            field
                                          )
                                        }
                                        value={field.start_time}
                                        disabled={Data && Data.isEdit === false}
                                      />
                                    </div>
                                  </div>
                                )}
                                {(Data && Data.isEdit === true) || !Data ? (
                                  <div className="col-md-2">
                                    <div className="name-type">
                                      <Field
                                        className="select-menu mb-2"
                                        name={`end_time${index}`}
                                        options={timeOptions}
                                        component={CustomSelect}
                                        placeholder="Select..."
                                        isMulti={false}
                                        changeData={(value) =>
                                          onChangeData(
                                            index,
                                            value,
                                            "end_time",
                                            field
                                          )
                                        }
                                        value={field.end_time}
                                        disabled={Data && Data.isEdit === false}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="col-md-3">
                                    <div className="name-type">
                                      <Field
                                        className="select-menu mb-2"
                                        name={`end_time${index}`}
                                        options={timeOptions}
                                        component={CustomSelect}
                                        placeholder="Select..."
                                        isMulti={false}
                                        changeData={(value) =>
                                          onChangeData(
                                            index,
                                            value,
                                            "end_time",
                                            field
                                          )
                                        }
                                        value={field.end_time}
                                        disabled={Data && Data.isEdit === false}
                                      />
                                    </div>
                                  </div>
                                )}

                                {((Data && Data.isEdit === true) || !Data) && (
                                  <div className="col-1">
                                    <Button
                                      className="btn btn-fillter-main"
                                      type="button"
                                      onClick={() =>
                                        handleRemoveArrayField(field.id)
                                      }
                                      name={
                                        <div className="Plus">
                                          <FaMinus />
                                        </div>
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            ))}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Payment URL
                          {/* <span className="required-label"></span> */}
                        </h4>
                        <Input
                          type="text"
                          name="paymentUrl"
                          label="Payment URL"
                          placeholder="Enter Payment URL"
                          className="add-roles"
                          hidelength="true"
                          value={values.paymentUrl}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">
                          Brand<span className="required-label"></span>
                        </h4>
                        <Field
                          className="basic-single"
                          name="brand"
                          options={brandOptions}
                          component={CustomSelect}
                          placeholder="Select..."
                          isMulti={true}
                          value={values.brand}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="paragraph-editor public-visible">
                        <h4 className="paragraph-title">Public visible</h4>
                        <Field
                          name="visible"
                          setFieldValue={setFieldValue}
                          className="toggle"
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field
                          name="active"
                          setFieldValue={setFieldValue}
                          className="toggle"
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="paragraph-editor public-visible">
                        <h4 className="paragraph-title">WhatsApp message</h4>
                        <Field
                          name="smsAllow"
                          setFieldValue={setFieldValue}
                          className="toggle"
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>

                    {/* delivery changes */}
                    <div className="col-3">
                      <div className="paragraph-editor public-visible">
                        <h4 className="paragraph-title">Delivery available</h4>
                        <Field
                          name="delivery"
                          setFieldValue={setFieldValue}
                          className="toggle"
                          component={Toggle}
                          disabled={Data && Data.isEdit === false}
                          onChangeData={(event) =>
                            onChangeDelivery(event, setFieldValue)
                          }
                        />
                      </div>
                    </div>
                    {(values.delivery === "1" ||
                      values.delivery === 1 ||
                      values.delivery === true) && (
                      <>
                        <div className="row">
                          <div className="col-md-5">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                Delivery Distance (KM)
                                {/* <span className="required-label"></span> */}
                              </h4>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">
                                Delivery charges (
                                {authuserData?.data?.symbol
                                  ? authuserData.data.symbol
                                  : "₹"}
                                )
                                {/* <span className="required-label"></span> */}
                              </h4>
                            </div>
                          </div>
                          <div className="col-1">
                            <div className="paragraph-editor">
                              <h4 className="paragraph-title">Add</h4>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            <Input
                              type="text"
                              name="delivery_distance"
                              label="Delivery distance"
                              placeholder="Enter Delivery distance"
                              className="add-roles"
                              maxLength={5}
                              hidelength="true"
                              Regex={/[^0-9\s]/g}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                          <div className="col-md-5">
                            <Input
                              type="text"
                              name="delivery_charge"
                              label="Delivery charges"
                              placeholder="Enter Delivery charges"
                              className="add-roles"
                              maxLength={5}
                              hidelength="true"
                              Regex={/[^0-9\s]/g}
                              disabled={Data && Data.isEdit === false}
                            />
                          </div>
                          <div className="col-1">
                            <Button
                              className="btn btn-fillter-main"
                              type="button"
                              onClick={() =>
                                handleAddField(values, setFieldValue)
                              }
                              name={
                                <div className="Plus">
                                  <FaPlus />
                                </div>
                              }
                            ></Button>
                          </div>
                        </div>
                        {fields &&
                          fields.length > 0 &&
                          fields
                            .slice()
                            .reverse()
                            .map((field, index) => (
                              <div className="row" key={index}>
                                <div className="col-md-5">
                                  <Input
                                    type="text"
                                    name={`delivery_distance${index}`}
                                    label="Delivery distance"
                                    placeholder="Enter Delivery distance"
                                    className="add-roles"
                                    value={field.delivery_distance}
                                    disabled={Data && Data.isEdit === false}
                                    maxLength={5}
                                    Regex={/[^0-9\s]/g}
                                    hidelength="true"
                                  />
                                </div>

                                <div className="col-md-5">
                                  <Input
                                    type="text"
                                    name={`delivery_charge${index}`}
                                    label="Delivery charges"
                                    placeholder="Enter Delivery charges"
                                    className="add-roles"
                                    value={field.delivery_charge}
                                    // changeInputData={(event) => changeInputData(index, event.target.value, setFieldValue, 'delivery_charge')}
                                    disabled={Data && Data.isEdit === false}
                                    Regex={/[^0-9\s]/g}
                                    maxLength={5}
                                    hidelength="true"
                                  />
                                </div>

                                <div className="col-1">
                                  <Button
                                    className="btn btn-fillter-main"
                                    type="button"
                                    onClick={() => handleRemoveField(field.id)}
                                    name={
                                      <div className="Plus">
                                        <FaMinus />
                                      </div>
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                      </>
                    )}
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || !Data) && (
                            <>
                              <Button
                                type="submit"
                                name="Save"
                                className="btn login-btn"
                              />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() =>
                                  handleCancleClick("/branch", navigate)
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}