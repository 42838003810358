import React, { useEffect } from 'react'
import './Sidebar.scss'
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./Sidebarmenu";
import { useDispatch, useSelector } from 'react-redux';
import { getIcon } from '../../services/Regular-services/IconService';
import Logo from '../../assets/images/Bespeakk-logo.png';
import { OpenSidebarAction } from '../../redux/Actions/OpenSideBarAction/OpenSideBarAction';

export default function Sidebar() {

  const [isToggled, setIsToggled] = useState(false);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(window.innerWidth <= 991 ? false : true);
  const order = useSelector(state => state.order);

  /**
   * Handle filter toggle
   */
  const handleButtonClick = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled);
  }

  useEffect(() => {
    dispatch(OpenSidebarAction(isOpen));
    // eslint-disable-next-line
  }, [isOpen])

  const sideBarData = useSelector(state => state.sideBar?.items);
  const [routeData, setRouteData] = useState([]);

  useEffect(() => {
    if (Array.isArray(sideBarData)) {
      const sortedSideBarData = [...sideBarData].sort((a, b) => a.sequence - b.sequence);
      let newRouteData = [];
      let currentRoute = null;

      for (const item of sortedSideBarData) {
        if (item.parent_id === 0) {
          currentRoute = {
            path: item.router_link,
            name: item.name,
            icon: getIcon(item.icon),
            subRoutes: []
          };
          newRouteData.push(currentRoute);
        } else {
          if (currentRoute && item.parent_id !== 3 && item.parent_id !== 4 && item.parent_id !== 22 && item.parent_id !== 23 && item.parent_id !== 24 && item.parent_id !== 31 && item.parent_id !== 39) {
            if (item.allow !== '0') {
              currentRoute.subRoutes.push({
                path: '/' + item.router_link,
                name: item.name
              });
            }
          } else {
            if (item.allow !== '0') {
              let separateRoute;
              if (item.name === 'Orders') {
                // const itemCount = order && order.items && order.items.length > 0
                //   ? order.items.filter(item => item.status === '0').length
                //   : 0;

                const itemCount = order?.dataCount && order.dataCount > 0 ? order.dataCount : 0;
                
                separateRoute = {
                  path: '/' + item.router_link,
                  name: (
                    <span>
                      {item.name} {itemCount > 0 && <span className='pending'>({itemCount})</span>}
                    </span>
                  ),
                  icon: getIcon(item.icon)
                };
              } else {
                separateRoute = {
                  path: '/' + item.router_link,
                  name: item.name,
                  icon: getIcon(item.icon)
                };
              }
              newRouteData.push(separateRoute);
            }
          }
        }
      }

      const filteredRouteData = newRouteData.filter(route => {
        if (route.subRoutes && route.subRoutes.length === 0) {
          return false; // Filter out routes with empty subRoutes
        }
        return true; // Keep routes with non-empty subRoutes
      });

      setRouteData(filteredRouteData);
    }
    // eslint-disable-next-line
  }, [sideBarData, order]);

  //Routes in sidebar
  // const routes = [
  //   {
  //     path: "/",
  //     name: "Customers",
  //     icon: <MdGroups />,
  //     subRoutes: [
  //       {
  //         path: "/customer",
  //         name: "Customers",
  //       },
  //       {
  //         path: "/subscriber",
  //         name: "Subscribers",
  //       },
  //     ]
  //   },
  //   {
  //     path: "/orders",
  //     name: "Orders",
  //     icon: <FaShoppingCart />,
  //   },
  //   {
  //     path: "/carts",
  //     name: "Carts",
  //     icon: <FaCartPlus />,
  //   },
  //   {
  //     path: "/product-management",
  //     name: "Product Management",
  //     icon: <BiAnalyse />,
  //     subRoutes: [
  //       {
  //         path: "/brand-list",
  //         name: "Brand",
  //       },
  //       {
  //         path: "/category",
  //         name: "Category",
  //       },
  //       {
  //         path: "/sub-category",
  //         name: "Sub Category",
  //       },
  //       {
  //         path: "/product",
  //         name: "Products",
  //       },
  //       {
  //         path: "/imagemanager",
  //         name: "Image Gallery",
  //       },
  //       {
  //         path: "/leaf-level-category",
  //         name: "Leaf Level Category",
  //       },
  //       {
  //         path: "/tags",
  //         name: "Tags",
  //       },
  //     ]
  //   },
  //   {
  //     path: "/admin-user-management",
  //     name: "Admin User Management",
  //     icon: <PiUserSwitchFill />,
  //     subRoutes: [
  //       {
  //         path: "/adminusers",
  //         name: "Admin Users",
  //       },
  //       {
  //         path: "/roles",
  //         name: "Roles",
  //       }
  //     ]
  //   },
  //   {
  //     path: "/discount-management",
  //     name: "Discount Management",
  //     icon: <PiPercentFill />,
  //     subRoutes: [
  //       {
  //         path: "/discount",
  //         name: "Discount",
  //       },
  //       {
  //         path: "/discount-catalogue",
  //         name: "Discount Catalogue",
  //       }
  //     ]
  //   },
  //   {
  //     path: "/cms",
  //     name: "CMS",
  //     icon: <FaFileLines />,
  //     subRoutes: [
  //       {
  //         path: "/policy-list",
  //         name: "Policies",
  //       },
  //       {
  //         path: "/faq-list",
  //         name: "FAQ",
  //       },
  //       {
  //         path: "/blog-list",
  //         name: "Blog",
  //       }
  //     ]
  //   },
  //   {
  //     path: "/country-and-tax-management",
  //     name: "Country And Tax Management",
  //     icon: <FaFlagCheckered />,
  //     subRoutes: [
  //       {
  //         path: "/country",
  //         name: "Country",
  //       },
  //       {
  //         path: "/tax",
  //         name: "Tax",
  //       },
  //       {
  //         path: "/warehouse",
  //         name: "Warehouse",
  //       },
  //     ]
  //   },
  //   {
  //     path: "/product-review-management",
  //     name: "Product Review Management",
  //     icon: <FaStarHalfAlt />,
  //   },
  //   {
  //     path: "/tv",
  //     name: "TV",
  //     icon: <MdLiveTv />,
  //   },
  //   {
  //     path: "/influencer-reviews",
  //     name: "Influencer Reviews",
  //     icon: <FaStarHalfAlt />,
  //   },
  //   {
  //     path: "/inventory",
  //     name: "Inventory",
  //     icon: <MdOutlineInventory />,
  //   }

  // ]

  const toggle = () => setIsOpen(!isOpen);
  const [activeUrl, setActiveUrl] = useState(null);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0,
      },
    },
  }

  useEffect(() => {
    // Scroll to the active URL route
    const scrollToActive = () => {
      const activeMenuItem = document.querySelector('.active');
      if (activeMenuItem) {
        activeMenuItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    };

    // Wait for the DOM to be fully rendered before scrolling
    const intervalId = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(intervalId);
        scrollToActive();
      }
    }, 100);

    // Clean up on unmount
    return () => clearInterval(intervalId);
  }, []);

  /**
 * Url of add/update form pages
 */
  const listPagePaths = [
    { pagePath: "/add-order", listPath: "/orders" },
    { pagePath: "/edit-order", listPath: "/orders" },
    { pagePath: "", listPath: "" }
    // Add more list page paths as needed
  ];

  useEffect(() => {
    routeData.some(route => {
      // Check if window location pathname matches any subRoute path
      if (!route.subRoute) {
        if (route.path === window.location.pathname) {
          setActiveUrl(route.path);
          return true; // If there's an exact match, return true
        }
        // Otherwise, check if any pagePath matches the window location pathname
        const matchedPagePath = listPagePaths.find(item => item.pagePath === window.location.pathname);
        if (matchedPagePath && matchedPagePath.listPath === route.path) {
          setActiveUrl(route.path)
          return true; // If listPath matches, return true
        }
      }
      setActiveUrl(null)
      return false; // If no match found, return false
    });
    // eslint-disable-next-line
  }, [window.location.pathname])




  return (

    <>
      <div className="main-container">
        <motion.div animate={{
          width: isOpen ? "280px" : "50px",
          transition: {
            duration: 0,
            damping: 10,
          },
        }} className='sidebar scrollbar-width-none'>
          <section className="top_section">
            {isOpen && <h1 className="logo">
              <img src={Logo} alt='Logo' />
            </h1>}
            <div className="bars" onClick={handleButtonClick}>
              <MdKeyboardArrowRight className={isToggled ? 'main' : ''} onClick={toggle} />
            </div>
          </section>
          <section className='routes scrollbar-width-none'>
            {routeData.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }
              return (
                <NavLink to={route.path} key={index} className={`link${activeUrl === route.path ? ' active' : ''}`}>
                  <div className="icon">
                    {route.icon}
                  </div>
                  <AnimatePresence>
                    {isOpen && <motion.div variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text">
                      {route.name}
                    </motion.div>}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>
      </div>
    </>
  )
}