const warehouseFilterInitialState = {
    name: '', 
    isActive: '',
    isDelete: ''
};

/**
 * Store warehouse filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const warehouseFilterReducer = (state = warehouseFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_WAREHOUSE_FILTER':
            return {
                ...state,
                name: action.payload.name,
                isActive: action.payload.isActive,
                isDelete: action.payload.isDelete
            }
        case 'CLEAR_WAREHOUSE_FILTER':
            return warehouseFilterInitialState;
        default:
            return state;
      };
}