/**
 * set leaf level category filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getLeafFilterData = ({name, subCategory, isActive, isDelete}) => ({
    type: 'GET_LEAF_FILTER',
    payload: {name,subCategory, isActive, isDelete}
})

/**
 * clear leaf level category filtered data
 * @returns {*}
 */
export const clearLeafFilterData = () => ({
    type: 'CLEAR_LEAF_FILTER'
})