import { toast } from "react-toastify";
import { API_URL, ValidationMessage, setToken } from "../../Utils/Utils";
import { loginAuth, requestOTP } from "../../pages/login/Login-Service/loginService.tsx";
// import { encryptData } from "../Regular-services/EncryptionService";
import { requestOtp } from "../../pages/ForgotPassword/RequestOtpService";
import { verifyOtp } from "../../pages/OTP/VerifyOtpService";
import { resetPasswordService } from "../../pages/Resetpassword/ResetPasswordService";
import { clearLoader, setLoader } from "../../redux/Actions/LoaderAction";

const username = 'HrIAfpGuwvqVEKYyDBbF3gWATxeqE5aZQEXaSt7O7knyYECSyL1h9wTTDGSUxPMlsv6QyfCflkakdB3cWcSHo9UoagW6vDKZ3yTD9L5LhZLoPtco6ESAWp01tgH1im3G';
const password = 'xs6llx0WztwLsckeNVRNl9pu7odlLEASukwAU+BiiXkzD1i20MlkwZQ7EbfFXYmi/R9aqa9raAmau5Y+vmotxgdOk+UPd+2NGKpBj6dYKG42AFzkcs6WCJ3AfkbphdBK7yNx/FlAfBNLaeRL/O+Tfkm33HfEtKgC8TCM/JGBlOxkW5gjjISQ3SHhd+NpqjtwGw+OTZOzvamQaPEIfoDriGUC4VfMfDOOcni58087X/kmHiTtOPmy2FEuwUN7I4xxTKC3lA0oifC4adwgTte14394rkvdq0KIFrTjhaidTozH/E1zTgroaUN32Sxwkymfl7RYqfOXsI+drVQm0lkQbgIp9ElLshj9rG782e1P3NI=';

const basicAuth = 'Basic ' + btoa(username + ':' + password);

//Generate Token API
const getToken = async () => {

  try {
    const response = await fetch(API_URL.TOKEN_URL + 'generateToken', {
      method: 'GET',
      headers: {
        'Authorization': basicAuth
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setToken(data.data); //Setting token in local storage
    return data;
  } catch (error) {
    toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
  }
};

//Fetching Token Data and using it in other Api's
const fetchToken = (values, page, navigate) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader());
      const token = await getToken();
      if (token && token.status === 'success') {
        if (page === 'login') {
          // loginAuth(values.email, encryptData(values.password), values.rememberMe, token.data, dispatch);
          loginAuth(values.phone, values.otp, token.data, dispatch);
        }
        else if (page === 'requestLoginOtp') {
          requestOTP(values.phone, token.data, navigate, dispatch)
        }
        else if (page === 'requestOtp') {
          dispatch(requestOtp(values.email, token.data, navigate));
        }
        else if (page === 'verifyOtp') {
          dispatch(verifyOtp(values.email, values.otp, token.data, navigate))
        }
        else if (page === 'resetPassword') {
          dispatch(resetPasswordService(values.email, values.password, token.data, navigate))
        }
      }
      else {
        dispatch(clearLoader());
        toast.error('Error generating Token!');
      }
    } catch (error) {
      dispatch(clearLoader());
      toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
    }
  }
}

export default fetchToken;