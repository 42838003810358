
import './Checkbox.scss'
import React from 'react'
import { Form } from 'react-bootstrap';


export default function Checkbox(props) {

  const { name, field, setFieldValue, value, label, ...rest } = props;


  /**
   * On checking the checkbox
   * @param {*} event 
   */
  const onChange = (event) => {
    const { checked } = event.target;
    if (field && setFieldValue) {
      setFieldValue(field.name, checked);
    }
  };

  return (
    <>
      <div className="form-group">
        <Form.Check
          name={(field ? field.name : (name ? name : 'checkbox'))}
          label={label ? label : ''}
          type="switch"
          id={(field ? field.name : (name ? name : 'custom-checkbox'))}
          className="custom-checkbox"
          checked={value}
          onChange={onChange}
          {...rest}
        />
      </div>
    </>
  );
}