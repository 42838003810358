import { orderDate } from "../../../services/Regular-services/DateService";

const vendorFilterInitialState = {
    orderNo: '',
    orderStartDate:orderDate(),
    orderEndDate:'',
    vendor: '',
    status: '',
    payment:'',
    // isDelete: '',
};

/**
 * Store vendor filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const VendorFilterReducer = (state = vendorFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_VENDOR_FILTER':
            return {
                ...state,
                orderNo:action.payload.orderNo,
                orderStartDate: action.payload.orderStartDate,
                orderEndDate:action.payload.orderEndDate,
                payment: action.payload.payment,
                status:action.payload.status,
                vendor:action.payload.vendor
            }
        case 'CLEAR_VENDOR_FILTER':
            return vendorFilterInitialState;
        default:
            return state;
    };
}