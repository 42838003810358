const productInitialState = { 
    items: []
};

/**
 * Store product list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const ProductReducer = (state = productInitialState, action) => {
    switch (action.type) {
        case 'GET_PRODUCT_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_PRODUCT_DATA':
            return productInitialState;
        default:
            return state;
      };
}

const totalProductInitialState = {
    items: []
}

/**
 * Store total product list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalProductReducer = (state = totalProductInitialState, action) => {
    switch (action.type) {
        case 'TOTAL_PRODUCT_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_PRODUCT':
            return totalProductInitialState
        default:
            return state;
      };
}