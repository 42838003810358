import CryptoJS from 'crypto-js';

// Encrypt Data
export const encryptData = (data) => {
    const decryptedData = data;
    
    const key = 'xs6llx0WztwLsckeNVRNl9pu7odlLEASukwAU+BiiXkzD1i20MlkwZQ7EbfFXYmi/R9aqa9raAmau5Y+vmotxgdOk+UPd+2NGKpBj6dYKG42AFzkcs6WCJ3AfkbphdBK7yNx/FlAfBNLaeRL/O+Tfkm33HfEtKgC8TCM/JGBlOxkW5gjjISQ3SHhd+NpqjtwGw+OTZOzvamQaPEIfoDriGUC4VfMfDOOcni58087X/kmHiTtOPmy2FEuwUN7I4xxTKC3lA0oifC4adwgTte14394rkvdq0KIFrTjhaidTozH/E1zTgroaUN32Sxwkymfl7RYqfOXsI+drVQm0lkQbgIp9ElLshj9rG782e1P3NI=';

    const encryptedData = CryptoJS.AES.encrypt(decryptedData, key).toString();

    return encryptedData;
  };

  // Decrypt Data
  export const decryptData = (data) => {
    const encryptedData = data;
    
    const key = 'xs6llx0WztwLsckeNVRNl9pu7odlLEASukwAU+BiiXkzD1i20MlkwZQ7EbfFXYmi/R9aqa9raAmau5Y+vmotxgdOk+UPd+2NGKpBj6dYKG42AFzkcs6WCJ3AfkbphdBK7yNx/FlAfBNLaeRL/O+Tfkm33HfEtKgC8TCM/JGBlOxkW5gjjISQ3SHhd+NpqjtwGw+OTZOzvamQaPEIfoDriGUC4VfMfDOOcni58087X/kmHiTtOPmy2FEuwUN7I4xxTKC3lA0oifC4adwgTte14394rkvdq0KIFrTjhaidTozH/E1zTgroaUN32Sxwkymfl7RYqfOXsI+drVQm0lkQbgIp9ElLshj9rG782e1P3NI=';

    const decryptedData = CryptoJS.AES.decrypt(encryptedData, key).toString();

    return decryptedData;
  };