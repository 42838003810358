import { Dispatch } from "redux";
import { SidebarResponse } from "./SidebarModal";
import { toast } from "react-toastify";
import { clearLoader, setLoader } from "../../../redux/Actions/LoaderAction";
import { getApiService } from "../../../services/API-services/GetService";
import { API_URL, ValidationMessage, removePersistStorage } from "../../../Utils/Utils";
import { getSidebarData } from "../../../redux/Actions/SidebarAction/SidebarAction";

/*
 * Service to get sidebar by role id
*/
let errorShow = false;
export const getSideBarByRoleId = (
  id: string, token: string,
  dispatch: Dispatch): Promise<SidebarResponse> => {
  return new Promise<SidebarResponse>(async (resolve, reject) => {
    try {
      dispatch(setLoader())
      const response = await getApiService(API_URL.BASE_URL + API_URL.SIDEBAR + '/' + id, token);
      if (response && (response.status === 200 || response.status === '200' || response.status === 'success')) {
        dispatch(getSidebarData(response.data));
        resolve(response);
      }
      else {
        toast.error(response?.message ? response.message : ValidationMessage.SOMETHING_WENT_WRONG);
        errorShow = true;
        if (response?.message && response.message === 'Unauthorized access!') {
          removePersistStorage(dispatch);
          // dispatch(logout());
        }
      }
      resolve(response);
    } catch (error) {
      if (errorShow === false) {
        toast.error(ValidationMessage.SOMETHING_WENT_WRONG);
      }
    } finally {
      errorShow = false;
      dispatch(clearLoader());
    }
  });
};