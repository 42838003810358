const adminPageInitialState = { 
    items: 1
};

/**
 * Store admin user page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const AdminPageReducer = (state = adminPageInitialState, action) => {
    switch (action.type) {
        case 'GET_ADMIN_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_ADMIN_PAGE':
            return adminPageInitialState;
        default:
            return state;
      };
}