import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import * as Yup from 'yup';
import UtilsData, { ValidationMessage } from '../../../Utils/Utils';
import { useNavigate, useLocation } from 'react-router-dom';
import Toggle from '../../../components/Toggle/Toggle';
import Button from '../../../components/Button/Button';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import { useSelector, useDispatch } from 'react-redux';
import { addUpdateWarehouseService } from '../WarehouseService/WarehouseService';

//Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required(ValidationMessage.TITLE_REQUIRED),
  address: Yup.string().required(ValidationMessage.ADDRESS_REQUIRED),
  pincode: Yup.string().required(ValidationMessage.PINCODE_REQUIRED)
})

export default function AddWarehouse() {

  const navigate = useNavigate();
  const userData = useSelector(state => state.auth.user);
  const warehousePage = useSelector(state => state.warehousePage?.items);
  const dispatch = useDispatch();
  const tokenData = UtilsData();

  //Setting initial values
  const [initialValues, setInititialValues] = useState({
    name: '',
    address: '',
    pincode: '',
    status: true
  })

  const location = useLocation();
  const { Data } = location.state || {};

  useEffect(() => {
    if (Data && Data.data) {
      setInititialValues({
        name: Data.data.name,
        address: Data.data.address,
        pincode: Data.data.pincode,
        status: (Data.data.is_active === '1' || Data.data.is_active === 1 || Data.data.is_active === true) ? true : false
      })
    }
  }, [Data])

  /**
   * Handling form submission
   * @param {*} values 
   */
  const handleSubmit = (values) => {
    if (!Data) {
      const requestBody = {
        p_name: values.name,
        p_address: values.address,
        p_pincode: values.pincode,
        p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_created_by: userData.data.userId
      }
      dispatch(addUpdateWarehouseService(requestBody, tokenData, 'add', navigate));
    }
    else {
      const requestBody = {
        p_id: Data.data.warehouseId,
        p_name: values.name,
        p_address: values.address,
        p_pincode: values.pincode,
        p_isActive: (values.status === true || values.status === 1 || values.status === '1') ? '1' : '0',
        p_IsDeleted: Data.data.IsDeleted,
        p_modified_by: userData.data.userId
      }
      if (Data?.length && warehousePage > 1) {
        if ((values.status === '0' || values.status === 0 || values.status === false)) {
          if (Data.length > 1) {
            handlesaveClick(dispatch, addUpdateWarehouseService(requestBody, tokenData, 'update', navigate, warehousePage), 'update');
          }
          else {
            handlesaveClick(dispatch, addUpdateWarehouseService(requestBody, tokenData, 'update', navigate, warehousePage - 1), 'update');
          }
        }
        else {
          handlesaveClick(dispatch, addUpdateWarehouseService(requestBody, tokenData, 'update', navigate, warehousePage), 'update');
        }
      }
      else {
        handlesaveClick(dispatch, addUpdateWarehouseService(requestBody, tokenData, 'update', navigate), 'update');
      }
    }
  }

  return (
    <>
      <div className="main-section add-image-section">
        <div className="sidebar-content">
          <Sidebar />
        </div>
        <div className="body-content">
          <Header />
          <div className="table-body-content">
            <div className="roles">
              <div>
                <div className="title">
                  <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Warehouse</h4>
                </div>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate("/home")}>Home</Breadcrumb.Item>
                  <Breadcrumb.Item onClick={() => navigate("/warehouse")}>Warehouse</Breadcrumb.Item>
                  <Breadcrumb.Item active>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Warehouse</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div className="add-roles-title">
              <h4>{(Data && Data.isEdit === true) ? 'Edit ' : ((Data && Data.isEdit === false) ? 'View ' : 'Add ')} Warehouse</h4>
            </div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="add-roles-form row">
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Warehouse name <span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="name"
                          label="Warehouse name"
                          placeholder="Enter Warehouse name"
                          className="add-roles"
                          maxLength={20}
                          Regex={/[^a-zA-Z\s]/g}
                          value={values.name}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Address <span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="address"
                          label="address"
                          placeholder="Enter Address"
                          className="add-roles"
                          maxLength={70}
                          Regex={/[^a-zA-Z0-9\-/\s]/g}
                          value={values.address}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="name-type">
                        <div className="paragraph-editor ">
                          <h4 className="paragraph-title">Pincode <span className="required-label"></span></h4>
                        </div>
                        <Input
                          type="text"
                          name="pincode"
                          label="pincode"
                          placeholder="Enter pincode"
                          className="add-roles"
                          maxLength={6}
                          Regex={/[^0-9]/g}
                          value={values.pincode}
                          disabled={Data && Data.isEdit === false}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <h4 className="paragraph-title">Status</h4>
                        <Field name='status' className='toggle' setFieldValue={setFieldValue} component={Toggle} disabled={Data && Data.isEdit === false} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                          {((Data && Data.isEdit === true) || (!Data)) && (
                            <>
                              <Button type="submit" name="Save" className="btn login-btn" />
                              <Button
                                type="button"
                                name="Cancel"
                                className="btn clear-btn login-btn"
                                onClick={() => handleCancleClick('/warehouse', navigate)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}