// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-btn {
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 10px;
}
.action-btn .dropdown-toggle::after {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Tag/Tag.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AACQ;EACI,aAAA;AACZ","sourcesContent":[".action-btn{\n    padding: 10px 15px;\n    border-radius: 5px;\n    margin-right: 10px;\n    .dropdown-toggle{\n        &::after{\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
