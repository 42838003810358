export const blogAdd = ({id, name, author, date, category, tags, isActive, isDelete, restData}) => ({
    type: 'ADD_BLOG_DATA',
    payload: {id, name, author, date, category, tags, isActive, isDelete, restData}
})

export const blogUpdate = ({id, name, author, date, category, tags, isActive, isDelete, restData}) => ({
    type: 'UPDATE_BLOG_DATA',
    payload: {id, name, author, date, category, tags, isActive, isDelete, restData}
})

export const blogRemoveItem = (id) => ({
    type: 'DELETE_BLOG_DATA',
    payload: id
  });