export const parseImage = (dataStr) => {
     const pairs = dataStr.replace("[{", "").replace("}]", "").split("},{");
     const jsonArray = [];
     pairs.forEach(pair => {
       const components = pair.split(",");
       const jsonObject = {};
       components.forEach(component => {
         const [key, value] = component.split(":");
         if (key && value) {
           jsonObject[key.trim().replace(/\"/g, '')] = value.trim().replace(/\"$/, '');
         }
        //  jsonObject[key.trim().replace(/\"/g, '')] = value.trim().replace(/\"$/, '');
       });
       jsonArray.push(jsonObject);
     });
     return jsonArray ? jsonArray : [];
}

export const parseOrderProduct = (dataStr) => {
  const cleanedStr = dataStr.replace(/^\[\{/, "").replace(/\}\]$/, "");
  const pairs = cleanedStr.split(/},\s*\{/);

  const jsonArray = pairs.map(pair => {
    const jsonObject = {};
    // Match key-value pairs, including those with commas inside values
    const regex = /"([^"]+)"\s*:\s*"([^"]*)"/g;
    let match;

    while ((match = regex.exec(pair)) !== null) {
      jsonObject[match[1]] = match[2];
    }
    
    return jsonObject;
  });

  return jsonArray;
};
