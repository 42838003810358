const leafPageInitialState = { 
    items: 1
};

/**
 * Store leaf level category page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const LeafPageReducer = (state = leafPageInitialState, action) => {
    switch (action.type) {
        case 'GET_LEAF_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_LEAF_PAGE':
            return leafPageInitialState;
        default:
            return state;
      };
}