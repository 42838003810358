import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from "formik";
import Input from "../../../components/input/Input";
import Button from "../../../components/Button/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Sidebar from "../../../components/Sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CKEditorCpmponent from "../../../components/CKEditor-component/CKEditorCpmponent";
import Toggle from '../../../components/Toggle/Toggle';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { ValidationMessage } from '../../../Utils/Utils';
import { handleCancleClick, handlesaveClick } from '../../../components/Alertmessage/Alertmessage';
import { formatDate } from '../../../services/Regular-services/DateService';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { faqAdd, faqUpdate } from '../../../redux/Actions/faqAction/FaqAction';

//Validation schema
const validationSchema = Yup.object({
  faqCategory: Yup.string().required(ValidationMessage.SINGLE_CATEGORY_SELECT),
  faqTag: Yup.string().required(ValidationMessage.TAG_REQUIRED),
  Question: Yup.string().required(ValidationMessage.FIELD_REQUIRED),
  Answer: Yup.string().required(ValidationMessage.FIELD_REQUIRED)
})

export default function AddFaq() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const faqData = useSelector(state => state.faq.items)

  //Initial Form Data
  const [initialValues, setInitialValues] = useState({
    faqCategory: '',
    faqTag:'',
    Question:'',
    Answer:'',
    status: true
  })

  //Getting data from blog-list
  const location = useLocation();
  const { Data } = location.state || {};

  useEffect(()=>{
    if(Data && Data.data){
      setInitialValues({
        faqCategory: Data.data.category,
        faqTag: Data.data.tags,
        Question: Data.data.question,
        Answer: Data.data.answer,
        status: Data.data.isActive
      })
    }
  }, [Data])
  
  //Handling form submission
  const handleSubmit = (values) => {
    let newId;
    if (faqData.length === 0) {
      newId = faqData.length + 1;
    } else {
      const lastKey = faqData[faqData.length - 1].id;
      newId = lastKey + 1;
    }

    const currentDate = new Date(); //Getting current date
    const formattedDate = formatDate(currentDate); //Function to set date in correct format
    if(!Data){
      dispatch(faqAdd({id: newId, question: values.Question, answer: values.Answer, category: values.faqCategory,tags: values.faqTag, date: formattedDate, isActive: values.status, isDelete: false }));
      toast.success('FAQ added successfully!');
      setTimeout(()=>{
        navigate('/faq-list');
      }, 400);
    }
    else{
      handlesaveClick(dispatch,
        faqUpdate({id: Data.data.id, 
          question: values.Question, 
          answer: values.Answer, 
          category: values.faqCategory, 
          tags: values.faqTag, 
          date: Data.data.date, 
          isActive: values.status, 
          isDelete: Data.data.isDelete }),
          navigate, '/faq-list'
        );
    }
  }
    
  const brandtypeOptions = [
    { value: "red", label: "Red" },
    { value: "blue", label: "Blue" },
  ];

  return (
    <>
    <div className="main-section add-image-section">
      <div className="sidebar-content">
        <Sidebar />
      </div>
      <div className="body-content">
        <Header />
        <div className="table-body-content">
          <div className="roles">
            <div>
              <div className="title">
                <h4>Add FAQ</h4>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item onClick={()=> navigate('/home')}>Home</Breadcrumb.Item>
                <Breadcrumb.Item onClick={()=> navigate('/faq-list')}>FAQ list</Breadcrumb.Item>
                <Breadcrumb.Item active>Add FAQ</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="add-roles-title">
            <h4>Add FAQ</h4>
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
            {({setFieldValue})=>(
              <Form>
              <div className="add-roles-form row">
              <div className="col-md-6">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Select Categories<span className="required-label"></span></h4>
                    <Field
                    className="select-menu mb-2"
                    name="faqCategory"
                    options={brandtypeOptions}
                    component={CustomSelect}
                    placeholder="Select..."
                    isMulti={false}
                    disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="name-type">
                    <div className="paragraph-editor ">
                      <h4 className="paragraph-title">Tag<span className="required-label"></span></h4>
                    </div>
                    <Input
                      type="text"
                      name="faqTag"
                      label="Tag"
                      placeholder="Tag Title"
                      className="add-roles"
                      maxLength={70}
                      disabled={Data && Data.isEdit === false}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Question<span className="required-label"></span></h4>
                    <Field name="Question" component={CKEditorCpmponent} maxLength={225} disabled={Data && Data.isEdit === false}/>
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Answer<span className="required-label"></span></h4>
                    <Field name="Answer" component={CKEditorCpmponent} maxLength={225} disabled={Data && Data.isEdit === false}/>
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <h4 className="paragraph-title">Status</h4>
                    <Field name='status' setFieldValue={setFieldValue} component={Toggle} disabled={Data && Data.isEdit === false}/>
                  </div>
                </div>
                <div className="col-12">
                  <div className="paragraph-editor">
                    <div className="btn-submit mt-3">
                         { 
                            ((Data && Data.isEdit === true) || (!Data)) && (
                              <>
                                <Button type="submit" name="Save" className="btn login-btn"/>
                                <Button
                                  type="button"
                                  name="Cancel"
                                  className="btn clear-btn login-btn"
                                  onClick={() => handleCancleClick('/faq-list', navigate)}
                                />
                              </>
                            )
                          }
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            )}
          </Formik>
        </div>
        <Footer />
      </div>
    </div>
  </>
  )
}
