// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-copyright {
  background-color: #1D3B42;
  font-size: 14px;
  color: #FFF;
  font-weight: 500;
}
.footer-copyright a {
  color: #FFF;
  transition: 0.2s ease-in-out;
}
.footer-copyright a:hover {
  color: #FF4F2B;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACI,yBCHQ;EDIR,eAAA;EACA,WCHS;EDIT,gBAAA;AADJ;AAEI;EACI,WCNK;EDOL,4BAAA;AAAR;AACQ;EACI,cCVG;ADWf","sourcesContent":["@import '../../variables';\n\n.footer-copyright{\n    background-color: $main-color;\n    font-size: 14px;\n    color: $white-color;\n    font-weight: 500;\n    a{\n        color: $white-color;\n        transition: 0.2s ease-in-out;\n        &:hover {\n            color: $primary-color;\n        }\n    }\n}","$main-color:#1D3B42;\n$primary-color:#FF4F2B;\n$white-color:#FFF;\n$black-color:#000;\n$offWhite:#f1f1f1;\n$bodyColor:#E4E9F7;\n$successColor:#28a745;\n$dangerColor:#dc3545;\n$border-color:#DEE2E6;\n$red-color: #ff0000;\n$star-rating: #e1c100;\n$disabled-bg:#e9ecef;\n$gray:#E6E6E6;\n$lightLIGHTGRAY:#aaa;\n$orange-color:#008dff;\n$completed:#22bb33;\n$Confirmed:#fcaa11;\n$In-progress:#5bc0de;\n$Pending:#deb887;\n$Cancelled:#bb2124;\n$bespeakk-color:#e25233;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
