/**
 * Set brand page data
 * @param {*} page 
 * @returns {*}
 */
export const getBrandPageData = (page) => ({
    type: 'GET_BRAND_PAGE',
    payload: page
})

/**
 * Clear brand page data
 * @returns {*}
 */
export const clearBrandPageData = () => ({
    type: 'CLEAR_BRAND_PAGE'
})