import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, FloatingLabel } from 'react-bootstrap';

export default function FileUploadModal({ isOpen, onClose, onFileUpload, isClose }) {
    const [selectedFile, setSelectedFile] = useState(null);

    /**
     * Handle file change
     * @param {*} event 
     */
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    /**
     * Prevent default
     * @param {*} e 
     */
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    /**
     * Handle upload
     */
    const handleUpload = () => {
        if (selectedFile) {
            onFileUpload(selectedFile);
        }
        setSelectedFile(null);
    };

    useEffect(() => {
        if (isClose === false) {
            setSelectedFile(null);
        }
    }, [isClose]);

    return (
        <Modal show={isOpen} onHide={onClose} dialogClassName="modal-sm">
            <Modal.Header closeButton>
                <Modal.Title>Import Excel File</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel className='mb-2 show-and-hide-btn'>
                    <div className="input-container">
                        <Form.Control
                            type='file'
                            placeholder='Import excel here'
                            onChange={(e) => handleFileChange(e)}
                            onDragOver={(e) => handleDragOver(e)}
                        />
                    </div>
                </FloatingLabel>
                {/* <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} name='import' /> */}
            </Modal.Body>
            <Modal.Footer>
                <div className="col-12">
                    <div className="paragraph-editor">
                        <div className="btn-submit mt-3">
                            <Button variant="secondary" onClick={onClose}>
                                Close
                            </Button>
                            &nbsp;
                            <Button variant="primary" onClick={handleUpload}>
                                Upload
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}