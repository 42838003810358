const InventoryPageInitialState = { 
    items: 1
};

/**
 * Store inventory page data
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const InventoryPageReducer = (state = InventoryPageInitialState, action) => {
    switch (action.type) {
        case 'GET_INVENTORY_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_INVENTORY_PAGE':
            return InventoryPageInitialState;
        default:
            return state;
      };
}