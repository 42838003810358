import React from 'react'
import './DateTimeSplitterService.scss'

export default function DateTimeSplitterService({ dateTimeString }) {
    const dateTime = new Date(dateTimeString);

    // Get the date parts
    const day = dateTime.getDate().toString().padStart(2, '0');
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const year = dateTime.getFullYear();

    // Get the time parts
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0')

    return (
        <div>
            <span className="date-time">{day}-{month}-{year} -</span>
            <span className="date-time">{hours}:{minutes}</span>
        </div>
    );
}