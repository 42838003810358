/**
 * Set image page data into reducer
 * @param {*} page 
 * @returns {*}
 */
export const getImagePageData = (page) => ({
    type: 'GET_IMAGE_PAGE',
    payload: page
})

/**
 * Clear image page data
 * @returns {*}
 */
export const clearImagePageData = () => ({
    type: 'CLEAR_IMAGE_PAGE'
})