/**
 * Setting brand filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getBrandFilterData = ({name,category, subCategory, leafCategory, tags, isActive, isDelete, type}) => ({
    type: 'GET_BRAND_FILTER',
    payload: {name,category, subCategory, leafCategory, tags, isActive, isDelete, type}
})

/**
 * Clearing brand filtered data
 * @returns {*}
 */
export const clearBrandFilterData = () => ({
    type: 'CLEAR_BRAND_FILTER'
})