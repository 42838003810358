/**
 * Set service provider filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getServiceProviderFilterData = ({name, isActive, isDelete}) => ({
    type: 'GET_SERVICE_PROVIDER_FILTER',
    payload: {name, isActive, isDelete}
})

/**
 * Clear service provider filtered data
 * @returns {*}
 */
export const clearServiceProviderFilterData = () => ({
    type: 'CLEAR_SERVICE_PROVIDER_FILTER'
})