/**
 * Setting branch list data into reducer
 * @param {*} Branch 
 * @returns {*}
 */
export const getBranchData = (Branch) => ({
    type: 'GET_BRANCH_DATA',
    payload: Branch
})

/**
 * clearing branch list data
 * @returns {*}
 */
export const clearBranchData = () => ({
    type: 'CLEAR_BRANCH_DATA'
})

/**
 * setting total branch list data into reducer (No pagination)
 * @param {*} Branch 
 * @returns {*}
 */
export const getTotalBranchData = (Branch) => ({
    type: 'GET_TOTAL_BRANCH',
    payload: Branch
})

/**
 * cleating total branch list data
 * @returns {*}
 */
export const clearTotalBranchData = () => ({
    type: 'CLEAR_TOTAL_BRANCH'
})