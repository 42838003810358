/**
 * Set warehouse list data into reducer
 * @param {*} warehouse 
 * @returns {*}
 */
export const getWarehouseData = (warehouse) => ({
    type: 'GET_WAREHOUSE_DATA',
    payload: warehouse
})

/**
 * Clear warehouse list data
 * @returns {*}
 */
export const clearWarehouseData = () => ({
    type: 'CLEAR_WAREHOUSE_DATA'
})

/**
 * Set total warehouse list data into reducer (No pagination)
 * @param {*} warehouse 
 * @returns {*}
 */
export const getTotalWarehouseData = (warehouse) => ({
    type: 'GET_TOTAL_WAREHOUSE',
    payload: warehouse
})

/**
 * Clear total warehouse list (No pagination)
 * @returns {8}
 */
export const clearTotalWarehouseData = () => ({
    type: 'CLEAR_TOTAL_WAREHOUSE'
})