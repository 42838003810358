const rolesInitialState = { 
    items: []
};

/**
 * Store role list data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const RolesReducer = (state = rolesInitialState, action) => {
    switch (action.type) {
        case 'GET_ROLE_DATA':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_ROLE_DATA':
            return rolesInitialState;
        default:
            return state;
      };
}

const totalRolesInitialState = {
    items: []
}

/**
 * Store total role list data (No pagination)
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const TotalRolesReducer = (state = totalRolesInitialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_ROLE':
            return {
                ...state,
                items: [action.payload]
            }
        case 'CLEAR_TOTAL_ROLE':
            return totalRolesInitialState
        default:
            return state;
      };
}