/**
 * setting attribute filtered data into reducer
 * @param {*} param0 
 * @returns {*}
 */
export const getAttributeFilterData = ({name, isActive, isDelete}) => ({
    type: 'GET_ATTRIBUTE_FILTER',
    payload: {name, isActive, isDelete}
})

/**
 * clearing attribute filtered data
 * @returns {*}
 */
export const clearAttributeFilterData = () => ({
    type: 'CLEAR_ATTRIBUTE_FILTER'
})