const discountCataloguePageInitialState = { 
    items: 1
};

/**
 * Store page number
 * @param {number} state 
 * @param {*} action 
 * @returns {number}
 */
export const DiscountCataloguePageReducer = (state = discountCataloguePageInitialState, action) => {
    switch (action.type) {
        case 'GET_DISCOUNT_CATALOGUE_PAGE':
            return {
                ...state,
                items: action.payload
            }
        case 'CLEAR_DISCOUNT_CATALOGUE_PAGE':
            return discountCataloguePageInitialState;
        default:
            return state;
      };
}