import React from "react";
import Swal from "sweetalert2";
import Button from "../Button/Button";
import "./Alertmessage.scss";
import { policyRemoveItem } from "../../redux/Actions/policyAction/PolicyAction.js";
import { logout } from "../../redux/Actions/authAction/AuthAction.js";
import { blogRemoveItem } from "../../redux/Actions/blogAction/BlogAction.js";
import { faqRemoveItem } from "../../redux/Actions/faqAction/FaqAction.js";
import {
  addUpdateRolesService,
  deleteRolesService,
  getRoleById,
} from "../../pages/Roles/Role-Service/roleService.tsx";
import {
  addUpdateUserService,
  deleteUserService,
  getUserById,
} from "../../pages/AdminUsers/User-service/UserService.tsx";
import {
  addUpdateCategoryService,
  deleteCategoryService,
  getCategoryById,
} from "../../pages/Category/Category-service/CategoryService.tsx";
import {
  addUpdateSubCategoryService,
  deleteSubCategoryService,
  getSubCategoryById,
} from "../../pages/SubCategory/SubCategoryService/SubCategoryService.tsx";
import {
  addUpdateLeafLevelService,
  deleteLeafLevelService,
  getLeafCategoryById,
} from "../../pages/LeafLevelCategory/LeafLevelService/LeafLevelService";
import {
  addUpdateTagService,
  deleteTagService,
} from "../../pages/Tag/TagService/TagService";
import {
  deleteImageService,
  updateImageService,
} from "../../pages/Imagemanager/ImageService/ImageService";
import {
  addUpdateBrandService,
  deleteBrandService,
  getBrandById,
} from "../../pages/BrandList/BrandService/BrandService";
import {
  addUpdateProductService,
  deleteProductService,
  getProductById,
} from "../../pages/Product/ProductService/ProductService";
import { parseImage } from "../../services/Regular-services/ImageService";
import {
  addUpdateAttributeService,
  deleteAttributeService,
} from "../../pages/Attributes/AttributeService/AttributeService";
import {
  addUpdateWarehouseService,
  deleteWarehouseService,
} from "../../pages/Warehouse/WarehouseService/WarehouseService";
import {
  addUpdatePackagerService,
  deletePackagerService,
} from "../../pages/Packager/PackagerService/PackagerService";
import {
  addUpdateDiscountService,
  deleteDiscountService,
  getDiscountById,
} from "../../pages/Discount/DiscountService/DiscountService";
import { format } from "date-fns";
import {
  DiscountCatalogueById,
  addUpdateDiscountCatalogueService,
  deleteDiscountCatalogueService,
} from "../../pages/DiscountCatalogue/DiscountCatalogueService/DiscountCatalogueService";
import {
  addUpdateBranchService,
  deleteBranchService,
} from "../../pages/Branches/BranchService/BranchService";
import {
  addUpdateServiceProviderService,
  deleteServiceProviderService,
  getServiceProviderById,
} from "../../pages/ServiceProvider/ServiceProviderService/ServiceProviderService";
import { getServiceProviderPageData } from "../../redux/Actions/ServiceProviderAction/ServiceProviderPageAction";
import { getBranchPageData } from "../../redux/Actions/BranchAction/BranchPageAction";
import { getDiscountCataloguePageData } from "../../redux/Actions/DiscountCatalogueAction/DiscountCataloguePageAction";
import { getDiscountPageData } from "../../redux/Actions/DiscountAction/DiscountPageAction";
import { getPackagerPageData } from "../../redux/Actions/PackagerAction/PackagerPageAction";
import { getWarehousePageData } from "../../redux/Actions/WarehouseAction/WarehousePageAction";
import { getAttributePageData } from "../../redux/Actions/AttributeAction/AttributePageAction";
import { getProductPageData } from "../../redux/Actions/productAction/ProductPageAction";
import { getBrandPageData } from "../../redux/Actions/brandAction/BrandPageAction";
import { getImagePageData } from "../../redux/Actions/imageGalleryAction/ImageGalleryPageAction";
import { getTagPageData } from "../../redux/Actions/tagAction/TagPageAction";
import { getLeafPageData } from "../../redux/Actions/leafLevelCategoryAction/LeafPageAction";
import { getSubCategoryPageData } from "../../redux/Actions/subCategoryAction/SubCategoryPageAction";
import { getCategoryPageData } from "../../redux/Actions/categoryAction/CategoryPageAction";
import { getAdminPageData } from "../../redux/Actions/adminAction/AdminPageAction";
import { getRolePageData } from "../../redux/Actions/rolesAction/RolesPageAction";
import { getServicePageData } from "../../redux/Actions/ServiceAction/ServicePageAction";
import {
  addUpdateServiceService,
  deleteServiceService,
  getServiceById,
} from "../../pages/Service/ServiceService/ServiceService";
import { deleteNotificationById } from "../Notification/Notification-service/NotificationService";
import { clearSidebarData } from "../../redux/Actions/SidebarAction/SidebarAction";
import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getOrderPageData } from "../../redux/Actions/orderAction/OrderPAgeAction";
import {
  deleteLineLevelProduct,
  deleteOrderService,
  LineLevelPlaceOrderService,
  placeOrderService,
  viewOrdersService,
} from "../../pages/Order/OrderService/OrderService";
import {
  addUpdateCompanyService,
  deleteCompanyService,
  getCompanyById,
} from "../../pages/Company/Company-service/companyService";
import { getCompanyPageData } from "../../redux/Actions/companyAction/CompanyPageAction";
import {
  addUpdateSpotService,
  deleteSpotService,
} from "../../pages/Spot/SpotService/SpotService";
import { getSpotPageData } from "../../redux/Actions/spotAction/SpotPageAction";
import { ProductToggleRequestBody } from "../../pages/Product/ProductRequestBody/ProductToggleRequest";
import { AdminUserToggleRequest } from "../../pages/AdminUsers/AdminUserToggleRequest/AdminUserToggleRequest";
import { attributeToggleReqest } from "../../pages/Attributes/AttributeToggleRequest/AttributeToggleRequest";
import { categoryToggleRequest } from "../../pages/Category/CategoryToggleRequest/CategoryToggleRequest";
import { DiscountToggleRequest } from "../../pages/Discount/DiscountToggleRequest/DiscountToggleRequest";
import { WarehouseToggleRequest } from "../../pages/Warehouse/WarehouseToggleRequest/WarehouseToggleRequest";
import { spotToggleRequest } from "../../pages/Spot/SpotToggleRequest/SpotToggleRequest";
import { TagToggleRequest } from "../../pages/Tag/TagToggleRequest/TagToggleRequest";
import { SubCategoryToggleRequest } from "../../pages/SubCategory/SubCategoryToggleRequest/SubCategoryToggleRequest";
import { RoleToggleRequest } from "../../pages/Roles/RoleToggleRequest/RoleToggleRequest";
import { BranchToggleRequest } from "../../pages/Branches/BranchToggleRequest/BranchToggleRequest";
import { OrderRequestBodyOnToggle } from "../../pages/Order/OrderRequestBodyOnToggle/OrderRequestBodyOnToggle";
import { getInventoryItemPageData } from "../../redux/Actions/InventoryItemAction/InventoryItemPageAction";
import {
  addUpdateInventoryItemService,
  deleteInventoryItemService,
} from "../../pages/InventoryItems/InventoryItemService/InventoryItemService";
import {
  convertToBaseUnit,
  GramToKg,
  KgToGram,
  LiterToMl,
  MlToLitre,
} from "../../services/Regular-services/ConvertUnitService";
import { deleteProductInventoryService } from "../../pages/ProductInventory/ProductInventoryService/ProductInventoryService";
import { getProductInventoryPageData } from "../../redux/Actions/ProductInventoryAction/ProductInventoryPageAction";
import { addUpdateVendorService } from "../../pages/Vendors/VendorsService.js/VendorService";
import { toast } from "react-toastify";
import { getNewVendorPageData } from "../../redux/Actions/NewVendorAction/NewVendorPageAction";
import {
  addUpdateNewVendorPersonService,
  deleteNewVendorPersonService,
} from "../../pages/NewVendorPerson/NewVendorPersonService/NewVendorPersonService";
import { getUnitsPageData } from "../../redux/Actions/UnitsAction/UnitsPageAction";
import { addUpdateUnitsService, deleteUnitService } from "../../pages/Units/UnitsService/UnitsService";

/**
 * Handling error
 */
export const handleErrorClick = () => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
  });
};

/**
 * Handling delete function
 * @param {*} event
 * @param {*} dispatch
 * @param {*} page
 * @param {*} tokenData
 * @param {*} length
 * @param {*} pageNumber
 */
export const handledeletClick = (
  event,
  dispatch,
  page,
  tokenData,
  length,
  pageNumber
) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const successMessages = {
    policies: "Policy has been deleted!",
    policy: "Policy has been deleted!",
    brand: "Brand has been deleted!",
    category: "Category has been deleted!",
    categories: "Category has been deleted!",
    subcategory: "Subcategory has been deleted!",
    subcategories: "Subcategory has been deleted!",
    imagemanager: "Image has been deleted!",
    leaf: "Leaf level category has been deleted successfully!",
    leafcategory: "Leaf level category has been deleted!",
    leafLevelCategory: "Leaf level category has been deleted!",
    leaflevelcategory: "Leaf level category has been deleted!",
    product: "Product has been deleted!",
    blog: "Blog has been deleted!",
    uploadimage: "Image has been deleted!",
    faq: "FAQ has been deleted!",
    customer: "Customer has been deleted!",
    tag: "Tag has been deleted!",
    tags: "Tag has been deleted!",
    spots: "Spot has been deleted!",
    branch: "Branch has been deleted!",
    order: "Order has been deleted!",
    // Add more pages as needed
    default: "Item has been deleted!", // Default message
  };
  swalWithBootstrapButtons
    .fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        if (page === "roles" || page === "role") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getRolePageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteRolesService(event.id.toString(), tokenData, pageNumber)
          );
        } else if (page === "company") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getCompanyPageData(pageNumber + 1));
            }
          }
          // return
          dispatch(
            deleteCompanyService(event.id.toString(), tokenData, pageNumber)
          );
        }
        // spot
        else if (page === "spots") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getSpotPageData(pageNumber + 1));
            }
          }
          dispatch(deleteSpotService(event.id, tokenData, pageNumber));
        } else if (page === "inventory-items" || page === "inventory-item") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getInventoryItemPageData(pageNumber + 1));
            }
          }
          dispatch(deleteInventoryItemService(event.id, tokenData, pageNumber));
        } else if (
          page === "product-inventory" ||
          page === "product-inventory"
        ) {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getProductInventoryPageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteProductInventoryService(
              event.productId,
              tokenData,
              pageNumber
            )
          );
        } else if (page === "new-vendor-person") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getNewVendorPageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteNewVendorPersonService(event.id, tokenData, pageNumber)
          );
        } else if (page === "units") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getUnitsPageData(pageNumber + 1));
            }
          }
          dispatch(deleteUnitService(event.id, tokenData, pageNumber));
        } else if (page === "users") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getAdminPageData(pageNumber + 1));
            }
          }
          dispatch(deleteUserService(event.userId, tokenData, pageNumber));
        } else if (page === "category" || page === "categories") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getCategoryPageData(pageNumber + 1));
            }
          }
          dispatch(deleteCategoryService(event.id, tokenData, pageNumber));
        } else if (
          page === "subCategory" ||
          page === "Subcategory" ||
          page === "subcategory" ||
          page === "SubCategory"
        ) {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getSubCategoryPageData(pageNumber + 1));
            }
          }
          dispatch(deleteSubCategoryService(event.id, tokenData, pageNumber));
        } else if (
          page === "leaf" ||
          page === "leafCategory" ||
          page === "leafLevelCategory" ||
          page === "leaflevelcategory"
        ) {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getLeafPageData(pageNumber + 1));
            }
          }
          dispatch(deleteLeafLevelService(event.id, tokenData, pageNumber));
        } else if (page === "tags" || page === "tag") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getTagPageData(pageNumber + 1));
            }
          }
          dispatch(deleteTagService(event.tagId, tokenData, pageNumber));
        } else if (page === "imageManager") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getImagePageData(pageNumber + 1));
            }
          }
          dispatch(deleteImageService(event.imageId, tokenData, pageNumber));
        } else if (page === "brand" || page === "Brand") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getBrandPageData(pageNumber + 1));
            }
          }
          dispatch(deleteBrandService(event.brandId, tokenData, pageNumber));
        } else if (page === "product" || page === "Product") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getProductPageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteProductService(event.productId, tokenData, pageNumber)
          );
        } else if (page === "attributes" || page === "attribute") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getAttributePageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteAttributeService(event.attributeId, tokenData, pageNumber)
          );
        } else if (page === "warehouse") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getWarehousePageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteWarehouseService(event.warehouseId, tokenData, pageNumber)
          );
        } else if (page === "packager") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getPackagerPageData(pageNumber + 1));
            }
          }
          dispatch(
            deletePackagerService(
              event.productPackagerId,
              tokenData,
              event.type,
              pageNumber
            )
          );
        } else if (page === "discount") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getDiscountPageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteDiscountService(event.discountId, tokenData, pageNumber)
          );
        } else if (page === "discountCatalogue") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getDiscountCataloguePageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteDiscountCatalogueService(
              event.discountId,
              tokenData,
              pageNumber
            )
          );
        } else if (page === "branch") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getBranchPageData(pageNumber + 1));
            }
          }
          dispatch(deleteBranchService(event.branchId, tokenData, pageNumber));
        } else if (page === "serviceProvider") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getServiceProviderPageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteServiceProviderService(
              event.serviceProviderId,
              tokenData,
              pageNumber
            )
          );
        } else if (page === "service") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getServicePageData(pageNumber + 1));
            }
          }
          dispatch(
            deleteServiceService(event.serviceId, tokenData, pageNumber)
          );
        } else if (page === "notification") {
          if (length) {
            dispatch(
              deleteNotificationById(event.NotificationId, tokenData, length)
            );
          } else {
            dispatch(deleteNotificationById(event.NotificationId, tokenData));
          }
        } else if (page === "order") {
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getOrderPageData(pageNumber + 1));
            }
          }
          dispatch(deleteOrderService(event.id, tokenData, pageNumber));
        } else {
          const successMessage =
            successMessages[page && page.toLowerCase()] ||
            successMessages["default"];
          swalWithBootstrapButtons
            .fire({
              title: "Deleted!",
              text: successMessage,
              icon: "success",
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            })
            .then(() => {
              //Dispatch Delete Action according to page
              if (
                page === "Policies" ||
                page === "policies" ||
                page === "Policy" ||
                page === "policy"
              ) {
                dispatch(policyRemoveItem(event.id));
              }
              if (page === "blog") {
                dispatch(blogRemoveItem(event.id));
              }
              if (page === "faq") {
                dispatch(faqRemoveItem(event.id));
              }
            });
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your data is safe :)",
          icon: "error",
          timer: 2000,
        });
      }
    });
};

/**
 * Handling Save function
 * @param {*} dispatch
 * @param {*} action
 * @param {*} navigate
 * @param {*} route
 */
export const handlesaveClick = (dispatch, action, navigate, route, message) => {
  Swal.fire({
    title: message ? message : "Do you want to save the changes?",
    showDenyButton: false,
    showCancelButton: true,
    confirmButtonText: "Save",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      if (navigate === "update") {
        dispatch(action);
      } else {
        if (navigate && route === "imageManager") {
          dispatch(action);
        } else {
          Swal.fire({
            title: "Saved!",
            text: "success",
            icon: "success",
            timer: 1500,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            dispatch(action);
            if (navigate && route === "imageManager") {
              navigate();
            }
            if (navigate && route) {
              navigate(route);
            }
          });
        }
      }
    } else if (result.isDenied) {
      Swal.fire({
        title: "Changes are not saved",
        text: "",
        icon: "info",
        timer: 2000,
      });
    }
  });
};

/**
 * Handling status change on toggle click
 * @param {*} dispatch
 * @param {*} action
 * @param {*} toggleChange
 * @param {*} tokenData
 * @param {*} userId
 * @param {*} toggleData
 * @param {*} length
 * @param {*} pageNumber
 */
export const handleToggleChange = (
  dispatch,
  action,
  toggleChange,
  tokenData,
  userId,
  toggleData,
  length,
  pageNumber
) => {
  const confirmationMessages = {
    true: "Are you sure you want to make this data active?",
    false: "Are you sure you want to make this data inactive?",
    Pending: "Are you sure you want to change the order status to pending",
    Confirm: "Are you sure you want to confirm the order",
    "In-progress":
      "Are you sure you want to change the order status to in progress",
    Complete: "Are you sure you want to complete the order",
    Cancel: "Are you sure you want to cancel the order",
    default: "Do you want to save the changes?", // Default message
  };
  Swal.fire({
    title:
      confirmationMessages[toggleChange] || confirmationMessages["default"],
    showDenyButton: confirmationMessages[toggleChange] ? true : false,
    showCancelButton: confirmationMessages[toggleChange] ? false : true,
    confirmButtonText: confirmationMessages[toggleChange] ? "Yes" : "Save",
  }).then(async (result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      //If we update toggle of role page
      if (action === "roles" && tokenData && userId) {
        const roleDetail = await getRoleById(
          toggleData.id,
          tokenData,
          dispatch
        );

        if (roleDetail && roleDetail.status === "success") {
          const requestBody = RoleToggleRequest({
            roleDetail: roleDetail,
            userId: userId,
            isActive: true,
          });
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getRolePageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateRolesService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "company") {
        const company = await getCompanyById(
          toggleData.id,
          tokenData,
          dispatch
        );
        if (
          company &&
          company.status === "success" &&
          company.data.companyDetails.length > 0
        ) {
          const details = company.data.companyDetails[0];

          const is_active =
            details.is_active === true ||
            details.is_active === 1 ||
            details.is_active === "1"
              ? "0"
              : "1";

          const companyPermisionData = company.data.permissions.reduce(
            (acc, permission) => {
              // Extract id and value for each permission type
              const permissionTypes = [
                "allow",
                "add",
                "view",
                "edit",
                "delete",
              ];
              const permissionData = permissionTypes.reduce((data, type) => {
                if (
                  permission[type] === 1 ||
                  permission[type] === true ||
                  permission[type] === "1"
                ) {
                  const id = parseInt(permission[type + "_id"]);
                  data.push({
                    id: id,
                    value:
                      permission[type] === true ||
                      permission[type] === 1 ||
                      permission[type] === "1"
                        ? "1"
                        : "1",
                  });
                }
                return data;
              }, []);
              acc.push(...permissionData);
              return acc;
            },
            []
          );
          const requestBody = {
            company_id: details.id,
            p_userId: details.userID,
            p_is_deleted: details.IsDeleted,
            name: details.company_name,
            address: details.address,
            pincode: Number(details.pincode),
            p_is_active: is_active,
            company_logo: details.company_logo,
            company_color: details.company_color,
            tax_type: details.tax_type,
            cgst: details.cgst,
            sgst: details.sgst,
            vat: details.vat,
            country: details.country,
            company_code: details.company_code,
            gst_number: details.gst_number,
            company_email: details.company_email ? details.company_email : null,
            owner_first_name: details.first_name,
            owner_last_name: details.last_name,
            owner_email: details.email ? details.email : null,
            owner_phone_number: Number(details.phone_number),
            p_role_id: details.role_id,
            role_permission_data: companyPermisionData,
            company_range: Number(details.company_range),
            p_industry_id: Number(details.industry_id),
            // permissionData: companyPermisionData
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getCompanyPageData(pageNumber + 1));
            }
          }

          dispatch(
            addUpdateCompanyService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "category") {
        const categoryDetail = await getCategoryById(
          toggleData.id,
          tokenData,
          dispatch
        );
        if (categoryDetail && categoryDetail.status === "success") {
          const requestBody = categoryToggleRequest({
            categoryDetail: categoryDetail,
            toggleData: toggleData,
            userId: userId,
            isActive: true,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getCategoryPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateCategoryService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "subCategory") {
        const subCategoryDetail = await getSubCategoryById(
          toggleData.id,
          tokenData,
          dispatch
        );
        if (subCategoryDetail && subCategoryDetail.status === "success") {
          const requestBody = SubCategoryToggleRequest({
            subCategoryDetail: subCategoryDetail,
            userId,
            isActive: true,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getSubCategoryPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateSubCategoryService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "users" && tokenData && userId) {
        const userDetail = await getUserById(
          toggleData.userId,
          tokenData,
          dispatch
        );
        if (userDetail && userDetail.status === "success") {
          const adminRequestBody = AdminUserToggleRequest({
            toggleData: toggleData,
            userDetail: userDetail,
            userId: userId,
            isActive: true,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getAdminPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateUserService(
              adminRequestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "leaf") {
        const leafDetail = await getLeafCategoryById(
          toggleData.id,
          tokenData,
          dispatch
        );

        if (leafDetail && leafDetail.status === "success") {
          const leafImage = leafDetail.data.image_data
            ? parseImage(JSON.stringify(leafDetail.data.image_data))
            : null;

          const leafImageData = leafImage
            ? leafImage.map((item) => ({
                id: parseInt(item.id),
                type_id: parseInt(item.type),
              }))
            : [];

          const leafRequestBody = {
            p_id: toggleData.id,
            p_name: toggleData.Title,
            p_description: toggleData.Description,
            p_subcategoryId: toggleData.SubcategoryId,
            p_isActive: toggleData.isActive === "1" ? "0" : "1",
            p_deleted: toggleData.isDeleted,
            p_createdBy: toggleData.createdBy,
            p_modifiedBy: userId,
            p_imageIds: leafImageData,
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getLeafPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateLeafLevelService(
              leafRequestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "tags") {
        const requestBody = TagToggleRequest({
          toggleData: toggleData,
          userId: userId,
          isActive: true,
        });

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getTagPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateTagService(
            requestBody,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      }
      // changes by sakshi spot update
      else if (action === "spots") {
        const requestBody = spotToggleRequest({
          toggleData: toggleData,
          isActive: true,
        });

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getSpotPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateSpotService(
            requestBody,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      } else if (action === "inventory-item") {
        let { quantityInBaseUnit, unit } = convertToBaseUnit(
          toggleData.quantity,
          toggleData.unit
        );

        const requestBody = {
          p_id: toggleData.id,
          p_title: toggleData.name,
          p_thresoldvalue: quantityInBaseUnit,
          p_thresoldunit: unit,
          p_isActive:
            toggleData.status === "1" || toggleData.status === 1 ? "0" : "1",
          p_IsDeleted: toggleData.is_delete,
          p_category_id: toggleData.category_id,
          p_instruction: toggleData.instruction,
        };

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getInventoryItemPageData(pageNumber + 1));
          }
        }

        dispatch(
          addUpdateInventoryItemService(
            requestBody,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      } else if (action === "new-vendor-person") {
        const categoryData = JSON.parse(toggleData.categoryData);
        const requestBody = {
          p_id: toggleData.id,
          p_first_name: toggleData.first_name,
          p_last_name: toggleData.last_name,
          p_shop_name: toggleData.shopName,
          p_mobile_no: toggleData.mobile,
          p_category_ids: categoryData,
          p_status:
            toggleData.status === "1" || toggleData.status === 1 ? "0" : "1",
          p_is_wp_allow: toggleData.wp_sms_allow,
          p_deleted: toggleData.isDeleted,
        };
        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getNewVendorPageData(pageNumber + 1));
          }
        }

        dispatch(
          addUpdateNewVendorPersonService(
            requestBody,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      } else if (action === "units") {
        const requestBody = {
          p_id: toggleData.id,
          p_unit_id: toggleData.unit,
          p_isActive:
            toggleData.status === "1" || toggleData.status === 1 ? "0" : "1",
          p_is_wp_allow: toggleData.wp_sms_allow,
          p_IsDeleted: toggleData.isDeleted,
        };
        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getNewVendorPageData(pageNumber + 1));
          }
        }

        dispatch(
          addUpdateNewVendorPersonService(
            requestBody,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      } else if (action === "brand" || action === "Brand") {
        const brandDetail = await getBrandById(
          toggleData.brandId,
          tokenData,
          dispatch
        );

        if (brandDetail && brandDetail.status === "success") {
          const categoryIds = brandDetail.data.category_ids
            ? brandDetail.data.category_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [];

          const subCategoryIds = brandDetail.data.sub_category_ids
            ? brandDetail.data.sub_category_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [];

          const leafIds = brandDetail.data.leaf_level_category_ids
            ? brandDetail.data.leaf_level_category_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [];

          const tagIds = brandDetail.data.tag_ids
            ? brandDetail.data.tag_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [];

          const brandImage = brandDetail.data.image_data
            ? parseImage(JSON.stringify(brandDetail.data.image_data))
            : null;
          const brandImageData = brandImage
            ? brandImage.map((item) => ({
                id: parseInt(item.id),
                type_id: parseInt(item.type),
              }))
            : [];
          const brandRequestBody = {
            p_id: toggleData.brandId,
            p_name: toggleData.name,
            p_description: toggleData.description,
            p_brand_type_id: brandDetail.data.type,
            p_category_id: categoryIds.map((item) => ({ id: item })),
            p_sub_category_id: subCategoryIds.map((item) => ({ id: item })),
            p_leaf_level_category_id: leafIds.map((item) => ({ id: item })),
            p_imageIds: brandImageData,
            p_tag_id: tagIds.map((item) => ({ id: item })),
            p_isActive: brandDetail.data.is_active === "1" ? "0" : "1",
            p_IsDeleted: brandDetail.data.IsDeleted,
            p_created_by: brandDetail.data.created_by,
            p_modified_by: userId,
            p_brand_code: toggleData.brandCode,
            // p_tax: toggleData.tax,
            // p_gst_number: toggleData.tax === 'GST' ? toggleData.gst : null,
            // p_cgst: toggleData.tax === 'GST' ? toggleData.cgst : null,
            // p_sgst: toggleData.tax === 'GST' ? toggleData.sgst : null,
            // p_vat: toggleData.tax === 'VAT' ? toggleData.vat : null,
            p_food_type: toggleData.food_type,
            p_color: {
              mainColor:
                JSON.parse(toggleData.color)?.mainColor &&
                JSON.parse(toggleData.color)?.mainColor !== ""
                  ? JSON.parse(toggleData.color)?.mainColor
                  : null,
              primaryColor:
                JSON.parse(toggleData.color)?.primaryColor &&
                JSON.parse(toggleData.color)?.primaryColor !== ""
                  ? JSON.parse(toggleData.color)?.primaryColor
                  : null,
            },
            // p_publicly_available: toggleData.publicly_available,
            // p_delivery_available: brandDetail.data.delivery_available,
            // p_multipal_beep: brandDetail.data.multipal_beep,
            // p_wp_sms_allow: brandDetail.data.wp_sms_allow,
            // p_distance_data: brandDetail.data?.distance_data ? JSON.parse(brandDetail.data.distance_data) : null,
            // p_payment_url: brandDetail.data?.payment_url && brandDetail.datapayment_url !== '' ? brandDetail.data.payment_url : null
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getBrandPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateBrandService(
              brandRequestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "product" || action === "Product") {
        const productDetail = await getProductById(
          toggleData.productId,
          tokenData,
          dispatch
        );
        if (productDetail && productDetail.status === "success") {
          const requestBody = ProductToggleRequestBody({
            productDetail: productDetail,
            isActive: true,
            userId: userId,
            toggleData: toggleData,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getProductPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateProductService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "attributes") {
        const requestBody = attributeToggleReqest({
          toggleData: toggleData,
          userId: userId,
          isActive: true,
        });

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getAttributePageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateAttributeService(
            requestBody,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      } else if (action === "warehouse") {
        const requestBody = WarehouseToggleRequest({
          toggleData: toggleData,
          userId: userId,
          isActive: true,
        });

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getWarehousePageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateWarehouseService(
            requestBody,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      } else if (action === "packager") {
        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getPackagerPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdatePackagerService(
            toggleData,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      } else if (action === "discount") {
        const discountDetail = await getDiscountById(
          toggleData.discountId,
          tokenData,
          dispatch
        );
        if (discountDetail && discountDetail.status === "success") {
          const requestBody = DiscountToggleRequest({
            discountDetail: discountDetail,
            userId: userId,
            isActive: true,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getDiscountPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateDiscountService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "order") {
        dispatch(
          placeOrderService(toggleData, tokenData, "update", false, pageNumber)
        );
      } else if (action === "discountCatalogue") {
        const discountCatalogueData = await DiscountCatalogueById(
          toggleData.discountId,
          tokenData,
          dispatch
        );
        if (
          discountCatalogueData &&
          discountCatalogueData.status === "success"
        ) {
          const discountCatalogueImage = discountCatalogueData.data.image_data
            ? parseImage(JSON.stringify(discountCatalogueData.data.image_data))
            : null;

          const discountCatalogueImageData = discountCatalogueImage
            ? discountCatalogueImage.map((item) => ({
                id: parseInt(item.id),
                type_id: parseInt(item.type),
              }))
            : [];

          const requestBody = {
            p_id: toggleData.discountId,
            p_title: discountCatalogueData.data.title,
            p_discount_type: discountCatalogueData.data.discount_type,
            p_max_discount_amount: discountCatalogueData.data.discount_amount,
            p_discount_type_on: discountCatalogueData.data.discount_type_on,
            p_discount_catlogue_for:
              discountCatalogueData.data.discount_catlogue_for,
            p_start_date: format(
              new Date(discountCatalogueData.data.start_date),
              "yyyy-MM-dd"
            ),
            p_end_date: format(
              new Date(discountCatalogueData.data.end_date),
              "yyyy-MM-dd"
            ),
            p_modified_by: userId,
            p_is_active:
              discountCatalogueData.data.is_active === "1" ? "0" : "1",
            p_imageIds: discountCatalogueImageData,
            p_IsDeleted: discountCatalogueData.data.IsDeleted,
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getDiscountCataloguePageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateDiscountCatalogueService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "branch") {
        const requestBody = BranchToggleRequest({
          toggleData: toggleData,
          userId: userId,
          isActive: true,
        });
        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getBranchPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateBranchService(
            requestBody,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      }
      // else if (action ===)
      else if (action === "serviceProvider") {
        const serviceProviderDetail = await getServiceProviderById(
          toggleData.serviceProviderId,
          tokenData,
          dispatch
        );
        if (
          serviceProviderDetail &&
          serviceProviderDetail.status === "success"
        ) {
          const schedule = parseImage(serviceProviderDetail.data.scheduleData);
          const scheduleData = schedule.map((item) => ({
            day_id: parseInt(item.day_id),
            start_time: parseInt(item.start_time),
            end_time: parseInt(item.end_time),
            branch_id: parseInt(item.branch_id),
          }));
          const requestBody = {
            p_id: toggleData.serviceProviderId,
            p_name: serviceProviderDetail.data.name,
            p_granularity: serviceProviderDetail.data.granularity,
            p_schedule: scheduleData,
            p_isActive: serviceProviderDetail.data.isActive === "1" ? "0" : "1",
            p_IsDeleted: serviceProviderDetail.data.isDeleted,
            p_created_by: serviceProviderDetail.data.createdBy,
            p_modified_by: userId,
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getServiceProviderPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateServiceProviderService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "service") {
        const serviceDetail = await getServiceById(
          toggleData.serviceId,
          tokenData,
          dispatch
        );
        if (serviceDetail && serviceDetail.status === "success") {
          const tagData = serviceDetail.data.tag_ids
            ? serviceDetail.data.tag_ids.split(",").map(Number)
            : null;
          const tagId = tagData
            ? tagData.map((item) => ({
                id: item,
              }))
            : null;
          const categoryData = serviceDetail.data.category_ids
            ? serviceDetail.data.category_ids.split(",").map(Number)
            : null;

          const categoryId = categoryData
            ? categoryData.map((item) => ({
                id: item,
              }))
            : [];

          const subCategoryData = serviceDetail.data.sub_category_ids
            ? serviceDetail.data.sub_category_ids.split(",").map(Number)
            : null;

          const subCategoryId = subCategoryData
            ? subCategoryData.map((item) => ({
                id: item,
              }))
            : [];

          const branchData = serviceDetail.data.branch_ids
            ? serviceDetail.data.branch_ids.split(",").map(Number)
            : null;

          const branchId = branchData
            ? branchData.map((item) => ({
                id: item,
              }))
            : [];

          const imageData = serviceDetail.data.image_data
            ? parseImage(serviceDetail.data.image_data)
            : null;
          const imageRequest = imageData
            ? imageData.map((item) => ({
                id: parseInt(item.id),
                type_id: parseInt(item.type),
              }))
            : [];

          const attributeData = serviceDetail.data.attribute_data
            ? parseImage(serviceDetail.data.attribute_data)
            : [];

          const attributeImageData = serviceDetail.data.attribute_image_data
            ? parseImage(
                JSON.stringify(serviceDetail.data.attribute_image_data)
              )
            : [];

          const attributeImageMap = new Map(
            attributeImageData.map((item) => [item.id, item])
          );
          const mergedAttributeData = attributeData.map((data) => ({
            id: parseInt(data.id),
            value: data.value,
            imageId:
              parseInt(attributeImageMap.get(data.attribute_image).id) || null,
            type_id:
              parseInt(attributeImageMap.get(data.attribute_image).type) ||
              null,
            category_id: parseInt(data.attribute_category),
          }));

          const serviceProviderData = serviceDetail.data.serviceProvider_data
            ? parseImage(
                JSON.stringify(serviceDetail.data.serviceProvider_data)
              )
            : null;

          const serviceProviderRequest = serviceProviderData
            ? serviceProviderData.map((item) => ({
                provider_id: parseInt(item.id),
                price: parseInt(item.price),
              }))
            : [];

          const requestBody = {
            p_id: toggleData.serviceId,
            p_brand_id: serviceDetail.data.brand_id,
            p_name: serviceDetail.data.name,
            p_description: serviceDetail.data.description,
            p_Tags: tagId,
            p_imageIds: imageRequest,
            p_category_ids: categoryId,
            p_sub_category_ids: subCategoryId,
            p_attributeData: mergedAttributeData,
            p_branch_ids: branchId,
            p_serviceProviderData: serviceProviderRequest,
            p_is_active: serviceDetail.data.is_active === "1" ? "0" : "1",
            p_IsDeleted: serviceDetail.data.IsDeleted,
            p_created_by: serviceDetail.data.created_by,
            p_modified_by: userId,
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getServicePageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateServiceService(
              requestBody,
              tokenData,
              "update",
              false,
              pageNumber
            )
          );
        }
      } else if (action === "vendors") {
        dispatch(
          addUpdateVendorService(
            toggleData,
            tokenData,
            "update",
            false,
            pageNumber
          )
        );
      } else {
        Swal.fire({
          title: "Saved!",
          text: "success",
          icon: "success",
          timer: 1500,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          dispatch(action);
        });
      }
    } else if (result.isDenied) {
      Swal.fire({
        title: "Changes are not saved",
        text: "",
        icon: "info",
        timer: 2000,
      });
    }
  });
};

/**
 * Function for Undo Delete
 * @param {*} dispatch
 * @param {*} action
 * @param {*} undoData
 * @param {*} tokenData
 * @param {*} userId
 * @param {*} length
 * @param {*} pageNumber
 */
export const handleUndoDelete = (
  dispatch,
  action,
  undoData,
  tokenData,
  userId,
  length,
  pageNumber
) => {
  Swal.fire({
    title: "Are you sure you want to proceed with undeleting the data?",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Yes",
  }).then(async (result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      //If we update toggle of role page
      if (action === "roles" && tokenData && userId) {
        const roleDetail = await getRoleById(undoData.id, tokenData, dispatch);

        if (roleDetail && roleDetail.status === "success") {
          const roleRequestBody = RoleToggleRequest({
            roleDetail: roleDetail,
            userId: userId,
            isDelete: true,
          });
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getRolePageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateRolesService(
              roleRequestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "users" && tokenData && userId) {
        const userDetail = await getUserById(
          undoData.userId,
          tokenData,
          dispatch
        );

        if (userDetail && userDetail.status === "success") {
          const adminRequestBody = AdminUserToggleRequest({
            toggleData: undoData,
            userDetail: userDetail,
            userId: userId,
            isDelete: true,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getAdminPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateUserService(
              adminRequestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "category" && tokenData && userId) {
        const categoryDetail = await getCategoryById(
          undoData.id,
          tokenData,
          dispatch
        );
        if (categoryDetail && categoryDetail.status === "success") {
          const categoryRequestBody = categoryToggleRequest({
            categoryDetail: categoryDetail,
            toggleData: undoData,
            userId: userId,
            isDelete: true,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getCategoryPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateCategoryService(
              categoryRequestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "subCategory") {
        const subCategoryDetail = await getSubCategoryById(
          undoData.id,
          tokenData,
          dispatch
        );

        if (subCategoryDetail && subCategoryDetail.status === "success") {
          const subCategoryRequestBody = SubCategoryToggleRequest({
            subCategoryDetail: subCategoryDetail,
            userId,
            isDelete: true,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getSubCategoryPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateSubCategoryService(
              subCategoryRequestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "leafCategory") {
        const leafDetail = await getLeafCategoryById(
          undoData.id,
          tokenData,
          dispatch
        );

        if (leafDetail && leafDetail.status === "success") {
          const leafImage = leafDetail.data.image_data
            ? parseImage(JSON.stringify(leafDetail.data.image_data))
            : null;

          const leafImageData = leafImage
            ? leafImage.map((item) => ({
                id: parseInt(item.id),
                type_id: parseInt(item.type),
              }))
            : [];

          const leafRequestBody = {
            p_id: undoData.id,
            p_name: undoData.Title,
            p_description: undoData.Description,
            p_subcategoryId: undoData.SubcategoryId,
            p_isActive: undoData.isActive,
            p_deleted: "0",
            p_createdBy: undoData.createdBy,
            p_modifiedBy: userId,
            p_imageIds: leafImageData,
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getLeafPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateLeafLevelService(
              leafRequestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "tags") {
        const tagRequestBody = TagToggleRequest({
          toggleData: undoData,
          userId: userId,
          isDelete: true,
        });

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getTagPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateTagService(
            tagRequestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "imageManager") {
        const imageRequestBody = {
          images: undoData.imagePath,
          type_id: undoData.imageType,
          created_by: undoData.createdBy,
          modified_by: userId,
          image_ids: [
            {
              id: undoData.imageId,
            },
          ],
          IsDeleted: undoData.isDeleted === "1" ? "0" : "1",
        };
        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getImagePageData(pageNumber + 1));
          }
        }
        dispatch(
          updateImageService(imageRequestBody, tokenData, "undo", pageNumber)
        );
      } else if (action === "brand" || action === "Brand") {
        const brandDetail = await getBrandById(
          undoData.brandId,
          tokenData,
          dispatch
        );

        if (brandDetail && brandDetail.status === "success") {
          const categoryIds = brandDetail.data.category_ids
            ? brandDetail.data.category_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [];

          const subCategoryIds = brandDetail.data.sub_category_ids
            ? brandDetail.data.sub_category_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [];

          const leafIds = brandDetail.data.leaf_level_category_ids
            ? brandDetail.data.leaf_level_category_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [];

          const tagIds = brandDetail.data.tag_ids
            ? brandDetail.data.tag_ids
                .split(",")
                .map((tagId) => parseInt(tagId.trim()))
            : [];

          const brandImage = brandDetail.data.image_data
            ? parseImage(JSON.stringify(brandDetail.data.image_data))
            : null;
          const brandImageData = brandImage
            ? brandImage.map((item) => ({
                id: parseInt(item.id),
                type_id: parseInt(item.type),
              }))
            : [];

          const brandRequestBody = {
            p_id: undoData.brandId,
            p_name: undoData.name,
            p_description: undoData.description,
            p_brand_type_id: brandDetail.data.type,
            p_category_id: categoryIds.map((item) => ({ id: item })),
            p_sub_category_id: subCategoryIds.map((item) => ({ id: item })),
            p_leaf_level_category_id: leafIds.map((item) => ({ id: item })),
            p_imageIds: brandImageData,
            p_tag_id: tagIds.map((item) => ({ id: item })),
            p_isActive: brandDetail.data.is_active,
            p_IsDeleted: "0",
            p_created_by: brandDetail.data.created_by,
            p_modified_by: userId,
            p_brand_code: undoData.brandCode,
            p_food_type: undoData.food_type,
            p_color: {
              mainColor:
                JSON.parse(undoData.color)?.mainColor &&
                JSON.parse(undoData.color)?.mainColor !== ""
                  ? JSON.parse(undoData.color)?.mainColor
                  : null,
              primaryColor:
                JSON.parse(undoData.color)?.primaryColor &&
                JSON.parse(undoData.color)?.primaryColor !== ""
                  ? JSON.parse(undoData.color)?.primaryColor
                  : null,
            },
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getBrandPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateBrandService(
              brandRequestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "product" || action === "Product") {
        const productDetail = await getProductById(
          undoData.productId,
          tokenData,
          dispatch
        );
        if (productDetail && productDetail.status === "success") {
          const requestBody = ProductToggleRequestBody({
            productDetail: productDetail,
            isDelete: true,
            userId: userId,
            toggleData: undoData,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getProductPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateProductService(
              requestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "attributes" || action === "attribute") {
        const requestBody = attributeToggleReqest({
          toggleData: undoData,
          userId: userId,
          isDelete: true,
        });

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getAttributePageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateAttributeService(
            requestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "warehouse") {
        const requestBody = WarehouseToggleRequest({
          toggleData: undoData,
          userId: userId,
          isDelete: true,
        });

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getWarehousePageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateWarehouseService(
            requestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "packager") {
        const requestBody = {
          p_id: undoData.productPackagerId,
          p_name: undoData.name,
          p_address: undoData.address,
          p_type: undoData.type,
          p_pincode: undoData.pincode,
          p_isActive: undoData.is_active,
          p_IsDeleted: "0",
          p_modified_by: userId,
        };
        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getPackagerPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdatePackagerService(
            requestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "discount") {
        const discountDetail = await getDiscountById(
          undoData.discountId,
          tokenData,
          dispatch
        );
        if (discountDetail && discountDetail.status === "success") {
          const requestBody = DiscountToggleRequest({
            discountDetail: discountDetail,
            userId: userId,
            isDelete: true,
          });

          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getDiscountPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateDiscountService(
              requestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "discountCatalogue") {
        const discountCatalogueData = await DiscountCatalogueById(
          undoData.discountId,
          tokenData,
          dispatch
        );
        if (
          discountCatalogueData &&
          discountCatalogueData.status === "success"
        ) {
          const discountCatalogueImage = discountCatalogueData.data.image_data
            ? parseImage(JSON.stringify(discountCatalogueData.data.image_data))
            : null;

          const discountCatalogueImageData = discountCatalogueImage
            ? discountCatalogueImage.map((item) => ({
                id: parseInt(item.id),
                type_id: parseInt(item.type),
              }))
            : [];

          const requestBody = {
            p_id: undoData.discountId,
            p_title: discountCatalogueData.data.title,
            p_discount_type: discountCatalogueData.data.discount_type,
            p_max_discount_amount: discountCatalogueData.data.discount_amount,
            p_discount_type_on: discountCatalogueData.data.discount_type_on,
            p_discount_catlogue_for:
              discountCatalogueData.data.discount_catlogue_for,
            p_start_date: format(
              new Date(discountCatalogueData.data.start_date),
              "yyyy-MM-dd"
            ),
            p_end_date: format(
              new Date(discountCatalogueData.data.end_date),
              "yyyy-MM-dd"
            ),
            p_modified_by: userId,
            p_is_active: discountCatalogueData.data.is_active,
            p_imageIds: discountCatalogueImageData,
            p_IsDeleted: "0",
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getDiscountCataloguePageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateDiscountCatalogueService(
              requestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "branch") {
        const requestBody = BranchToggleRequest({
          toggleData: undoData,
          userId: userId,
          isDelete: true,
        });
        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getBranchPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateBranchService(
            requestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "serviceProvider") {
        const serviceProviderDetail = await getServiceProviderById(
          undoData.serviceProviderId,
          tokenData,
          dispatch
        );
        if (
          serviceProviderDetail &&
          serviceProviderDetail.status === "success"
        ) {
          const schedule = parseImage(serviceProviderDetail.data.scheduleData);
          const scheduleData = schedule.map((item) => ({
            day_id: parseInt(item.day_id),
            start_time: parseInt(item.start_time),
            end_time: parseInt(item.end_time),
            branch_id: parseInt(item.branch_id),
          }));
          const requestBody = {
            p_id: undoData.serviceProviderId,
            p_name: serviceProviderDetail.data.name,
            p_granularity: serviceProviderDetail.data.granularity,
            p_schedule: scheduleData,
            p_isActive: serviceProviderDetail.data.isActive,
            p_IsDeleted: "0",
            p_created_by: serviceProviderDetail.data.createdBy,
            p_modified_by: userId,
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getServiceProviderPageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateServiceProviderService(
              requestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else if (action === "spots") {
        const requestBody = spotToggleRequest({
          toggleData: undoData,
          isDelete: true,
        });
        dispatch(
          addUpdateSpotService(
            requestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "inventory-item") {
        let { quantityInBaseUnit, unit } = convertToBaseUnit(
          undoData.quantity,
          undoData.unit
        );
        const requestBody = {
          p_id: undoData.id,
          p_title: undoData.name,
          p_thresoldvalue: quantityInBaseUnit,
          p_thresoldunit: unit,
          p_isActive:
            undoData.status === "1" || undoData.status === 1 ? "1" : "0",
          p_IsDeleted: "0",
          p_category_id: undoData.category_id,
          p_instruction: undoData.instruction,
        };

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getInventoryItemPageData(pageNumber + 1));
          }
        }

        dispatch(
          addUpdateInventoryItemService(
            requestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "new-vendor-person") {
        const requestBody = {
          p_id: undoData.id,
          p_first_name: undoData.first_name,
          p_last_name: undoData.last_name,
          p_shop_name: undoData.shopName,
          p_mobile_no: undoData.mobile,
          p_category_ids: JSON.parse(undoData.categoryData),
          p_status:
            undoData.status === "1" || undoData.status === 1 ? "1" : "0",
          p_is_wp_allow: undoData.wp_sms_allow,
          p_deleted: "0",
        };

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getNewVendorPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateNewVendorPersonService(
            requestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "units") {
        const requestBody = {
          p_id: undoData.id,
          p_unit_id: undoData.p_unit_id,
          p_isActive:
            undoData.status === "1" || undoData.status === 1 ? "1" : "0",
          p_IsDeleted: "0",
        };

        if (length && length > 1) {
          if (pageNumber && pageNumber > 1) {
            dispatch(getUnitsPageData(pageNumber + 1));
          }
        }
        dispatch(
          addUpdateUnitsService(
            requestBody,
            tokenData,
            "update",
            "undo",
            pageNumber
          )
        );
      } else if (action === "service") {
        const serviceDetail = await getServiceById(
          undoData.serviceId,
          tokenData,
          dispatch
        );

        if (serviceDetail && serviceDetail.status === "success") {
          const tagData = serviceDetail.data.tag_ids
            ? serviceDetail.data.tag_ids.split(",").map(Number)
            : null;
          const tagId = tagData
            ? tagData.map((item) => ({
                id: item,
              }))
            : null;
          const categoryData = serviceDetail.data.category_ids
            ? serviceDetail.data.category_ids.split(",").map(Number)
            : null;

          const categoryId = categoryData
            ? categoryData.map((item) => ({
                id: item,
              }))
            : [];

          const subCategoryData = serviceDetail.data.sub_category_ids
            ? serviceDetail.data.sub_category_ids.split(",").map(Number)
            : null;

          const subCategoryId = subCategoryData
            ? subCategoryData.map((item) => ({
                id: item,
              }))
            : [];

          const branchData = serviceDetail.data.branch_ids
            ? serviceDetail.data.branch_ids.split(",").map(Number)
            : null;

          const branchId = branchData
            ? branchData.map((item) => ({
                id: item,
              }))
            : [];

          const imageData = serviceDetail.data.image_data
            ? parseImage(serviceDetail.data.image_data)
            : null;
          const imageRequest = imageData
            ? imageData.map((item) => ({
                id: parseInt(item.id),
                type_id: parseInt(item.type),
              }))
            : [];

          const attributeData = serviceDetail.data.attribute_data
            ? parseImage(serviceDetail.data.attribute_data)
            : [];

          const attributeImageData = serviceDetail.data.attribute_image_data
            ? parseImage(
                JSON.stringify(serviceDetail.data.attribute_image_data)
              )
            : [];

          const attributeImageMap = new Map(
            attributeImageData.map((item) => [item.id, item])
          );
          const mergedAttributeData = attributeData.map((data) => ({
            id: parseInt(data.id),
            value: data.value,
            imageId:
              parseInt(attributeImageMap.get(data.attribute_image).id) || null,
            type_id:
              parseInt(attributeImageMap.get(data.attribute_image).type) ||
              null,
            category_id: parseInt(data.attribute_category),
          }));

          const serviceProviderData = serviceDetail.data.serviceProvider_data
            ? parseImage(
                JSON.stringify(serviceDetail.data.serviceProvider_data)
              )
            : null;

          const serviceProviderRequest = serviceProviderData
            ? serviceProviderData.map((item) => ({
                provider_id: parseInt(item.id),
                price: parseInt(item.price),
              }))
            : [];

          const requestBody = {
            p_id: undoData.serviceId,
            p_brand_id: serviceDetail.data.brand_id,
            p_name: serviceDetail.data.name,
            p_description: serviceDetail.data.description,
            p_Tags: tagId,
            p_imageIds: imageRequest,
            p_category_ids: categoryId,
            p_sub_category_ids: subCategoryId,
            p_attributeData: mergedAttributeData,
            p_branch_ids: branchId,
            p_serviceProviderData: serviceProviderRequest,
            p_is_active: serviceDetail.data.is_active,
            p_IsDeleted: "0",
            p_created_by: serviceDetail.data.created_by,
            p_modified_by: userId,
          };
          if (length && length > 1) {
            if (pageNumber && pageNumber > 1) {
              dispatch(getServicePageData(pageNumber + 1));
            }
          }
          dispatch(
            addUpdateServiceService(
              requestBody,
              tokenData,
              "update",
              "undo",
              pageNumber
            )
          );
        }
      } else {
        Swal.fire({
          title: "Saved!",
          text: "success",
          icon: "success",
          timer: 1500,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          dispatch(action);
        });
      }
    } else if (result.isDenied) {
      Swal.fire("Data is remain deleted", "", "info");
    }
  });
};

/**
 * Function for cancel click
 * @param {*} route
 * @param {*} navigate
 */
export const handleCancleClick = (route, navigate) => {
  Swal.fire({
    title: "Do you want to cancel the changes?",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.fire({
        title: "Canceled!",
        icon: "success",
        showConfirmButton: true,
        timer: 2000,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(() => {
        if (route === "imageManager") {
          navigate();
        } else {
          navigate(route);
        }
      });
    } else if (result.isDenied) {
      Swal.fire({
        title: "Changes are not saved",
        text: "",
        icon: "info",
        timer: 2000,
      });
    }
  });
};

/**
 * Function for order confirm and reject click
 */
// take from here
export const handleOrderConfirmationClick = (
  orderStatus,
  orderData,
  tokenData,
  dispatch,
  orderPage
) => {
  Swal.fire({
    title:
      orderStatus === "Confirm"
        ? "Are you sure you want to accept the order?"
        : "Are you sure you want to cancel the order?",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      const requestBody = OrderRequestBodyOnToggle({
        item: orderData,
        status: orderStatus === "Confirm" ? "1" : "4",
      });
      dispatch(
        placeOrderService(requestBody, tokenData, "update", false, orderPage)
      );
    }
  });
};

// changes by sakshi
export const handleLineLevelOrderConfirmationClick = (
  productData,
  tokenData,
  dispatch,
  orderPage
) => {
  Swal.fire({
    title: "Are you sure you want to mark Product as delivered?",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      productData = {
        ...productData,
        productStatus: 1,
      };

      const requestBody = {
        p_order_id: productData.orderId,
        p_product_id: productData.productId.toString(),
        map_table_id: productData.map_table_id.toString(),
        p_status: "1",
      };
      // Call the LineLevelPlaceOrderService function with dispatch
      dispatch(
        LineLevelPlaceOrderService(requestBody, tokenData, dispatch, orderPage)
      );
    }
  });
};

const isProductEligible = (product, discountDetails) => {
  const {
    product_ids,
    brand_ids,
    category_ids,
    sub_category_ids,
    leaf_level_category_ids,
  } = discountDetails;
  // Check if the product ID matches
  if (product_ids && product_ids.includes(product.productId.toString())) {
    return true;
  }

  // Check if the brand ID matches
  if (brand_ids && brand_ids.includes(product.brand_id.toString())) {
    return true;
  }

  // Check if the category ID matches
  if (category_ids && category_ids.includes(product.category_ids)) {
    return true;
  }

  // Check if the sub-category ID matches
  if (sub_category_ids && sub_category_ids.includes(product.sub_category_ids)) {
    return true;
  }

  // Check if the leaf level category ID matches
  if (
    leaf_level_category_ids &&
    leaf_level_category_ids.includes(product.leaf_level_category_ids)
  ) {
    return true;
  }
  return false;
};

export const handleLineLevelOrderDeleteClick = (
  productData,
  tokenData,
  dispatch,
  orderData
) => {
  Swal.fire({
    title: "Are you sure you want to remove this product?",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.isConfirmed) {
      const addedProducts = orderData.productData.filter(
        (item) => item.map_table_id !== productData.map_table_id
      );
      const productTax =
        Number(productData.quantity) *
        Number(productData.price) *
        (Number(productData.cgst) / 100 +
          Number(productData.sgst) / 100 +
          Number(productData.igst) / 100 +
          Number(productData.vat) / 100);

      const newTax =
        Math.round(Number(orderData.total_tax_amount)) -
        Math.round(Number(productTax));

      const totalPrice = addedProducts.reduce(
        (sum, product) => sum + product.price * product.quantity,
        0
      );

      if (orderData?.discount_id) {
        getDiscountById(orderData?.discount_id, tokenData, dispatch).then(
          (res) => {
            if (
              res?.status &&
              (res.status === 200 ||
                res.status === "200" ||
                res.status === "success" ||
                res.status === "Status")
            ) {
              let eligibleTotal = 0;
              let totalCartAmount = 0;

              addedProducts.forEach((product) => {
                totalCartAmount += product.price * product.quantity;

                if (isProductEligible(product, res.data)) {
                  eligibleTotal += product.price * product.quantity;
                }
              });

              if (res.data?.spacific_item === "0") {
                if (totalCartAmount >= res.data?.min_cart_value) {
                  const discountAmount =
                    (totalCartAmount * res.data.discount_amount) / 100;
                  let newDiscount = Math.min(
                    discountAmount,
                    res.data.max_discount_ammount
                  );

                  const requestBody = {
                    p_order_id: orderData.id,
                    p_product_id: productData.productId,
                    map_table_id: productData.map_table_id,
                    p_discount_amount: newDiscount,
                    p_total_amount:
                      totalPrice +
                      newTax -
                      newDiscount +
                      Number(orderData.delivery_charge),
                    p_total_tax_amount: newTax,
                    p_discount_id: res.data?.id,
                    p_discount_code: res.data?.code,
                  };
                  deleteLineLevelProduct(requestBody, dispatch, tokenData);
                } else {
                  const requestBody = {
                    p_order_id: orderData.id,
                    p_product_id: productData.productId,
                    map_table_id: productData.map_table_id,
                    p_discount_amount: 0,
                    p_total_amount:
                      totalPrice +
                      newTax -
                      0 +
                      Number(orderData.delivery_charge),
                    p_total_tax_amount: newTax,
                    p_discount_id: 0,
                    p_discount_code: "",
                  };
                  deleteLineLevelProduct(requestBody, dispatch, tokenData);
                  toast.warn(
                    "Applied discount is not applicable for the remaining products!"
                  );
                }
              } else if (eligibleTotal < res.data.min_cart_value) {
                const requestBody = {
                  p_order_id: orderData.id,
                  p_product_id: productData.productId,
                  map_table_id: productData.map_table_id,
                  p_discount_amount: 0,
                  p_total_amount:
                    totalPrice + newTax - 0 + Number(orderData.delivery_charge),
                  p_total_tax_amount: newTax,
                  p_discount_id: 0,
                  p_discount_code: "",
                };
                deleteLineLevelProduct(requestBody, dispatch, tokenData);
                toast.warn(
                  "Applied discount is not applicable for the remaining products!"
                );
              } else {
                const discountAmount =
                  (eligibleTotal * res.data?.discount_amount) / 100;
                let newDiscount = Math.min(
                  discountAmount,
                  res.data.max_discount_ammount
                );
                const requestBody = {
                  p_order_id: orderData.id,
                  p_product_id: productData.productId,
                  map_table_id: productData.map_table_id,
                  p_discount_amount: newDiscount,
                  p_total_amount:
                    totalPrice +
                    newTax -
                    newDiscount +
                    Number(orderData.delivery_charge),
                  p_total_tax_amount: newTax,
                  p_discount_id: res.data?.id,
                  p_discount_code: res.data?.code,
                };
                deleteLineLevelProduct(requestBody, dispatch, tokenData);
              }
            } else {
              const addedProducts = orderData.productData.filter(
                (item) => item.productId !== productData.productId
              );
              const productTax =
                Number(productData.quantity) *
                Number(productData.price) *
                (Number(productData.cgst) / 100 +
                  Number(productData.sgst) / 100 +
                  Number(productData.igst) / 100 +
                  Number(productData.vat) / 100);

              const newTax =
                Math.round(Number(orderData.total_tax_amount)) -
                Math.round(Number(productTax));

              const totalPrice = addedProducts.reduce(
                (sum, product) => sum + product.price * product.quantity,
                0
              );
              const requestBody = {
                p_order_id: orderData.id,
                p_product_id: productData.productId,
                map_table_id: productData.map_table_id,
                p_discount_amount: 0,
                p_total_amount:
                  totalPrice + newTax - 0 + Number(orderData.delivery_charge),
                p_total_tax_amount: newTax,
                p_discount_id: 0,
                p_discount_code: "",
              };
              deleteLineLevelProduct(requestBody, dispatch, tokenData);
              toast.warn(
                "Applied discount is not applicable for the remaining products!"
              );
            }
          }
        );
      } else {
        const requestBody = {
          p_order_id: orderData.id,
          p_product_id: productData.productId,
          map_table_id: productData.map_table_id,
          p_discount_amount: 0,
          p_total_amount:
            totalPrice + newTax - 0 + Number(orderData.delivery_charge),
          p_total_tax_amount: newTax,
          p_discount_id: 0,
          p_discount_code: "",
        };
        deleteLineLevelProduct(requestBody, dispatch, tokenData);
      }
    }
  });
};

export const handleViewOrderQuantity = async (tokenData, dispatch) => {
  return viewOrdersService(tokenData, dispatch);
};

/**
 * Function for order logout click
 * @param {*} dispatch
 * @param {*} navigate
 * @param {*} store
 */
export const handleLogoutClick = (dispatch, navigate, store) => {
  Swal.fire({
    title: "Do you want to logout?",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.fire({
        title: "Successfully Logged out!",
        icon: "success",
        showConfirmButton: true,
        timer: 2000,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then(async () => {
        await persistStore(store, { storage }).purge();
        dispatch(logout());
        dispatch(clearSidebarData());
        navigate("/login");
      });
    } else if (result.isDenied) {
      Swal.fire({
        title: "Something went wrong, please try again!",
        text: "",
        icon: "info",
        timer: 2000,
      });
    }
  });
};

/**
 * Alert message component
 */
export default function Alertmessage() {
  return (
    <>
      <Button
        type="submit"
        name="Alert"
        Classname="btn login-btn"
        onClick={handleErrorClick}
      />
    </>
  );
}
