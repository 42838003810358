const inventoryFilterInitialState = {
    productCode: '',
    sku: '',
    warehouse: '',
    name: '',
    isActive: '',
    isDelete: ''
};

/**
 * Store inventory filtered data
 * @param {*} state 
 * @param {*} action 
 * @returns {*}
 */
export const inventoryFilterReducer = (state = inventoryFilterInitialState, action) => {
    switch (action.type) {
        case 'GET_INVENTORY_FILTER':
            return {
                ...state,
                productCode: action.payload.productCode,
                sku: action.payload.sku,
                warehouse: action.payload.warehouse,
                name: action.payload.name,
                isActive: action.payload.isActive,
                isDelete: action.payload.isDelete
            }
        case 'CLEAR_INVENTORY_FILTER':
            return inventoryFilterInitialState;
        default:
            return state;
    };
}